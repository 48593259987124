.feature_sec_page_new{position: relative;padding: 30px 0;}
.feature_sec_page_new_head{position: relative;text-align: center;margin-bottom: 80px;}
.feature_sec_page_new_head h6{position: relative;color: #2B2B2B;font-size: 50px;font-weight: 700;line-height: inherit;}
.feature_sec_page_new_head h6 span{color: #1865C1;position: relative;}
.feature_sec_page_new_head h6 span::after{ background: url(../../assets/images/homepage_line.svg);position: absolute; content: "";left: 0;background-repeat: no-repeat;width: 100%;height: 38px;left: 150px;top: 60px;}

.feature_sec_page_new_body{position: relative;}
.feature_sec_page_new_body_single{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 60px;width: 80%;margin: 0 auto;align-items: center;margin-bottom: 80px;}
.feature_sec_page_new_body_single:last-child{margin-bottom: 0px;}
.feature_sec_page_new_body_single_img{position: relative;display: flex;align-items: center;flex-direction: column;box-shadow: 0px 13.31839656829834px 46.61438751220703px 0px #00000014;background: #FFFFFF;padding: 50px;text-align: center;border-radius: 10px;}
.feature_sec_page_new_body_single_img img{width: 130px;margin: 0 auto 20px;}
.feature_sec_page_new_body_single_img h6{position: relative;color: #000000;font-size: 27px;font-weight: 600;margin-bottom: 20px;}
.feature_sec_page_new_body_single_img p{position: relative;margin-bottom: 0px;color: #000000;font-size: 14px;font-weight: 400;}

.feature_sec_page_new_body_single_cont{position: relative;}
.feature_sec_page_new_body_single_cont ul{display: block;position: relative;}
.feature_sec_page_new_body_single_cont ul li{display: block;position: relative;margin-bottom: 25px;}
.feature_sec_page_new_body_single_cont ul li:last-child{margin-bottom: 0px;}
.feature_sec_page_new_body_single_cont ul li h6{position: relative;color: #3338FF;font-size: 24px;margin-bottom: 10px;line-height: inherit;font-weight: 500;}
.feature_sec_page_new_body_single_cont ul li p{position: relative;color: #000000;font-size: 16px;font-weight: 400;margin-bottom: 0px;}
.feature_sec_page_new_body_single_cont ul li:before { content: ''; position: absolute; left: -20px;top: 10px; height: 10px;
    width: 10px; background: #3338FF; border-radius: 50%;}
.ai_interview ul li h6{color: #5D089A;}
.ai_interview ul li:before{background: #5D089A;}   

.ai_hring ul li h6{color: #1490B4;}
.ai_hring ul li:before{background: #1490B4;}  

.features_transfrom_inner{position: relative;padding: 40px 0;}
.features_transfrom_inner_sec_new_inner{position: relative;text-align: center;width: 80%; margin: 0 auto;}
.features_transfrom_inner_sec_new_inner h6{position: relative;color: #000000;font-size: 30px;font-weight: 400;margin-bottom: 35px;line-height: inherit;}
.features_transfrom_btn{position: relative;}
.features_transfrom_btn button{position: relative;display: inline-block;width: 212px;height: 55px;background: #fff;color: #000;border: 2px solid #1865C1;border-radius: 30px;font-size: 18px;font-weight: 500;}

.new_esay{margin-bottom: 140px;margin-top: 50px;}
.text-left span{font-weight: 900;font-size: 26px;}
.new_esay .text-left{font-size: 20px;}


.get_in_touch{position: relative;padding: 20px 0 60px 0;}
.get_in_touch_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 30px;}
.get_in_touch_inner_left{position: relative;box-shadow: 0px 11px 38.5px 0px #00000014;background: #fff;padding: 30px;border-radius: 10px;}
.get_in_touch_inner_left h6{position: relative;color: #E97810;font-size: 30px;font-weight: 500;margin-bottom: 20px;}
.get_in_touch_inner_left h5{position: relative;color: #111019;font-size: 40px;font-weight: 700;margin-bottom: 25px;}
.get_in_touch_inner_left p{position: relative;color: #2F2F2F;font-size: 22px;font-weight: 400;border-bottom: 1px solid #B6B6B6;padding-bottom: 10px;margin-bottom: 30px;}
.get_in_touch_inner_left_form_sec{position: relative;}
.get_in_touch_inner_left_form_sec_grid{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 25px;}
.get_in_touch_inner_left_form_sec_single{position: relative;margin-bottom: 25px;}
.get_in_touch_inner_left_form_sec_single label{position: relative;color: #3D4852;font-size: 18px;font-weight: 500;margin-bottom: 8px;display: block;}
.get_in_touch_inner_left_form_sec_single input{position: relative;width: 100%;height: 44px;background: #F5F5F5;color: #333;font-size: 15px;font-weight: 400;border: none;border-radius: 8px;padding-left: 20px;}
.get_in_touch_inner_left_form_sec_single textarea{position: relative;width: 100%;height: 44px;background: #F5F5F5;color: #333;font-size: 15px;font-weight: 400;border: none;border-radius: 8px;padding: 15px;}
.get_in_touch_inner_left_form_sec_single select{position: relative;width: 100%;height: 44px;background: #F5F5F5;color: #333;font-size: 15px;font-weight: 400;border: none;border-radius: 8px;padding-left: 20px;}

.get_in_touch_btn_sec{position: relative;text-align: center;}
.get_in_touch_btn_sec button{position: relative;width: 115px;height: 45px;border-radius: 30px;background: #1865C1;color: #fff;font-size: 16px;font-weight: 600;border: none;}
.get_in_touch_inner_right{position: relative;}
.get_in_touch_inner_right_img{position: relative;margin-bottom: 30px;text-align: center;}
.get_in_touch_inner_right_img img{width: auto;}
.get_in_touch_inner_right_single{position: relative;background: #fff;box-shadow: 0px 11px 38.5px 0px #00000014;height: 85px;width: 100%;display: flex;align-items: center;border-radius: 10px;padding: 10px 50px;gap: 30px;margin-bottom: 25px;}
.get_in_touch_inner_right_single span{width: 60px;height: 60px;background: #DBF0FF;border-radius: 10px;display: flex;align-items: center;justify-content: center;}
.get_in_touch_inner_right_single span i{color: #0E9FB1;font-size: 22px;padding: 36px;}
.get_in_touch_inner_right_single a{color: #000;font-size: 22px;font-weight: 500;}
.get_in_touch_inner_right_single_location {color: #000;font-size: 14px;font-weight: 500;}
.get_in_touch_inner_right_single:last-child{margin-bottom: 0px;}
.address-one{
font-size: 18px !important;
}
.midle_bg:before{content: '';position: absolute;left: 0;top: 0;width: 38px;height: 145px;background-size: cover;background: url(../../assets/images/bg-bg-new.svg); left: -225px;background-repeat: no-repeat;}
.btm_bg:after{content: '';position: absolute;left: auto;top: 0;width: 38px;height: 145px;background-size: cover;background: url(../../assets/images/bg-bg-new.svg); right: -225px;background-repeat: no-repeat;}
.btm_bg:before{content: '';position: absolute;left: -260px;top: 0;width: 90px;height: 38px;background-size: cover;background: url(../../assets/images/gp-img-new.svg);background-repeat: no-repeat;}

.get_touch span.error{color: rgb(217, 72, 47);font-size: 12px;font-weight: 500;}



.ai_solution_haire{position: relative;margin: 0 0 30px 0;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);padding: 30px 0;}
.ai_solution_haire_inner{position: relative;}
.ai_solution_haire_inner_head{position: relative;text-align: center;margin-bottom: 50px !important;}
.ai_solution_haire_inner_head h4{position: relative;margin-bottom: 0px !important;line-height: inherit;font-size: 50px;color: #fff;font-weight: 500;}
.ai_solution_haire_inner_body{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 60px;padding-bottom: 60px;}
.ai_solution_haire_inner_body_left{position: relative;}
.ai_solution_haire_inner_body_left_single{position: relative;display: flex;align-items: flex-start;gap: 20px;margin-bottom: 35px;}
.ai_solution_haire_inner_body_left_single span{position: relative;display: flex;align-items: center;justify-content: center;width: 36px;height: 36px;border-radius: 50%;background: #fff;color: #000;font-size: 18px;font-weight: 600;flex: 0 0 36px;}
.ai_solution_haire_inner_body_left_single_cont h6{position: relative;font-size: 24px;font-weight: 700;color: #fff;margin-bottom: 10px !important;line-height: inherit;}
.ai_solution_haire_inner_body_left_single_cont p{position: relative;font-weight: 400;font-size: 16px;color: #fff;margin-bottom: 0px !important;}
.ai_solution_haire::before{content: '';position: absolute;left: 0;top: 0;background: url(../../assets/images/mis_frame.svg);width: 38px; height: 105px;background-size: cover;background-position: center center;}
.ai_solution_haire::after{content: '';position: absolute;right: 5px;bottom: -50px;background: url(../../assets/images/mis_frame-1.svg);width: 38px; height: 105px;background-size: cover;background-position: center center;}



.embacre_sec_new{position: relative;margin: 80px 0 20px 0;}
.embacre_sec_new_inner{position: relative;box-shadow: 0px 4px 71px 0px #00000014;background: #fff;padding: 80px 150px;display: flex;align-items: center;gap: 100px;}
.embacre_sec_new_inner_left{position: relative;flex: 0 0 400px;display: flex;}
.embacre_sec_new_inner_left h5{background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;font-size: 50px;font-weight: 900;margin-bottom: 0px !important;line-height: inherit;}
.embacre_sec_new_inner_right{position: relative;}
.embacre_sec_new_inner_right p{position: relative;font-size: 24px;font-weight: 400;margin-bottom: 20px !important;}







@media only screen and (min-width:768px) and (max-width:991px) {

.feature_sec_page_new_head h6 span::after{ left: 0;top: 40px;}
.feature_sec_page_new_body_single{grid-template-columns: 1fr;}
.new_esay {margin-bottom: 40px; margin-top: 10px;}
.feature_sec_page_new_head h6{font-size: 30px;}




}



@media only screen and (max-width:767px) {

.feature_sec_page_new { padding: 20px 0;}
.feature_sec_page_new_head {margin-bottom: 20px;}
.feature_sec_page_new_body_single { grid-template-columns: 1fr;grid-gap: 20px;width: 100%;margin-bottom: 20px;}
.feature_sec_page_new_head h6{font-size: 16px;}
.feature_sec_page_new_body{padding-left: 0px;}
.feature_sec_page_new_body_single_cont ul li h6{font-size: 16px;padding-left: 15px;}
.feature_sec_page_new_body_single_cont ul li:before {left: 0px;top: 8px; height: 6px;width: 6px;}
.feature_sec_page_new_body_single_cont ul li p{font-size: 14px;}
.feature_sec_page_new_body_single_cont ul li{margin-bottom: 15px;}
.feature_sec_page_new_body_single_img{padding: 15px;}
.feature_sec_page_new_body_single_img h6 {font-size: 20px;margin-bottom: 10px;}
.feature_sec_page_new_body_single_img p{font-size: 12px;}
.features_transfrom_inner {padding: 0 0 20px 0;}
.features_transfrom_inner_sec_new_inner{width: 100%;}
.features_transfrom_inner_sec_new_inner h6 {font-size: 14px;margin-bottom: 10px;}
.new_esay {margin-bottom: 30px;margin-top: 10px;}
.feature_sec_page_new_head h6 span::after{display: none;}

.ai_solution_haire_inner_head h4{font-size: 30px;}
.ai_solution_haire::before{display: none;}
.ai_solution_haire::after{display: none;}
.ai_solution_haire_inner_body {grid-template-columns: 1fr;grid-gap: 30px;padding-bottom: 30px;}





}