.Email_popup_wrapper{
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  width: 90%;
}
.email_input{
  width: 100%;
  border: 0;
  height: 42px;
  padding: 9px 0px 9px 17px;
  border-radius: 4px;
  background: rgba(248, 248, 248, 1);

}
.login_btn_blue_enable{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(11, 74, 150, 1);
  border-radius: 30px;
  color: white;
  align-items: center;
  height: 51px;
  font-weight: 500;
  margin-top: 27px;
  font-size: 16px;
  border: 0;
}
.login_btn_blue_disable{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(179, 179, 179, 1);
  border-radius: 30px;
  color: white;
  margin-top: 27px;
  align-items: center;
  height: 51px;
  font-weight: 500;
  font-size: 16px;
  border: 0;
}
.verification-modal-subtitle {
  font-size: 12px;
  font-weight: 400;
}

.verification-modal-email {
  font-weight: 700;
}

.verification-modal-cta {
  cursor: pointer;
  font-weight: 700;
  color: rgba(24, 101, 193, 1);
}

.resend_otp_btn_new {
  color: rgba(24, 101, 193, 1);
  cursor: pointer;
}

.resend_otp_btn_new:disabled {
  color: #7b8389;
}

.verification-container{
  
  h6{
    color: rgba(47, 47, 47, 1);
    font-weight: 700;
    font-size: 25px;
    line-height: 33.75px;
    text-align: center;
  }
}






