
.spacer1px {clear:both;line-height:0;font-size:0;overflow:hidden;height:1px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer5px {clear:both;line-height:0;font-size:0;overflow:hidden;height:5px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer15px {clear:both;line-height:0;font-size:0;overflow:hidden;height:15px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer10px {clear:both;line-height:0;font-size:0;overflow:hidden;height:10px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer20px {clear:both;line-height:0;font-size:0;overflow:hidden;height:20px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer30px {clear:both;line-height:0;font-size:0;overflow:hidden;height:30px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer50px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer80px {clear:both;line-height:0;font-size:0;overflow:hidden;height:80px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer60px {clear:both;line-height:0;font-size:0;overflow:hidden;height:60px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer100px {clear:both;line-height:0;font-size:0;overflow:hidden;height:100px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}

.head_sec_pro_rect {
  position: relative;
  padding: 12px 0 20px 0;
}
.head_sec_pro_rect_inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.head_sec_pro_rect_inner_logo {
  position: relative;
  /* flex: 0 0 130px; */
  /* flex: 0 0 165px; */
  flex:0 0 auto;
  left: -15px;
}
.head_sec_pro_rect_inner_logo img {
  width: 100%;
  object-fit: cover;
}
.hammenu {
  display: none; /* Hide hamburger menu by default */
}

/* Responsive menu hidden by default */
.responsive_menu {
  opacity: 0;
  display: none;
  position: fixed;
  top: 10px;
  right: -270px;
  left: auto;
  width: 75%;
  height: auto;
  background-color: #ECF5FF;
  z-index: 1000;
  overflow-y: hidden;
  padding: 42px 15px 42px 15px;
  text-align: center;
  border-radius: 30px;
  box-shadow: 0px 4px 13.399999618530273px 0px #00000040;
  transition: 0.5s ease-in-out;
}
/*Pricing Section Start*/
.highlighted .card {
  transform: scale(1.05);
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #1865C1;
  height: 450px;
}
.card {
  border: none;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-body {
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 15px;
  text-align: left;
}
.home_banner_sec_new {
  position: relative;
  padding: 60px 0 20px 0;
}
.home_banner_sec_new_inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
}
.home_banner_sec_new_inner_left {
  position: relative;
  z-index: 11;
}
.home_banner_sec_new_inner_left h6 {
  position: relative;
  color: #2b2b2b;
  font-weight: 700;
  font-size: 65px;
  margin-bottom: 15px;
}
.home_banner_sec_new:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/banner-bg.svg);
  background-size: cover;
  height: 155px;
  background-position: center center;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: -1;
}
.typewrite {
  position: relative;
  background: linear-gradient(124.13deg, #1fb8c3 18.04%, #7977f7 86.09%);
  font-size: 70px;
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
  line-height: 90px;
}
.main_h1 {
  position: relative;
  margin-bottom: 0px;
}
.home_banner_sec_new_inner_left p {
  position: relative;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 400;
  color: #000;
  max-width: 500px;
}
.get_start_btn {
  position: relative;
  border: 2px solid #1865c1;
  border-color: #1865c1;
  color: #020202;
  font-weight: 500;
  font-size: 19px;
  padding: 13px 40px;
  border-radius: 50px;
  transition: 0.6s ease-in-out;
}
.ctabtn_main_cls{
  width: 190px;
  height: 53px;
  display: block;
  margin: 0px auto 0 0;
  overflow: hidden;
  top: 10px;
  position: relative;
  line-height: 53px;
}
.get_start_btn:focus{ border: 2px solid #1865c1;}
.get_start_btn:after {
  content: "";
  /* background: #1865c1; */
  background-image: url(../../assets/images/btn-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  border-radius: 50px;
  position: absolute;
  z-index: -1;
  transition: 0.8s ease-in-out;
  opacity: 0;
  transform: translate(0px, 60px);
  
  
}
.get_start_btn:hover {
  color: #fff;
  /* */
  transition: 0.8s ease-in-out;
 
}
.get_start_btn:hover:after {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  transition: 0.8s ease-in-out;
}
.get_start_btn:focus{outline: inherit;}

.home_banner_sec_new_right {
  position: relative;
  text-align: center;
  margin: 0 auto;
}
.home_banner_sec_new_right_bg {
  position: relative;
  width: 475px;
  height: 475px;
  background: #ffffff;
  box-shadow: 0px 67px 138px 0px #00000012;
  border-radius: 50%;
  box-shadow: 0px 0px 138px 20px #00000012;
}
.home_banner_sec_new_right_bg_img {
  position: relative;
  width: 600px;
  height: auto;
  left: -90px;
  top: 50px;
}
.home_banner_sec_new_right_bg_img img {
  width: 100%;
  object-fit: cover;
}
.people_hired_sec {
  position: absolute;
  width: 280px;
  height: 75px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 12.514432907104492px 10.01154613494873px 0px #00000009;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -100px;
  top: -40px;
  z-index: 11;
}
.card-title {
  margin-bottom: 20px;
  color: black;
  font-size: 28px;
}

.price {
  font-size: 18px;
  font-weight: 800;
}

.description {
  margin-bottom: 14px;
}

.features {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
  list-style-type: disc;
  margin-left: 34px;
}

.features li {
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
}

.btn-primary {
  border-radius: 20px;
}
.buy_now{
    margin-bottom: 20px;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #1865C1;
}

/*Pricing Section End*/
/* Testimonials section start */
.testimonials {
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
  padding: 40px;
  position: relative;
}

.testimonial {
  padding: 20px;
  outline: none;
  margin-bottom: 35px;
}
.testimonial-info {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.star-rating {
  color: gold;
  font-size: 20px;
}

.star-rating span {
  cursor: pointer;
}

.slick-center .testimonial {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.slick-slide {
  transition: opacity 0.3s ease;
}
.testimonial-info {
    position: relative;
    margin-top: -45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* top: 194px; */
    z-index: 1111;
}
/* Testimonials section end */

/* Dev ui FAQ start */

.faq_section {
  background-image: linear-gradient(124.13deg, #1fb8c3 18.04%, #7977f7 86.09%);
}
.question-box {
  max-width: 500px;
  min-height: 430px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.question-input textarea {
  width: 100% !important;
  padding: 15px;
padding-right: 50px !important;
  overflow: hidden;
  margin-bottom: 10px;
  text-wrap: wrap;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  resize: none;
}

.answer-placeholder{
  color: #3E3E3E;
  font-size: 18px;
  font-weight: 500;
  display: flex;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    top: 0px;
    position: relative;
}
.answer-placeholder span{font-weight: 800;}
.answer-placeholder p{position: relative;margin: 0px;}


.faq_questions_accordion {
  padding: 70px 18px;
}
.accordion-button:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none;
}

.accordion {
  background-image: #d6e6ff33;
}

#company_main_container .accordion-item{background: #fff;padding: 20px 30px;}
/* Dev ui FAQ end */



/*Dev ui footer css start */
.footer_sec_pro_rect {
  background: #f7faff;
  position: relative;
}
.footer_terms_condition{position: absolute;right: 20px;bottom: 15px;}
.footer_main_wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  padding: 50px 0px 25px 0;
}
.footer_main_wrapper h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
}
.footer_main_wrapper p {
  font-size: 14px;
}
.footer_main_wrapper .footer_main_wrapper_terms {
  display: flex;
  justify-content: space-between;
}
.footer_resources_section ul li,
.footer_about_section ul li {
  padding-bottom: 20px;
  font-size: 14px;
}
.footer_resources_section ul li a,
.footer_about_section ul li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.footer_main_article {
  width: 300px;
}
.Term{
  cursor: pointer;
  color: #007bff;
}
.footer_main_wrapper_terms {
  display: flex;
  justify-content: space-between;
  
}
.footer_subscribe_section {
  display: flex;
  justify-content: space-between;
  gap:15px;
  position: relative;
}
button.footer_subscribe_button {
  border-radius: 60px;
  background: #1865c1;
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  border: none;
}
button.footer_subscribe_button:hover {
  background: white;
  color: #1865c1;
  border: 1px solid #1865c1;
}
.footer_subscribe_section input {
  padding: 6px 10px;
  border: none;
  width: 100%;
  border-radius: 50px;
}
input::placeholder {
  color: #a8adb7;
  font-size: 14px;
}
.footer_main_wrapper_terms p {
  font-size: 14px;
}

.social-icons {
  display: flex;
  justify-content: space-around;
}

.social-icon {
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  margin: 0 5px;
  background-color: #bfbcbc66;
  color: #333;
  font-size: 20px;
}

.social-icon:hover {
  color: #fff;
  background-color: #000;
  text-decoration: none;
}
.social-icons {
  display: flex;
  justify-content: space-between;
  width: 160px;
}

.country_seletor{
  width: fit-content;
  max-width: 200px;
  font-size:x-small;
  font-weight: 900;
  border: none;
  background-color: #f7fafe;
  border-color: none;
}

/*Dev ui footer css  end*/

.head_sec_pro_rect_inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.head_sec_pro_rect_inner_menu {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
}
.head_sec_pro_rect_inner_menu ul {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 60px;
}
.head_sec_pro_rect_inner_menu ul li {
  display: inline-block;
  position: relative;
}
.head_sec_pro_rect_inner_menu ul li a {
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: #000;
  font-size: 18px;
  text-decoration: none;
}
.sign_up_btn_new {
  position: relative;
  display: inline-block !important;
  padding: 12px 40px;
  background: #1865c1;
  border-radius: 100px;
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: none;
}

.gradient-button {
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 90px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(94.39deg, #5094E7 50%, #0B4A96 103.69%);
}


.responsive-book-btn{
  display: none !important;
}
.home_banner_sec_new:after {
  position: absolute;
  content: "";
  background: url(../../assets/images//banner-bg.svg);
  background-size: cover;
  height: 155px;
  background-position: center center;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: -1;
}
.typewrite {
  position: relative;
  background: linear-gradient(124.13deg, #1fb8c3 18.04%, #7977f7 86.09%);
  font-size: 70px;
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 25px;
  line-height: 90px;
}
.main_h1 {
  position: relative;
  margin-bottom: 0px;
}



.people_hired_sec {
  position: absolute;
  width: 280px;
  height: 75px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 12.514432907104492px 10.01154613494873px 0px #00000009;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -100px;
  top: -40px;
  z-index: 11;
}
.hire_img {
  width: 40px;
}

.people_hired_sec_cont_main {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.people_hire_text {
  position: relative;
}
.people_hire_text h6 {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 5px;
}
.ple_star_icn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  color: #8a898e;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}
.ple_star_icn i {
  color: #febb43;
  font-size: 14px;
  font-weight: 600 !important;
}
.ple_star_icn span {
  font-size: 12px;
  color: #000000;
  font-weight: 700;
}
.client_trusted_sec {
  position: absolute;
  background: #ffffff;
  width: 220px;
  height: 85px;
  padding: 13px;
  z-index: 11;
  right: -80px;
  border-radius: 16px;
  top: -10px;
}
.client_trusted_sec_cont {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}
.client_trusted_sec_cont h5 {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0px;
}
.client_trust_inner_part {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.client_trust_inner_part img {
  width: auto;
}
.client_trust_inner_part span {
  color: #516371;
  font-size: 12px;
  font-weight: 500;
}
.client_trust_inner_part span i {
  color: #ff8933;
  font-size: 22px;
}

.people_hired_sec_down {
  position: absolute;
  width: 190px;
  height: 62px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 12.514432907104492px 10.01154613494873px 0px #00000009;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -95px;
  top: auto;
  z-index: 11;
  bottom: 40px;
}
.people_hired_sec_down_cont_main {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center;
}
.candidate_down_right {
  position: absolute;
  box-shadow: 0px 100px 75.19999694824219px 0px #00000012;
  background: #fff;
  width: 220px;
  height: 180px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 20px;
  right: -70px;
  z-index: 11;
  bottom: -90px;
}
.candidate_down_right_inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  text-align: left;
}
.candidate_down_right_inner img {
  width: auto;
  object-fit: cover;
}
.candidate_down_right_inner span h4 {
  position: relative;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.candidate_down_right_inner span p {
  position: relative;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #202430;
}

.trusted_partne_sec_main {
  position: relative;
  padding: 30px 0 30px 0;
  background: url(../../assets/images/trsted-bg-after.svg);
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  top: 50px;
}
.trusted_partne_logo {
  position: relative;
  padding: 50px 0 0 0px;
  background: url(../../assets/images/trsted-bg-logo.svg);
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0px;
}
.trusted_partne_logo_slide {
  position: relative;
  text-align: center;
}
.trusted_partne_logo_slide h6 {
  position: relative;
  color: #7e7e7e;
  font-size: 24px;
  margin-bottom: 35px !important;
  font-weight: 500;
}

/* devesh ui Quick-step */
.Quick_steps_getting_job {
  padding: 100px 0px;
  /* margin-top: 350px; */
}
.Quick_steps-right {
  display: flex;
  gap: 50px;
  padding: 25px 50px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
  position: relative;
  align-items: center;
}
.Quick_steps-right-head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Quick_steps-right-head p {
  padding: 5px 10px 5px 100px;
  font-size: 22px;
  font-weight: 700;
}
.forrecruiters::after{
  background: url(../../assets/images/homepage_line_red.svg);
  position: absolute;
  content: "";
  left: 0;
  background-repeat: no-repeat;
  width: 235px;
  height: 38px;
  left: 82px;
  top: 65px;
}
.forcandidates::after{
  background: url(../../assets/images/homepage_line_redblue.svg);
  position: absolute;
  content: "";
  left: 0;
  background-repeat: no-repeat;
  width: 235px;
  height: 38px;
  left: 82px;
    top: 65px;
}
.Quick_steps-right-head h6 {
  font-size: 22px;
}
.Quick_steps-right-inner {
  text-align: end;
  position: relative;
  margin-bottom: 10px;
}
/* .Quick_steps-right::before {
    position: absolute;
    content: "1";
    background-size: cover;
    background-position: center center;
    z-index: -1;
    top: 50px;
    left: -100px;
    color:#FFF;
    font-size: 18px;
    padding: 15px;
    background: #1865C1;;
    border-radius: 100%;
} */
.Quick_steps_getting_job_img,
.Quick_steps_getting_job_img2 {
  margin-top: 80px;
}
.Quick_steps_getting_job_img2 {
  float: right;
  clear: both;
}

.ansbtn:disabled {
  background-color: #cccccc; /* Example gray color for disabled state */
  color: #666666; /* Example darker text color for better contrast */
  border-color: #cccccc; /* Example border color for disabled state */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
}

.ansbtn {
  height: 35px;
  width: 35px;
  background-color: #1865c1;
  color: #fff;
  border: none;
  position: absolute;
  right: 10px;
  top: 8px;
}
.question-box h4 {
  color: #1865c1;
}
#accordionFlushExample {
  background-color: transparent !important;
}
.accordion-button:not(.collapsed) {
  background: transparent !important;
}
/* .accordion-button {
    background: transparent !important;
} */
.accordion-button {
  opacity: 0.5; /* 50% opacity */
  background: rgba(214, 230, 255, 0.2);

  /* White color with 50% transparency */
}
blockquote {
    padding: 40px 20px;
    background: #f7faff;
    border-radius: 24px;
    margin-top: 36px;
    position: relative;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.08);
}
img.qoutes_img {
    position: absolute;
    top: -24px;
    width: 100%;
    left: -10px;
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
.slick-slide {
  padding: 0 15px; /* Adjust the value to control the gap size */
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  position: absolute;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: -224px;
  /* width: 147px; */
}
.testimonial-info p {
  font-weight: 700;
}

.future_hiring_easy_main {
  position: relative;
}
.border-corner{
  position: absolute;
  right: 90px;
  top: -38px;
}
.future_hiring_easy_head.text-center.d-flex {
  background: var(
    --AI-Generation-colour,
    linear-gradient(124deg, #1fb8c3 18.04%, #7977f6 86.09%)
  );
  width: 80%;
  margin: 0 auto;
  padding: 60px;
  position: relative;
}
.future_hiring_easy_head h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 900;
  text-align: left;
}
.future_hiring_easy_right p {
  font-size: 16px;
  color: #fff;
}
.explore_btn {
  border-radius: 60.922px;
  border: 1px solid #1865c1;
  background: #fff;
  padding: 8px 30px;
  font-size: 15px;
  font-weight: 500;
}
/* devesh ui */
.trusted_partne_sec_main {
  position: relative;
  padding: 30px 0 0px 0;
  background: url(../../assets/images/trsted-bg-after.svg);
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  top: 50px;
}
.trusted_partne_logo {
  position: relative;
  padding: 50px 0 10px 0px;
  background: url(../../assets/images/trsted-bg-logo.svg);
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0px;
}
.trusted_partne_logo_slide {
  position: relative;
  text-align: center;
}


.slide_logo_img_sec {
  position: relative;
}
.slide_logo_img_sec img {
  /* width: 100% !important; */
  margin: 0 auto;
  height: 50px !important;
}
.trusted_logo_slide_owl {
  position: relative;
  width: 55%;
  margin: 0 auto;
 
}
.remark_sec_new {
  position: relative;
  padding: 75px 0 0px 0;
}
.remark_sec_new_inner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0px;
  width: 90%;
  margin: 0 auto;
}
.remark_sec_new_inner_single {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 40px;
  justify-content: center;
  text-align: center;
  transition: 0.5s ease-in-out;
}
.remark_sec_new_inner_single h6 {
  position: relative;
  color: #1865c1;
  font-size: 40px;
  font-weight: 700;
  margin: 0px;
}
.remark_sec_new_inner_single p {
  position: relative;
  margin: 0px;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
}
.remark_sec_new_inner_single:hover {
  background: linear-gradient(124.13deg, #1fb8c3 18.04%, #7977f7 86.09%);
  box-shadow: 0px 4px 32.900001525878906px 0px #00000040;
}
.remark_sec_new_inner_single:hover h6 {
  color: #fff;
}
.remark_sec_new_inner_single:hover p {
  color: #fff;
}
.remark_sec_new_inner_single.active {
  background: linear-gradient(124.13deg, #1fb8c3 18.04%, #7977f7 86.09%);
  box-shadow: 0px 4px 32.900001525878906px 0px #00000040;
}
.remark_sec_new_inner_single.active h6 {
  color: #fff;
}
.remark_sec_new_inner_single.active p {
  color: #fff;
}

.ai_enhance_sec_new {
  position: relative;
  padding: 150px 0 70px 0;
}
.ai_enhance_sec_new::after {
  content: "";
  position: absolute;
  right: 0;
  top: 35%;
  background: url(../../assets/images/ai-after-img.svg);
  width: 38px;
  height: 146px;
  background-position: center;
}
.ai_enhance_sec_new_inner {
  position: relative;
}
.ai_enhance_sec_new_inner_head {
  position: relative;
  text-align: center;
  margin: 0 auto 80px;
}
.ai_enhance_sec_new_inner_head h5 {
  position: relative;
  color: #000000;
  font-weight: 500;
  font-size: 30px;
}
.ai_enhance_sec_new_inner_head h5 span {
  color: #212529;
  font-weight: 700;
  position: relative;
  font-size: 30px;
}
.responsive-hide-btn-on{
  display: none !important;
}
/* .ai_enhance_sec_new_inner_head h5 span::after {
  content: "";
  position: absolute;
  left: 0;
  background: #8dc2ff99;
  width: 450px;
  height: 53px;
  z-index: -1;
  top: -10px;
  left: -30px;
} */
.ai_enhance_sec_new_inner_head h5 span::before{
  background: url(../../assets/images/homepage_line.svg);
  position: absolute;
  content: "";
  left: 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 38px;
  left: -82px;
  top: 50px;
}
.ai_trusted_hring_inner_head span::after{
  background: url(../../assets/images/homepage_line.svg);
  position: absolute;
  content: "";
  left: 0;
  background-repeat: no-repeat;
  width: 100%;
  height: 38px;
  left: 777px;
  top: 41px;
}
.ai_enhance_sec_new_inner_body {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  width: 80%;
  margin: 0 auto 50px;
}
.ai_enhance_sec_new_inner_body_single {
  position: relative;
  box-shadow: 0px 10.67326831817627px 37.35643768310547px 0px #00000014;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
}
.ai_enhance_sec_new_inner_body_single img {
  width: auto;
  margin-bottom: 20px;
}
.ai_enhance_sec_new_inner_body_single h5 {
  position: relative;
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  min-height: 40px;
}
.ai_enhance_sec_new_inner_body_single p {
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.up_img_sec {
  position: relative;
  height: 120px;
}
.ai_enhave_btn_sec {
  position: relative;
  text-align: center;
  width: 190px;
  height: 56px;
  display: block;
  margin: 0px auto 0;
  overflow: hidden;
  top: 0;
  line-height: 56px;
}
.ai_trusted_hring {
  position: relative;
  padding: 10px 0 50px 0;
}
.ai_trusted_hring_inner {
  position: relative;
}
.ai_trusted_hring_inner_head {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}
.ai_trusted_hring_inner_head h5 {
  position: relative;
  font-weight: 700;
  color: #000;
  font-size: 40px;
  margin: 0px;
}
.ai_trusted_hring_inner_head h5 span {
  color: #1865c1;
}

.ai_trusted_hring_inner_body {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 40px;
}
.ai_trusted_hring_inner_body_single {
  position: relative;
  background: #fff;
  box-shadow: 0px 11px 38.5px 0px #00000014;
  padding: 35px 100px;
  border-radius: 10px;
}
.ai_trsut_cont_sec {
  position: relative;
}
.ai_trsut_cont_sec h6 {
  position: relative;
  color: #212121;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 15px;
}
.ai_trsut_cont_sec p {
  position: relative;
  margin-bottom: 0px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}
.ai_trusted_img {
  position: absolute;
  left: -35px;
}
.ai_trusted_img img {
  width: auto;
}
.ai_trusted_hring:before {
  position: absolute;
  content: "";
  background: url(../../assets/images/ai-trust-head-bg.svg);
  width: 146px;
  height: 38px;
  left: 0;
  top: 30px;
}

.for_candidate_rect_sec {
  position: relative;
  padding: 70px 0 30px 0;
}
.for_candidate_rect_sec_inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
}
.for_candidate_rect_sec_inner:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/for-can-bg.svg);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: 70%;
  z-index: -1;
}
.for_candidate_rect_sec_inner_left_head {
  position: relative;
  text-align: center;
  margin-bottom: 75px;
}
.for_candidate_rect_sec_inner_left_head h6 {
  position: relative;
  color: #1a1a1a;
  font-size: 38px;
  font-weight: 700;
  margin: 0px;
}
.for_candidate_rect_sec_inner_left_head:after {
  position: absolute;
  content: "";
  background: url(../../assets/images/homepage_line_sm.svg);
  width: 360px;
  height: 59px;
  left: 174px;
  background-repeat: no-repeat;
  top: 40px;
  right: 0;
}
.for_candidate_rect_sec_inner_left_body {
  position: relative;
  width: 80%;
}
.for_cand_single_cards {
  position: relative;
  box-shadow: 0px 0px 16px 0px #0000001a;
  background: #ffffff;
  padding: 25px 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 230px;
  gap: 12px;
}
.for_cand_single_cards span {
  position: relative;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  background: #1865c1;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
}
.for_cand_single_cards h6 {
  position: relative;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
}
.for_cand_single_cards p {
  position: relative;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.left_2 {
  position: absolute;
  right: 0;
  top: 15px;
}
.left_3 {
  position: absolute;
  bottom: -305px;
}
.left_4 {
  position: absolute;
  right: 0;
  top: auto;
  bottom: -340px;
}
.rgt_1 {
  position: absolute;
  top: 20px;
}
.rgt_2 {
  position: absolute;
  right: 0;
  top: 0;
}
.rgt_3 {
  position: absolute;
  bottom: -580px;
}
.rgt_4 {
  position: absolute;
  right: 0;
  bottom: -610px;
}

.for_candidate_rect_sec_inner_right {
  position: relative;
  right: -50px;
}
.for_candidate_rect_sec_inner_left:after {
  position: absolute;
  content: "";
  left: -100px;
  top: 75%;
  background: url(../../assets/images/gp-img.svg);
  width: 38px;
  height: 146px;
}
.for_candidate_rect_sec_inner_right:after {
  position: absolute;
  content: "";
  right: -50px;
  bottom: -320px;
  background: url(../../assets/images/gp-img.svg);
  width: 38px;
  height: 146px;
}


.accordion .accordion-item{border-radius: 8px;background: #D6E6FF33;}
.accordion-button{color: #333333 !important;font-weight: 600 !important;opacity: 1 !important;}
.accordion-body{color: #fff;font-size: 14px;font-weight: 400;}

.quick_step_head h2{position: relative;margin-bottom: 30px;color: #000;font-weight: 700;}
.quick_step_head h2 span{color: #1865C1;}
.Quick_steps_img{width: 70px;flex: 0 0 70px;}
.quick_step_img_sec_cont{position: relative;}
.quick_step_img_sec_cont h5{position: relative;color: #212121;font-weight: 700;font-size: 24px;margin-bottom: 10px;}
.quick_step_img_sec_cont p{position: relative;color: #000;font-weight: 400;font-size: 16px;margin-bottom: 0px;}
.Quick_steps-right{margin-bottom: 25px;position: relative;}
.Quick_steps-right:last-child{margin-bottom: 0px;}
.stepl_count {
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex: 0 0 60px;
    justify-content: center;
    background: #1865C1;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 50%;
    font-size: 24px;
    left: -140px;
}
.Quick_steps-right:before {
    content: '';
    position: absolute;
    left: -115px;
    height: 100%;
    border: 5px dashed #CBCBCB;
    top: 80px;
}
.Quick_steps-right:last-child::before{display: none;}
.Quick_steps_getting_job_img_new{position: relative;text-align: right;margin-top: 80px;}
.afrter_second_sec{position: relative;margin-bottom: 40px;}
.afrter_second_sec .stepl_count{left: auto;right: -140px;}
.afrter_second_sec .Quick_steps-right:before { left: auto;right: -115px;}
.faq_sec_pro_rect{position: relative;margin-bottom: 50px;}

span.subscribe_error {
  position: absolute;
  top: 50px;
  left: 20px;
  font-size: 12px; 
  color: #e22d2d;
}

/* new landing page ui start  */
.Book-demo{
  display: flex;
  gap: 30px;
}
.banner-container {
  text-align: center;
  color: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.header-banner {
  border-radius: 50px;
  background: var(--secondary-light-secondary-100, #EDF5FE);
  padding: 10px 32px;
  display: inline-block;
}

.AI-Driven-Smart {
  padding: 20px;
}

.AI-Driven-Smart h1 {
  font-size:64px;
  margin-bottom: 20px;
  background: #0B4A96;
  background: linear-gradient(to right, #0B4A96 40%, #5094E7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.AI-Driven-Smart p {
  font-size: 24px;
  margin-bottom: 40px;
  font-weight: 500;
  color: #212529;
}

.try-button {
  width: 200px;
  border-radius: 90px;
  background: linear-gradient(95deg, #0B4A96 36.34%, #5094E7 105.5%);
  color: #fff;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
}  

.try-button:hover {
  background-color: #e0e0e0;
}
.ai_enhance_sec_new:before{position: relative;*background: url(../../assets/images/why_bg.svg);background-size: cover;
  width: 100%;background-repeat: no-repeat; background-position: center center;position: absolute;content: '';height: 100%;z-index: -11;}
.ai_enhance_sec_new_inner_body{z-index: 111}

.trusted_part_sec_new{position: relative;padding: 50px 0 50px 0;}





.round-arow {
  position: absolute;
  right: 2.5px;
  top: 4px;
  background-color: white;
  width: 45px;
  height: 44px;
  border-radius: 50px;
  transition: width 0.4s ease, height 0.4s ease; 
}

.round-arow:hover {
  width: 194px;
  height: 44px;
  transition: width 0.4s ease, height 0.4s ease; 
}
.try-haire-btn-sub-new{
  margin-top: 12px;
}






@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.future_hiring_easy_head::after{
    position: absolute;
    content: "";
    right: -990px;
    bottom: -180px;
    background: url(../../assets/images/Rings_circle_sm.svg);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
}
.future_hiring_easy_head::before{
    position: absolute;
    content: "";
    left: -105px;
    bottom: -110px;
    background: url(../../assets/images/Rings_circle_md.svg);
    width: 218px;
    height: 218px;
    background-repeat: no-repeat;
    z-index: -1;
}
.choose_the_plan{
    margin-top: 150px;
}
.quick_step_head h2 span{
    color: #007bff;
}
.row.choose_the_plan-main {
    margin-top: 70px;
}
.testimonials::before{
  position: absolute;
  content: "";
  left: -100px;
  bottom: 340px;
  background: url(../../assets/images/dotted-lines.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}
.choose_the_plan h2:after {
    background: url(../../assets/images/homepage_line_sm.svg);
    position: absolute;
    content: "";
    left: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 38px;
    left: 808px;
    top: 34px;
  }
/* .future_hiring_easy-section::after{
  position: absolute;
  content: "";
  left: 1121px;
  bottom: 41px;
  background: url(../../../public/images/border-corner.svg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
} */
.cardStyleOne .card-body,.cardStyleThree .card-body{
background-color: #F0F9FF
}
.slick-prev:before {
  content: \2190 !important;
}
.footer_main {
  display: contents;
}
.testimonials_sec_new{position: relative;}

.responsive_menu ul li {position: relative;padding: 0 0 30px 25px;text-align: left;}
.responsive_menu ul li:first-child{padding-left: 15px;}
.responsive_menu ul li:last-child{padding-bottom: 0px;}
.responsive_menu ul li a{position: relative;display: block;color: #000000;font-size: 15px;font-weight: 500;}
.clos_icn_menu{position: absolute;right: 25px;top: 15px;z-index: 1;color: #000;font-weight: 600;font-size: 18px;border: none;background: none;}
.accordion-button::after{background-position: 10px;background-size: 10px;}

/* .btn_bg_img{position: absolute;width: auto;bottom: -80px;display: none;width: 289px;height: 174px;}
.get_start_btn{overflow: hidden;} */

.navbar ul li a.active{color: #1865C1;font-weight: 700;}
.navbar ul li a.active:after {position: absolute; content: ''; left: 0;right: 0; bottom: -5px;
  height: 3px; width: 80%; background: #1865C1; margin: 0 auto; text-align: center; border-radius: 5px;}











/* 
a {
  width: 200px;
  padding: 20px 30px;
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
  
  &:hover {
    .wave {
      top: -120px;
    }
  }

  span {
    position: relative;
    z-index: 1;
    color: white;
    font-size: 15px;
    letter-spacing: 8px;
  }

  .wave {
    width: 200px;
    height: 200px;
    background-color: #1865C1;
    box-shadow: inset 0 0 50px rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    top: -80px;
    transition: 0.4s;

    &::before, &::after {
      width: 200%;
      height: 200%;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,-75%);
    }

    &::before {
      border-radius: 45%;
      background-color: #1865C1;
      animation: wave 5s linear infinite;
    }

    &::after {
      border-radius: 40%;
      background-color: rgba(24, 100, 193, 0.27);
      animation: wave 10s linear infinite;
    }
  }
}

@keyframes wave {
  0% {
    transform: translate(-50%,-75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%,-75%) rotate(360deg);
  }
} */


/* .menu-open {
  overflow: hidden;
} */



.faq_left_sec_new{position: relative;}
.faq_left_sec_new_head{position: relative;}
.faq_left_sec_new_head h6{position: relative;color: #FFFFFF;font-size: 60px;font-weight: 900;margin-bottom: 20px;line-height: inherit;}
.faq_section{padding: 10px 0 0 0;}
.faq_left_sec_new_body_sec{position: relative;}
.faq_left_sec_new_body_sec_form_sec{position: relative;margin-bottom: 25px;}
.faq_boy_icn_new_sec{position: relative;text-align: center;padding-top: 210px;}
.faq_boy_icn_new_sec img{width: auto;object-fit: cover;}
.faq_inner_sec{align-items: center;position: relative;}

.answer_main_div {
  margin-top: 0;
  padding: 15px 20px;
  background-color: white;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 330px;
  text-align: left;
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 500;
  border-radius: 25px;
  top: 65px;
  right: 0;
  left: 90px;
  height: 130px;
  display: flex;
  align-items: center;
}
.answer{position: relative;max-height: 110px;overflow-y: auto;}
.answer_inner{position: relative;}
/* .answer_inner{position: relative;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical;} */
.answer::-webkit-scrollbar {width: 4px;position: absolute;right: 0px;}
.answer::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #F1F1F1;  border-radius: 10px;} 
.answer::-webkit-scrollbar-thumb {background:#1865C199; border-radius: 50px;}
.answer::-webkit-scrollbar-thumb:hover {background: #1865C199;}

/* .answer:after{content: '';position: absolute;right: 0;bottom: -25px;background: url(../../../public/images/vc-img.svg);background-size: cover;background-repeat: no-repeat;background-position: center center;width: 36px;height: 26px;z-index: 11;left: 0;margin: 0 auto;} */

.aft_img_sec{position: absolute;}

.vc_img_sec {
  position: absolute;
  top: auto;
  left: 160px;
  right: 0;
  margin: 0 auto;
  bottom: -25px;
}

/* .answer_inner:after {
  position: absolute;
  content: 'Read More';
  right: 0;
  bottom: 10px;
  color: #000;
  font-size: 14px;
  cursor: pointer;
} */


/* .ans_inner_sec .modal-dialog{ max-width: 350px;background: #fff;border-radius: 10px;padding: 15px;}
.ans_inner_sec .modal-content{border: none;} */


.book_demo_btn{position: relative;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;font-size: 16px;font-weight: 600;border: none;padding: 12px 40px;display: inline-block;}
 
.book_demo_btn:after{content: ''; border: 2px solid;border-image-source: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);border-image-slice: 1;border-radius: 50px;position: absolute;width: 100%;height: 100%;left: 0;top: 0;}


.btn_new_after {
  padding: 12px 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  cursor: pointer;
  border: 1.5px solid;
}
.btn_new_after::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  background-color: white;
  z-index: -1;
  transition: 200ms
}
.btn_new_after::after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  transition: 200ms
}
.btn_new_after:hover::before {
  opacity: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: transparent;
}
.btn_new_after:hover::after{
  color: white;
}










.Book_demo_btn {
  padding: 12px 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  cursor: pointer;
  border: 1.5px solid;
}
.Book_demo_btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  background-color: white;
  z-index: -1;
  transition: 200ms
}
.Book_demo_btn::after {
  content: attr(data);
  font-size: 16px;
  background: transparent;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  transition: 200ms
}
.Book_demo_btn:hover::before {
  opacity: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: transparent !important;
}
.Book_demo_btn:hover{
  background: linear-gradient(94.39deg, #ffffff 50%, #ffffff 103.69%);
  
}
.responsive-book-demo-btn{
  display: none;
}
.Ready-btn{
  padding: 0px;
}
@media only screen and (max-width:767px) {

  .Book-demo{display: none;}


}
/* New landing page ai-inteview */
.new_landign_ai_banner_sec{position: relative;padding: 30px 0 50px 0;}
.new_landign_ai_banner_sec_main{position: relative;display: grid;grid-template-columns: 2fr 1fr;grid-gap: 40px;align-items: center;z-index: 1111;width: 95%;margin: 0 auto;}
.new_landign_ai_banner_sec:before{position: absolute;content: '';background: url(../../assets/images/fea_bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center 50px;height: 100%;z-index: -111;opacity: 0.6;}
.new_landign_ai_banner_sec_main_left{position: relative;}
.new_landign_ai_banner_sec_main_left h2{position: relative;font-size:64px; background: #0B4A96;
  background: linear-gradient(to right, #0B4A96 40%, #5094E7 100%);-webkit-background-clip: text;
  -webkit-text-fill-color: transparent; font-weight: 900;margin-bottom: 80px !important;}
.in_text{position: absolute;font-size:32px; margin-bottom: 0;background: #0B4A96;
  background: linear-gradient(to right, #0B4A96 40%, #5094E7 100%);-webkit-background-clip: text;
  -webkit-text-fill-color: transparent; font-weight: 300; right: 275px;top: 245px;}  
.new_landign_ai_banner_sec_main_right{position: relative;box-shadow: 0px 3.78px 9.27px 0px #00000040;border-radius: 25px;padding: 10px;}
.new_landign_ai_banner_sec_main_right html::-webkit-scrollbar {width: 4px;position: absolute;right: 0px;display: none !important;}
.new_landign_ai_banner_sec_main_right html::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #F1F1F1;  border-radius: 10px;} 
.new_landign_ai_banner_sec_main_right html::-webkit-scrollbar-thumb {background:#1865C199; border-radius: 50px;}
.new_landign_ai_banner_sec_main_right html::-webkit-scrollbar-thumb:hover {background: #1865C199;}

.interview_text{position: relative;display: inline-block;background: #EDF5FE;border-radius: 100px;padding: 15px 40px;}
.interview_text p{font-size: 50px;font-weight: 500;background: #0B4A96;
    background: linear-gradient(to right, #0B4A96 40%, #5094E7 100%);-webkit-background-clip: text;
    -webkit-text-fill-color: transparent; margin-bottom: 0px !important;}

.interview_text img{position: absolute;right: 0;top:-15px;z-index: 11;}
.hm_list_sec{position: relative;display: block;margin-bottom: 20px;}
.hm_list_sec li{display: inline-block;margin-right: 20px;color: #000000;font-weight: 700;font-size: 24px;position: relative;}
.hm_list_sec li:after{content: '';position: absolute;right: -10px;top: 0;width: 1px;height: 100%;background: #9E9E9E;}
.hm_list_sec li:last-child{margin-right: 0px;}
.hm_list_sec li:last-child::after{display: none;}
.trust_sec_new_home{width: 85%;margin: 0 auto;}
.inte_suu_ai{position: relative;padding: 30px 0;}
.inte_suu_ai:before{position: absolute;content: '';background: url(../../assets/images/fea_bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center 50px;height: 100%;z-index: -111;opacity: 0.3;}
.inte_suu_ai_inner{position: relative;}
.inte_suu_ai_inner_head{position: relative;text-align: center;margin-bottom: 30px !important;}
.inte_suu_ai_inner_head h6{position: relative;color: #212529;font-size: 48px;font-weight: 700;margin-bottom: 20px !important;}
.inte_suu_ai_inner_head p{position: relative;font-size: 24px;font-weight: 500;color: #212529;margin-bottom: 0px !important;}
.inte_suu_ai_inner_body{position: relative;}
.inte_suu_ai_inner_body_inner{position: relative;}
.inte_suu_ai_inner_body_inner_img{position: relative;width: 55%;margin: 0 auto 30px;}
.inte_suu_ai_inner_body_inner_img img{width: 100%;object-fit: cover;}
.inte_suu_ai_inner_body_btm_sec{position: relative;text-align: center;margin-top: 50px;}

.try_button_new {
  width: 260px;
  height: 55px;
  border-radius: 90px;
  background: linear-gradient(95deg, #0B4A96 36.34%, #5094E7 105.5%);
  color: #fff;
  border: none;
  /* padding: 15px 20px; */
  font-size: 18px;
  cursor: pointer;
}  

.try_button_new:hover {
  background-color: #e0e0e0;
}
.no_para{position: relative;font-size: 16px;font-weight: 400;color: #030303;margin-top: 10px;}
.padd_0{padding: 0px;}
.new_land_trust{position: relative;padding: 30px 0 50px 0;width: 80%;margin: 0 auto;}
.new_ai_bg{position: relative;padding: 30px 0 20px 0;}
.new_ai_bg:after{position: absolute;background: url(../../assets/images/fea_bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center center;height: 100%;content: '';top: 0;left: 0;z-index: -111;opacity: 0.5;}
.new_ai_bg .ai_enhance_sec_new_inner{z-index: 111;}
.how_works_for_req_inner_body_sec_single_img{background: #fff; border-radius: 10px;}
.new_how_work .how_works_for_req_inner_body_sec_single:after{opacity: 0;}
.new_how_work .how_works_for_req_inner_body_sec{height: 491px;align-items: center;}
.new_how_work .how_works_for_req_inner_body_sec:before{background: url(../../assets/images/arrow_new_image.png);background-size: contain;height: 491px;width: 100%;background-repeat: no-repeat;background-position: center 0px;content: '';position: absolute;}
.new_how_work .how_works_for_req_inner_body_sec_single_img img{height: 299px;object-fit: cover;}
.wht_para{position: relative;font-size: 16px;font-weight: 400;color: #FFFFFF;margin-bottom: 0 !important;}
.flex_direction_column{flex-direction: column;gap: 15px;}
.new_sec_key{padding: 20px 0!important;}
.bg_client_blue{position: relative;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);padding: 40px 0;}
.bg_client_blue h6{color: #fff;}
.skip_sec_new{position: relative;padding: 0 0 0px 0;width: 88%;margin: 0 auto;}
.skip_sec_new_onner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 50px;align-items: center;}
.skip_sec_new_onner_left{position: relative;}
.skip_sec_new_onner_left h6{position: relative;font-size: 48px;line-height: inherit;margin-bottom: 20px;color: #000;font-weight: 700;}
.skip_sec_new_onner_left h5{position: relative;font-size: 24px;line-height: inherit;margin-bottom: 20px !important;color: #000;font-weight: 400;}
.skip_sec_new_onner_right{position: relative;}
.skip_sec_new_onner_right img{width: 530px;object-fit: cover;height: 706px;}
.ftr_new_land{position: relative;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);padding: 20px 0;}
.ftr_new_land_inner{position: relative;display: flex;align-items: center;justify-content: space-between;}
.vide_sec{width: 114px;height: 54px;margin-bottom: 15px;}
.vide_sec img{width: 100%;}
.ftr_new_land_inner_left p{position: relative;margin-bottom: 0px;color: #FFFFFF;font-size: 14px;font-weight: 500;}
.ftr_new_land_inner_midlle{position: absolute;right: 0; left: 0; text-align: center; bottom: 0;}
.ftr_new_land_inner_midlle p{position: relative;margin-bottom: 0px;color: #FFFFFF;font-size: 14px;font-weight: 700;}
.req_vid {
  position: absolute;
  width: 95px;
  height: 45px;
  left: 0;
  top: 30px;
  right: 0;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.req_vid img{width: 100%;height: auto !important;}

.bg_client_blue .client_testi_img_img{width: 145px;height: auto;}
.bg_client_blue .client_testi_img_img img{border-radius: none;}
.new_middle{position: relative;}
