.shedule_inertview_sec .modal-dialog{max-width: 720px;background: #fff; padding: 40px 20px; border-radius: 10px;top: 100px;height: 295px;display: flex;align-items: center;}
.shedule_inertview_sec .modal-content{border: none;}

.stay_connect_sec{position: relative;text-align: center;}
.stay_connect_sec h5{position: relative;color: #000000;font-size: 28px;font-weight: 700;margin-bottom: 15px !important;}
.stay_connect_sec p{position: relative;font-size: 18px;font-weight: 500;margin-bottom: 30px;}

.congratu_sec{position: relative;text-align: center;}
.congratu_sec img{width: auto;margin: 0 auto 30px;}
.congratu_sec h5{position: relative;color: #000000;font-size: 28px;font-weight: 700;margin-bottom: 20px !important;}
.congratu_sec p{position: relative;font-size: 18px;font-weight: 500;margin-bottom: 10px !important;}

.Sheduleint_sec{position: relative;text-align: center;}
.Sheduleint_sec img{width: auto;margin: 0 auto 30px;}
.Sheduleint_sec h6{position: relative;color: #000000;font-size: 24px;font-weight: 700;margin-bottom: 20px !important;}
.Sheduleint_sec_list{position: relative;display: flex;align-items: center;gap: 10px;justify-content: center;}
.back_jobs{position: relative;width: 130px;height: 40px;display: flex;align-items: center;justify-content: center;border: 2px solid #000000;color: #000000;font-size: 14px;font-weight: 500;background: #fff;border-radius: 30px;}
.yes_btn{position: relative;width: 130px;height: 40px;display: flex;align-items: center;justify-content: center;border: 2px solid #1865C1;color: #fff;font-size: 14px;font-weight: 500;background: #1865C1;border-radius: 30px;}

.hire_ai_interview{position: relative;padding: 60px 0 30px 0;height: 100%;}
.hire_ai_interview_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;align-items: center;}
.hire_ai_interview_inner_left{position: relative;}
.hire_ai_interview_inner_left h6{position: relative;color: #000000;font-size: 40px;font-weight: 700;margin-bottom: 20px !important;}
.hire_ai_interview_inner_left p{position: relative;margin-bottom: 40px;color: #000000;font-size: 20px;font-weight: 400;}

.hire_left_btn_sec{position: relative;display: flex;align-items: center;gap: 20px;}
.shedule_int{position: relative;width: 245px;height: 50px;border-radius: 30px;color: #000000;font-size: 18px;font-weight: 500;border: 2px solid #000;display: flex;align-items: center;justify-content: center;gap: 10px;}
.shedule_int i{font-size: 20px;}
.start_interview{position: relative;width: 245px;height: 50px;border-radius: 30px;color: #fff;font-size: 18px;font-weight: 500;border: 2px solid #1865C1;display: flex;align-items: center;justify-content: center;gap: 10px;background: #1865C1;}


.ai_interview_process{position: relative;padding: 30px 0 30px 0;}
.ai_interview_process_inner{position: relative;}
.ai_interview_process_inner h6{position: relative;color: #000000;font-size: 28px;font-weight: 700;margin-bottom: 15px;text-align: center;}
.ai_interview_process_inner p{position: relative;font-size: 16px;font-weight: 400;margin-bottom: 50px;color: #000;text-align: center;margin-bottom: 25px;}

.ai_interview_process_inner_sec_new{position: relative;width: 80%;margin: 0 auto;}
.ai_int_sec_single{position: relative;padding: 15px;}
/* .ai_int_sec_single{overflow: hidden;transition: transform 0.5s ease-in-out;transform: translateX(100%);} */
.sing_first{transform: translateX(0px) !important;}
.ai_int_sec_single.active{transform: translateX(0px);}
.ai_int_sec_single_cont_sec{position: relative;box-shadow: 0px 3.59698224067688px 18.799999237060547px 0px #0000001F;background: #fff;padding: 70px 40px;text-align: center;margin-bottom: 50px;}
.ai_int_sec_single_cont_sec img{width: auto;margin: 0 auto 30px;}
.ai_int_sec_single_cont_sec p{position: relative;color: #000000;font-size: 20px;font-weight: 500;margin-bottom: 0px;}
.ai_int_sec_single_btn{position: relative;display: flex;align-items: center;justify-content: flex-end;width: 100%;}
.ai_int_sec_single_btn button{position: relative;width: 265px;display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 18px;font-weight: 500;height: 50px;border-radius: 30px;background: #1865C1;border: none;color: #fff;}
.ai_int_sec_single_cont_sec h4{position: relative;margin-top: 25px;text-align: center;color: #000;font-weight: 600;margin-bottom: 0px;}
.ai_int_sec_single_btn a{position: relative;width: 265px;display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 18px;font-weight: 500;height: 50px;border-radius: 30px;background: #1865C1;border: none;color: #fff;}


.inter_view_panel_sec{position: relative;background: #fff; border-radius: 0 0 5px 5px;z-index: 11;padding: 20px 0;
    box-shadow: 0px 2px 7px 0px #0000001f;    border-bottom: 1.5px solid #D9D9D94A;}
.inter_view_panel_sec_inner{position: relative;display: flex;align-items: center;gap: 40px;}
.inter_view_panel_sec_inner_left{position: relative;display: flex;align-items: center;gap: 20px;}
.inter_view_panel_sec_inner_left:after {position: absolute; right: -20px;top: -35px;width: 2px; height: 94px;
    content: '';background: #EAE9E994;}
.vid_img{position: relative;width: 65px;}
.logo_img_sec{width: 110px;}
.inter_view_panel_sec_inner_right{position: relative;display: flex;align-items: flex-start;flex-direction: column;gap: 8px;}
.inter_view_panel_sec_inner_right h6{position: relative;color: #000;font-weight: 500;font-size: 24px;margin-bottom: 0px;}
.inter_view_panel_sec_inner_right p{position: relative;color: #ACACAC;font-size: 14px;font-weight: 400;margin-bottom: 0px;}

.int_panel_body_sec{position: relative;background: #f3f8ff;padding: 20px 0;}
.int_panel_body_sec_inner{position: relative;background: linear-gradient(98.89deg, rgba(31, 184, 195, 0.2) 13.07%, rgba(121, 119, 246, 0.2) 79.46%);padding: 25px;box-shadow: 0px 0px 6px 0px #0000000F;border-radius: 10px;text-align: center;height: 70vh;display: flex;align-items: center;flex-direction: column;}
.int_panel_body_sec_inner img{width: 220px;object-fit: cover;margin: 0px auto;}

.int_panel_body_sec_inner {
  position: relative;
  background-image: url('../../../../assets/images/HairoAvatarBackground.jpg'); /* Add your image path here */
  background-size: cover; /* Cover the entire area of the element */
  background-position: center; /* Center the image within the element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-blend-mode: multiply;
  padding: 25px;
  box-shadow: 0px 0px 6px 0px #0000000F;
  border-radius: 14px;
  text-align: center;
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tooltip_interview {
  font-family: 'Satoshi-Variable' !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-tooltip {
  font-family: 'Satoshi-Variable' !important;
  font-size: 10px;
}
.timer{
  margin: 7px;
  /* background: rgba(130, 130, 130, 0.7); */
  border-radius: 50px;
  padding: 7px;
}

.progress_section{
  position: absolute;
  width: 100%;
  top:-22px;
}
:where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-success-bg, :where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-bg
{
  background-color: #1865C7;
}
.progress_section :where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-success-bg, :where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-bg
{
  background-color: #5094E7 !important;
}
.progress_section .ant-progress-bg {
  height: 22px !important;
}
.progress_section .ant-progress-inner {
  background-color: rgb(80 148 231 / 30%) !important;
}
.ant-progress-text{
  position: absolute;
  z-index: 99999;
  top: -22px;
}

.progress_section:where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-text{
  color: rgb(249 249 249 / 88%) !important;
}

.progress_section:where(.css-dev-only-do-not-override-17sses9).ant-progress .ant-progress-text{
  color: rgb(249 249 249 / 88%) !important;
}
.progress-wd .ant-progress .ant-progress-text {
  color: rgb(80 148 231) !important;
  font-size: 16px;
  font-weight: 500;
}
.progress-wd .ant-progress-text{
  top: 0px;
}
.int_panel_body_sec_inner_top span{
  background:transparent !important;
  font-size: 14px !important;
}
.welcm_text_inner {
  width: 60%;
  margin: 10px 0 auto;
  position: absolute;
  bottom: 
30px
;
  background-color: #252525CC;
  padding: 
10px

20px
;
  border-radius: 
5px
;
}
.max_margin{
  margin-top: 80px;
}
.skipQ {
  position: absolute !important;
  bottom: 34px !important;
  left: 35px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  cursor: pointer;
  z-index: 1;
}
.warning_text{
  text-align: center;
}
.warning_text img{
  margin-bottom: 20px;
}
.ctr-btn{
  display: flex;
  justify-content: center;
}
.ctr-btn button{
  border:1px solid #1865C1;
  border-radius: 50px;
  color: #1865C1;
}

.final_warning{
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.warning_mod p{
  font-size: 14px;
  font-weight: 400;
}
.warning_button{
  width: 200px;
  margin: 0 auto;
  text-align: center;
}
.disa{
  display: flex;
  gap: 6px;
  align-items: center;
}
.disa span{
  font-weight: 500;
}
.time .MuiPickersToolbar-content,.MuiPickersLayout-toolbar{
  margin: 0 auto !important;
}
.slipQ_disable{
  color: gray !important;
  pointer-events: none;
  cursor: not-allowed;
}
.slipQ_disable2{
  color: #FFFFFF !important;
  background-color: #A7A7A7 !important;
  pointer-events: none;
  cursor: not-allowed;
}
.tap_speak_button{
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 99px;
    gap: 57px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
    background: #EAF3FE;
    border: none;
}
.tap_speak_button span svg{
  margin-right: 10px;
}
.tap_to_stop{
  display: flex;
    align-items: center;
    gap: 10px;
}
.progress_section .ant-progress-bg{
  border-radius:0px 0px 0px 0px !important; 
}

.progress_section .ant-progress-outer{
  border-radius:0px 0px 0px 0px !important;
}
.progress_section .ant-progress .ant-progress-inner {
  border-radius:8px 8px 0px 0px !important;
}
.roate_icn{-webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;}  
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.int_panel_body_sec_inner_btn{position: relative;text-align: center;width: 100%;display: flex;align-items: center;justify-content: center;color: #000;}
.int_panel_body_sec_inner_btn a{position: relative;width: 250px;height: 50px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;font-size: 18px;font-weight: 500;background: #1865C1;border-radius: 30px;
gap: 10px;}
.int_panel_body_sec_inner_btn button{position: relative;width: 250px;height: 50px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;font-size: 18px;font-weight: 500;background: #1865C1;border-radius: 30px;
  gap: 10px;border: none;}


.allow_micro_phone_pop .modal-dialog{position: relative;max-width: 660px;border-radius: 14px;background: #fff;box-shadow: 0px 0px 19.860000610351562px 0px #0000001A;padding: 30px 50px;top: 10%;}
.allow_micro_phone_pop .modal-content{position: relative;border: none;}
.allow_micro_phone_pop_inner{position: relative;text-align: center;display: flex;align-items: center;flex-direction: column;width: 100%;}
.allow_micro_phone_pop_inner img{width: auto;margin-bottom: 20px;}
.allow_micro_phone_pop_inner h4{position: relative;font-size: 14px;font-weight: 500;color: #000;margin-bottom: 20px;}
.allow_micro_phone_pop_inner a{position: relative;display: flex;align-items: center;justify-content: center;width: 265px;height: 40px;color: #FFFFFF;font-size: 14px;font-weight: 600;background: #1865C1;border-radius: 30px;}


.int_panel_body_allow_btn_sec{position: relative;background: #f3f8ff;}
.int_panel_body_allow_btn_sec_inner{position: relative;background: #fff;padding: 20px 0;margin-bottom: 20px;border-radius: 20px;}

.get_ready_panel_sec{position: relative;padding: 20px 0;}
.get_ready_panel_sec_inner{position: relative;height: 80vh;padding: 50px 0;border-radius: 10px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.get_ready_panel_sec_inner_box{position: relative;width: 320px;height: 498px;background: unset;padding: 15px;margin: 0 auto;text-align: center;border-radius: 10px;display: flex; align-items: center; justify-content: center;}
  
.wave_icn{width: 40px !important;}
.int_panel_body_sec_inner_top{position: absolute;top: 20px;display: flex;align-items: center;justify-content: space-between;width: 100%;z-index: 1;}
.int_panel_body_sec_inner_top span {position: relative;background: #0F0E0E99;display: flex; align-items: center;  gap: 10px; font-size: 17px;  font-weight: 500; color: #fff; padding: 7px 28px;border-radius: 30px; width: 80px;}
.int_panel_body_sec_inner_top .wave_icn{margin: 0 !important;}
.well_interview_sec_text{position: relative;margin: 10px auto;text-align: center;width: 100%;}
.welcm_text_inner{width: 100%;position: absolute;bottom: 0px;padding-left: 20px;padding-right: 20px;padding-bottom: 15px;}
.welcm_text_inner h6{position: relative;color: #FFF;font-size: 16px;font-weight: 600;margin-bottom: 0px;display: flex;align-items: center;justify-content: center;line-height: inherit;}

.record_vdeo_icn_sec{position: relative;display: flex;align-items: center;justify-content: center;gap: 0px;}
.record_vdeo_icn_sec span a{position: relative;display: flex;align-items: center;justify-content: center;height: 40px;width: 40px;background: #1865C1;border-radius: 50%;}
.record_vdeo_icn_sec span i{position: relative;font-size: 22px;font-weight: 500;color: #fff;}
.record_vdeo_icn_sec span.disa a{opacity: 0.5;}
.your_videoi_part_sec{position: absolute;right: 15px;width: 190px;height: 110px;border-radius: 10px;top: 32px;}
.your_videoi_part_sec img.cls-img{width: 100%;object-fit: cover;border-radius: 10px;background: transparent !important;border: none !important;}
:where(.css-dev-only-do-not-override-17sses9).ant-card-bordered {
  border:none !important;
}
.ent_int_btn{position: absolute; right: 15px; top: 0; display: flex; align-items: center; height: 100%;}
.ent_int_btn button {position: relative;display: flex; width: 155px; height: 48px; background: #EB5757;
    color: #FFFFFF;font-size: 16px; font-weight: 500; align-items: center; justify-content: center;
    border: none; border-radius: 30px;}
.ent_int_btn a {position: relative;display: flex; width: 155px; height: 48px; background: #EB5757;
        color: #FFFFFF;font-size: 16px; font-weight: 500; align-items: center; justify-content: center;
        border: none; border-radius: 30px;}    
.your_videoi_part_sec h6 {position: absolute; bottom: 0px; left: 10px; background: #0000004D;
    color: #EAE9E9; font-size: 12px; font-weight: 500; width: 50px; height: 30px;  margin: 0; display: flex;
    align-items: center;  justify-content: center; border-radius: 30px;}   
.your_videoi_part_sec span{position: absolute;z-index: 11;right: 10px;bottom: 10px;}
.your_videoi_part_sec span img{width: 32px;}    

.analsyse_sec_new{position: relative;padding: 50px 0 30px 0;}
.analsyse_sec_new_inner{position: relative;text-align: center;}
.analsyse_sec_new_inner h6{position: relative;font-size: 28px;font-weight: 700;color: #1865C1;margin-bottom: 30px !important;text-align: center;}
.analsyse_sec_new_inner img{width: 150px;margin-bottom: 30px;}
.analsyse_sec_new_inner ul{display: block;text-align: left;position: relative;}
.analsyse_sec_new_inner ul li{display: block;position: relative;margin-bottom: 20px;font-size: 18px;font-weight: 500;color: #000;padding-left: 20px;}
.analsyse_sec_new_inner ul li:last-child{margin-bottom: 0px;}
.analsyse_sec_new_inner ul li:before{position: absolute;content: '';left: 0;top: 7px;height: 8px;width: 8px;background: linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%);border-radius: 50%;animation: pulse-animation 2s infinite;}
.pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }

.generating_p{
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 24px;
}
.cong_sec_new {
    position: relative;
    background: #f3f8ff;
    padding: 30px 0;
    height: 100vh;
    display: flex;align-items: center;
}
.cong_sec_new_inner{position: relative;width: 90%;margin: 0 auto;}
.cong_sec_new_inner_head{position: relative;width: 100%;height: 240px;background: linear-gradient(98.89deg, rgba(31, 184, 195, 0.3) 13.07%, rgba(121, 119, 246, 0.3) 79.46%);border-radius: 20px;margin-bottom: 25px;}
.cong_sec_new_inner_head_inner{position: relative;display: flex;flex-direction: column;gap: 0px;align-items: center;justify-content: center;height: 100%;}
.cong_sec_new_inner_head_inner img{width: 70px;margin-bottom: 20px;}
.cong_sec_new_inner_head_inner h6{position: relative;font-size: 28px;font-weight: 700;color: #000;margin-bottom: 20px;}
.cong_sec_new_inner_head_inner p{position: relative;font-size: 18px;font-weight: 500;color: #000;margin-bottom: 0px; text-align: center;}
.cong_sec_new_inner h4{position: relative;font-size: 18px;font-weight: 500;margin-bottom: 20px;text-align: center;width: 70%;margin: 0 auto 20px;line-height: inherit;}
.bavk_jobs{gap: 16px;width: 100%;display: flex;align-items: center;justify-content: center;}
.bavk_jobs a{position: relative;display:flex;align-items: center;justify-content: center;border: 2px solid #000000;color: #000000;font-size: 14px;font-weight: 500;width: 130px;height: 40px;border-radius: 30px;}

.interview_ended{position: relative;padding: 60px 0 30px 0;}
.interview_ended_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 60px;align-items: center;}
.interview_ended_inner_left{position: relative;}
.interview_ended_inner_left h6{position: relative;color: #000000;font-size: 36px;font-weight: 700;margin-bottom: 25px;}
.interview_ended_inner_left p{position: relative;font-size: 16px;font-weight: 500;color: #000000;margin-bottom: 25px;}
.interview_ended_inner_right{position: relative;text-align: center;}
.interview_ended_inner_right img{width: auto;object-fit: cover;}
.interview_ended_inner_left select{position: relative;width: 500px;height: 50px;padding-left: 15px;color: #000000;font-size: 14px;font-weight: 500;background: #F2F2F2;border: 2px solid #F2F2F2;border-radius: 10px;}

.interview_ended_inner_left input{position: relative;width: 500px;height: 50px;padding-left: 15px;color: #00000080;font-size: 14px;font-weight: 500;background: #fff;border: 2px solid #F2F2F2;border-radius: 10px;margin-bottom: 25px;}

.interview_ended_inner_left a{position: relative;display: flex;background: #1865C1;color: #FFFFFF;font-size: 15px;font-weight: 600;width: 150px;height: 40px;border-radius: 30px;align-items: center;justify-content: center;}

.opacity_box{opacity: 0.5 ;}



@keyframes quiet {
  25%{
    transform: scaleY(.6);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.6);
  }
}
@keyframes loud {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(1.2);
  }
}

.boxContainer{
  display: flex;
  justify-content: space-between;
  height: 30px;
  --boxSize: 8px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
  position: absolute;
    left: 0;
    top: 25%;
    right: 0;
    margin: 0 auto;
}

.box{
  transform: scaleY(.4);
  height: 100%;
  width: var(--boxSize);
  background: #fff;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.box1{
  animation-name: quiet;
}

.box2{
  animation-name: normal;
}

.box3{
  animation-name: quiet;
}

.box4{
  animation-name: loud;
}

.box5{
  animation-name: quiet;
}

.value{font-size: 25px;font-weight: 600;color: #0B4A96;color: #fff;}
.timer{font-size: 20px;font-weight: 500;color: #fff;}
.timer a{font-size: 20px;font-weight: 500;color: #0B4A96;color: #fff;}
/* .timer-wrapper svg path{stroke: #ccc;} */
.timer img{
  width: 20px !important;
}
.timer{
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px !important;
  margin: 12px;
}

.reshedule_screen .modal-dialog{max-width: 700px;background: #fff;border-radius: 10px;padding: 20px;}
.reshedule_screen .modal-content{padding: 0px;border: none;}
.reshedule_screen_inner{position: relative;display: flex;align-items: center;flex-direction: column;text-align: center;}
.reshedule_screen_inner img{width: auto;margin-bottom: 15px;}
.reshedule_screen_inner h6{position: relative;color: #000000;font-size: 28px;font-weight: 700;margin-bottom: 15px;}
.reshedule_screen_inner p{position: relative;font-size: 18px;color: #000000;font-weight: 500;margin-bottom: 15px;}
.reshedule_screen_inner a{position: relative;display: flex;width: 145px;height: 40px;color: #1865C1;font-size: 14px;font-weight: 500;border: 2px solid #1865C1;color: #1865C1;border-radius: 20px;align-items: center;justify-content: center;}


.record_vdeo_icn_sec span button {
  background: none;
  border: none;
  height: auto;
  width: auto;
  box-shadow: none;
}
.record_vdeo_icn_sec span button:hover{
  background: none !important;
  border: none;
  height: auto;
  width: auto;
  box-shadow: none;
}

.int_panel_body_sec_inner .ant-card-body{position: relative;height: 100%;width: 100%;padding: 0px !important;object-fit: cover;overflow: hidden;}




.inter_panel_ready_sec .modal-dialog{ max-width: 800px;border-radius: 10px;}
.inter_panel_ready_sec_inner{background: #fff;border-radius: 10px;text-align: center;display: flex;flex-direction: column;align-items: center;gap: 20px;}
.inter_panel_ready_sec_inner img{width: 70px;margin: 0 auto 20px;}
.inter_panel_ready_sec_inner h6{position: relative;color: #000000;font-size: 28px;font-weight: 700;margin-bottom: 15px;}
.inter_panel_ready_sec_inner p{position: relative;color: #000000;font-size: 18px;font-weight: 400;margin-bottom: 15px;}
.inter_panel_ready_sec_inner_btns{position: relative;display: flex;align-items: center;justify-content: center;gap: 15px;}
.no_btn_int{position: relative;width: 165px;height: 40px;display: flex;align-items: center;justify-content: center;border: 2px solid #000000;border-radius: 30px;color: #000000;font-size: 14px;font-weight: 500;background: #fff;}
.yes_btn_int{position: relative;width: 165px;height: 40px;display: flex;align-items: center;justify-content: center;border: 2px solid #EB5757;border-radius: 30px;color: #fff;font-size: 14px;font-weight: 500;background: #EB5757;}
.datetimepicker_container {
  display: flex;
  gap: 60px;
  width: 70%;
  padding: 40px;
  border-right: 1px solid grey;
}
.continue_btn{
  border: 1px solid #1865C1;
  background-color: transparent;
  color: #1865C1;
  border-radius: 25px;
}

.datedetails_main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.candidate_data {
  display: flex;
  flex-direction: column;
  gap: 240px; /* Adjust as needed */
  padding: 30px;
  /* width:30%; */
}

.candidate_data h3 {
  margin: 0;
}

.candidate_data p {
  margin: 5px 0;
  margin-bottom: 15px;
}

.mod_body_padding {
  padding: 0px !important;
}

.selected_date p {
  margin-bottom: 0px;
  text-align: center;
}

.selected_date {
  padding: 10px 30px;
  background-color: #F3F9FF;
  width: 345px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

button.confirm_btn {
  border: none;
  background: #1865C1;
  color: white;
  padding: 10px 30px;
  border-radius: 50px;
  width: 100%;
}
.des_name{
  font-size: 16px;
  font-weight: 600;
}
.gZmPOF {
  display: flex;
  text-align: center;
  justify-content: center !important;
}
.spacer{
  padding:50px;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root{
  padding: 10px !important;
  background-color: #BDDCFB ;
  border-radius: 5px;
  ;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  background: #1865C1;
  color: white !important;
  padding: 10px !important;
  border-radius: 5px;
  
}
.clock-icon {
  background-color: white;
}
.image_time{
  display: flex;
  align-items: baseline;
}
.image_time img{
  margin-right: 5px;
  margin-top: -3px;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root,.css-1f43ekz-MuiTypography-root-MuiPickersToolbarText-root-MuiTimePickerToolbar-separator{
  font-size: 16px !important;
}
.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected{
  background: #1865C1;
  color: white !important;
}
.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {
  font-size: 12px !important;
}
.css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher{
  display: none !important;
}
.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: rgba(0, 0, 0, 0.87);
  padding: 3px;
  font-size: 8px !important;
  border-radius: 3px;
}
.css-tfwo93-MuiClock-squareMask{
  background-color:#F3F9FF;
}

.intervier_details_main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 19px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 1px 14.7px 0px rgba(0, 0, 0, 0.10);
  margin-bottom: 20px;
}
.bg-blue{
  border-radius: 20px;
  background: #E3F4FF;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.bestof{
  text-align: center;
  margin-top: 40px;
}
.back_job{
  border-radius: 30px;
border: 2px solid #000;
background-color: white;
padding: 10px 30px;
}
.intervier_details_main p{
  margin: 0px;
}
.intervier_details_main span{
 font-weight: 700;
 font-size: 18px;
 margin-right: 6px;
}
.mod_body_padding .css-1aj9me-MuiPickersLayout-root{display:block}
.next_question{
  margin-left:10px;
  margin-bottom: 0px;
  font-size: 24px;
}
:where(.css-dev-only-do-not-override-17sses9).ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end:0px !important;
  padding-inline-end:0px !important;
}
.progress_section:where(.css-17sses9).ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: calc(0em - 0px) !important;
  padding-inline-end: calc(0em + 0px) !important;
}
.ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: calc(0em - 0px) !important;
  padding-inline-end: calc(0em + 0px) !important;
}
.progress_section .ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: calc(0em - 0px) !important;
  padding-inline-end: calc(0em + 0px) !important;
}
:where(.css-17sses9).ant-progress .ant-progress-text{
  color: #FFF !important;
}
:where(.css-17sses9).ant-progress .ant-progress-success-bg, :where(.css-17sses9).ant-progress .ant-progress-bg {
  position: relative;
  background-color: #1865C7 !important;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.modal_permission_sec{display: block;}
.modal_permission_sec .modal-dialog{width: 80%;max-width: 80%; border-radius: 10px;}
.modal_permission_sec_inner{position: relative;padding: 35px 60px;background: #F3F8FF; border-radius: 10px;}
.modal_permission_sec_inner_head{position: relative;text-align: center;margin-bottom: 35px;}
.modal_permission_sec_inner_head h6{position: relative;margin: 0px;font-size: 22px;font-weight: 700;color: #000;line-height: inherit;}
.modal_permission_sec_inner_body{position: relative;display: grid;grid-template-columns: 1fr 2.5fr;grid-gap: 20px;margin-bottom: 30px;}
.modal_permission_sec_inner_body_left{position: relative;width: 410px;height: 410px;}
.modal_permission_sec_inner_body_left img{width: 100%;object-fit: cover;height: 100%;border-radius: 10px;}
.modal_permission_sec_inner_body_right{position: relative;}
.modal_permission_sec_inner_body_right_single{position: relative;display: flex;align-items: center;gap: 15px;margin-bottom: 10px;}
.modal_permission_sec_inner_body_right_single:last-child{margin-bottom: 0px;}
.modal_permission_sec_inner_body_right_single_sec{position: relative;background: #FFFFFF;border-radius: 18px;padding: 15px 30px;width: 530px;height: 130px;display: flex;align-items: flex-start;flex-direction: column;    gap: 25px;}
.modal_permission_sec_inner_body_right_single_sec_head{position: relative;margin-bottom: 0px;display: flex;align-items: center;justify-content: space-between;width: 100%;}
.head_single_left_sec{position: relative;display: flex;align-items: center;gap: 10px;}
.head_single_left_sec img{width: 24px;}
.head_single_left_sec h5{position: relative;margin: 0px !important;line-height: inherit;color: #3D4852;font-size: 16px;font-weight: 700;}
.modal_permission_sec_inner_body_right_single_sec_body{position: relative;padding-left: 35px;}
.modal_permission_sec_inner_body_right_single_sec_body h4{position: relative;margin-bottom: 10px !important;font-size: 12px;color: #000000;font-weight: 400;}
.right_test_btn{position: relative;}
.test_btn_check{position: relative;display: flex;align-items: center;justify-content: center;color: #FFFFFF;font-size: 12px;font-weight: 700;background: #0B4A96;border: none;border-radius: 50px;width: 80px;height: 32px;}
.head_single_right_sec{position: relative;}
.head_single_right_sec h6{position: relative;margin-bottom: 0 !important;color: #252525;font-size: 12px;font-weight: 700;}
.head_single_right_sec h6 span{color: #15B341;}
.tested_class{color: #15B341;font-size: 12px;font-weight: 700;width: 80px;text-align: center;display: block;}
.head_single_right_sec h3{position: relative;margin-bottom: 0px !important;color: #1865C1;font-size: 12px;font-weight: 700;text-align: right;display: block;line-height: inherit;}
.microphone_check_sec{position: relative;padding-left: 35px;display: flex;align-items: center;gap: 15px;}
.microphone_check_sec img{width: 18px;}
.microphone_check_sec input{position: relative;width: 405px;height: 38px;border-radius: 100px;background-color: #EBF5FF;padding-left: 20px;color: #212529;font-size: 12px;font-weight: 500;border: none;}
.microphone_check_sec input:placeholder{color: #ACACAC;font-weight: 500;}
.audio_check_sec{position: relative;padding-left: 35px;display: flex;align-items: center;gap: 15px;width: 100%;}
.btm-25{bottom: -25px;position: relative;}
.modal_btn_sec{ position: relative;text-align: center;width: 100%;display: flex;align-items: center;justify-content: center;}
.mod_btn_blue{position: relative;width: 240px;height: 45px;display: flex;align-items: center;justify-content: center;background: #0B4A96;color: #FFFFFF;font-size: 16px;font-weight: 700;border-radius: 100px;}
.mod_btn_blue.disa_btn{background: #989FA4;cursor: none;}
.out_level_internet{position: relative;display: grid;grid-template-columns: repeat(48 , 1fr);grid-gap: 2px;width: 438px;}
.int_box{position: relative;height: 8px;width: 100%;background-color: #D9D9D9;border-radius: 1px;}
.red {background-color: #FF3434;}
.yellow {background-color: #EECF00;}
.green {background-color: #15B341;}

.waves_sec{position: relative;background: url(../../../../assets/images/sound_waves_new.gif); height: 370px;width: 100%;
  background-size: 75px;background-repeat: repeat-x;}

.ai_inter_guide_sec{height: 75vh;position: relative;background: #fff;padding: 20px 40px;border-radius: 10px;}
.ai_inter_guide_sec_head{position: relative;}
.ai_inter_guide_sec_head h6{position: relative;color: #3D4852;font-size: 24px;font-weight: 700;margin-bottom: 30px !important;}
.ai_inter_guide_sec_body{position: relative;}
.ai_inter_guide_sec_body_single{position: relative;margin-bottom: 20px;}
.ai_inter_guide_sec_body_single h5{position: relative;color: #0B4A96;font-size: 16px;font-weight: 700;margin-bottom: 10px !important;}
.ai_inter_guide_sec_body_single_inner{position: relative;margin-bottom: 10px;}
.ai_inter_guide_sec_body_single_inner h4{position: relative;color: #3D4852;font-size: 16px;font-weight: 700;margin-bottom: 10px !important;}
.ai_inter_guide_sec_body_single_inner ul{display: block;padding-left: 15px;}
.ai_inter_guide_sec_body_single_inner ul li{display: block;padding-bottom: 5px !important;color: #3D4852;font-size: 14px;font-weight: 400;position: relative;}
.ai_inter_guide_sec_body_single_inner ul li:before{content: '';position: absolute;left: -15px; top: 8px; height: 4px; width: 4px;background: #3D4852;border-radius: 50%;}
.practice_ai_ftr_sec{position: relative;background: #fff;}
.practice_ai_ftr_sec_inner{position: relative;display: flex;align-items: center;justify-content: center;flex-direction: column;height: 110px;gap: 10px;}
.common_blue_btn{position: relative;display: flex;align-items: center;justify-content: center;padding: 12px 16px;border-radius: 100px;background: #0B4A96;color: #FFFFFF;font-size: 16px;font-weight: 700;border: none;}
.practice_ai_ftr_sec_inner p{position: relative;color: #212529;font-size: 14px;font-weight: 500;margin-bottom: 0px !important;}


.ftr_menu_sec_new_int{position: relative;margin-top: 20px;background: #FFFFFF;border:2px solid #EDF0F6;border-radius: 10px;padding:15px 15px;display: flex;align-items: center;justify-content: space-between;}  
.skip_qu_btn{position: relative;}
.skip_qu_btn button{color: #000000;font-size: 16px;font-weight: 500;border: none;background: none;}
.skip_qu_btn.z_index{z-index: 111;}
.skip_qu_btn.z_index button{color: #fff;}
.end_btn_new{position: relative;}
.btn_new_end_sec{position: relative;display: flex;align-items: center;justify-content: center;background: #EB5757;width: 160px;height: 48px;color: #FFFFFF;font-size: 16px;font-weight: 500;border: none;border-radius: 50px;}
.tap_to_speak_sec{position: relative;}
.tap_ans_btn{position: relative;width: 220px;height: 60px;display: flex;align-items: center;gap: 10px;background: #A7A7A7;border: none;border-radius: 100px;color: #FFFFFF;font-size: 18px;font-weight: 700;justify-content: center;}
.tap_ans_btn span{height: 40px;width: 40px;background: #777777;color: #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center}
.tap_ans_btn span svg{width: 20px;}

.tap_ans_btn.active{position: relative;width: 220px;height: 60px;display: flex;align-items: center;gap: 10px;background: #EAF3FE;border: none;border-radius: 100px;color: #3B3B3B;font-size: 18px;font-weight: 700;justify-content: center;z-index: 111;}
.tap_ans_btn.active span{height: 40px;width: 40px;background: #0B4A96;color: #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center}
.tap_ans_btn.active span svg{width: 20px;}

.practice_ovelay_sec{position: relative;padding: 20px 0 0 0 !important;}
.prac_main_aft{position: relative;}
.prac_main_aft:after{position: absolute;top: 0px;left: 0;height: 100%;width: 100%;background: #00000099;z-index: 11;content: '';overflow: hidden;}
.practice_step_sec{position: absolute;right: 70px;bottom: 150px;z-index: 111;}
.practice_step_sec h6{position: relative;color: #FFFFFF;margin-bottom: 0 !important;font-size: 16px;font-weight: 600;}
.practice_step_sec h6 button{color: #B9D5F8;background: none;border: none;}
.practice_step_sec h6 button svg{font-size: 12px;}
.getting_start_sec{position: absolute;z-index: 111;left: 100px;bottom: 250px;}
.getting_start_sec h5{position: relative;font-size: 32px;font-weight: 700;color: #CDDAEB;margin-bottom: 20px !important;}
.getting_start_sec p{position: relative;color: #FFFFFF;font-size: 18px;font-weight: 400;margin-bottom: 20px !important;}
.getting_start_sec button{position: relative;display: inline-block;width: 110px;height: 45px;border-radius: 50px;border: 2px dashed #9DB5D7;font-size: 16px;font-weight: 400;color: #B5C8E1;background: none;}
.getting_start_sec_left{position: relative;}
.getting_start_sec_left span{position: relative;height: 40px;width: 40px;display: flex;align-items: center;justify-content: center;border-radius: 50%;background: #5880B7;font-size: 16px;font-weight: 500;color: #fff;}
.mian_start_sec{display: flex;align-items: flex-start;gap: 20px;}
.step_1{}
.step_1::after{content: '';background: url(../../../../assets/images/step-1.svg);height: 131px;width: 436px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: absolute;top: 150px;
  left: 200px;}
.z_index{z-index: 111;} 
.z_index_active{z-index: 111;}
.getting_right_btns{display: flex;align-items: center;gap: 15px;}
.step_3::after{content: '';background: url(../../../../assets/images/step-3.svg);height: 152px;width: 263px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: absolute;top: 145px;
  left: 10px;}
.step_4::after{content: '';background: url(../../../../assets/images/step-4.svg);height: 137px;width: 884px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: absolute;top: 150px;
  left: 260px;}
.speak_sec{position: absolute;right: 10px;border: none;color: #fff;  background: #0b4a96; border-radius: 50%;     width: 30px;
  height: 30px;display: flex;align-items: center;justify-content: center;} 
.speak_sec svg{font-size: 14px;}
  
button.troubleshoot {
  width: 95px;
  background: none;
  border: 1px solid #9DB5D7;
  color: #333;
}
.interview_mscq_module{position: relative;width: 100%;background:#F3F8FF;padding: 30px 0 40px 0;height: calc(100vh - 0vh);}

.interview_mscq_module_inner{position: relative;}
.interview_mscq_module_inner_head{position: relative;margin-bottom: 30px;}
.interview_mscq_module_inner_head_list{position: relative;margin-bottom: 12px;}
.list_sec_timer{position: relative;}
.interview_mscq_module_inner_head_list ul{position: relative;display: flex;align-items: center;justify-content: center;gap: 10px;}
.interview_mscq_module_inner_head_list ul li{position: relative;display: flex;align-items: center;justify-content: center;height: 41px;width: 41px;color: #333333;font-size: 14px;font-weight: 500;background: #fff;border-radius: 50%;border: 1.13px solid #F1F1F1}
.interview_mscq_module_inner_head_list ul li.active{background: #0B4A96;color: #fff !important;}
.list_sec_timer{position: absolute;right: 0px;top: 0;width: 140px;text-align: center;}
.list_sec_timer span{position: relative;display: block;text-align: center;color: #222222;font-size: 16px;font-weight: 700;margin-bottom: 5px;}
.list_sec_timer div{position: relative;display: flex;align-items: center;gap: 5px;font-size: 16px;color: #212529;gap: 10px;justify-content: center;}
.list_sec_timer div svg{font-weight: 400;}
.interview_mscq_module_inner_body{position: relative;width: 460px;margin: 0 auto;}
.ques_ans_sec{position: relative;margin-bottom: 0px;min-height: 352px;}
.ques_ans_sec_main_top{position: relative;text-align: center;}
.ques_ans_sec_main_top h6{position: relative;font-size: 16px;font-weight: 700;color: #252525;margin-bottom: 15px !important;}
.ques_ans_sec_main_btm{position: relative;margin-top: 25px;}
.ques_ans_sec_main_btm ul{display: block;}
.ques_ans_sec_main_btm ul li{margin-bottom: 20px;}
.ques_ans_sec_main_btm ul li:last-child{margin-bottom: 0px;}
.ques_ans_single{position: relative;width: 100%;}

.camera_start_sec{position: relative;height: 100%;background: #FFFFFF;border-radius: 18px;display: flex;align-items: center;
      justify-content: center;flex-direction: column;gap: 10px;}
.camera_start_sec img{width: 46px;height: 36px;}
.camera_start_sec p{position: relative;margin-bottom: 0px !important;color: #3D4852;font-size: 16px;font-weight: 700;}
.camera_start_sec p img{width: 18px;height: 18px;}
.mcq_sec_new_main.modal .ques_ans_sec_main_top h6{font-size: 14px;}


/* all set popup after tour */
.ques_ans_single .container_mcq {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #3D4852;
  font-weight: 500;
  width: 100%;
  background: #E2EAF1;
  /* height: 50px; */
  height: auto;
  display: flex;align-items: center;padding: 13px 20px; border-radius: 8px;justify-content: space-between;
  transition: background-color 0.3s;
  gap: 5px;
}
.container_mcq input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark_mcq {
  position: relative;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: #E2EAF1;
  border-radius: 50%;
  border: 1.5px solid #3D4852;
  top: 0%;
  transform: translateY(0%);
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, border 0.3s;
  flex: 0 0 18px;
}
.container_mcq:hover input ~ .checkmark_mcq {
  background-color: #E2EAF1;
}
.ques_ans_single .container_mcq input:checked ~ .checkmark_mcq {
  background-color: #0B4A96;
  border: 1.5px solid #fff;
}
.checkmark_mcq:after {
  content: "\f111";
  position: relative;
  font-family: 'Font Awesome 5 Free';
  display: none;
}
.container_mcq input:checked ~ .checkmark_mcq:after {
  display: block;
}
.container_mcq .checkmark_mcq:after {
  border-radius: 50%;
  top: 0px;
  left: 0px;
  font-weight: 900;
  color: #fff;
  font-size: 9px;
}

.container_mcq input:checked ~ .container_mcq {
  background-color: #0B4A96;
  color: #fff;
  box-shadow: 0px 4px 10px 0px #0000001A;
}
.checked_class{
  background-color: #0B4A96 !important;
  color: #fff !important;
  box-shadow: 0px 4px 10px 0px #0000001A;
}

.ques_ans_sec_btn{position: relative;display: flex;align-items: center;justify-content: space-between; margin: 30px 0 10px 0;}
.ques_btn_prev{position: relative;width: 120px;height: 45px;display: flex;align-items: center;justify-content: center;border-radius: 100px;border: 1.5px solid #0B4A94;color: #0B4A96;background: #fff;font-size: 16px;font-weight: 500;}
.ques_btn_next{position: relative;width: 120px;height: 45px;display: flex;align-items: center;justify-content: center;border-radius: 100px;border: 1.5px solid #0B4A96;color: #fff;background: #0B4A96;font-size: 16px;font-weight: 500;}

.new_body{width: 750px !important;position: relative;margin: 0 auto;}
.new_body1{width: 850px !important;position: relative;margin: 0 auto;}
.ques_ans_sec_text_area{position: relative;max-width: 540px;margin: 0 auto 60px;}
.ques_ans_sec_text_area textarea{height: 300px;width:600px;padding: 20px;border-radius: 10px;background: #E2EAF1;font-size: 16px;color: #252525;font-weight: 500;border: none;}
.ques_ans_sec_text_area_result textarea{width: 100%;height: 300px;padding: 20px;border-radius: 10px;background: #E2EAF1;font-size: 16px;color: #252525;font-weight: 500;border: none;}
.ques_ans_sec_text_area input{width: 100%;height: 250px;padding: 20px;border-radius: 10px;background: #E2EAF1;font-size: 16px;color: #252525;font-weight: 500;border: none;}
.new_lengt_btn{width: 500px;margin: 0 auto;}
.mic_icn_new{position: absolute;left: 0;right: 0;text-align: center;bottom: 15px;}
.mic_icn_new button{border: none;height: 32px;width: 32px;background: #777777;border-radius: 50%;}
.mic_icn_new button svg{color: #fff;font-size: 14px;}
.mic_wave_cin{position: absolute;top: 20px;left: 20px;}
.mic_wave_cin img{width: 18px;}

.allset_modal_sec .modal-dialog{max-width: 720px;background: #fff;padding: 15px;border-radius: 10px;}
.allset_modal_sec .modal-content{border: none;}
.allset_modal_sec_inner{position: relative;display: flex;align-items: center;justify-content: center;flex-direction: column;height: 270px;gap: 10px;}
.allset_modal_sec_inner img{width: 70px;margin: 0 auto 10px;}
.allset_modal_sec_inner h6{position: relative;font-size: 28px;font-weight: 700;color: #000000;margin-bottom: 0px !important;}
.allset_modal_sec_inner p{position: relative;margin-bottom: 0px;color: #000000;font-size: 18px;font-weight: 500;}

.hire_ai_interview_inner_left h4{position: relative;color: #000000;font-size: 22px;font-weight: 700;}
.hire_ai_interview_inner_left span{position: relative;display: block;width: 100%;color: #000000;font-size: 16px;font-weight: 400;margin-bottom: 30px !important;}
.interview_list_part{position: relative;margin: 40px 0px 40px 0 !important;}
.interview_list_part h3{position: relative;font-size: 16px;font-weight: 500;margin-bottom: 10px !important;line-height: inherit;color: #000;}
.interview_list_part ul{display: block;list-style: decimal !important;padding-left: 18px;}
.interview_list_part ul li{position: relative;font-size: 16px;font-weight: 500;margin-bottom: 10px !important;line-height: inherit;color: #000;}
.interview_list_part ul li b{font-weight: 700;display: inline-block !important;}


.modal_ass_instruction{display: block;}
.modal_ass_instruction .modal-dialog{width: 940px;max-width: 940px; border-radius: 10px;}
.modal_ass_instruction_inner{position: relative;padding: 35px 35px;background: #fff; border-radius: 10px;}
.modal_ass_instruction_inner h6{position: relative;color: #3D4852;font-size: 32px;font-weight: 400;margin-bottom: 25px !important;}
.modal_ass_instruction_inner h5{position: relative;color: #000;font-size: 22px;font-weight: 700;margin-bottom: 25px !important;}
.modal_ass_instruction_inner ul{display: block;position: relative;margin-bottom: 30px;}
.modal_ass_instruction_inner ul li{display: block;margin-bottom: 20px;}
.modal_ass_instruction_inner ul li h4{position: relative;line-height: inherit;color: #3D4852;font-size: 16px;font-weight: 700;margin-bottom: 5px !important;}
.modal_ass_instruction_inner ul li p{position: relative;margin-bottom: 3px !important;color: #212529;font-size: 14px;font-weight: 400;list-style: disc !important;}
.back_skp_btn{position: relative;display: flex;align-items: center;justify-content: space-between;}
.back_skp_btn_left{position: relative;}
.inst_back{position: relative;color: #4E5862;font-size: 14px;font-weight: 700;background: none;border: none;}
.back_skp_btn_right{position: relative;display: flex;align-items: center;gap: 10px;}
.skip_btn{position: relative;display: flex;align-items: center;justify-content: center;background: none;border: 1.5px solid #9DB5D7;padding: 8px 16px;color: #4E5862;font-size: 14px;font-weight: 700;border-radius: 50px;width: 160px;height: 50px;}
.assesment_btn{position: relative;display: flex;align-items: center;justify-content: center;border: 1.5px solid #0B4A96;padding: 12px 16px;color: #fff;font-size: 14px;font-weight: 700;border-radius: 50px;width: 255px;background: #0B4A96;height: 50px;}


.progress_section .ant-progress .ant-progress-outer {
  display: block;
}

.back_btn_main_sc{position: relative;display: flex;align-items: center;gap: 10px;color: #000;font-size: 18px;font-weight: 500;background: none;border: none;top: -30px;}
.back_btn_main_sc i{font-size: 14px;}
.interview_mscq_module_inner_main{position: relative;*box-shadow: 0px 2px 7px 0px #0000001f;*padding: 30px;
  *background: #fff;border-radius: 10px;}
  .interview_mscq_module_inner_main p{    color: #3D4852;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px !important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;}


.analsyse_sec_new_inner .progress-wd .ant-progress-text {
  top: 45px;
  text-align: center;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.list_sec_new{margin-top: 60px;text-align: center;}
.list_sec_new li{text-align: left;}
.mt_top_80{margin-top: 80px;}
.ques_ans_text_sec_new{position: relative;margin-top: 20px;text-align: center;}
.ques_ans_text_sec_new h5{font-size: 12px;margin-bottom: 0px !important;color: #858C93;font-weight: 700;}
.ques_ans_text_sec_new h5 span{display: inline-block;background: #E6FFE5;padding: 4px 10px;border-radius: 8px;font-size: 12px;font-weight: 700;}
.incoorect{background: #FFDEDE;color: #B24747;}
.coorect{background: #E6FFE5;color: #40A638;}

.mcq_sec_new_main.modal .cm-line{font-size: 9px !important;}
.mcq_sec_new_main.modal  .ͼ18{height: 300px !important;}


/* For screens smaller than 768px */
@media (max-width: 767px) {
  .next_question{
    margin-bottom: 0px !important;
    font-size: 12px;
  }
  .datetimepicker_container {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-right: none;
  }
  .loader_min{
    width: 20px;
    height: 20px;
  }
  .int_panel_body_sec_inner_btn button {
    width: 215px;
    height: 35px;
    font-size: 15px;
}
  .candidate_data {
    width: 100%;
    gap: 20px;
    padding: 20px;
  }
  .final_warning{
    font-size: 16px;
  }
  .warning_mod p{
    font-size: 12px;
  }

  .selected_date {
    width: 100%;
    padding: 10px;
    font-size: 12px;
  }

  .confirm_btn {
    width: 100%;
  }
  .mod_body_padding .col-6 {
    flex: 0 0 auto;
    width: 100%;
}

.modal_ass_instruction .modal-dialog {
  width: 95%;
  max-width: 95%;
}
.modal_ass_instruction_inner{padding: 15px;}
.modal_ass_instruction_inner h6{font-size: 20px;margin-bottom: 15px !important;}
.modal_ass_instruction_inner h5{font-size: 18px;margin-bottom: 15px !important;}
.modal_ass_instruction_inner ul li h4{font-size: 12px;}
.modal_ass_instruction_inner ul li p{font-size: 12px;}
.inst_back{font-size: 12px;}
.skip_btn{width: 100px;height: 40px;font-size: 10px;}
.assesment_btn{width: 170px;height: 40px;font-size: 10px;}
.interview_mscq_module_inner_body{width: 100%;}


}

@media only screen and (min-width: 992px){
.confirm-modal {
  --bs-modal-width: 1140px;
}
}
@media only screen and (min-width: 1801px) and (max-width: 1950px) {
  .welcm_text_inner h6{font-size: 20px;}
  .int_panel_body_sec_inner img{width: 450px;}
  .welcm_text_inner{bottom: 45px;}
  .boxContainer{height: 60px;top: 25%;}
  .int_panel_body_sec_inner{height: 77vh;}

}



@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .welcm_text_inner h6{font-size: 20px;}
  .int_panel_body_sec_inner img{width: 450px;}
  .welcm_text_inner{bottom: 45px;}
  .boxContainer{height: 60px;top: 25%;}
  .int_panel_body_sec_inner{height: 77vh;}

}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .welcm_text_inner h6{font-size: 20px;}
  .int_panel_body_sec_inner img{width: 430px;}
  .welcm_text_inner{bottom: 45px;}
  .boxContainer{height: 60px;top: 23%;}
  .int_panel_body_sec_inner{height: 75vh;}


}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .modal_permission_sec_inner{padding: 30px;}
  .camera_start_sec p{font-size: 12px;}
  .modal_permission_sec_inner_body_left{width: 330px;}
  .modal_permission_sec_inner_body_right_single_sec{width: 430px;padding: 15px;}
  .head_single_left_sec h5{font-size: 14px;}
  .head_single_left_sec img{width: 16px;}
  .head_single_right_sec h6{font-size: 10px;}
  .out_level_internet{width: 350px;}
  .microphone_check_sec input{width: 305px;}
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .modal_permission_sec_inner{padding: 30px;}
  .camera_start_sec p{font-size: 12px;}
  .modal_permission_sec_inner_body_left{width: 300px;}
  .modal_permission_sec_inner_body_right_single_sec{width: 340px;padding: 15px;}
  .head_single_left_sec h5{font-size: 12px;}
  .head_single_left_sec img{width: 14px;}
  .head_single_right_sec h6{font-size: 10px;}
  .out_level_internet{width: 270px;}
  .microphone_check_sec input{width: 270px;        padding-right: 45px;}
  .max_margin { margin-top: 15px;}
  .hire_ai_interview_inner{grid-template-columns: 1fr;}
  .test_btn_check{font-size: 10px;height: 30px;width: 75px;}

  /* .interview_mscq_module{background: none;} */
  .getting_start_sec h5{font-size: 22px;margin-bottom: 10px !important;}
  .getting_start_sec button{width: 100px;height: 40px;font-size: 14px;}
  .practice_step_sec h6{font-size: 14px;}
  .btn_new_end_sec{width: 150px;height: 42px;font-size: 14px;}
  .tap_ans_btn{width: 170px;height: 50px;font-size: 14px;}
  .skip_qu_btn button{font-size: 14px;}
  .hire_ai_interview_inner_left span{margin-bottom: 15px !important;}
  .assesment_btn{width: 200px;}
  .skip_btn{width: 140px;}

}
@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .modal_permission_sec .modal-dialog{width: 58%;max-width: 85%;}
  .modal_permission_sec_inner_body_right_single_sec{width: 300px;}
  .out_level_internet {width: 240px;}
  .microphone_check_sec input {width: 220px;}
  .step_1:after{left: 30px;background-size: 315px;}
  .modal_ass_instruction .modal-dialog {border-radius: 10px;max-width: 820px;width: 820px;}
  .assesment_btn, .skip_btn{font-size: 12px;height: 40px;}
  .interview_list_part {margin: 25px 0 !important;}
  



}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .shedule_int {width: 180px;height: 45px;font-size: 14px;}
  .shedule_int i{font-size: 14px;}
  .hire_left_btn_sec{gap: 12px;}
  .start_interview{width: 180px;height: 45px;font-size: 14px;}
  .hire_ai_interview_inner_left h6 {font-size: 32px;margin-bottom: 15px;}
  .hire_ai_interview_inner_left p {margin-bottom: 30px;font-size: 16px;}
  .int_panel_body_sec{background: none;}
  .int_panel_body_allow_btn_sec{background: none;}
  .welcm_text_inner h6{font-size: 16px;}
  .your_videoi_part_sec{width: 120px;height: auto;}
  .your_videoi_part_sec h6 {bottom: 5px; left: 5px; font-size: 10px; width: 40px; height: 25px;}
  .your_videoi_part_sec span img{width: 15px;}




}



@media only screen and (min-width: 768px) and (max-width: 991px) {

  .shedule_int {width: 180px;height: 45px;font-size: 14px;}
  .shedule_int i{font-size: 14px;}
  .hire_left_btn_sec{gap: 12px;}
  .start_interview{width: 180px;height: 45px;font-size: 14px;}
  .hire_ai_interview_inner_left h6 {font-size: 32px;margin-bottom: 15px;}
  .hire_ai_interview_inner_left p {margin-bottom: 30px;font-size: 16px;}
  .int_panel_body_sec{background: none;}
  .int_panel_body_allow_btn_sec{background: none;}
  .welcm_text_inner h6{font-size: 16px;}
  .your_videoi_part_sec{width: 120px;height: auto;}
  .your_videoi_part_sec h6 {bottom: 5px; left: 5px; font-size: 10px; width: 40px; height: 25px;}
  .your_videoi_part_sec span img{width: 15px;}

  .hire_ai_interview{height: 100vh;}

  :where(.css-dev-only-do-not-override-17sses9).ant-card-bordered {
    border: 1px solid transparent !important;
}
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .analsyse_sec_new_inner h6{
    font-size: 22px;
  }
  :where(.css-dev-only-do-not-override-17sses9).ant-progress-line{
    font-size: 12px;
  }
  .analsyse_sec_new_inner ul li{
    font-size: 16px;
  }
  .analsyse_sec_new_inner ul li:before {
    position: absolute;
    content: '';
    left: -28px;
    top: 8px;
    height: 8px;
    width: 8px;
    background: linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%);
    border-radius: 50%;
    animation: pulse-animation 2s infinite;
}
.analsyse_sec_new_inner ul li{
  padding-left: 0px;
}
.inter_view_panel_sec_inner_right h6{
  font-size: 18px;
  
}
}


@media only screen and (max-width:767px) {
  .cong_sec_new{align-items: baseline;}
  .ai_interview_process_inner h6{font-size: 18px;margin-bottom: 10px;}
  .ai_interview_process_inner p{margin-bottom: 10px;font-size: 12px;}
  .ai_interview_process_inner_sec_new{width: 100%;}
  .ai_int_sec_single_cont_sec{padding: 20px 15px;margin-bottom: 30px;}
  .ai_int_sec_single_cont_sec img {width: 100px;margin: 0 auto 20px;}
  .ai_int_sec_single_btn button { width: 120px;font-size: 14px;height: 40px;}
  .ai_int_sec_single_cont_sec h4 { margin-top: 15px;font-size: 14px;}
  .ai_int_sec_single_btn a {width: 150px;font-size: 14px;height: 45px;}
  .inter_view_panel_sec_inner {gap: 20px; flex-direction: row;align-items: flex-start;justify-content: space-between;}
  .vid_img{width: 30px;}
  .logo_img_sec {
    width: 60px;
}
  .inter_view_panel_sec_inner_right h6{font-size: 12px;}
  .inter_view_panel_sec_inner_right p{font-size: 10px;}
  .inter_view_panel_sec_inner_left:after{display: none;}
  .int_panel_body_sec_inner_btn a {width: 165px; height: 40px; font-size: 12px;}
  .int_panel_body_sec_inner{padding: 0px;}
  .int_panel_body_sec_inner_top {position: relative;top: 9;display: flex;align-items: center; justify-content: space-between;width: 100%;}
  .Sheduleint_sec h6{font-size: 14px;}
  .back_jobs{width: 100px;height: 35px;font-size: 11px;}
  .yes_btn{width: 100px;height: 35px;font-size: 11px;}
  .stay_connect_sec h5{font-size: 20px;}
  .stay_connect_sec p{font-size: 12px;}
  .congratu_sec h5{font-size: 19px;}
  .congratu_sec p{font-size: 13px;}
  .hire_ai_interview{padding: 25px 0;}
  .hire_ai_interview_inner {grid-template-columns: 1fr;grid-gap: 25px;}
  .hire_ai_interview_inner_left h6{font-size: 20px;margin-bottom: 10px;}
  .hire_ai_interview_inner_left p {font-size: 14px;margin-bottom: 20px;}
  .hire_left_btn_sec{ gap: 15px;flex-direction: column;}
  .shedule_int i {font-size: 12px;}
  .shedule_int {width: 200px;height: 42px;font-size: 14px;}
  .start_interview{width: 200px;height: 35px;font-size: 14px;}
  .allow_micro_phone_pop_inner a {width: 200px;height: 35px;font-size: 10px;}
  .int_panel_body_sec_inner_top span {font-size: 10px;padding: 7px 12px; width: auto;}
  .wave_icn {width: 25px !important;}
  .int_panel_body_sec{background: none;}
  .int_panel_body_allow_btn_sec{background: none;}
  .well_interview_sec_text {margin: 10px auto 20px;width: 100%;}
  .welcm_text_inner h6{font-size: 12px;}
  .your_videoi_part_sec {position: relative; right: 0; bottom: 0;width: 100%; height: auto;margin: auto 0 auto auto;}
  .your_videoi_part_sec h6 {bottom: 5px;left: 5px;font-size: 11px;width: 50px;height: 20px;}
  .your_videoi_part_sec span img { width: 20px;}
  .record_vdeo_icn_sec{margin-bottom: 15px;gap: 0px;justify-content: flex-start;}
  .record_vdeo_icn_sec span a{height: 25px;width: 25px;}
  .ent_int_btn{position: absolute;right: 0px;    top: -18px;}
  .ent_int_btn a { width: 85px; height: 35px; font-size: 10px;}
  .int_panel_body_allow_btn_sec_inner{padding-top: 0px;}
  .interview_ended {padding: 30px 0;}
  .interview_ended_inner{grid-template-columns: 1fr;}
  .interview_ended_inner_left h6 { font-size: 25px;margin-bottom: 12px;}
  .interview_ended_inner_left p {font-size: 12px;margin-bottom: 15px;}
  .interview_ended_inner_left select {width: 100%;height: 40px;}
  .interview_ended_inner_left input{width: 100%;height: 40px;}
  .interview_ended_inner_left a {font-size: 15px;width: 120px;height: 35px;}
  .reshedule_screen_inner h6{font-size: 18px;margin-bottom: 10px;}
  .reshedule_screen_inner p{font-size: 12px;margin-bottom: 10px;}
  .reshedule_screen_inner a { width: 115px; height: 30px; font-size: 10px;}
  .get_ready_panel_sec_inner_box { width: 100%; height: 100%; padding: 70px 15px;}
  .int_panel_body_sec_inner{height: 65vh;}


  .inter_panel_ready_sec_inner {padding: 20px 10px;}
.inter_panel_ready_sec_inner img{width: 40px;}
.inter_panel_ready_sec_inner h6{font-size: 18px;}
.inter_panel_ready_sec_inner p{font-size: 12px;}
.inter_view_panel_sec_inner_left {
  gap: 5px;
  align-items: center;
}
.output_level, .listening, .internet {
  font-size: 16px;
  font-weight: 700;
}
.max_margin {
  margin-top: 0px;
}
.allow_micro_phone_pop .modal-dialog{
  padding: 10px 40px;
}
.clear_bg_btn{
  font-size: 14px !important;
  padding: 0px 20px;
}
.blue_bg_btn img{
  display: none;
}
.cong_sec_new_inner_head_inner h6{
  font-size: 16px;
}
.cong_sec_new_inner_head_inner p{
  font-size: 12px;
  padding: 0px 10px;
}
.cong_sec_new_inner_head_inner img {
  width: 68px;
  margin-bottom: 0px;
}
.cong_sec_new_inner{
  width: 100%;
}
.ant-image .ant-image-img {
  height: 46vh !important;
}
.int_panel_body_sec_inner img{width: 100% !important;}
.boxContainer{top: 30%;}
.welcm_text_inner {width: 100%;position: relative;bottom: -50px;padding: 10px;}
.progress_section .ant-progress .ant-progress-text{
  width: auto;
  font-size: 8px !important;
}
.progress_section {
  position: absolute;
  width: 100%;
  top: -26px;
}
.progress_section .ant-progress .ant-progress-inner {
  border-radius: 6px 6px 0px 0px !important;
}
.progress_section .ant-progress-bg {
  height: 14px !important;
}
.int_panel_body_sec_inner img{
    height: auto !important;
    bottom: -73px !important;
}
.record_vdeo_icn_sec{
  display: block;
  margin-bottom: 28px;
}
.your_videoi_part_sec {
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: auto 0 auto auto;
  background: transparent;
  border: none;
}
.your_videoi_part_sec h6 {
  bottom: 10px;
  left: 2px;
  font-size: 8px;
  width: 38px;
  height: 15px;
}
.your_videoi_part_sec {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 135px;
}
.int_panel_body_sec_inner .ant-card-body {
  position: relative;
  height: auto;
  width: auto;
  padding: 0px !important;
}
.skipQ {
  position: absolute !important;
  bottom: 243px !important;
  left: 8px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  cursor: pointer;
  z-index: 1;
  padding: 6px 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px #0000001f;
}
.tap_speak_button{
width: 100%;
justify-content: center;
}
.tap_speak_button span{
  font-size: 14px;
}
.tap_speak_button span svg {
  margin-right: 10px;
  width: 34px;
}
.timer img{
  width: 12px !important;
}
.welcm_text_inner {
  width: 100%;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 14px 14px;
}
.timer{
  font-size: 12px !important;
}
.disa{
  flex-direction: column;
}
.ent_int_btn{
  position: relative;
}
.ent_int_btn button{
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.generating_p{
  font-size: 14px;
}
.interview_modal_sm{
  width: 90% !important; 
}

.ai_inter_guide_sec{padding: 5px;}
.ai_inter_guide_sec_head h6{padding-bottom: 15px !important;}


}

@media only screen and (min-width: 700px) and (max-width: 767px) {



 
}
@media only screen and (min-width: 690px) and (max-width: 830px){
  .int_panel_body_sec_inner img{
    width: 30vh !important;
  }
  .int_panel_body_sec_inner {
    height: 50vh;
    display: grid;
}
.timer img{
  width: 20px !important;
}
.ant-image .ant-image-img {
  height: 38vh !important;
  top: -16px;
  width: 100% !important;
}
.progress_section {
  position: absolute;
  width: 100%;
  top: -37px;
}
.progress_section .ant-progress .ant-progress-inner {
  border-radius: 10px 14px 0px 0px !important;
}
}

@media (min-width: 280px) and (max-width: 320px){
 .int_panel_body_sec_inner {
    height: 50vh;
}
}