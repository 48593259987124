/* Transcript.css */
.video-marker-wrapper {
  position: absolute;
  bottom: 5px; /* Position just above the player controls */
  width: 100%;
  height: 5px;
  pointer-events: none;
}

.video-marker {
  position: absolute;
  height: 5px;
  width: 2px;
  background-color: red;
  pointer-events: auto; /* Allow clicking */
  cursor: pointer;
}

.highlighted-text {
  background-color: yellow; /* Highlight the search terms */
}

.Transcript_main_action1 {
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 10px;
}
  .Transcript_main_action {
    padding: 0px 5px;
  }


.video-player-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 12px;
}

.video-player-section {
  width: 100%; /* Changed from 65% to fill container */
  height: 20%;
  background-color: transparent !important; /* Dark background for video */
  aspect-ratio: 16 / 9;
  position: relative;
}

.search-bar {
  display: flex;
  margin-bottom: 0px;
  gap: 20px;
}
.search_input_transcript {
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 10px;
}
.trans_question_main {
  margin-bottom: 0px;
}
.ques_sec{color: #0B4A96;margin-bottom: 10px;font-size: 12px;} 
.custom_que_tag {
  font-size: 8px;
  font-weight: 700;
  background-color: rgba(203, 223, 250, 1);
  color: rgba(11, 74, 150, 1);
  border: none;
}
.Transcript_main_title {
  display: flex;
  justify-content: space-between;
  height: 21px;
  margin-bottom: 12px;

  p {
    /* style through the class if affect many */
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .transcript_download_button {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and icon */
    padding: 8px 16px;
    color: #1865c1; /* Blue color from your design */
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px; /* Fixed width to maintain consistent button size */
    height: 20px; /* Fixed height to maintain consistent button size */
  }
}

.navigation-buttons {
  display: flex;
  margin: 12px 0px;
}

.navigation-buttons .ant-btn {
  border: none;
  background: none;
  cursor: pointer;
}


.transcript-entry {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.collaps_comp.highlighted-section {
  background-color: rgba(237, 245, 254, 1); /* Light transparent blue */
  border: 1px solid rgba(185, 213, 248, 1);
  .ant-collapse-content {
    background-color: rgba(237, 245, 254, 1);
  }
}

.video-marker {
  position: absolute;
  top: 0;
  width: 4px;
  height: 10px;
  background-color: red;
  cursor: pointer;
}

.Transcript_main_search_section {
  /* position: absolute; */
  /* top:-70px; */
}

.download_btn {
  color: rgba(11, 74, 150, 1);
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 0px;
  img {
    margin-left: 8px;
    margin-bottom: 5px;
  }
}
.min-vd {
  height: 60vh;
}

.mux-video-wrapper {
  position: relative; /* Ensures children are positioned relative to this wrapper */
  width: 100%;
  height: auto;
}

.marker-container {
  position: absolute; /* Aligns markers relative to the video */
  bottom: 30px; /* Adjust this to match the position of the timeline in your video player */
  left: 0;
  width: 100%; /* Match the width of the video element */
  height: 10px; /* Adjust for the height of the marker line */
  z-index: 10; /* Place it above the video */
  pointer-events: none; /* Ensures clicks pass through */
}

.marker {
  position: absolute; /* Individual markers are absolutely positioned within the container */
  top: 0;
  width: 2px;
  height: 100%; /* Adjust the height to fully extend within the container */
  background-color: red;
  pointer-events: auto; /* Allows interaction with markers */
  cursor: pointer;
}

/* .results-container{
    background-color: rgb(235, 235, 235);
    padding: 60px ;
  } */
.results-container .ant-tabs-nav-list {
  padding: 8px 20px;
  border-radius: 30px;
  width: 50%;
  height: 52px;
  background-color: #fff;
  margin-bottom: 12px;
}

/* Assuming you have a separate CSS file, add these styles */
.results-container .ant-tabs-nav::before,
.results-container .ant-tabs-nav-wrap {
  border-bottom: none; /* Removes the default bottom border */
}

.results-container .ant-tabs-tab {
  margin-right: 0px; /* Removes space between tabs */
  width: 210px;
  display: flex;
  justify-content: center;
}

.results-container {
  background-color: #f5f6fb;
  margin-top: 0px;
  padding-top: 76px;
  height: 100vh;
  overflow: auto;

  .Transcript_main {
    background-color: transparent;
    height: calc(85vh);
    margin: 0px;
    padding: 0px;
  }

  .Transcript_main_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.results-container .ant-tabs-tab.ant-tabs-tab-active {
  border-radius: 40px;
  width: 210px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.results-container
  :where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: rgba(11, 74, 150, 1) !important;
}
.results-container .ant-tabs-ink-bar {
  display: none; /* Hides the default active tab marker */
}
.results-container .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 14px;
}

.collaps_comp {
  margin-top: 12px;
  border: none;
  background-color: transparent;
  /* background-color: red; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08) !important;
}
  .ant-collapse-header {
    border: none;
    background-color: transparent;
    border-radius: 8px !important;
    /* padding-bottom: 0px !important; */
  }
  /* Remove the border between items */
  .ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
    border-radius: 8px;
  }

  /* If you still see any lines, you can also add this */
  .ant-collapse-content {
    border-top: none !important;
  }
  .ant-collapse-content-box {
    padding: 0px 16px;
  }
  .ant-collapse-item {
    border-bottom: none;
  }
  .trans_answer::before {
    background-color: transparent !important;
  }

  .candidate_ans_header {
    width: 100%;
    display: flex;
    font-weight: 700 !important;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
  .hearder_first {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
.span_sec_text .ant-tag{margin-top: 0px !important;}

.answer_is_correct {
  background-color: #E6FFE5 !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: #40A638 !important;

}


.answer_is_correct.container_mcq .checkmark_mcq {
  background-color: #E6FFE5 !important;
  border: 1.5px solid #40A638 !important;
}
.answer_is_correct .checkmark_mcq:after {
  content: "\f111";
  position: relative;
  font-family: 'Font Awesome 5 Free';
  display: block;
}
.answer_is_correct.container_mcq .checkmark_mcq:after {
  border-radius: 50%;
  top: 0px;
  left: 0px;
  font-weight: 900;
  color: #40A638;
  font-size: 9px;
}

.answer_is_correct.container_mcq input:checked ~ .container_mcq {
  background-color: #40A638;
  color: #40A638;
}

.answer_is_incorrect {
  background-color: rgba(255, 214, 214, 0.8) !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: #B24747 !important;
}

.answer_is_incorrect.container_mcq input:checked ~ .checkmark_mcq{
  border: 1.5px solid #B24747;
}



.answer_is_incorrect.container_mcq .checkmark_mcq {
  background-color:  rgba(255, 214, 214, 0.8) !important;
  border: 1.5px solid #B24747;
}
.answer_is_incorrect .checkmark_mcq:after {
  content: "\f111";
  position: relative;
  font-family: 'Font Awesome 5 Free';
  display: block;
}
.answer_is_incorrect.container_mcq .checkmark_mcq:after {
  border-radius: 50%;
  top: 0px;
  left: 0px;
  font-weight: 900;
  color: #B24747;
  font-size: 9px;
}

.answer_is_incorrect.container_mcq input:checked ~ .container_mcq {
  background-color: #B24747;
  color: #B24747;
}

.remove_checkmark::after {
  display: none !important;
}

/* 
.ques_ans_single .container_mcq{
  background: #F7F7F7;
} */


.result_screen_new_sec{position: relative;padding: 100px 0 50px 0;background: #f5f6fb;height: 100vh;}
.result_screen_new_sec_inner{position: relative;}
.result_screen_back{position: relative;padding-bottom: 15px;}
.back_btn_main{position: relative;display: flex;align-items: center;gap: 10px;color: #000;font-size: 18px;font-weight: 500;background: none;border: none;}
.back_btn_main i{font-size: 14px;}
.result_screen_head_tab{position: relative;background: #fff;border-radius: 30px;padding: 8px 10px;width: 750px;}
.result_screen_head_tab_inner{position: relative;display: flex;align-items: center;}
.result_screen_head_tab_inner button{width: 180px;height: 36px;background: #fff;color: #212529;font-size: 14px;font-weight: 700;display: flex;align-items: center;justify-content: center;border: none;background: #fff;border-radius: 30px;}
.result_screen_head_tab_inner button.active{background: #B5D7FF;color: #1865C1;}


.tab_main_sec_new{position: relative;}
.tab_main_sec_new .ant-tabs-nav-wrap{border-bottom: none;}
.tab_main_sec_new .ant-tabs-nav-list{position: relative;background: #fff;border-radius: 30px;padding: 8px 10px;width: auto;align-items: center;justify-content: space-between;}
.tab_main_sec_new .ant-tabs-tab{width: 180px;height: 36px;background: #fff;color: #212529;font-size: 14px;font-weight: 700;display: flex;align-items: center;justify-content: center;border: none;background: #fff;border-radius: 30px;    font-family: 'Satoshi-Variable';margin: 0px;}
.tab_main_sec_new .ant-tabs-tab-active{width: 180px;height: 36px;font-size: 14px;font-weight: 700;display: flex;align-items: center;justify-content: center;border: none;background: #fff;border-radius: 30px;    font-family: 'Satoshi-Variable';background: #B5D7FF;color: #1865C1;margin: 0px;}
.ant-tabs-ink-bar{display: none;}

.result_screen_body_sec{position: relative;margin-top: 20px;}
.Profile_tab_main_sec{position: relative;}
.Profile_tab_main_sec_upper{position: relative;background: #fff;border: 1px solid #EEEEEE;padding: 15px 30px;display: flex;align-items: center;justify-content: space-between;width: 100%;border-radius: 10px;}
.Profile_tab_main_sec_upper_left{position: relative;display: flex;align-items: flex-start;flex-direction: column;gap: 10px;}
.Profile_tab_main_sec_upper_left h6{position: relative;font-size: 14px;font-weight: 700;color: #000;margin-bottom: 0px !important;line-height: inherit;}
.Profile_tab_main_sec_upper_left h5{position: relative;font-size: 14px;font-weight: 700;color: #000;line-height: inherit;margin-bottom: 0px !important;}
.Profile_tab_main_sec_upper_left p{position: relative;font-size: 14px;font-weight: 400;color: #000;margin-bottom: 0px;}
.Profile_tab_main_sec_upper_right{position: relative;display: flex;align-items: flex-end;gap: 10px;flex-direction: column;}
.resumePdf {padding: 10px 20px; font-size: 12px;  border-radius: 5px; border: 0.6px solid #d6ddeb; cursor: pointer;
    color: #000; font-weight: 500;}
.Profile_tab_main_sec_upper_right p{position: relative;text-align: right;font-size: 12px;font-weight: 400;color: #000;margin-bottom: 0px;}    

.Profile_tab_main_sec_below{position: relative;background: #fff;border: 1px solid #EEEEEE;padding: 15px 30px;display: flex;align-items: center;justify-content: space-between;width: 100%;border-radius: 10px;margin-top: 20px;flex-direction: column;}
.Profile_tab_main_sec_below_single{position: relative;width: 100%;margin-bottom: 20px;}
.Profile_tab_main_sec_below_single label{position: relative;color: #3D4852;font-size: 12px;font-weight: 700;padding-bottom: 10px;}
.Profile_tab_main_sec_below_single_body{position: relative;display: flex;align-items: center;gap: 10px;width: 100%;}
.select_box_reu{position: relative;width: 60px;height: 44px;background:#F5F5F5;display: flex;align-items: center;justify-content: center;color: #000;font-size: 16px;border: none;appearance: none;border-radius: 8px;text-align: center;}
.input_box_new{position: relative;width: 100%;height: 44px;background: #F5F5F5;color: #ACB1B6;font-size: 12px;font-weight: 400;border: none;border-radius: 8px;padding-left: 20px;align-items: center;display: flex;}
.text_box_sec{position: relative;width: 100%;background: #F5F5F5;color: #ACB1B6;font-size: 12px;font-weight: 400;border: none;border-radius: 8px;padding: 20px;}
.Written_test_tab_main_sec{position: relative;margin-top: 20px;}
.Written_test_tab_main_sec h4{position: relative;color: #038840;font-size: 16px;font-weight: 700;text-align: center;margin: 40px 0 !important;}
.Written_test_tab_main_sec_inner{position: relative;background: #fff;border: 1px solid #EEEEEE;padding: 15px 30px;border-radius: 10px;}
.table_sec_written thead tr td{position: relative;padding: 0 0 15px 0!important;color: #000001;font-size: 16px;font-weight: 700;border-bottom: 1px solid #F0F0F0;text-align: center;}
.table_sec_written thead tr td:first-child{text-align: left;}
.table_sec_written tbody tr td{position: relative;padding: 15px 0 5px 0!important;color:#3D4852;font-size: 14px;font-weight: 400;border-bottom:none;text-align: center;}
.table_sec_written tbody tr td:first-child{text-align: left;}
.rotate{transform: rotate(45deg);}
.mcq_sec_new_main .modal-dialog{max-width: 100%;margin-left: 15%;margin-right: 15%; box-shadow: 0px 4px 13px 0px #00000040;background: #fff;padding: 20px;border-radius: 10px;top: 50px;}
.mcq_sec_new_main .modal-content{border: none;}
.mcq_sec_new_main .close_icn{position: absolute;top: -80px;right: 0;left: 0;background: #fff;box-shadow: 0px 4px 4px 0px #0000000A;border-radius: 50%;height: 50px;width: 50px;border: none;text-align: center;margin: 0 auto;}
.mcq_sec_new_main .close_icn i{font-size: 18px;}
.mcq_sec_new_main_inner{position: relative;}
.mcq_sec_new_main .inter_view_panel_sec{display: none;}

.mcq_sec_new_main_inner_head{position: relative;text-align: center;margin-bottom: 15px;}
.mcq_sec_new_main_inner_head h6{position: relative;color: #212529;font-size: 16px;font-weight: 700;margin-bottom: 0px;}
.mcq_sec_new_main .interview_mscq_module{background: #fff;padding: 10px 0;height: 100%;max-height: 100%;}
.mcq_sec_new_main .interview_mscq_module .container-fluid { padding-left: 0;padding-right: 0;}
.mcq_sec_new_main .interview_mscq_module .list_sec_timer{top: -50px;}
.mcq_sec_new_main .interview_mscq_module .ques_ans_sec { position: relative;margin-bottom: 30px; min-height: auto;}
.mcq_sec_new_main .interview_mscq_module .ques_ans_sec_btn{margin-bottom: 0px;}
.mcq_sec_new_main .interview_mscq_module .practiceAssessment_question_container__3vW6A{width: auto;margin-left: 0px;width: auto;margin-bottom: 20px;padding: 15px 0 0 0;}
.mcq_sec_new_main .interview_mscq_module .interview_mscq_module_inner_body{width: auto;}
.mcq_sec_new_main .interview_mscq_module .CodeEditor_code_mirror_sec__YREi4{padding: 0px;}

.mcq_sec_new_main .ques_ans_single .container_mcq{background: #f7f7f7;height: 50px;padding: 0 20px;}
.mcq_sec_new_main_inner_head span{
  position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    color: #212529;
    font-size: 14px;
    font-weight: 500;
}

.ant-tabs-tab-active .ant-tabs-tab-active-focus{outline: none;}
.Transcript_main_wrapper{display: flex;gap: 10px;justify-content: space-between;}
.new_trasn_sec{padding: 20px;background: #fff;border-radius: 10px;height: auto;}


.ant-tabs-content-holder .Written_test_tab_main_sec h4{margin: 20px 0;}

.result_screen_new_sec_inner .tab_main_sec_new .ant-tabs-nav-wrap{}

.new_div_sec_flex{display: flex;align-items: center;justify-content: space-between;width: 100%;flex-direction: column;align-items: flex-end;}
.transcript_download_new{
  position: absolute;
    right: 0;
    z-index: 9;
    top:7px;
    padding: 8px 20px;
    border: 2px solid #1865C1;
    background: #1865C1;
    color: #fff;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
}
.btn_main{width: 125px;}
.position_absolute_sec {
  position: absolute;
  left: auto;
  top: 50px;
  z-index: 9;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 125px;
  right: 0;
}
.position_absolute_sec li button{color: #212529;font-weight: 500;font-size: 12px;padding: 8px 5px;}

.ant-tooltip-inner{
  background-color: #fff !important;
  color: #000 !important;
}

:where(.css-dev-only-do-not-override-19lec04).ant-tooltip{
  --antd-arrow-background-color: white !important;
}

@media only screen and (min-width: 1100px) and (max-width: 1199px) {

  .result_screen_new_sec_inner .tab_main_sec_new .ant-tabs-nav-wrap {
    width: 80%;
    flex: 0 0 80%;
}

}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {

  .result_screen_new_sec_inner .tab_main_sec_new .ant-tabs-nav-wrap {
    width: 80%;
    flex: 0 0 80%;
}

}

@media only screen and (min-width: 900px) and (max-width: 999px) {

  .result_screen_new_sec_inner .tab_main_sec_new .ant-tabs-nav-wrap {
    width: 80%;
    flex: 0 0 80%;
}

}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .Transcript_main_wrapper { gap: 30px; flex-direction: column; height: auto;}
  .Transcript_main_wrapper .ant-col{ flex: 100%; max-width: 100%;}
  .result_screen_new_sec{padding: 20px 0 !important;}
  .tab_main_sec_new .ant-tabs-nav-list{width: 100%;}

}


@media only screen and (max-width:767px) {
  .transcript_download_new{top: -50px;}
  .result_screen_new_sec{padding: 50px 0;}
  .Transcript_main_wrapper{flex-direction: column;}
}
