.trusted_logo_slide_owl{
    width: 100% !important;
    margin-top: 80px;
}   
 .ai_new{
    font-size: 58px;
    margin-bottom: 40px !important;
    color: #212529;
}
.ai_new span{
    color: #0B4A96;
}

.scroll-down {
  margin-top: 40px;
  position: relative; /* Ensure the container is positioned relatively */
  height: 100px; /* Set the height of the container */
  overflow: hidden; /* Hide overflow to keep the dot within bounds */
}

.scroll-down .scroll-dot {
  height: 62px;
  width: 32px;
  border: 2px solid #1865C1;
  position: absolute;
  left: 50%;
  top: 50%; /* Center vertically */
  transform: translate(-50%, -50%); /* Adjust for vertical centering */
  border-radius: 50px;
  cursor: pointer;
}

.scroll-down .scroll-dot:before {
  content: "";
  position: absolute;
  bottom: 20%;
  left: 35%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid #1865C1;
  border-radius: 10px;
  background-color: #1865C1;
  animation: scroll-down 1.8s ease-in-out infinite;
}
.header-banner span{
  display: flex;
    gap: 10px;
    align-content: center;
    align-items: center;
    color: #000;
    font-weight: 500;
}

@keyframes scroll-down {
  0% {
    opacity: 1;
    transform: translateY(0); /* Start from the initial position */
  }
  50% {
    opacity: 1;
    transform: translateY(-20px); /* Move the dot up within the container */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Move back to the starting position */
  }
}





/* Rajesh Css */


.how_works_for_req{position: relative;margin: 20px 0;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);padding: 50px 0 60px 0;}
.how_works_for_req_inner{position: relative;}
.how_works_for_req_inner_head{position: relative;text-align: center;margin-bottom: 50px;}
.how_works_for_req_inner_head h3{position: relative;font-size: 50px;font-weight: 700;color: #fff;margin: 0px;line-height: inherit;}
.how_works_for_req_inner_body_sec{position: relative;display: grid;grid-template-columns: repeat(4,1fr);grid-gap: 70px;margin-bottom: 50px;}
.how_works_for_req_inner_body_sec_single{position: relative;text-align: center;}
.how_works_for_req_inner_body_sec_single_img{position: relative;height: auto;width: 100%;margin-bottom: 30px;}
.how_works_for_req_inner_body_sec_single_img img{width: 100%;object-fit: cover;box-shadow: 0px 0.97px 6.9px 0px #00000014;border-radius: 12px}
.how_works_for_req_inner_body_sec_single_cont{position: relative;}
.how_works_for_req_inner_body_sec_single_cont h4{position: relative;font-size: 20px;font-weight: 500;color: #fff;margin-bottom: 0px;line-height: inherit;}

.sign_up_req_btn_sec{position: relative;display: flex;align-items: center;justify-content: center;}
.sign_up_req_btn_sec a{position: relative;padding: 16px 32px;color: #000000;font-size: 18px;font-weight: 700;background: #fff;border-radius: 90px;}

.how_works_for_req_inner_body_sec_single:after{content: '';position: absolute;background: url(../../assets/images/arrow_line.svg);width: 52px;height: 16px;right: -65px;top: 130px;}
.how_works_for_req_inner_body_sec_single:last-child:after{display: none;}




.key_features_sec{position: relative;padding: 80px 0;background: url(../../assets/images/fea_bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center center;}
.key_features_sec_inner{position: relative;}
.key_features_sec_head{position: relative;text-align: center;margin-bottom: 50px;}
.key_features_sec_head h6{position: relative;font-size: 48px;font-weight: 700;color: #252525;margin-bottom: 0px;}
.key_features_sec_head h6 span{ background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;}
.key_features_sec_inner_body{position: relative;display: grid;grid-template-columns: repeat(4,1fr);grid-gap: 40px;margin-bottom: 50px;}
.key_features_sec_inner_body_single{position: relative;box-shadow: 0px 10.67px 37.36px 0px #00000014;background: #FFFFFF;border-radius: 15px;padding: 30px;height: 330px;}
.key_features_sec_inner_body_single h5{position: relative;color: #0954AE;font-size: 24px;font-weight: 700;margin-bottom: 15px;}
.key_features_sec_inner_body_single p{position: relative;font-size: 16px;color: #000000;line-height: 24px;font-weight: 400;margin-bottom: 0px;}
.key_features_sec_inner_body_single span{position: absolute;height: 52px;width: 52px;background: #0954AE;color: #fff;font-size: 24px;font-weight: 700;display: flex;align-items: center;justify-content: center;border-radius: 50%;bottom: 15px;
    right: 15px;}
.key_features_sec_btn_new{position: relative;text-align: center;}    



.client_testi_sec{position: relative;padding: 10px 0 60px 0;}
.client_testi_sec_inner{position: relative;}
.client_testi_sec_head{position: relative;text-align: center;margin-bottom: 50px;}
.client_testi_sec_head h6{position: relative;font-size: 48px;font-weight: 700;color: #252525;margin-bottom: 0px;}
.client_testi_sec_head h6 span{ background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
    -webkit-background-clip: text; -webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;}
.client_testi_sec_inner_body{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 40px;}
.client_testi_sec_single{position: relative;width: 100%;box-shadow: 0px 4px 68.7px 0px #0000000D;background: #FFFFFF;padding: 50px 60px;border-radius: 15px;}    
.client_testi_sec_single p{position: relative;font-size: 18px;font-weight: 400;color: #000;margin-bottom: 70px;}
.client_testi_img{position: relative;display: flex;align-items: center;gap: 10px;}
.client_testi_img_img{position: relative;width: 53px;height: 53px;}
.client_testi_img_img img{border-radius: 50%;width: 100px;height: 53px;}
.client_testi_img_text{position: relative;}
.client_testi_img_text h6{position: relative;font-size: 18px;font-weight: 900;color: #000;margin-bottom: 10px !important;}
.client_testi_img_text p{position: relative;font-size: 14px;font-weight: 400;color: #989898;margin-bottom: 0px !important;}
img.back_slash_sec {margin-bottom: 30px;    width: 34px !important;}

.client_testi_sec_inner_body.owl-carousel .owl-item {
  padding: 20px;
}


.ai_enhance_sec_new_inner_head h5 span::before{
    display: none;
}
.ai_enhance_sec_new_inner_head h5 span{
    color: black;
}
.ai_enhave_btn_sec{
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    display: block;
    margin: 0px auto 0;
    overflow: hidden;
    top: 0;
    line-height: normal;
}
.Data-driven{
    font-size: 32px;
    color: black;
    font-weight: 700;
}
.remark_sec_new {
    position: relative;
    padding: 45px 0 0px 0;
}
.remark_sec_new_inner_single h6{
    color: #0B4A96 !important;
}
.remark_sec_new_inner_single:hover {
    background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%) !important;

    box-shadow: 0px 4px 32.900001525878906px 0px #00000040;
  }
  .remark_sec_new_inner_single:hover h6 {
    color: #fff !important;
  }
  .remark_sec_new_inner_single:hover p {
    color: #fff !important;
  }
  .remark_sec_new_inner_single.active {
    background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%) !important;
    box-shadow: 0px 4px 32.900001525878906px 0px #00000040;
  }
  .remark_sec_new_inner_single.active h6 {
    color: #fff !important;
  }
  .remark_sec_new_inner_single.active p {
    color: #fff !important;
  }
  .card_title{
    font-size: 22px !important;
    font-weight: 700 !important;
  }
  .remark_sec_new_inner{
    width: 100%;
  }
  .footer_sec_pro_rect_new{
    padding: 30px;
    background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
    position: relative;
  }
  .footer_about_section_new ul{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    color: #fff;
  }
  .footer_about_section_new ul li a{
    color: #fff;
  }
  .social-icons-new{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
  .thin-line{
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: 0.5px solid #629FEA;
  }
  .main-theam {
    color: #fff;
  }
  .footer-text {
    color: white;
  }

  .img-margin{
    margin-right: 20px;
  }
.haire-landing-video{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.faq-new-bg{
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%) !important;
}
.New-accordian{
  background: rgba(214, 230, 255, 0.24) !important;
  border: none !important;
}
.New-accordian .accordion-button,.accordion-body{
  color: #FFFFFF !important;
}
.question-input:disabled{
  color:#FFFFFF !important;
}
.banner-container1{
  position: relative;
    padding: 80px 0;
    background: url(../../assets/images/Wave_lg.svg);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    text-align: center;
    padding-bottom: 0px;
}
.light-blue{
  background: linear-gradient(to right, #0B4A96 40%, #5094E7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f8f9fa; /* Adjust the background color as needed */
}

.footer-section {
  flex: 1;
}

.footer-left {
  text-align: left;
  
}
.footer-left p{
  margin-bottom: 0px;
}

.footer-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.footer-contacts {
  display: flex;
  justify-content: flex-end;
}

.footer-contact-item {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.footer-icon {
  margin-right: 10px;
}

.footer-text {
  font-size: 14px;
  color: #6c757d; /* Adjust the text color as needed */
}
.faq_boy_icn_new_sec1 {
  position: relative;
  text-align: center;
  padding-top: 50px;
}

/* #flush-collapseOne{
border-top: 1px solid white;
} */





  .get_in_touch_sec_left_new{position: relative;}
  .get_in_touch_sec_left_new h6{position: relative;color: #416EAC;font-size: 48px;font-weight: 700;margin-bottom: 30px !important;}
  .get_in_touch_sec_left_new p{position: relative;color: #212529;font-size: 18px;font-weight: 400;margin-bottom: 20px !important;}
  .get_in_touch_sec_left_new a{position: relative;display: inline-flex;align-items: center;gap: 8px;color: #000000;font-size: 14px;font-weight: 500;}
  .get_in_touch_sec_left_new a span{position: relative;display: flex;align-items: center;justify-content: center;height: 40px;width: 40px;background: #DBF0FF;border-radius: 5px;}
  .get_in_touch_sec_left_new a span .svg-inline--fa{font-size: 18px;font-weight: 600;color: #1865C1;}
  .required_asterisk {
    color: red;
    margin-left: 1px;
    top: -5px;
    position: relative;
}
  
  .new_get_sec{position: relative;padding: 80px 0;background: url(../../assets/images/get_Bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center center;}

  

  .haire_key_sec_new{position: relative;padding: 30px 0 60px 0;background: url(../../assets/images/hikes_bg.svg);background-size: cover;width: 100%;background-repeat: no-repeat;background-position: center center;}
  /* .haire_key_sec_new{position: relative;padding: 30px 0 60px 0;} */
  .haire_key_sec_new_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 35px;}
  .haire_key_sec_new_inner_left{position: relative;}
  .haire_key_sec_new_inner_left h5{position: relative;font-size: 48px;font-weight: 700;color: #212529;line-height: 57.6px;margin-bottom: 50px !important;}
  .haire_key_sec_new_inner_left h5 span{color: #0B4A96;}

  .szh-accordion__item{box-shadow: 0px 10.67px 37.36px 0px #00000014;padding: 25px;border-radius: 10px;position: relative;}
  .szh-accordion__item-heading{position: relative;line-height: inherit;margin-bottom: 0px !important;}
  .szh-accordion__item-heading button{position: relative;color: #0B4A96;border: none;background: none;line-height: inherit;font-size: 24px;font-weight: 600;padding: 0px;}
  .szh-accordion__item-panel{padding-top: 10px;}
  .szh-accordion__item-panel p{position: relative;color: #212529;font-size: 16px;font-weight: 500;margin-bottom: 0px !important;}
  .span_sec_num{position: absolute;right: 0;top: 0;}

  .haire_key_sec_new_inner_right{position: relative;}
  .haire_key_sec_new_inner_right .accordion .accordion-item { border-radius: 0; background: #fff;padding: 0;
    background-color: #fff; border: none !important;box-shadow: 0px 10.67px 37.36px 0px #00000014;padding: 25px;border-radius: 10px;position: relative;}
  .haire_key_sec_new_inner_right .accordion-header{margin-bottom: 0px !important;}
  .haire_key_sec_new_inner_right .accordion-header button{position: relative;color: #0B4A96 !important;border: none;background: none !important;line-height: inherit;font-size: 24px;font-weight: 600 !important;padding: 0px !important;}
  .haire_key_sec_new_inner_right .accordion-header button:after{display: none;}

  .filter-container1 {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.Need-help-main{
  margin-top: 30px;
  margin-bottom: 60px;
}
.need-help{
  background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);
  margin: 0 auto;
  padding: 60px;
  position: relative;
}
.Need-help-main_das{
  color: #FFFFFF;
}
  .haire_key_sec_new_inner_right .number-count {right: 0;height: 40px;width: 40px;
    display: flex;align-items: center;justify-content: center;background: #0B4A96;border-radius: 50%;color: #fff;
    font-size: 16px;}
  .haire_key_sec_new_inner_right .accordion-header button:after{display: none;}
  .haire_key_sec_new_inner_right .accordion-body p{position: relative;color: #212529;font-size: 16px;font-weight: 500;margin-bottom: 0px !important;text-align: end;}
  .anser_main_new{
    position: relative !important;
    top: 0px !important;
    width: 100% !important;
    left: 0px !important;
    padding: 15px 20px;
    background-color: white;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 16px;
    color: #3E3E3E;
    font-weight: 500;
    border-radius: 5px !important;
    height: 130px;
    display: flex;
    align-items: center;
    flex: 0 0 40px;
  }

  .data_driven_sec_new{position: relative;padding: 0 0 60px 0;}
  .data_driven_sec_new .ai_enhance_sec_new_inner_head{margin: 0px auto 60px;}
  .ai_enhance_sec_new_inner_head h5{font-weight: 500;}
  .video_sec_home_new{position: relative;padding: 0px 0 0px 0;margin-top: 48px;}
  .ai_enhave_btn_sec{top: -35px;}
  .footer-icon span{font-size: 14px;}
  .custom-textarea-landing textarea{height: auto;}
  .custom-ht{height: 56px;}
  .try-haire-btn{
    position: relative;
  }
  .try-haire-btn-sub{
    position: absolute;
    right: 0px;
    top: -6px;
  }

  .pricing-button-container{
    display: none;
  }
.video_sec_bg_blue{margin-bottom: 20px;}
.video_sec_bg_blue .haire-landing-video {padding: 50px;border-radius: 30px;box-shadow: 0px 5.6px 141.47px 0px #0000001A;background: linear-gradient(107.66deg, #E5EDF9 0.3%, #C4DFFF 98.89%);}
.video_sec_bg_blue .haire-landing-video:hover .play-button{top: 50.5% !important;cursor: pointer;opacity: 0;}
.video_sec_bg_blue .haire-landing-video:hover .play-button img{width: 91px !important;}
.new_trust_sec_inner{position: relative;}
.new_trust_sec_inner .slide_logo_img_sec img{height: auto !important;width: 50% !important;}
.new_trust_sec_inner .new_land_trust{padding: 30px 0 20px 0;}
.new_trust_sec_inner .owl-stage{display: flex;align-items: center;}
  
.ftr_new_land_inner_midlle a{color: #fff;text-decoration: underline;}
.ftr_new_land_inner_midlle a:hover{color: #fff;text-decoration: underline;}


  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer_sec_pro_rect_new{
      padding: 20px;
    }
    .footer_about_section_new ul{gap: 5px;}
    .footer_about_section_new ul li a{font-size: 12px;}
    .haire_key_sec_new_inner{grid-gap: 5px;}
    .haire_key_sec_new_inner_left h5{font-size: 35px;}
    .haire_key_sec_new_inner_right .accordion .accordion-item{padding: 10px;}
    .filter-container1{gap: 10px;}
    .how_works_for_req_inner_body_sec_single:after{display: none;}

  }


  @media only screen and (min-width: 900px) and (max-width: 1000px) {
    .banner-container1{padding-top: 40px;}
  }


@media only screen and (max-width:767px) {

  .scroll-down{margin-top: 0px;height: 50px;}
  .scroll-down .scroll-dot {height: 40px;width: 20px;}
  .scroll-down .scroll-dot:before {height: 5px;width: 5px;}

  .faq_sec_pro_rect{
    padding: 0px;
}
.key_features_sec_inner_body_single h5{
font-size: 20px;
}
.key_features_sec_inner_body_single p{
    font-size: 18px;
}
.sign_up_req_btn_sec a {
    position: relative;
    padding: 8px 20px;
    color: #000000;
    font-size: 12px;
    font-weight: 700;
    background: #fff;
    border-radius: 90px;
}
.client_testi_sec_single{
  padding: 30px;
}

.video_sec_bg_blue .video_sec_home_new{margin-top: 10px;}


  }
.footer-contact-item .footer_new_link,.footer-phone-number{
  color: #fff;
  font-size: 16px;
}
.footer-phone-number{
  font-size: 14px;
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .video_sec_bg_blue .haire-landing-video{padding: 15px;}
}


@media only screen and (max-width:767px) {
  .video_sec_bg_blue .haire-landing-video{padding: 15px;    border-radius: 10px;}
}
