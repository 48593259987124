.singin_sec_new {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  /* grid-template-columns: 1fr 1fr; */
  align-items: center;
  gap: 10px;
}

.register-comon-btn {
  height: 46px;
}
.singin_sec_new_left {
  position: relative;
}

.position_back {
  position: absolute;
  top: 10px;
  left: 65%;
  transform: translateX(-50%);
  width: 50%;
}

.singin_sec_new_left::before {
  content: "";
  background: #f0f9ff;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.singin_sec_new_left img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.singin_sec_new_right {
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  padding: 90px 50px;
  width: 515px;
  border-radius: 10px;
  margin: 0 auto;
}

.singin_sec_new_right_inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.singin_sec_new_right_inner h6 {
  position: relative;
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.singin_sec_new_right_inner p {
  position: relative;
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 35px;
  width: 80%;
}

.signup_google_sec {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 50px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  border: none;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  max-width: 400px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.signup_google_sec:disabled {
  opacity: 50%;
}

.signup_google_sec img {
  width: 22px;
}

.alredy_have_account {
  position: relative;
  display: block;
  text-align: center;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
}

.login_btn_alredy {
  color: #1865c1;
  font-weight: 600;
  text-decoration: underline;
}

.job_seeking_rect_sec {
  position: relative;
  width: 100%;
  padding: 0 85px 0 25px;
  text-align: center;
}

.job_seeking_rect_sec_head {
  position: relative;
  margin-bottom: 70px;
}

.job_seeking_rect_sec_head h6 {
  position: relative;
  color: #000000;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 18px;
}

.job_seeking_rect_sec_head h5 {
  position: relative;
  font-size: 36px;
  color: #2f2f2f;
  font-weight: 700;
}

.job_seeking_rect_sec_body_sec {
  position: relative;
  background-color: #f0f9ff;
  width: 100%;
  padding: 110px 30px 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  gap: 20px;
}

.job_seeking_rect_single {
  position: relative;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  background: #fff;
  width: 100%;
  padding: 60px 50px 40px 50px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.job_seeking_rect_single h6 {
  position: relative;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}

.img_span {
  position: absolute;
  width: 98px;
  height: 98px;
  border: 2px solid #ffffff;
  background: #f4fbff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -60px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.img_span img {
  width: 32px;
}

.acc_verifictaion_sec {
  position: relative;
  width: 80%;
}

.acc_verifictaion_sec h6 {
  position: relative;
  color: #2f2f2f;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
}

.acc_verifictaion_sec_inner {
  position: relative;
  width: 100%;
  padding: 0 0px 0 25px;
  text-align: center;
}

.acc_ver_in_box {
  position: relative;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  background: #ffffff;
  padding: 50px;
  text-align: left;
}

.acc_ver_in_box h5 {
  position: relative;
  color: #292731;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}

.inner_input_box_select {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.inner_input_box_select select {
  position: relative;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  background: #f8f8f8;
}

.acc_veri .enter_otp_box_sec_inputs {
  display: flex;
}

.acc_veri label {
  text-align: left;
}

.inner_input_box_select .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root {
  height: 50px;
  border-radius: 10px;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  display: none;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  display: none;
}

.inner_input_box_select .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0px 0 0 10px;
}

.inner_input_box_select .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}

.inner_input_box_select .css-ittuaa-MuiInputAdornment-root {
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  background: #f8f8f8;
  max-height: inherit;
  border-radius: 5px;
}
.inner_input_box_select .MuiPhoneNumber-flagButton.css-1yxmbwk {
  padding: 0px !important;
}

.inner_input_box_select .css-i44wyl {
  width: 100%;
}

.inner_input_box_select .mobile_number_input {
  width: 100%;
}

.inner_input_box_select .MuiInputBase-root {
  display: flex;
  align-items: center;
  gap: 15px;
}

.inner_input_box_select .css-jcincl::before {
  border-bottom: none;
}

.inner_input_box_select
  .css-jcincl:hover:not(.Mui-disabled, .Mui-error)::before {
  border: navajowhite;
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .job_seeking_rect_sec_head h6 {
    font-size: 20px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 26px;
  }

  .job_seeking_rect_sec {
    padding: 0 40px 0 25px;
  }

  .job_seeking_rect_single {
    padding: 60px 40px 50px 40px;
  }

  .job_seeking_rect_single h6 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .singin_sec_new {
    grid-template-columns: 1fr;
  }

  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    margin: 30px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .singin_sec_new {
    grid-template-columns: 1fr;
  }

  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    margin: 30px auto;
  }

  .job_seeking_rect_sec {
    width: 100%;
    padding: 30px 25px;
  }

  .acc_verifictaion_sec {
    width: 80%;
    margin: 50px auto;
  }

  .job_seeking_rect_sec_head {
    margin-bottom: 35px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 25px;
  }
}

@media (max-width: 1200px) {
  .position_back {
    top: 25px;
    left: 34%;
  }
}
@media (max-width: 860px) {
  .position_back {
    left: 30%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
}

.right-wrapper {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  padding-left: 2rem;
  margin: auto;
  width: 100%;
  max-width: 700px;
}
.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 6rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.haire-logo {
  cursor: pointer;
}
.toggle-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  height: 60px;
}

.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 28.6px 0px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  font-weight: 500;
  color: #000;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn img {
  margin-right: 8px;
  transition: filter 0.3s ease;
}

.toggle-btn.active {
  color: rgba(24, 101, 193, 1); /* Font color */
  border: 2px solid rgba(24, 101, 193, 1); /* Border color */
}

.toggle-btn.active svg {
  fill: rgba(24, 101, 193, 1); /* Example filter to change the image color */
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.text-button {
  justify-content: space-between;
}

.label-wrapper {
  margin-bottom: 8px;
}

.form-row label {
  display: flex;
  flex-direction: column;
  width: 48%; /* Adjust based on your label style */
  color: rgba(69, 83, 96, 1);
  font-size: 14px;
  font-weight: 500;
}

/* .form-row label.full-width {
  width: 100%; 
}  */

.form-row input {
  height: 42px;
  background: rgba(248, 248, 248, 1);
  border-radius: 4px;
  border: 0px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
}

.required {
  color: red;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.singin_sec_new_left {
  position: relative;
}
.singin_sec_new_text {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 90%;
}
.post-banner {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  color: #fff;
  text-align: center;
}
.post-banner h2 {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.post-banner p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* .singin_sec_new_text div {
  width: 90%;
  margin: 0 auto;
} */
.signin_qoutes {
  font-size: 16px;
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .singin_sec_new {
    grid-template-columns: 1fr;
    align-items: center;
    height: auto;
    overflow: auto;
  }
  .form-row {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .res-wd {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    padding: 20px;
    width: 80%;
  }

  .singin_sec_new_right_inner h6 {
    font-size: 20px;
  }

  .singin_sec_new_right_inner p {
    font-size: 14px;
    width: 100%;
  }

  .alredy_have_account {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .job_seeking_rect_sec {
    width: 100%;
    padding: 10px;
  }

  .job_seeking_rect_sec_head {
    margin-bottom: 30px;
  }

  .job_seeking_rect_sec_head h6 {
    font-size: 16px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 18px;
  }

  .job_seeking_rect_sec_body_sec {
    width: 100%;
    padding: 80px 20px 20px 20px;
    gap: 100px;
    flex-direction: column;
  }

  .acc_verifictaion_sec {
    width: 100%;
    padding: 15px 0;
  }

  .acc_verifictaion_sec_inner {
    padding: 0px;
  }

  .acc_ver_in_box {
    padding: 20px;
  }

  .acc_verifictaion_sec h6 {
    font-size: 25px;
  }

  .job_seeking_rect_single h6 {
    font-size: 14px;
  }

  .position_back {
    top: 25px;
    left: 34%;
  }
  .right-wrapper {
    max-width: 360px;
    padding: 0px 10px;
  }
  .toggle-btn {
    font-size: 14px;
    height: 60px;
    border: 1px solid rgb(233 228 228);
    box-shadow: none;
  }
  .toggle-btn .icon-s {
    width: 25px;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root svg {
    width: 18px;
    height: 18px;
  }
  .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill::placeholder {
    font-size: 8px;
  }
  .res-wd-mob {
    margin-bottom: 20px;
  }
}

.custom-phone-input
  .css-1q40g0l-MuiInputAdornment-root.MuiInputAdornment-positionStart.css-1q40g0l-MuiInputAdornment-root:not(
    .MuiInputAdornment-hiddenLabel
  ) {
  margin-top: 0px;
}

/* .custom-phone-input
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiPhoneNumber-flagButton.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
  top: -8px !important;
} */
.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  display: none;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  display: none;
}

.inner_input_box_select .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0px 0 0 10px;
}

.inner_input_box_select .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}
.inner_input_box_select .css-exjcht {
  align-items: baseline !important;
}
.inner_input_box_select
  .css-es1obo.MuiInputAdornment-positionStart.css-es1obo:not(
    .MuiInputAdornment-hiddenLabel
  ) {
  margin-top: 0px !important;
}
/* .css-1yxmbwk{
  font-size: 1.5rem !important;
  padding: 0px !important;
  
} */
@media only screen and (min-width: 690px) and (max-width: 830px) {
  .right-wrapper {
    max-width: 80%;
    padding: 0px 10px;
  }
}
@media (min-width: 280px) and (max-width: 320px) {
}
