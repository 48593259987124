.nojobapplicantfound_container {
  width: 550px;
  margin: 50px auto;
}
.nopage_found {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.nopage_found p {
  font-size: 18px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 800;
}
.Generate_with_ai_section {
  border: 10px solid #f8f8f8;
  background-color: white;
  border-radius: 6px;
  text-align: center;
}
.Generate_with_ai_main {
  margin: 30px 20px;
}
.Generate_with_ai_main p {
  font-size: 16px;
  color: black;
  font-weight: 700;
}
.progress-wd {
  margin: 0 auto;
  width: 60%;
}
.page_not_found_main {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 10px;
}
.page_not_found_main h3 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 12px;
}
.page_not_found_main p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 660px;
  margin-bottom: 16px;
}
.Pagenotfoundbackbtn {
  border-radius: 30px;
  background-color: #fff;
  border: 2px solid #000;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 30px;
}
.Pagenotfoundbackbtn:focus {
  border: 2px solid #000 !important;
}
.suggestion_sec_new::before {
  content: "Suggestions";
  display: block;
  color: #808080;
  font-size: 12px;
  padding-bottom: 10px;
  font-family: 'Satoshi-Variable';
}
.enterkey {
  position: absolute;
  top: 9px;
  right: 10px;
  z-index: 1;
}

.custom-image-upload img {
  max-width: 100%;
  height: auto;
  /* Add more styling here similar to Ant Design's Upload component */
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 14px;
  word-wrap: break-word;
  max-width: 600px; /* Max width can be adjusted to fit your design */
  overflow-wrap: break-word; /* Ensure long words can break and wrap */
}

.chip button {
  margin-left: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.chip img {
  width: 16px;
  height: 16px;
}

.chip span,
.chip div {
  flex: 1;
  text-align: left;
  min-width: 0;
  word-break: break-word;
}

.maintainance_main {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 30px;
  margin-top: 100px;
}
.maintainance_main h3 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 12px;
}
.maintainance_main p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 660px;
  margin-bottom: 16px;
}

@media only screen and (max-width:767px) {
.enterkey {
  top: 4px;
}
.nojobapplicantfound_container{
  width: auto;
}
.nopage_found img{
  width: 150px ;
}
.nopage_found p{
  font-size: 12px;
}
.page_not_found_main{
  margin-top: 50px;
}
.page_not_found_main h3 {
  font-size: 20px;
  margin-bottom: 0px;
}
.page_not_found_main p{
  font-size: 12px;
  width: 90%;
}
.Pagenotfoundbackbtn {
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 20px;
}
}