.fullscreen-bg {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/Try_Haire_background.svg'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    z-index: -1; 
  }
  
 
.verficiation_mod .ant-modal-content {
    border-radius: 15px;
  }
  
  .verficiation_mod .ant-modal-header {
    border-bottom: none;
    text-align: center;
  }
  
  .verficiation_mod .ant-modal-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .verficiation_mod .verification-modal-subtitle {
    font-size: 14px;
    color: #6c757d;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .verficiation_mod .steps-container {
    margin: 20px 0;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .verficiation_mod .ant-steps-item-title {
    font-size: 14px;
    font-weight: bold;
  }
  
  .verficiation_mod .ant-steps-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .verficiation_mod .ant-steps-item-process .ant-steps-item-icon {
    background-color: #0B4A96;
  }
  
  .verficiation_mod .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #0B4A96;
  }
  
  .verficiation_mod .ant-btn-primary {
    background-color: #0B4A96;;
    border-color: #0B4A96;
    width: 100%;
    border-radius: 100px;
    padding: 8px 14px;
    height: 50px;
  }
  
  .verficiation_mod .ant-btn-primary:focus, 
  .verficiation_mod .ant-btn-primary:hover {
    background-color: #fff !important;
    border-color: #0B4A96 !important;
    color:#0B4A96 !important;
    
  }
  
   .custom-select2 {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #F5F5F5;
    background-image: none;
    border-width: none;
    border-color: none;
    border-radius: 6px;
    transition: all 0.2s;
    height: 44px;
    border: none !important;
  }
  .verficiation_mod .ant-input{
    background-color: #F5F5F5;
    height: 44px;
  }
  .disabled-btn {
    background-color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    color: #fff !important;
  }
  .mdl-h6{
font-size: 20px ;
font-weight: 700;
line-height: 27px;
text-align: center;
color: #000 ;

  }
  .mdl-p{
font-size: 12px;
font-weight: 400;
line-height: 16.2px;
text-align: center;
color: #000 ;
  }

  .custom-stepper .ant-steps-horizontal{
    width: 60%;
    margin: 0 auto;
  }
  .steps-action .Previous-btn{
    margin-bottom: 20px;
  }
  .spinner-custom{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  .custom-select2:focus{
    border-color: #0B4A96;
    outline: 0;
  } 
  .verficiation_mod .ant-input:focus{
    border-color: #0B4A96;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
  }

   
  .custom-select2:focus {
    outline: none;
  }
  .custom-select2 option {
    background-color: #fff; 
    color: #333;
  }
  .custom-select2 option:hover {
    background-color: #f0f0f0;
    color: #000;
  }


  #country-menu{
    z-index: 9999999 !important;
  }
  #select-country{
    z-index: 9999999 !important;
  }
  .loader-cust{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 468px;
  }
  .try-haire-transcript{
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 #0000001a;
    padding: 20px;
    position: relative;
  }
  .blur-text {
    color: transparent;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    filter: blur(2px);
    transition: filter 0.3s ease, text-shadow 0.3s ease, color 0.3s ease;
  }
  .note-class{
    font-size: 12px;
    color: #858C93;
    font-weight: 600;
  }
  .top-m{
    margin-top: 100px;
  }
  .timer-set{
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
  }
  .timer-set-inner {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
font-style: normal;
font-weight: 500;
}
.timer-set-inner p{
  margin-bottom: 0px;
}
.blue_line_min{
  border: 1.3px solid #1865C1 !important;
  background-color: #1865C1 !important;
  height: 2px;
  margin: 10px 0px;
}
  
.guideline1{
  text-align: center;
}
.guideline1 h1{
  color: #000;
  font-size: 22px;
  font-weight: 700;
}
.guideline1 p{
  color: #000;
font-size: 14px;
font-weight: 400;
}
.custom-wd-md{
  padding: 30px;
}
.guideline1 img{
  margin-bottom: 25px;
}
.Explore_plans{
  border-radius: 34px;
background: var(--primary-1000, #0B4A96);
color: #FFF;
font-size: 16px;
font-weight: 700;
padding: 12px 70px;
border:none;
}
.custom-phone-input1 .MuiButtonBase-root{
  width: 60px;
  padding-right: 20px;
}
.custom-phone-input1 .MuiButtonBase-root:hover{
  background-color: transparent;
}
.custom-phone-input1 .MuiButtonBase-root::before {
  content: '\25BC'; 
  position: absolute;
  right: 0; 
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; 
  font-size: 10px; 
  color: #333; 
}
.custom-phone-input1 .MuiInputAdornment-root{
  margin-top: 0px !important;
}
.job-img-cont{
  width: 150px;
  margin: 0 auto;
}
.job-img-cont-btn{
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.trans_interpretation_description{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .job-img-cont-btn {
    justify-content: center;
    flex-direction: column;
  }}
.Btn-out {
  background: transparent;
  border: 1px solid #1865c1;
  color: #1865c1;
  border-radius: 50px;
  height: 50px;
  width: 200px;
} 
.p-40{
  padding: 40px;
}
.main-container-demo{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap :10px;
}

.demo-button{
  width:200px;
}


.blur-background {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.demo-report-head{
  display: flex;
  justify-content: space-between;
}
.demo-report-head-btn{
  background-color: #0B4A96;
  color: #ffffff;
  border: #0B4A96;
  border-radius: 26px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
}
.demo-report-head-btn-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .ant-tour-footer{
  display: none !important;
}
.ant-tour-inner{
  background-color: transparent !important;
  box-shadow: none !important;
} */
.tour-head{
  font-size: 18px;
  margin-bottom: 8px;
}
  .tour-description{
    width: 300px;

  }
  .tour-para{
    font-size: 12px;
    color: #FFF;
  }
  .tour-btn button{
    border: 1px solid #9DB5D7;
    background-color: transparent;
    border-radius: 25px;
    color: #FFF;
    padding: 6px 16px;
  }
  .tour-btn button:hover{
    border: 1px solid #9DB5D7;
    background-color: #fff;
    border-radius: 25px;
    color: #000;
    padding: 6px 16px;
  }
  .back-button2{
      background-color: transparent;
      border: none;
      left: 12px;
      font-size: 20px;
      gap: 9px;
      top: 10px;
      position: absolute;
  }
    @media only screen and (max-width:767px){


    .mdl-h6{
      font-size: 18px ;
    }
    .verficiation_mod .verification-modal-subtitle{
      font-size: 12px;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .verficiation_mod .ant-steps.ant-steps-vertical{
      display: none;
    }
    .back-button2{
      left: 6px;
      top: 18px;
      font-size: 18px;
    }
  }
  @media only screen and (max-width:767px) {
    .timer-set-inner{
      font-size: 14px;
    }
    .timer-set{
      gap: 10px;
    }
    .demo-report-head-btn{
      padding: 6px 10px;
      font-size: 8px;
      height: 30px;
    }
    .transcript_header_sub_h5{
      margin-bottom: 0px;
    }
    /* .Transcript_main_pd{
      height: 20px;
    } */
    .demo-report-head-btn-container{
      margin-top: 30px;
      margin-bottom: 0px;
    }
    .transcript_header_sub_try{
      margin: 0px;
      display: flex;
        flex-direction: row;
    }
    .trans_interpretation_description{
      display: flex;
      flex-direction: column;
      align-items: unset;
      justify-content: space-between;
    }
    .demo-report-head-btn-container{
      margin-bottom: 10px;
    }
    .tablists .ant-tabs-nav {
    margin-top: 20px;
    }
}