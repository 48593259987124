@media only screen and (min-width:1801px) and (max-width:3400px) {

}

@media only screen and (min-width:1801px) and (max-width:1900px) {

}
@media only screen and (min-width:1701px) and (max-width:1800px) {

}
@media only screen and (min-width:1601px) and (max-width:1700px) {

}
@media only screen and (min-width:1501px) and (max-width:1600px) {
    .new_landign_ai_banner_sec_main_left{width: 100%;}
    .in_text{right: 225px;}

    .key_features_sec_inner_body_single h5{font-size: 18px;}
    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 16px; margin-bottom: 40px; height: 80px;}
}

@media only screen and (min-width:1401px) and (max-width:1500px) {
    .new_landign_ai_banner_sec_main_left h2{font-size: 50px;margin-bottom: 40px !important;}
    .interview_text p{font-size: 30px;}
    .how_works_for_req_inner_body_sec{grid-gap: 35px;}
    .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;}
    .new_how_work .how_works_for_req_inner_body_sec:before{height: 360px;background-position: center 0px;}
    .new_how_work .how_works_for_req_inner_body_sec{height: 360px;}
    .how_works_for_req{padding: 30px 0;}
    .how_works_for_req_inner_body_sec{margin-bottom: 20px;}
    .skip_sec_new_onner_left h6{font-size: 40px;}
    .in_text {font-size: 22px;right: 210px;top: 190px;}
    .new_landign_ai_banner_sec_main_left{width: 690px;}

    .key_features_sec_inner_body_single h5{font-size: 18px;}
    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 16px; margin-bottom: 40px; height: 80px;}

}
@media only screen and (min-width:1301px) and (max-width:1400px) {

    .container-fluid {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .head_sec_pro_rect_inner_logo{flex: 0 0 150px}
    .home_banner_sec_new_inner_left h6{font-size: 42px;}
    .typewrite {font-size: 50px;line-height: 60px;}
    .home_banner_sec_new_inner_left p{font-size: 18px;}
    .home_banner_sec_new_inner_left{max-width: 480px;}
    .get_start_btn {font-size: 14px;padding: 11px 30px;}
    .home_banner_sec_new_right_bg {Width: 430px; height: 430px;}
    .people_hired_sec { width: 200px; height: 60px; left: -90px;top: 0px;}
    .people_hire_text h6{font-size: 12px;}
    .ple_star_icn{font-size: 10px;}
    .hire_img { width: 35px;}
    .client_trusted_sec_cont h5{font-size: 12px;}    
    .client_trust_inner_part img { width: 100px; }
    .client_trust_inner_part span i {font-size: 16px; margin-right: 5px;}
    .client_trusted_sec {width: 200px; height: 75px;padding: 8px;}
    .home_banner_sec_new_right_bg_img {width: 535px;left: -80px;top: 50px; }
    .people_hired_sec_down {width: 150px; height: 60px;left: -50px;bottom: 20px;}
    .remark_sec_new {padding: 40px 0 0px 0; }
    .remark_sec_new_inner_single{padding: 25px;}
    .remark_sec_new_inner_single h6{font-size: 35px;}
    .remark_sec_new_inner_single p{font-size: 16px;}
    .ai_enhance_sec_new {padding: 130px 0 55px 0;}
    .left_2{right: -70px;}
    .left_4{right: -70px;bottom: -280px;}
    .rgt_2{right: -70px;}
    .rgt_4{right: -75px;}
    .left_3 {bottom: -260px;}
    .for_cand_single_cards h6{font-size: 18px;}
    .for_cand_single_cards p{font-size: 12px;}
    .rgt_3 {bottom: -480px;}
    .rgt_4 { bottom: -460px;}
    .Quick_steps_getting_job {padding: 30px 0 40px 0px; margin-top: 280px;}
    .stepl_count{left: -105px;}
    .Quick_steps-right:before{left: -80px;}
    .afrter_second_sec .stepl_count{right: -100px;}
    .afrter_second_sec .Quick_steps-right:before{right: -75px;}
    .future_hiring_easy_head::before {left: -110px; bottom: -155px;}
    .future_hiring_easy_head::after {right: -830px;bottom: -200px;}
    .for_candidate_rect_sec_inner_right:after{display: none;}
    .login_sec_new_right_inner{padding: 15px 20px;}
    .login_sec_new_right_inner h6 { font-size: 20px; margin-bottom: 8px;}
    .login_sec_new_right_inner p { font-size: 12px; margin-bottom: 10px;}
    .inner_input_box label{font-size: 14px;}
    .inner_input_box input{height: 42px;}
    .eye_icn_slash i{font-size: 16px;}
    .frgt_pass_sec_new{font-size: 12px;}
    .login_btn_blue button{height: 45px;}
    .signup_google_sec {height: 45px; font-size: 14px;}
    .signup_google_sec img{width: 18px;}
    .login_sec_new_right_inner .spacer20px{height: 12px;}
    .alredy_have_account{font-size: 14px;}
    .job_seeking_rect_sec_head h6{font-size: 12px;}
    .job_seeking_rect_sec_head h5{font-size: 28px !important;}
    .job_seeking_rect_single h6 { font-size: 16px;line-height: inherit;}


    .ai_enhance_sec_new_inner_head h5 span::before{left: 0px;background-size: 300px;}
    .ai_trusted_hring_inner_head span::after{left: 655px;background-size: 250px;}
    .for_candidate_rect_sec_inner_left_head:after{right: 0;background-size: 200px;}
    .choose_the_plan h2:after{background-size: 250px;    left: 585px;}


    .new_landign_ai_banner_sec_main_left h2{font-size: 50px;margin-bottom: 40px !important;}
    .interview_text p{font-size: 30px;}
    .how_works_for_req_inner_body_sec{grid-gap: 35px;}
    .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;}
    .new_how_work .how_works_for_req_inner_body_sec:before{height: 360px;background-position: center 0px;}
    .new_how_work .how_works_for_req_inner_body_sec{height: 360px;}
    .how_works_for_req{padding: 30px 0;}
    .how_works_for_req_inner_body_sec{margin-bottom: 20px;}
    .skip_sec_new_onner_left h6{font-size: 40px;}
    .in_text {font-size: 22px;right: 210px;top: 190px;}
    .new_landign_ai_banner_sec_main_left{width: 690px;}

    .key_features_sec_inner_body_single h5{font-size: 18px;}
    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 15px; margin-bottom: 40px; height: 80px;}




}
@media only screen and (min-width:1200px) and (max-width:1300px) {
    
    .container-fluid {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .head_sec_pro_rect_inner_logo{flex: 0 0 150px}
    .home_banner_sec_new_inner_left h6{font-size: 42px;}
    .typewrite {font-size: 50px;line-height: 60px;}
    .home_banner_sec_new_inner_left p{font-size: 18px;}
    .home_banner_sec_new_inner_left{max-width: 480px;}
    .get_start_btn {font-size: 14px;padding: 11px 30px;}
    .home_banner_sec_new_right_bg {Width: 430px; height: 430px;}
    .people_hired_sec { width: 200px; height: 60px; left: -90px;top: 0px;}
    .people_hire_text h6{font-size: 12px;}
    .ple_star_icn{font-size: 10px;}
    .hire_img { width: 35px;}
    .client_trusted_sec_cont h5{font-size: 12px;}    
    .client_trust_inner_part img { width: 100px; }
    .client_trust_inner_part span i {font-size: 16px; margin-right: 5px;}
    .client_trusted_sec {width: 200px; height: 75px;padding: 8px;}
    .home_banner_sec_new_right_bg_img {width: 535px;left: -80px;top: 50px; }
    .people_hired_sec_down {width: 150px; height: 60px;left: -50px;bottom: 20px;}
    .remark_sec_new {padding: 40px 0 0px 0; }
    .remark_sec_new_inner_single{padding: 25px;}
    .remark_sec_new_inner_single h6{font-size: 35px;}
    .remark_sec_new_inner_single p{font-size: 16px;}
    .ai_enhance_sec_new {padding: 130px 0 55px 0;}
    .left_2{right: -70px;}
    .left_4{right: -70px;bottom: -280px;}
    .rgt_2{right: -70px;}
    .rgt_4{right: -75px;}
    .left_3 {bottom: -260px;}
    .for_cand_single_cards h6{font-size: 18px;}
    .for_cand_single_cards p{font-size: 12px;}
    .rgt_3 {bottom: -480px;}
    .rgt_4 { bottom: -460px;}
    .Quick_steps_getting_job {padding: 30px 0 40px 0px; margin-top: 280px;}
    .stepl_count{left: -105px;}
    .Quick_steps-right:before{left: -80px;}
    .afrter_second_sec .stepl_count{right: -100px;}
    .afrter_second_sec .Quick_steps-right:before{right: -75px;}
    .future_hiring_easy_head::before {left: -110px; bottom: -155px;}
    .future_hiring_easy_head::after {right: -830px;bottom: -200px;}
    .for_candidate_rect_sec_inner_right:after{display: none;}
    .login_sec_new_right_inner{padding: 15px 20px;}
    .login_sec_new_right_inner h6 { font-size: 20px; margin-bottom: 8px;}
    .login_sec_new_right_inner p { font-size: 12px; margin-bottom: 10px;}
    .inner_input_box label{font-size: 14px;}
    .inner_input_box input{height: 42px;}
    .eye_icn_slash i{font-size: 16px;}
    .frgt_pass_sec_new{font-size: 12px;}
    .login_btn_blue button{height: 45px;}
    .signup_google_sec {height: 45px; font-size: 14px;}
    .signup_google_sec img{width: 18px;}
    .login_sec_new_right_inner .spacer20px{height: 12px;}
    .alredy_have_account{font-size: 14px;}
    .job_seeking_rect_sec_head h6{font-size: 12px;}
    .job_seeking_rect_sec_head h5{font-size: 28px;}
    .job_seeking_rect_single h6 { font-size: 16px;line-height: inherit;}


    .ai_enhance_sec_new_inner_head h5 span::before{left: 0px;background-size: 300px;}
    .ai_trusted_hring_inner_head span::after{left: 655px;background-size: 250px;}
    .for_candidate_rect_sec_inner_left_head:after{right: 0;background-size: 200px;}
    .choose_the_plan h2:after{background-size: 250px;    left: 585px;}

    .sign_up_btn_new {padding: 10px 32px;font-size: 12px !important;}



    .new_landign_ai_banner_sec_main_left h2{font-size: 50px;margin-bottom: 40px !important;}
    .interview_text p{font-size: 30px;}
    .how_works_for_req_inner_body_sec{grid-gap: 35px;}
    .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;}
    .new_how_work .how_works_for_req_inner_body_sec:before{height: 360px;background-position: center 0px;}
    .new_how_work .how_works_for_req_inner_body_sec{height: 360px;}
    .how_works_for_req{padding: 30px 0;}
    .how_works_for_req_inner_body_sec{margin-bottom: 20px;}
    .skip_sec_new_onner_left h6{font-size: 40px;}

    .in_text {font-size: 22px;right: 210px;top: 190px;}
    .new_landign_ai_banner_sec_main_left{width: 690px;}

    .key_features_sec_inner_body_single h5{font-size: 18px;}
    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 14px; margin-bottom: 40px; height: 80px;}






}
@media only screen and (min-width:992px) and (max-width:1199px) {


    .container-fluid {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    
    .hammenu{display: none;}
    .sign_up_btn_new {padding: 10px 32px;font-size: 12px !important;}
    .head_sec_pro_rect_inner_logo { flex: 0 0 120px;}
    .home_banner_sec_new_inner_left h6{font-size: 28px;}
    .typewrite{font-size: 30px;line-height: inherit;}
    .head_sec_pro_rect_inner_menu ul{gap: 25px;}
    .head_sec_pro_rect_inner_menu ul li a{font-size: 15px;}
    .home_banner_sec_new_inner_left p{font-size: 16px;}
    .home_banner_sec_new_right_bg{width: 320px;height: 320px;}
    .home_banner_sec_new_right_bg_img { width: 360px; left: -25px; top: 50px; }
    .people_hired_sec{width: auto;padding: 5px 15px;}
    .remark_sec_new_inner_single{padding: 30px;}
    .remark_sec_new_inner_single h6{font-size: 25px;}
    .remark_sec_new_inner_single p{font-size: 14px;}
    .client_trust_inner_part span i{font-size: 14px;}
    .candidate_down_right { width: 195px;height: 162px; padding: 12px; right: -22px;bottom: -90px;}
    .people_hired_sec_down{width: 170px;}
    .client_trusted_sec {width: 200px; height: 85px;right: -25px; top: -15px;}
    .get_start_btn {font-size: 14px;padding: 12px 25px;}
    .remark_sec_new { padding: 15px 0 0px 0;}
    .ai_enhance_sec_new {padding: 100px 0 50px 0;}
    .ai_enhance_sec_new_inner_head h5{font-size: 25px;}
    .ai_enhance_sec_new_inner_head{margin: 0 auto 60px;}
    .ai_enhance_sec_new_inner_body {grid-gap: 25px; width: 100%;margin: 0 auto 55px;}
    .ai_enhance_sec_new_inner_body_single h5 {font-size: 18px; margin-bottom: 10px; min-height: 25px;}
    .ai_enhance_sec_new_inner_body_single p{font-size: 14px;}
    .ai_enhance_sec_new::after { right: -12px; background-size: 25px;background-repeat: no-repeat;}
    .ai_trusted_hring:before{ background-size: 95px;background-repeat: no-repeat;}
    .ai_trusted_hring_inner_body {grid-template-columns: 1fr; grid-column-gap: 60px; grid-row-gap: 25px;}
    .ai_trusted_img { left: 20px;}
    .ai_trusted_hring_inner_body_single{padding: 35px 100px 35px 125px;}
    .for_candidate_rect_sec {padding: 25px 0;}
    .for_candidate_rect_sec_inner {grid-template-columns: 1fr 1fr;grid-gap: 0px;}
    .for_candidate_rect_sec_inner_left_head:after {width: 200px; left:0px; right: 0;}
    .for_candidate_rect_sec_inner_left_head{text-align: left;}
    .for_candidate_rect_sec_inner_left_head h6{font-size: 25px;}
    .left_2{position: relative;}
    .left_3 {position: relative;bottom: 0;top: 30px;}
    .left_4 { position: relative; right: 0;top: 40px; bottom: 0;}
    .rgt_1{position: relative;top: 0;}
    .rgt_2{position: relative;top: 10px;}
    .rgt_3 {position: relative;bottom: 0;top: 30px;}
    .rgt_4 { position: relative; right: 0;top: 40px; bottom: 0;}
    .for_cand_single_cards{width: 100%;}
    .for_candidate_rect_sec_inner_left:after{display: none;}
    .for_candidate_rect_sec_inner:after{display: none;}
    .Quick_steps_getting_job { padding: 70px 0px 0 0; margin-top: 0;}
    .Quick_steps-right { margin-bottom: 20px; flex-direction: column;align-items: flex-start; gap: 30px;padding: 25px;}
    .for_candidate_rect_sec_inner_right:after{display: none;}
    .sec_first{    flex-direction: column-reverse;position: relative;display: flex;}
    .afrter_second_sec.flex-row-reverse {flex-direction: column-reverse !important;position: relative;display: flex;}
    .ai_trusted_hring_inner_head h5{font-size: 30px;}
    .future_hiring_easy_head.text-center.d-flex{width: 100%;padding: 40px;display: inline-block !important;}
    .future_hiring_easy_head::after{display: none;}
    .future_hiring_easy_head::before{display: none;}
    .choose_the_plan{margin-top: 65px;}
    .card-body{padding: 15px;}
    .footer_main_wrapper {grid-template-columns: 2fr 1fr 1fr;}

    .ai_enhance_sec_new_inner_head h5 span::before{left: -20px;top: 35px;background-size: 250px;}
    .ai_trusted_hring_inner_head span::after{display: none;}
    .choose_the_plan h2:after{left: 80px;background-size: 200px;}
    .border-corner{display: none;}

    .blockquote{font-size: 12px;}
    .testimonial-info p{font-size: 10px;}
    .star-rating{font-size: 12px;}

    .answer { width: 75%; top: 15px; left: 50px; }
    .vc_img_sec { top: 20%; left: -25px;}

    .faq_inner_sec{ flex-direction: column;}
    .faq_inner_sec .col-md-6 {  flex: 0 0 auto; width: 100%;}
   


    .new_landign_ai_banner_sec_main{
        grid-template-columns:1fr 1fr;
    }

    .new_landign_ai_banner_sec_main_left h2{font-size: 30px;margin-bottom: 40px !important;}
    .interview_text p{font-size: 20px;}
    .hm_list_sec li{font-size: 12px;}
    .remark_sec_new_inner{    grid-template-columns: repeat(2, 1fr);}
    .new_how_work .how_works_for_req_inner_body_sec:before{display: none;}
    .new_how_work .how_works_for_req_inner_body_sec{height: auto;    grid-template-columns: repeat(2, 1fr);grid-gap: 20px;}
    .key_features_sec_head h6{font-size: 32px;}
    .key_features_sec_inner_body {grid-template-columns: repeat(2, 1fr);grid-gap: 20px;}
    .skip_sec_new_onner_left h6{font-size: 24px;}
    .skip_sec_new_onner_left h5{font-size: 18px;}
    .new_land_trust{padding: 20px 0;}
    .client_testi_sec{padding: 30px 0 10px 0;}
    .inte_suu_ai{padding: 0 0 30px 0;}
    .in_text { font-size: 20px;right: 60px;  top: 125px;}
    .skip_sec_new_onner_right img{height: 490px;}
    .calendly-inline-widget{height: 430px !important;}
    .new_ai_bg{padding: 0px;}
    .ai_new {font-size: 40px; margin-bottom: 20px !important;}
    .inte_suu_ai_inner_body_btm_sec{margin-top: 20px;}
    .try_button_new {width: 200px; height: 45px; font-size: 14px;}
    .inte_suu_ai_inner_head h6{font-size: 35px;}
    .spacer30px{height: 0px;}
    .client_testi_sec_inner_body .owl-nav{display: none;}


    .new_landign_ai_banner_sec_main_left{width: 410px;}
    .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;}
    .client_testi_sec_single{padding: 20px;}

    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 15px; margin-bottom: 40px; height: 80px;}
    
    
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
    .AI-Driven-Smart h1{font-size: 50px;}

}

@media only screen and (min-width:768px) and (max-width:991px) {

    .container-fluid {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    
    .hammenu{display: none;}
    .sign_up_btn_new {
        padding: 10px 10px;
        font-size: 10px !important;
    }
    .head_sec_pro_rect_inner_logo { flex: 0 0 120px;}
    .home_banner_sec_new_inner_left h6{font-size: 28px;}
    .typewrite{font-size: 30px;line-height: inherit;}
    .head_sec_pro_rect_inner_menu ul{gap: 25px;}
    .head_sec_pro_rect_inner_menu ul li a{font-size: 15px;}
    .home_banner_sec_new_inner_left p{font-size: 16px;}
    .home_banner_sec_new_right_bg{width: 320px;height: 320px;}
    .home_banner_sec_new_right_bg_img { width: 360px; left: -25px; top: 50px; }
    .people_hired_sec{width: auto;padding: 5px 15px;}
    .remark_sec_new_inner_single{padding: 30px;}
    .remark_sec_new_inner_single h6{font-size: 25px;}
    .remark_sec_new_inner_single p{font-size: 14px;}
    .client_trust_inner_part span i{font-size: 14px;}
    .candidate_down_right { width: 195px;height: 162px; padding: 12px; right: -22px;bottom: -90px;}
    .people_hired_sec_down{width: 170px;}
    .client_trusted_sec {width: 200px; height: 85px;right: -25px; top: -15px;}
    .get_start_btn {font-size: 14px;padding: 12px 25px;}
    .remark_sec_new { padding: 15px 0 0px 0;}
    .ai_enhance_sec_new {padding: 100px 0 50px 0;}
    .ai_enhance_sec_new_inner_head h5{font-size: 25px;}
    .ai_enhance_sec_new_inner_head{margin: 0 auto 60px;}
    .ai_enhance_sec_new_inner_body {grid-gap: 25px; width: 100%;margin: 0 auto 55px;}
    .ai_enhance_sec_new_inner_body_single h5 {font-size: 18px; margin-bottom: 10px; min-height: 25px;}
    .ai_enhance_sec_new_inner_body_single p{font-size: 14px;}
    .ai_enhance_sec_new::after { right: -12px; background-size: 25px;background-repeat: no-repeat;}
    .ai_trusted_hring:before{ background-size: 95px;background-repeat: no-repeat;}
    .ai_trusted_hring_inner_body {grid-template-columns: 1fr; grid-column-gap: 60px; grid-row-gap: 25px;}
    .ai_trusted_img { left: 20px;}
    .ai_trusted_hring_inner_body_single{padding: 35px 100px 35px 125px;}
    .for_candidate_rect_sec {padding: 25px 0;}
    .for_candidate_rect_sec_inner {grid-template-columns: 1fr 1fr;grid-gap: 0px;}
    .for_candidate_rect_sec_inner_left_head:after {width: 200px; left:0px; right: 0;}
    .for_candidate_rect_sec_inner_left_head{text-align: left;}
    .for_candidate_rect_sec_inner_left_head h6{font-size: 25px;}
    .left_2{position: relative;}
    .left_3 {position: relative;bottom: 0;top: 30px;}
    .left_4 { position: relative; right: 0;top: 40px; bottom: 0;}
    .rgt_1{position: relative;top: 0;}
    .rgt_2{position: relative;top: 10px;}
    .rgt_3 {position: relative;bottom: 0;top: 30px;}
    .rgt_4 { position: relative; right: 0;top: 40px; bottom: 0;}
    .for_cand_single_cards{width: 100%;}
    .for_candidate_rect_sec_inner_left:after{display: none;}
    .for_candidate_rect_sec_inner:after{display: none;}
    .Quick_steps_getting_job { padding: 70px 0px 0 0; margin-top: 0;}
    .Quick_steps-right { margin-bottom: 20px; flex-direction: column;align-items: flex-start; gap: 30px;padding: 25px;}
    .for_candidate_rect_sec_inner_right:after{display: none;}
    .sec_first{    flex-direction: column-reverse;position: relative;display: flex;}
    .afrter_second_sec.flex-row-reverse {flex-direction: column-reverse !important;position: relative;display: flex;}
    .ai_trusted_hring_inner_head h5{font-size: 30px;}
    .future_hiring_easy_head.text-center.d-flex{width: 100%;padding: 40px;display: inline-block !important;}
    .future_hiring_easy_head::after{display: none;}
    .future_hiring_easy_head::before{display: none;}
    .choose_the_plan{margin-top: 65px;}
    .card-body{padding: 15px;}
    .footer_main_wrapper {grid-template-columns: 2fr 1fr 1fr;}

    .ai_enhance_sec_new_inner_head h5 span::before{left: -20px;top: 35px;background-size: 250px;}
    .ai_trusted_hring_inner_head span::after{display: none;}
    .choose_the_plan h2:after{left: 80px;background-size: 200px;}
    .border-corner{display: none;}

    .blockquote{font-size: 12px;}
    .testimonial-info p{font-size: 10px;}
    .star-rating{font-size: 12px;}
 
    .answer { width: 75%; top: 15px; left: 50px; }
    .vc_img_sec { top: 20%; left: -25px;}

    .faq_inner_sec{ flex-direction: column;}
    .faq_inner_sec .col-md-6 {  flex: 0 0 auto; width: 100%;}

    .head_sec_pro_rect_inner_menu {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
    }

    .new_landign_ai_banner_sec_main{
        grid-template-columns:1fr 1fr;
    }

    .new_landign_ai_banner_sec_main_left h2{font-size: 30px;margin-bottom: 40px !important;}
    .interview_text p{font-size: 20px;}
    .hm_list_sec li{font-size: 12px;}
    .remark_sec_new_inner{    grid-template-columns: repeat(2, 1fr);}
    .new_how_work .how_works_for_req_inner_body_sec:before{display: none;}
    .new_how_work .how_works_for_req_inner_body_sec{height: auto;    grid-template-columns: repeat(2, 1fr);grid-gap: 20px;}
    .key_features_sec_head h6{font-size: 32px;}
    .key_features_sec_inner_body {grid-template-columns: repeat(2, 1fr);grid-gap: 20px;}
    .skip_sec_new_onner_left h6{font-size: 24px;}
    .skip_sec_new_onner_left h5{font-size: 18px;}
    .new_land_trust{padding: 20px 0;}
    .client_testi_sec{padding: 30px 0 10px 0;}
    .inte_suu_ai{padding: 0 0 30px 0;}
    .in_text { font-size: 20px;right: 60px;  top: 125px;}
    .skip_sec_new_onner_right img{height: 490px;}
    .calendly-inline-widget{height: 430px !important;}
    .new_ai_bg{padding: 0px;}
    .ai_new {font-size: 40px; margin-bottom: 20px !important;}
    .inte_suu_ai_inner_body_btm_sec{margin-top: 20px;}
    .try_button_new {width: 200px; height: 45px; font-size: 14px;}
    .inte_suu_ai_inner_head h6{font-size: 35px;}
    .spacer30px{height: 0px;}
    .client_testi_sec_inner_body .owl-nav{display: none;}
    .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;}

    .client_testi_sec_single{padding: 20px;}
    .client_testi_sec_single p {font-size: 15px; margin-bottom: 40px; height: 80px;}

}



@media only screen and (max-width:767px) {
    .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .responsive-book-demo-btn{
        display: block;
        padding: 6px 20px;
        font-size: 14px;
        margin-right: 10px;
        font-weight: 700;
    }

    .faq_left_sec_new_body_sec {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
    }

    .head_sec_pro_rect_inner_logo img {
        width: 100%;
        object-fit: cover;
    }
    .sign_up_btn_new {
        position: relative;
        display: inline-block !important;
        padding: 8px 24px;
        background: #1865c1;
        border-radius: 100px;
        color: #ffffff !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        border: none;
    }
    .book-btn-color{
        color: #1865c1 !important;
    }
    .res-book-demo{
        display: none;
    }
    /* .head_sec_pro_rect_inner_menu {
        position: relative;
        display: none;
    } */
    .home_banner_sec_new_inner {
        position: relative;
        display: flex;
        gap: 40px;
        align-items: center;
        flex-direction: column;
    }
    .home_banner_sec_new_right_bg_img img {
        width: 100%;
        object-fit: cover;
    }
    .home_banner_sec_new_right_bg {
        position: relative;
        width: 250px;
        margin: 0 auto;
        height: 250px;
    }
    .home_banner_sec_new_inner_left h6,.typewrite{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .ai_enhance_sec_new_inner_head h5,.ai_trusted_hring_inner_head h5,
    .for_candidate_rect_sec_inner_left_head h6{
        font-size: 16px;
    }
    .home_banner_sec_new_inner_left p{
        font-size: 14px;
        margin-bottom: 20px;
    }
    .remark_sec_new_inner_single{
        padding: 10px;
    }
    .remark_sec_new_inner_single h6,.ai_trsut_cont_sec h6{
        font-size: 16px;
    }
    .ai_trsut_cont_sec p{
        font-size: 12px;
    }
    .remark_sec_new_inner_single p,.footer_main_wrapper_terms p{
        font-size: 10px;
    }
    .ai_trusted_img img {
        width: 38px;
    }
    
    .ai_enhance_sec_new_inner_head {
        position: relative;
        text-align: center;
        margin: 0 auto 18px;
    }
    .ai_enhance_sec_new {
        position: relative;
        padding: 60px 0 30px 0;
    }
    .choose_the_plan h2 {
        width: auto;
        margin: 0 auto;
        font-size: 18px;
    }
    .choose_the_plan h2:after {
        content: "";
        position: absolute;
        right: 48px;
        top: 0px;
        background: #f4e3ff;
        width: 156px;
        height: 42px;
        z-index: -1;
    }
    .remark_sec_new_inner{
        width: 100%;
    }
    .ai_trusted_img {
        position: absolute;
        left: 10px;
    }
    .ai_enhance_sec_new_inner_head h5 span{
        display: block;
    }
    .ai_enhance_sec_new_inner_head h5 span::after {
        content: "";
        position: absolute;
        left: 0;
        background: #8dc2ff99;
        width: 170px;
        height: 30px;
        z-index: -1;
        top: -7px;
        left: 33px;
    }
    .ai_enhance_sec_new_inner_body ,.ai_trusted_hring_inner_body{
        display: flex;
        grid-gap: 15px;
        flex-direction: column;
    }
    .choose_the_plan-main-card {
        display: flex;
        flex-direction: column;
    }
    .ai_trusted_hring_inner_head h5{
        width: auto;
        margin: 0 auto;
    }
    .ai_trusted_hring_inner_head h5:after {
        content: "";
        position: absolute;
        right: 30px;
        top: 0px;
        background: #f4e3ff;
        width: 200px;
        height: 30px;
        z-index: -1;
    }
    .ai_trusted_hring:before {
        width: 44px;
        background-size: 44px;
        background-repeat: no-repeat;
        top: 45px;
    }
    .ai_trusted_hring_inner_body_single {
        padding: 20px 10px 10px 60px;
        width: 100%;

    }
    .future_hiring_easy_head{
        width: 100% !important;
        padding: 15px !important;
        flex-direction: column;
    }
    .slick-slide {
        padding: 0px;
    }
    .testimonials{
        padding: 0px;
        margin-bottom: 10px;
    }
    .testimonial{
        padding: 15px;
    }
    .slick-prev, .slick-next{top: 80%;}
    .faq_questions_accordion,.question-box{
        width: 100%;
    }
    .accordion .accordion-item{padding: 10px !important;}
    .faq_section{
        flex-direction: column;
    }
    
    .navbar{
        display: none;
    }
    .head_sec_pro_rect_inner_menu{
        gap: 5px;
    }
    .hammenu {
        display: block; /* Show hamburger menu */
        background-color: transparent;
        border: none;
    }

    .responsive_menu {
       display: block;/* Keep the responsive menu hidden until toggled */
        right: 10px;
        transition: 0.5s ease-in-out;
        opacity: 1;
    }

    .choose_the_plan{
        margin-bottom: 0px !important;
        margin-top: 10px;
    }
    .choose_the_plan .my-5{
        margin-top: 20px !important;
    }
    .faq_questions_accordion {
        padding: 30px 18px;
    }
    .footer_main_wrapper {
        display: flex;
        grid-template-columns: 2fr 1fr 1fr 2fr;
        padding: 30px 0px;
        flex-direction: column;
    }
    .question-box {
        max-width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }
    .footer_main {
        display: flex;
        gap: 5%;
        margin-top: 20px;
    }
    .footer_resources_section ul li, .footer_about_section ul li {
        padding-bottom: 12px;
        font-size: 10px;
    }
    .footer_main_wrapper p{
        font-size: 10px;
        margin-bottom: 10px;
    }
    .footer_subscribe_section input {
        padding: 6px 10px;
        border: none;
        width: 210px;
    }
    .footer_main_wrapper h3{
        margin-bottom: 0px;
    }
    .social-icons {
        display: flex;
        justify-content: space-between;
        width: 130px;
    }
    .social-icons a{
        width: 30px;
        height: 30px;
        line-height: normal;
    }
    .social-icons i{
        font-size: 15px;
    }
    button.footer_subscribe_button {
        width: 150px;
        padding: 10px 20px;
        font-size: 12px;
    }
    .footer_subscribe_section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .for_candidate_rect_sec_inner{
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
    .left_2,.left_3,.left_4,.rgt_1,.rgt_2,.rgt_3,.rgt_4{
        position: relative;
        right: 0;
        top: 0px;
    }
    .for_candidate_rect_sec_inner_left_body {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .for_candidate_rect_sec_inner:after,.for_candidate_rect_sec_inner_right:after{
        display: none;
    }
    .Quick_steps_getting_job {
        padding: 10px 0px;
        margin-top: 0;
    }
    .choose_the_plan .my-5{margin-bottom: 10px!important;}

    /* Rajesh Responsive */

    

    .typewrite {font-size: 25px; margin-bottom: 5px;line-height: inherit;}
    .get_start_btn {font-size: 14px;padding: 8px 22px;}
    .get_start_btn:after{display: none;}
    .home_banner_sec_new{padding: 20px 0 70px 0;}
    .home_banner_sec_new_right_bg_img {width: 260px; height: auto;left: -15px;}
    .hire_img{width: 20px;}
    .people_hire_text h6{font-size: 9px;}
    .ple_star_icn{font-size: 9px;}
    .ple_star_icn i{font-size: 10px;}
    .ple_star_icn span{font-size: 10px;}
    .people_hired_sec {width: 150px;height: 55px;left: -25px;top: -15px;}
    .client_trust_inner_part img { width: 50px;}
    .client_trusted_sec_cont h5{font-size: 10px;}
    .client_trust_inner_part span{font-size: 9px;}
    .client_trust_inner_part span i {font-size: 9px;margin-right: 2px;}
    .client_trusted_sec_cont{gap: 0px;}
    .client_trusted_sec {width: 120px; height: auto;padding: 8px;right: -14px;top: -10px;}
    .people_hired_sec_down {width: 120px; height: 45px;left: -15px;bottom: 8px;}
    .candidate_down_right_inner img{width: 35px;}
    .candidate_down_right_inner span h4{font-size: 14px;}
    .candidate_down_right_inner span p{font-size: 12px;}
    .candidate_down_right_inner{gap: 15px;}
    .candidate_down_right {width: 140px;height: 115px; padding: 10px;right: -5px;bottom: -80px;}
    .trusted_partne_logo {padding: 30px 0 10px 0px;}
    .trusted_logo_slide_owl .owl-dots{display: none !important;}
    .trusted_partne_logo_slide h6 {font-size: 14px;margin-bottom: 0px;}
    .trusted_logo_slide_owl{width: 100%;margin-top: 20px;}
    .remark_sec_new { padding: 10px 0;}
    .remark_sec_new_inner { grid-template-columns: repeat(2, 1fr); grid-gap: 10px;}
    .ai_enhance_sec_new_inner_body{width: 100%;margin: 0 auto 30px;}
    .ai_enhance_sec_new_inner_body_single{padding: 15px;}
    .ai_enhance_sec_new_inner_body_single img {width: 65px;margin-bottom: 10px;}
    .up_img_sec {height: 70px;}
    .ai_enhance_sec_new_inner_body_single h5 {font-size: 16px;margin-bottom: 10px;min-height: 20px;}
    .ai_enhance_sec_new_inner_body_single p{font-size: 14px;}
    .ai_enhance_sec_new::after{background-repeat: no-repeat;background-size: 15px;height: 65px;}
    .ai_trusted_hring_inner_head{margin-bottom: 30px;}
    .ai_trusted_hring {padding: 10px 0;}
    .row.choose_the_plan-main {margin-top: 25px;}
    .card-body{padding: 15px;}
    .faq_sec_pro_rect h2{position: relative;font-size: 14px;}
    .for_candidate_rect_sec {padding: 30px 0 20px 0;}
    .for_candidate_rect_sec_inner_left_head {margin-bottom: 40px;}
    .for_candidate_rect_sec_inner_left_head:after {width: 100%;height: 30px;left: 90px;z-index: -1;top: 15px; right: 0;background-size: 120px;}
    .for_cand_single_cards {padding: 20px 15px; width: 100%;gap: 12px;}
    .for_cand_single_cards span {width: 28px;height: 28px;flex: 0 0 28px;font-size: 16px;}
    .for_cand_single_cards h6{font-size: 16px;}
    .for_cand_single_cards p{font-size: 12px;}
    .for_candidate_rect_sec_inner_right{right: 0px;}
    .quick_step_head h2 {margin-bottom: 20px;font-size: 18px;}
    .Quick_steps_getting_job .sec_first{flex-direction: column-reverse !important;}
    .Quick_steps-right-head h6{font-size: 16px;margin: 0 0 5px 0;}
    .Quick_steps-right-head p{font-size: 14px;    padding: 5px 10px 5px 0px;}
    .Quick_steps-right {display: flex;gap: 15px;padding: 15px;flex-direction: row;align-items: flex-start;}
    .Quick_steps_img {width: 45px; flex: 0 0 45px;}
    .quick_step_img_sec_cont h5{font-size: 18px;}
    .quick_step_img_sec_cont p{font-size: 12px;}
    .future_hiring_easy_head::before{display: none;}
    .future_hiring_easy_head::after{display: none;}
    .future_hiring_easy_head h3 { font-size: 22px;line-height: inherit;margin:0 0 10px 0;}
    .future_hiring_easy_right p{font-size: 12px;}
    .explore_btn{font-size: 14px;}
    .Quick_steps_getting_job_img, .Quick_steps_getting_job_img2 { margin-top: 30px;}
    .Quick_steps_getting_job_img_new{margin-top: 0px;}
    .afrter_second_sec .stepl_count{display: none;}
    .afrter_second_sec .Quick_steps-right:before{display: none;}
    .accordion-button{padding: 10px;font-size: 13px !important;}
    .accordion-body{font-size: 12px !important;}
    .question-box{padding: 10px;}
    .footer_main{flex-wrap: wrap;}
    .footer_main_wrapper{flex-wrap: wrap;word-wrap: break-word;}
    .footer_main_wrapper p{width: 275px;}
    .head_sec_pro_rect {padding: 10px 0 15px 0;}
    .head_sec_pro_rect_inner_logo{flex: 0 0 110px;left: -15px;}
    .Quick_steps-right:before{display: none;}
    .stepl_count{display: none;}
    .afrter_second_sec .flex-row-reverse {flex-direction: column-reverse !important;}
    .ai_enhance_sec_new_inner_head h5 span::before {left: 0;top: 40px; background-size: 150px;display: none;}
    .forrecruiters::after{top: 50px;background-size: 132px;left: 0;}
    .forcandidates::after{top: 50px;background-size: 132px;left: 0px;}
    .afrter_second_sec.flex-row-reverse {flex-direction: column-reverse !important;}
    .border-corner{display: none;}
    .slick-next {
        right: 0 !important;
    }
    .slick-prev {
        left: 0px !important;
    }
    .blockquote{font-size: 12px !important;padding: 15px 15px 25px 15px !important;top: -10px;}
    .testimonial img.qoutes_img{width: 90px;left: 0;right: 0;margin: 0 auto;}
    .testimonial img{height: 30px;width: 30px;}
    .testimonial-info p{font-size: 10px;}
    .star-rating{font-size: 13px;}
    .ansbtn {
        right: 10px;
        top: 10px;
        height: 25px;
        width: 25px;
        font-size: 10px;
    }
    blockquote {padding: 20px 15px 40px 15px; font-size: 12px;}
    .question-input textarea{font-size: 12px;}

    .faq_sec_pro_rect .accordion .accordion-item{padding: 15px 15px !important;}

    .Quick_steps-right-inner{text-align: center;}
    .slick-prev:before, .slick-next:before{font-size: 14px;}
    .slick-prev, .slick-next{top: 85% !important;}
    .ctabtn_main_cls{top: 0px;}


    .faq_left_sec_new_head h6{font-size: 25px;}
    .answer { width: 100%;font-size: 14px; top: 0px; left: 0;}
    .vc_img_sec{top: auto; bottom: -25px;}
    .answer_main_div{width: 100%;left: 0px;top: 0;}
    .faq_boy_icn_new_sec{padding-top: 145px;}
    .faq_sec_pro_rect{
        margin-top: 40px !important;
    } 
    /* .AI-Driven-Smart h1,.ai_new ,.how_works_for_req_inner_head h3,.key_features_sec_head h6,.client_testi_sec_head,.client_testi_sec_head h6{
        font-size: 20px;
    } */
    /* .AI-Driven-Smart p{
        font-size: 18px;
    } */
    .banner-container1{
    padding-bottom: 0px;
    padding-top: 20px;
    }
    .try-button{
        padding: 6px 10px;
        font-size: 12px;
    }
    .ai_enhance_sec_new_inner_head h5 span::after{
        display: none;
    }
    .haire_key_sec_new_inner_left h5{
        font-size: 18px;
        line-height: normal;
        margin-bottom: 20px !important;
    }
    .haire_key_sec_new_inner_right .accordion .accordion-item{
    padding: 10px;      
    }
    .haire_key_sec_new_inner_right .accordion-header button span{
    
        height: 20px;
        width: 20px;
        font-size: 10px;
    }
    .haire_key_sec_new_inner_right .accordion-body p{
        font-size: 12px;
    }
    .footer_about_section_new ul li a{
        font-size: 10px;
    }
    .footer_about_section_new ul {
        /* display: flex;
        gap: 10px;
        justify-content: space-between;
        color: #fff;
        flex-wrap: wrap; */
        display: grid;
        gap: 6px;
        grid-template-columns: 1fr 1fr;
        column-gap: 50px;
    }
    .footer-container {
        display: flex;
        justify-content: space-betweeny[];
        align-items: center;
        color: #f8f9fa;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 12px;
    }
    /* .footer-contacts{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;
    } */
    .footer-contacts {
        display: flex;
        justify-content: space-between;
        width: 300px;
        margin-bottom: 0px;
    }
    .ftr_new_land_inner_midlle p{font-size: 12px;}
    .footer-contact-item{
        margin-left: 0px;
    }
    .footer-phone-number {
        font-size: 10px;
    }
    .footer-contact-item .footer_new_link, .footer-phone-number {
        color: #fff;
        font-size: 10px;
    }
    .footer-left p{
        font-size: 10px;
    }
    .footer_sec_pro_rect_new {
        padding: 30px 20px;
    }
    .social-icons-new{
        margin-top: 10px;
        justify-content:flex-start;
    }
    .scroll-down{
      margin-top: 10px;
    }
    .video_sec_home_new{
      padding-top: 20px;
    }
    .key_features_sec_inner_body{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .key_features_sec_inner_body_single span{
      display: none;
    }
    .key_features_sec_inner_body_single{
      height: auto;
    }
    .haire_key_sec_new_inner,.how_works_for_req_inner_body_sec{
        grid-template-columns: 1fr;
    }
    .header-banner{
        width: 250px;
        padding: 10px 14px
    }
    .header-banner span{

        font-size: 12px;
        font-weight: bold;
    }
    .header-banner span img{
        width: 16px;
    }
    .AI-Driven-Smart h1, .ai_new, .how_works_for_req_inner_head h3, .key_features_sec_head h6, .client_testi_sec_head, .client_testi_sec_head h6{
        font-size: 28px;
    }
    .ai_enhance_sec_new_inner_head h5 span{
        font-size: 24px;
    }
    .AI-Driven-Smart p{
        font-size: 16px;
    }
    .try-haire-btn-sub-new {
        margin-top: 7px;
        width: 14px;
    }
    .slide_logo_img_sec img {
        width: 80% !important;
        margin: 0 auto;
    }
    /* .sign_up_btn_new{
        display: none !important;
    } */
    responsive-hide-btn{
        display: none !important;
    }
    .responsive-hide-btn-on{
        position: relative;
        display: inline-block !important;
        padding: 8px 24px;
        background: #1865c1;
        border-radius: 100px;
        color: #ffffff !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        border: none;
        margin-top: 30px;
    }
    .trusted_part_sec_new {
        position: relative;
        padding: 10px 0 30px 0 !important;
    }
    .ai_enhance_sec_new ,.haire_key_sec_new,.how_works_for_req,.key_features_sec,.client_testi_sec{
        padding: 20px 0 20px 0;
    }
    .data_driven_sec_new{
        padding: 0px;
    }
    .haire_key_sec_new_inner,.how_works_for_req_inner_body_sec
    {
        grid-gap: 0px;
    }
    .client_testi_sec_head,.faq_sec_pro_rect{
        margin-bottom: 20px;
    }
    .faq_sec_pro_rect{
        padding: 40px 30px;  
    }
    .faq_sec_pro_rect{
        padding: 0px;
        margin-top: 0px !important;
    }
    .client_testi_sec{
        padding-top: 0px;
      }
      .key_features_sec{
        padding-bottom: 0px;
      }
      .embacre_sec_new_inner{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
      .embacre_sec_new_inner_left{
        flex: 0 0 100%;
      }
      .embacre_sec_new_inner_left h5{
        font-size: 34px;
      }
      .embacre_sec_new_inner_right p{
        font-size: 18px;
      }
      .ai_solution_haire_inner_body_left_single_cont h6{
        font-size: 16px;
      }
      .ai_solution_haire_inner_body_left_single_cont p{
        font-size: 14px;
      }
      .ai_solution_haire_inner_head{
        margin-bottom: 50px !important;
      }
        .ai_solution_haire{
            margin-bottom: 0px;
        }
        .embacre_sec_new{
            margin: 20px;
        }
        .need-help{
            padding: 20px;
            flex-direction: column;
        }
        .need-help h3{
            line-height: 32px;
        }
        .explore_btn{
            font-size: 14px;
        }
        .industries_serve_inner_body_single h5 {
            align-items: center;
        }
/* Container to hold the buttons */
.pricing-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap; /* Responsive wrapping */
    height: 40px;
  }
  
  /* Free button styling */
  .pricing-btn-free {
    background-color: white; /* Blue color for 'Free' */
    color: #949494;
    padding: 10px 20px;
    border-radius: 25px;
    border: 1px solid #E6E6E6;
    font-size: 14px;
    cursor: pointer;
    width: 27%;
    font-weight: 600;
    
  }
  
  /* Business button styling */
  .pricing-btn-business {
    background-color: white;
    color: #949494;
    padding: 10px 20px;
    border-radius: 25px;
    border: 1px solid #E6E6E6;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
  }
  
  /* Enterprise button styling */
  .pricing-btn-enterprise {
    background-color: white;
    color: #949494;
    padding: 10px 20px;
    border-radius: 25px;
    border: 1px solid #E6E6E6;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
  }
  
  /* Hover effect */
  .pricing-btn-free:hover,
  .pricing-btn-business:hover,
  .pricing-btn-enterprise:hover {
    opacity: 0.8;
  }
  
  /* Active button styling */
  .pricing-btn-free.active,
  .pricing-btn-business.active,
  .pricing-btn-enterprise.active {
    background-color: #1865c1; /* Same blue color for active button */
    color: white;
    border-color: #1865c1;
  }
  .haire_key_sec_new_inner_right{
    text-align: center;
  }
  .responsive-hide-btn{
    display: none !important;
  }


  .new_landign_ai_banner_sec_main_left h2{font-size: 25px;margin-bottom: 20px !important;}
  .interview_text{padding: 4px 10px;}
  .interview_text p{font-size: 20px;}
  .hm_list_sec li{font-size: 12px;}
  .hm_list_sec{margin-bottom: 20px;}
  .new_landign_ai_banner_sec_main{grid-template-columns: 1fr;width: 100%;grid-gap: 25px;}
  .try-button{display: block;margin: 0 auto;text-align: center;}
  .new_landign_ai_banner_sec{padding: 20px 0;}
  .spacer60px{height: 20px;}
  .card_title{font-size: 16px !important;}
  .remark_sec_new_inner_single h6{margin-bottom: 0px !important;}
  .inte_suu_ai_inner_body_btm_sec{margin-top: 15px;}
  .try_button_new { width: 180px; height: 40px; font-size: 14px;}
  .no_para{font-size: 14px;}
  .new_ai_bg{padding: 10px 0 20px 0;}
  .ai_new{font-size: 28px;margin-bottom: 20px !important;}
  .new_ai_bg:after{opacity: 0;}
  .trusted_partne_logo_slide h6{font-size: 15px !important;}
  .inte_suu_ai_inner_head h6{font-size: 25px;margin-bottom: 15px !important;}
  .inte_suu_ai_inner_head p{font-size: 18px;}
  .how_works_for_req_inner_head{margin-bottom: 20px;}
  .new_how_work .how_works_for_req_inner_body_sec{height: 100%;width: 90%;margin: 0 auto;}
  .new_how_work .how_works_for_req_inner_body_sec_single_img img{height: auto;padding: 5px;}
  .skip_sec_new_onner{grid-template-columns: 1fr;grid-gap: 0px;}
  .new_landign_ai_banner_sec_main_left{text-align: center;}
  .new_how_work .how_works_for_req_inner_body_sec:before{display: none;}
  .new_how_work .how_works_for_req_inner_body_sec_single{z-index: 111;}
  .new_how_work .how_works_for_req_inner_body_sec_single:after{opacity: 1 !important;right: 0;
    top: 300px;transform: rotate(90deg); z-index: -1; left: 0; text-align: center;margin: 0 auto;}
  .client_testi_sec_inner_body .owl-nav{display: none;}
  .bg_client_blue h6{font-size: 25px !important;}
  .bg_client_blue{padding-top: 40px !important;}
  .skip_sec_new_onner_left h6{font-size: 20px;}
  .skip_sec_new_onner_left h5{font-size: 14px;}
  .skip_sec_new{padding-top: 40px;width: 100%;}
  .ftr_new_land_inner_midlle{left: auto;top: 0;}
  .vide_sec{width: 80px;margin: 0px;}
  .inte_suu_ai{padding: 0px;}
  .ftr_new_land_inner{padding-bottom: 25px;}
  .ftr_new_land_inner_left p{position: absolute;font-size: 12px;left: 0; right: 0; bottom: 0;width: 100%;text-align: center;}
  .ftr_new_land_inner_right .social-icons-new{gap: 10px;margin-top: 20px;}
  .ftr_new_land_inner_right .social-icons-new img{width: 15px;}
  .skip_sec_new_onner_right img { width: auto; object-fit: cover; height: auto;}
  .in_text{font-size: 12px;right: 0;top: 90px;}



}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  
    

/* .hammenu{display: none;} */
.head_sec_pro_rect_inner_logo {flex: 0 0 120px;}
.navbar {display: none;}
.head_sec_pro_rect_inner_menu ul{gap: 20px;}
.head_sec_pro_rect_inner_menu ul li a{font-size: 15px;}
.sign_up_btn_new{font-size: 10px !important;margin-left: 15px;}
.home_banner_sec_new_inner {display: grid; grid-template-columns: 1fr 1fr; grid-gap: 30px;align-items: center;}
.home_banner_sec_new {padding: 45px 0 70px 0;}
.remark_sec_new_inner { grid-template-columns: repeat(4, 1fr);grid-gap: 10px;}
.ai_enhance_sec_new_inner_body{display: grid;}
.ai_trusted_hring_inner_body{display: grid;}
.for_candidate_rect_sec_inner {grid-template-columns: 1fr 1fr;grid-gap: 30px;}
.sec_first{ flex-direction: inherit !important;gap: 80px;}
.stepl_count{display: flex;width: 30px;height: 30px;flex: 0 0 30px;font-size: 12px;left: -60px;}
.Quick_steps-right:before{display: block;left: -50px;top: 45px;}
.Quick_steps-right{flex-direction: row;}
.afrter_second_sec .Quick_steps-right:before{display: block;}
.afrter_second_sec .stepl_count{display: flex;}
.afrter_second_sec{flex-direction: row-reverse !important;gap: 80px;}
.afrter_second_sec .Quick_steps-right:before {left: auto;right: -45px;}
.afrter_second_sec .stepl_count { right: -55px;}
.choose_the_plan-main-card {display: flex !important;flex-direction: inherit;}
.row.choose_the_plan-main{margin-top: 60px;}
.slick-prev { left: 0;}
.slick-next {right: 30px;}
.question-box{max-width: 95%;}
.footer_main { flex-wrap: nowrap;}
.footer_main_wrapper{ flex-wrap: nowrap;flex-direction: inherit;}
.responsive_menu{width: 45%;}

.new_landign_ai_banner_sec_main_right{width: 85%;margin: 0 auto;}
.calendly-inline-widget{height: 350px !important;}
.in_text {
    font-size: 12px;
    right: 50px;
    top: 95px;
}
.how_works_for_req_inner_body_sec{grid-gap: 10px!important;grid-template-columns: 1fr 1fr;}
.new_how_work .how_works_for_req_inner_body_sec_single:after{top: 325px;display: none;}
.new_landign_ai_banner_sec_main{grid-template-columns: 1fr 1fr;}
.skip_sec_new_onner {grid-template-columns: 1fr 1fr;grid-gap: 0px;}



}
  

#root{overflow-x: hidden;}
@media (min-width: 280px) and (max-width: 320px){
    .faq_sec_pro_rect {
        margin-top: 10px !important;
    }
    .sign_up_btn_new{
        padding: 8px 10px;
        font-size: 10px !important;
    }
    .hammenu img{
        width: 20px;
    }
    .slide_logo_img_sec img{
        height: 20px !important;
    }
    .Quick_steps-right {
        display: flex;
        gap: 10px;
        padding: 15px;
        flex-direction: row;
        align-items: center;
    }
    .quick_step_img_sec_cont h5{
        font-size: 14px;
        margin-bottom: 0px;
    }
  }