/* .Billing_info_main{
    border: 1px solid grey;
    background-color: aqua;
} */
.subscription-card {
  display: flex;
  flex-direction: column;
  border: 4px solid #1865c1;
  border-style: solid;
  /* border-image: linear-gradient(to right, #A9A8FF, #69A5EE) 1; */
  border-radius: 8px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #a9a8ff, #69a5ee) border-box;
  border-radius: 10px;
  border: 4px solid transparent;
  padding: 20px;
  margin: auto;
}
.subscription-card h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px !important;
}
.payment_method_main {
  margin-bottom: 30px;
}
.freesub_img {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}
.freesub_img span {
  color: #1865c1;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
.freesub_img p {
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-weight: 700;
}
.card_header3 {
  align-items: center;
  border-bottom: none !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.cancle_btn_plan {
  text-align: end;
}
.card-header h2 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
}
.card-body1 h3 {
  font-size: 20px;
  font-weight: 500;
}
.card-header {
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  border-bottom: 1px solid #e6e6e6;
}

.card-body1 {
  flex: 1;
  padding-bottom: 15px;
}
/* .ant-form-item .ant-form-item-label .tax_id{

}  */
.tax_id1{
  height: 22px !important;
}
/* .br-right::after {
  content: '';
  display: block;
  position: absolute;
  height: 40px;
  border: 1px solid black;
  top: 146px;
  left: 250px;
} */

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancel-button {
  background-color: #1865c1;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 50px;
}
.cancel-button:hover {
  background-color: #ffffff;
  border: none;
  padding: 10px 20px;
  color: #1865c1;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #1865c1;
}

.payment-methods {
}
.payment-card-data {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.billing-info {
  background: #fff;
  margin-bottom: 30px;
  padding: 20px;
  border: 4px solid #a9a8ff;
  border-radius: 5px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #a9a8ff, #69a5ee) border-box;
  border-radius: 10px;
  border: 4px solid transparent;
}
.bg-white {
  background-color: #ffffff;
}


.section-header {
  padding-bottom: 10px;
  margin-top: 20px;
}
.section-header1 {
  padding-bottom: 10px;
}
.Add_det {
  font-size: 16px;
  color: black;
  font-weight: 700;
}
.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-details {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #f4f9ff;
  flex-direction: column;
  padding: 30px;
  height: 180px;
}

.payment-card {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #c7c7c7;
}
.payment-details-add {
  display: flex;
  align-items: center;
  height: 180px;
  justify-content: center;
  border: 1px dashed #c7c7c7;
  border-radius: 6px;
}
.card-logo {
  font-weight: bold;
  color: #1a1f71;
}

.card-number,
.expiry-date {
  display: block;
  color: #333;
}

.button-add-method {
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.button-add-method:hover {
  background-color: #003d82;
}
.menulist .text-center {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.billing_form label {
  color: #909090 !important;
  height: auto;
}
.menulist .text-center:hover {
  background-color: #f0f0f0;
  border-radius: 50px; /* Light grey background on hover for all tabs */
}

.ant-form-item:last-child .ant-form-item-label > label {
  height: 22px;
  white-space: nowrap;
}
/* Active tab styles */
.active1 {
  color: #007bff;
  background-color: #dfeeff !important;
  border-radius: 4px;
  border-radius: 50px;
  
}
.tick {
  margin-right: 0px;
}
.card-body1 li {
  margin-bottom: 8px;
}
.text-center {
  position: relative;
}

.dropdown-icon img {
  margin-left: 4px;
}

.dropdown-menu1 {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  left: 120px;
  top: 50px;
}

.dropdown-menu1 .dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  display: block;
}

.dropdown-menu1 .dropdown-item:hover {
  background-color: #f6f6f6;
}
.Add_card_btn {
  /* padding: 10px 20px; */
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  width: auto;
  height: 44px;
  width: 140px;
}
.wdt_btn_bg{
  width: 200px;
}
.wdt_btn_bg_hover:hover{
  color: #FFF;
  background-color: #0056b3;
}
.Add_card_btn:hover {
  color: white;
}

.plan_cancle_p span {
  font-weight: 700;
}
.cancle_sub_btn {
  display: flex;
  justify-content: end;
  gap: 30px;
}
.clear_btn {
  background-color: white;
  color: #0056b3;
  border: 1px solid #0056b3;
}
.edit_btn{
  /* padding: 10px 20px; */
  background-color: white;
  color: #0056b3;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 700;
  width: auto;
  height: 44px;
  border: 2px solid #0056b3;
  width: 160px;
}
.edit_btn img{
  margin-left: 6px;
  margin-top: -3px;
}
.button_section {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 0px !important;
}
.mtb{
  margin: 20px 0px 20px 0px;
}
.right_section {
  text-align: center;
}
.Add_card_modal {
  padding: 0px;
}
.Add_card_modal p {
  color: rgba(24, 101, 193, 1);
  font-size: 12px;
  font-weight: 500;
}
.plan_cancle_p {
  font-size: 16px !important  ;
  color: #000 !important;
  font-weight: 400 !important;
}
.CreditCard_img {
  position: absolute;
  right: 12px;
  top: 14px;
  width: 140px;
}
.Pricing_head {
  font-size: 28px !important;
  background: linear-gradient(96.08deg, #1865C1 7.48%, #7977F6 94.48%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  -webkit-text-fill-color: transparent !important;
  line-height: inherit;
  margin-bottom: 20px !important;
}
.Pricing_credit {
  font-size: 18px;
  color: #333;
  -webkit-text-fill-color: #333;
}
.pricing-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
  gap: 20px; /* Spacing between cards */
}
.credits_span1 {
  font-size: 12px;
  text-align: end;
  color: #1865c1 !important;
}
.pricing-description {
  color: #1865c1 !important;
  font-size: 16px !important;
  font-weight: 700;
  padding-top: 30px;
}
.pricing-button2 {
  color: #1865c1;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 0 auto;
}
.pricing-card {
  position: relative;
  padding: 30px;
  width: 100%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  overflow: hidden;
}

.pricing-card:before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 3.37px; /* Match the original border thickness */
  border-radius: 35px;
  background: linear-gradient(154.89deg, #A9A8FF 2.91%, #69A5EE 99.99%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: subtract;
  pointer-events: none;
}
.Pricing_main_div_sec{display: flex;justify-content: space-between;}
.top_up_btn{display: inline-block;width: 156px;height: 38px;background: #0B4A96;color: #FFFFFF;font-size: 12px;font-weight: 500;border-radius: 70px;border: none;}
.pricing-header {
  color: #676767;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.pricing-amount {
  font-size: 30px;
  font-weight: bold;
  color: #0B4A96;
  margin-bottom: 10px;
}
.pricing-description,
.pricing-credit {
  color: #676767;
  margin-bottom: 10px;
}
.pricing-features ul {
  list-style: none;
  padding: 0;
}
.pricing-amount span {
  color: #676767;
  font-size: 15px;
}
.pricing-features {
  min-height: 165px;
}
.pricing-features li {
  margin-bottom: 5px;
}
.pricing-button {
  background: #1865c1;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  margin-top: 10px;
  width: 200px;
  margin: 0 auto;
}
.min-h {
  min-height: 379px;
  width: 100%;
}
.min-hb {
  margin-top: 25px;
  margin-bottom: 0px;
}
.pricing-features ul li {
  font-size: 14px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 15px;
}
/* .pricing-button:hover {
  background: #403ebf;
} */
.custom-modal-size .modal-dialog {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers the modal horizontally */
  max-width: 95%; /* Allows modal to expand up to 95% of the viewport width */
  margin: auto; /* Ensures modal has equal margins from the left and right */
}

.pricing-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.searchbar_new {
  width: 90%;
  padding: 4px 20px;
  border-radius: 50px;
}
.pricing-card {
  flex: 0 0 auto;
  margin: 0;
  width: calc(33.333% - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#components-slider-demo-mark h4 {
  margin: 0 0 16px;
}
#components-slider-demo-mark .ant-slider-with-marks {
  margin-bottom: 44px;
}
.slider-container {
  width: 100%; /* Use 100% of the parent's width */
  padding: 0 20px; /* Add some padding if needed */
}

.Payment_method_main {
  border: 1px solid #e2e2e2;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
}
.left_section {
  display: flex;
  background-color: #f0f1ff;
  width: 40%;
  border-radius: 25px 0px 0px 25px;
}
.right_section {
  background-color: #fff;
  border-radius: 0px 25px 35px 0px;
  width: 60%;
  padding: 30px;
}
.bill_price_count {
  width: 80%;
  margin: 30px auto;
}

.Congratulations_main {
  text-align: center;
}
.Congratulations_main h2 {
  color: #007bff;
  font-weight: 700;
}
.Congratulations_main p {
  font-weight: 500;
  font-size: 14px;
}
.Congratulations_main img {
  width: 300px;
}
.credits_counts {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.credits_counts img {
  margin-right: 10px;
}

.billing-form-data {
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(292deg, #fff 0%, #dfdfff 0.01%, #e3edff 100%);
  margin-bottom: 10px;
}
.billing_data_form label {
  margin-bottom: 0px;
  font-size: 12px;
}
.billing_data_form input {
  margin-bottom: 0px;
  height: 35px !important;
  font-size: 14px;
}
.custom_plan_head {
  color: #1865c1;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.billing-form-data p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.ContactMe {
  width: 200px;
  margin: 0 auto;
}

.thankyou {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background: linear-gradient(292deg, #fff 0%, #dfdfff 0.01%, #e3edff 100%);
  border-radius: 10px;
}
.required-asterisk {
  color: #f00;
  position: absolute;
  top: -7px;
  font-size: 18px;
}
.payment_failed {
  text-align: center;
  margin-top: 120px;
}
.payment_failed_head h4 {
  font-size: 40px;
  font-weight: 700;
  color: black;
  margin-top: 20px;
}
.payment_failed_head p {
  font-size: 20px;
  font-weight: 400;
  color: black;
}
.payment_id {
  margin-top: 40px;
  margin-bottom: 40px;
}
.go_back {
  padding: 10px 20px;
  border-radius: 25px;
  border: 2px solid #000;
  background-color: transparent;
}
.Try_agian {
  padding: 10px 20px;
  border-radius: 25px;
  border: 2px solid #1865c1;
  color: #1865c1;
  background-color: transparent;
}
.btn-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.payment_succeded {
  margin-top: 100px;
}

.upgrade-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.upgrade-heading {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.upgrade-subheading {
  font-size: 12px;
  font-weight: 500;
  color: #1865c1;
  text-decoration: underline;
}
.alloted_credits_main{
  padding: 40px 50px;
  border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 28.6px 0px rgba(0, 0, 0, 0.08);

}
.wd-100{
  width: 100%;
}
.delet_btn_alloted{
  background-color: transparent;
  border: none;
}
.AllotedCredit .head_sec_main{
  margin-bottom: 0px;
}


.main_plan_sec_new{display: block;margin-left: 15px;width: 100%;}
.main_plan_sec_new_single{position: relative;margin-bottom: 30px;}
.main_plan_sec_new_single_head{margin-bottom: 10px;display: block;}
.main_plan_sec_new_single_head h6{color: #000000;font-size: 20px;font-weight: 700;margin-bottom: 0px;}
.main_plan_sec_new_single_body{position: relative;padding: 28px 24px;border-radius: 10px;background: #fff;display: flex;align-items: center;gap: 20px;width: 100%;justify-content: space-between;}
.main_plan_sec_new_single_body_left{display: flex;align-items: flex-start;flex-direction: column;gap: 15px;}
.main_plan_sec_left_top{position: relative;}
.main_plan_sec_left_top ul{display: flex;align-items: center;gap: 15px;}
.main_plan_sec_left_top ul li{color: #3D4852;font-size: 14px;font-weight: 500;}
.main_plan_sec_left_top ul li strong{color: #000;}
.main_plan_sec_left_btm{display: block;}
.main_plan_sec_left_btm h6{color: #989FA4;font-size: 12px;font-weight: 500;margin-bottom: 0px;}
.main_plan_sec_left_btm h6 span{color: #000;}
.main_plan_sec_new_single_body_right{display: flex;align-items: center;gap: 15px;}
.blue_bg_change{display: block;width: 170px;height: 40px;background: #0B4A96;color: #FFFFFF;font-size: 14px;font-weight: 700;border-radius: 60px;border: none;border: 2px solid #0B4A96;}
.cancel_bg_btn{display: block;width: 170px;height: 40px;background: #fff;color: #0B4A96;font-size: 14px;font-weight: 700;border-radius: 60px;border: none;border: 2px solid #0B4A96;}










@media only screen and (min-width:  991px) and (max-width: 1199px) {
  .res-monitization{
    width: 434px !important;
  }
  .menulist .text-center{
    padding: 10px 10px;
  }
}
/* Responsive Design */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menulist .text-center{
    padding: 10px 10px;
  }
  .pricing-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column;
    margin: 20px;
}
.pricing-card{
  width: 100%;
  margin: 0px;
}
.Pricing_head{
  font-size: 20px !important;
}
}
@media (max-width: 768px) {
  .payment-details {
    flex-direction: column;
  }
  .cutoff-res{
    display: none;
    }
  .button-add-method {
    width: 100%;
    margin-top: 20px;
  }
  .subscription-card {
    flex-direction: column;
    /* margin-top: 20px; */
  }
  .card-body1 li{
    font-size: 10px;
  }
  .card-header p{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .card-body1 h3{
    font-size: 14px;
  }
  .card-header h2{
    font-size: 14px;
  }
  .tick {
    margin-right: 10px;
    width: 15px;
}
.pd-2{
    padding: 20px;
}
.cancel-button{
  font-size: 10px;
  padding: 8px 20px;
}
.card-header{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cancle_btn_plan{
  text-align: start;
}
.menulist .text-center {
    cursor: pointer;
    padding: 8px 8px;

}
.dropdown-menu1 .dropdown-item {
  padding: 8px 20px;
  font-size: 12px;
}
.dropdown-icon img{
  width: 10px;
}
.Add_card_modal {
  padding: 20px;
}
.wdt_btn_bg{
width: 100%;
}
.Add_card_btn{
  padding: 6px 10px ;
  font-size: 12px;
  height: 34px;
}
.Add_card_modal h3{
font-size: 15px;
}
.Add_card_modal .plan_cancle_p{
  font-size: 12px !important;
  margin-bottom: 15px;
}
.report_mod_list_new .checkmark {
  height: 15px;
  width: 15px;
  top: 0px;
}
.section-header1 h5{
font-size: 14px;
}
.edit_btn{
height: auto;
padding: 6px 10px;
font-size: 12px;
border: 1px solid #0056b3;
width: 100px;
}
.res-monitization{
  margin-bottom: 20px !important;
}
.credit-search input{
padding: 10px 10px 10px 35px;
}
.credit-search img{
  position: absolute;
  top: 10px;
  left: 12px;
  width: 16px;
  }
  .pricing-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column;
    /* margin: 10px; */
}
.pricing-card{
  width: 100%;
  margin: 0px;
}
.Pricing_head{
  font-size: 20px !important;
}
}