.shadow_pill {
  border: 1px solid #D2D2D2;
  border-radius: 50px !important;
  background: #FFF;
  /* box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08) !important; */
}

.Search_location,
.Search_jobs,
.Search_btn {
  position: relative;
}

.Search_location input,
.Search_jobs input {
  padding: 10px 10px 10px 50px;
}

.Search_location img,
.Search_jobs img {
  position: absolute;
  top: 10px;
  left: 12px;
}

/* .Search_btn img{
    position: absolute;
    top: 12px;
    left: 40px;
} */
.Search_btn button {
  width: 100%;
  padding: 8px;
  font-size: 18px;
  color: white;
  background: linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%);

}





.input-group {
  gap: 30px;
}

.input-group-text {
  border: 1px solid #ACACAC;
  color: #000;
  border-radius: 5px 0 0 5px;
}

.custom-select {
  border: 1px solid #ACACAC;
  background-color: #FFF;
  border-radius: 0;
  border-radius: 25px !important;
  padding: 10px 20px;
}



.btn-reset {
  color: #D9534F;
  background: none;
  border-radius: 0 5px 5px 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.custom-select:not(:last-child) {
  border-radius: 0;
}

.input-group>.input-group-text+.custom-select,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.btn {
  margin-left: -1px;
}

.filter {
  border: none;
  background: none;
}

.filterclass {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.filterclass img {
  margin-right: 10px;
}

.menudropdown {
  border-radius: 30px !important;
  background: #F5F5F5 !important;
  border: none !important;
  font-size: 14px !important;
  height: 50px;
  padding: 0px 25px;
  font-weight: 500;
}

.menulist {

  padding: 6px;
  background-color: #FFF;
  border-radius: 50px;
  box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  margin: 0 auto;
  /* margin-top: 40px; */
}

.menutabs-wd {
  width: auto !important;
}

.left {
  margin: initial;
}

.sort_by_container {
  display: flex;
}

.dropdown-inline-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.custom-select {
  border: 1px solid #ACACAC;
  background-color: #FFF;
  border-radius: 0;
  border-radius: 25px !important;
  padding: 10px 20px;
}



.btn-reset {
  color: #D9534F;
  background: none;
  border-radius: 0 5px 5px 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.custom-select:not(:last-child) {
  border-radius: 0;
}

.input-group>.input-group-text+.custom-select,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.btn {
  margin-left: -1px;
}

.filter {
  border: none;
  background: none;
}

.filterclass {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.filterclass img {
  margin-right: 10px;
}

.menudropdown {
  border-radius: 30px !important;
  background: #F5F5F5 !important;
  border: none !important;
  font-size: 14px !important;
  height: 50px;
  padding: 0px 25px;
  font-weight: 500;
}

.menulist {
  width: 400px;
  padding: 6px;
  background-color: #FFF;
  border-radius: 50px;
  box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  margin: 0 auto;
  /* margin-top: 40px; */
}

.menulist2 {
  width: 85%;
  padding: 6px;
  background-color: #FFF;
  border-radius: 50px;
  box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  margin: 0 auto;
  /* margin-top: 40px; */
}

.wd_menu{
  width: 434px !important;
}
.menutabs-wd {
  width: auto !important;
}

.left {
  margin: initial;
}

.sort_by_container {
  display: flex;
}
.responsive_skeleton{
  display: flex;
  gap: 20px;
  height: 360px;
}
.pagination-container .ant-pagination .ant-pagination-item-active{
border-radius: 0px;
}
.dropdown-inline-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.dropdown-inline-container span {
  color: #7C8493;
  font-size: 14px;
}

.pagination-container {
  margin: 20px 0px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.Dashboard_main_section {
  margin-top: 110px;
}

.Dashboard_main_section .container.my-4 .row {
  justify-content: space-between;
}

.flexRow {
  display: flex;
  /* background-color: #7C8493; */
  align-items: center;
  justify-content: space-between;
}

.flexRowRight {
  display: flex;
  align-items: center;
  gap: 25px;
  /* justify-content: space-between; */
  /* background-color: #1FB8C3; */
}

.popUp {
  display: none;
}

.salary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important; */
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #588877;
  border: 1px solid #D6D6D6;
  /* margin-bottom: 10px; */
}

.resetButton {
  font-size: 15px;
  font-weight: 500;
}

.count_details {
  display: flex;
  gap: 14px;
  align-items: baseline;
}

.count_details h5 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.comp_name_text{
  color: #808080 !important;
  position: relative  !important;
  display: block  !important;
  font-weight: 700  !important;
  font-size: 14px  !important;
  margin: 0 0 5px 0 !important;
}

.count_details_data {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.salary-button:focus {
  outline-style: none;
}

.salary-label {
  color: #000;
  font-weight: 500;
  font-size: 12px;
}

.salary-input-container {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* border: 1px solid #b4b2b2; */
  p{
    font-weight: 700;
    font-size: 16px;
    line-height: 21.6px;
  }
}

.main-input-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.salary-input-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.sort_sec_text{
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #7C8493;
}
.salary-input {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  width: 90px;
  text-align: right;
  height: 35px;
    color: #000;
}

.salary-button:focus {
  outline: none;
  /* Removes the default focus outline */
  border: 1px solid #fff;
  /* Keeps the border consistent with the non-focused state, change as needed */
}

.salary-input::placeholder {
  font-size: 12px;
}

.salary-dash {
  margin: 0 10px;

}
/* .responsive_filter_section{
  display: none;
} */

.FilterAi_btn_res{
  display: none;
}


.ant-pagination .ant-pagination-item{
  min-width: 40px;
  height: 40px;
  line-height: 39px;
  border: 1.28px solid #F1F1F1;
  border-radius: 50% !important;
}
.ant-pagination .ant-pagination-item a{
  border-radius: 50% !important;
  font-size: 14px !important;
  color: #000;
  font-weight: 400 !important;
}

.ant-pagination-prev{
  min-width: 40px !important;
  height: 40px !important;
  line-height: 39px !important;
  border: 1.28px solid #F1F1F1;
  border-radius: 50% !important;
}
.ant-pagination-next{
  min-width: 40px !important;
  height: 40px !important;
  line-height: 39px !important;
  border: 1.28px solid #F1F1F1;
  border-radius: 50% !important;
}

.ant-pagination-item-active{
  background: #0B4A96 !important;
  color: #fff !important;
}
.ant-pagination-item-active a{
  color: #fff !important;
}
.ant-pagination-next button{
  border-radius: 50% !important;
  font-size: 14px !important;
  color: #000;
  font-weight: 400 !important;
}
.ant-pagination-prev button{
  border-radius: 50% !important;
  font-size: 14px !important;
  color: #000;
  font-weight: 400 !important;
}
.ant-pagination-item-active:hover{
  border-color: #0B4A96 !important;
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .flexRowRight{
    gap: 20px;
  }


  .menulist {
    width: auto !important;
    margin-top: 15px;
  }
  .Dashboard_main_section {
    margin-top: 0px;
}


}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .sort_sec_text{font-size: 12px;}
  .flexRowRight {gap: 15px;}


  
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .count_details h5{
    font-size: 20px;
  }
  .flexRowRight{
    gap: 30px;
    /* justify-content: space-between; */
  }
  .filterclass,.resetButton{
    font-size: 12px;
  }
  .jobtype, .Sortby {
    font-size: 12px;  
}
.salary-button{
  font-size: 12px;
}
.dropdown-inline-container {
  justify-content: flex-end;
  gap: 6px;
  width: auto;
}
.filterclass img{
  display: none;
}
  .menulist {
    width: auto;
    margin: 15px auto 15px 0 !important;
  }

  .menutabs-wd {
    width: 100% !important;
  }

  .Dashboard_main_section .container.my-4 .row {
    flex-direction: row;
  }
  .Dashboard_main_section {
    margin-top: 0px;
}

  .Dashboard_main_section .container.my-4 .row .col-8 {
    flex: 0 0 auto;
    width: 100%;
  }

  .Dashboard_main_section .container.my-4 .row .col-3 {
    flex: 0 0 auto;
    width: 100%;
  }

  .Dashboard_main_section .container.my-4 .row .col {
    flex: 1 0 0%;
    margin-bottom: 10px;
  }

  .dropdown-inline-container {
    justify-content: flex-start;
  }

  .Sortby {
    width: 100%;
  }

  .dropdown-inline-container {
    justify-content: flex-end;
}

  /* .filterclass{
    display: none;
  } */

}



@media only screen and (max-width: 767px) {

  /* .non-responsive{
    display: none;
  }
  .responsive_filter_section{
    display: block;
  } */
  .Search_location input, .Search_jobs input {
    padding: 4px 8px 4px 35px;
}
.Search_location img, .Search_jobs img {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 16px;
}
.Search_btn button {
  padding: 4px;
}
  .menulist {
    width: 94% !important;
    margin: auto !important;;
  }
  
      .new_menu_sec_jobs .text-center {
        width: 100% !important;
        font-size: 11px !important;
      }
  .menutabs-wd .text-center {
    width: auto !important;
    font-size: 13px !important;
    padding: 6px !important;
  }

  .Search_jobs {
    margin-bottom: 0px;
  }

  .row.mt-4 {
    margin-top: 15px;
    gap: 10px;
  }

  .filterclass {
    margin-bottom: 15px;
  }

  .Dashboard_main_section .container.my-4 .row {
    flex-direction: column;
  }

  .Dashboard_main_section .container.my-4 .row .col-8 {
    flex: 0 0 auto;
    width: 100%;
  }

  .Dashboard_main_section .container.my-4 .row .col-3 {
    flex: 0 0 auto;
    width: 100%;
  }

  .Dashboard_main_section .container.my-4 .row .col {
    flex: 1 0 0%;
    margin-bottom: 10px;
  }

  .dropdown-inline-container {
    justify-content: flex-end;
  }

  /* .jobtype,
  .Sortby {
    width: 100% !important;
  } */
  .Dashboard_main_section{
    margin-top: 0px;
  }
  .count_details h5{
    font-size: 16px;
    margin-bottom: 0px;
  }
  .count_details_data{
    font-size: 12px;
    margin-bottom: 0px;
  }
  .count_details{
    justify-content: space-between
  }
  .flexRowRight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
.filterclass{
  display: none;
}
.flexRow{
  flex-direction: column;
}
.salary-button{
  padding: 4px 5px;
  font-size: 11px;
  font-weight: 500;
}
.salary-input-container{
  padding: 10px;
  left: 120px;
}
.salary-input{
  width: 60px;
  font-size: 10px;
  
}
.salary-label{
  font-size: 8px;
}
.salary-dash {
  margin: 0 5px;
}
.resetButton {
  font-size: 12px;
}
.flexRow{
  gap:10px;
  align-items: end;
}
.dropdown-inline-container span{
  font-size: 12px;
}
.pagination-container .ant-pagination .ant-pagination-item-active{
  border-radius: 0px;
}
.pagination-container .ant-pagination{
  font-size: 12px;
}
.pagination-container .ant-pagination .ant-pagination-item{
    display: inline-block;
    min-width: 20px;
    height: 21px;
    margin-inline-end: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 19px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    user-select: none;
}
.non-responsive{
  display: none;
}
.FilterAi_btn_res{
  display: flex;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  color: #1865c1;
  gap: 4px;
  font-weight: 700;

}
.applicant_dashboard_filter {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.salary-input-container1{
  position:static;
}
.salary-button1{
  border: none;
  box-shadow: none !important;
  background-color: transparent !important;
}
.filter_by_search {
  background: transparent;
  border: 1px solid #1865c1;
  border-radius: 25px;
  width: 200px;
  padding: 10px 20px;
}
.filter_by_search_main{
  display: flex;
  justify-content: center;
}
.salary-input-container11{
  padding: 5px;
  }    
   .salary-input-container11 .salary-input{
    padding: 4px; 
    }
}
@media only screen and (min-width: 653px) and (max-width: 720px){}
@media (min-width: 280px) and (max-width: 320px){
  .row.mt-4{
    margin-top: 10px !important ;
  }
  .menutabs-wd .text-center{
    font-size: 8px !important;
    margin-right: 0px !important;
  }
  .Search_location input, .Search_jobs input {
    padding: 4px 4px 4px 40px;
}
.Search_location img, .Search_jobs img {
  position: absolute;
  top: 10px;
  left: 12px;
  width: 15px;
}
.Search_btn button{
  padding: 4px;
    font-size: 10px;
}
.count_details h5{
  font-size: 13px;
}
.my-4{
  margin: 0px;
}
.nopage_found img {
  width: 123px;
}
.nojobapplicantfound_container{
  margin: 0px;
}
.Search_btn img{
  width: 16px;
}
.related_sec_new_inner_left_top_head{
  gap: 10px;
}
.related_sec_new_inner_left_top_head h6{
  font-size: 12px;
}
}