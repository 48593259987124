.navbar-container {
  display: flex;
}

.get_start_btn {
  position: relative;
  border: 2px solid #1865c1;
  border-color: #1865c1;
  color: #020202;
  font-weight: 500;
  font-size: 19px;
  padding: 13px 40px;
  border-radius: 50px;
  transition: 0.6s ease-in-out;
}
.join_now_btn{
    padding: 12px 40px;
    background: #fff;
    border-radius: 100px;
    color: #1865c1;
    font-size: 16px !important;
    font-weight: 600 !important;
    border: 1px solid #1865c1;
}
.join_now_btn:hover{
  cursor: pointer;
  background: #1865c1;
  border-radius: 100px;
  color: #fff;
}
.Credits_btn{
  background-color: #FFEED6;
  color: #000000;
  padding: 10px 30px;
  border-radius: 6px;
  border: 1px solid #FDC511;
  display: flex;
  align-items: center;
}
.Credits_btn:focus{outline: none;border: 1px solid #FDC511;}
.Credits_btn_warning{
  background-color: #FFE5E5;
  color: #000;
  padding: 10px 30px;
  border-radius: 6px;
  border: 1px solid #DC0000;
  display: flex;
  align-items: center;
}
.Credits_btn img{
margin-right: 10px;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  /* box-shadow: 0px -3px 17.200000762939453px 0px #00000014; */
  background:#fff !important;
  /* border-radius: 0 0 14px 14px; */
  z-index: 11;
  padding: 10px 0;
  box-shadow: 0px 2px 7px 0px #0000001f;
  position: fixed;
  width: 100%;
}
.navbar-brand img {
  width: 115px;
}

.navbar-brand2 img {
  width: 100%;
  margin-top: -15px;
}

.form-select {
  background-color: inherit;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
}
/* .sticky_header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    background: #fff !important;
    z-index: 99 !important;
    padding: 5px 0 ;
    -webkit-box-shadow: 0 1px 7px 0 rgba(0,0,0,.4) !important;
    -moz-box-shadow: 0 1px 7px 0 rgba(0,0,0,.4) !important;
    box-shadow: 0 1px 7px 0 rgba(0,0,0,.4) !important;
  } */
.navbar-nav select:focus {
  box-shadow: none;
}
.bell_svg {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
}
.nav_bar_sec {
  display: flex;
  align-items: center;
  gap: 40px;
}
.pricing_text_new{color: #EEAC00;font-size: 16px;font-weight: 500;margin-bottom: 0px !important;cursor: pointer;}


.bell_drop_sec {
  position: relative;
}
.bell_drop_sec .dropdown-toggle::after {
  display: none;
}
.bell_drop_sec_cont_sec {
  position: absolute;
  right: 0;
  top: auto;
  width: 600px;
  box-shadow: 0px 4px 20px 0px #0000001a;
  border: none;
  background: #fff;
  border-radius: 10px;
}
.bell_drop_sec_cont_sec_inner {
  position: relative;
  padding: 15px 25px;
  max-height: 465px;
  overflow-y: scroll;
}
.bell_drop_sec_cont_sec_inner::-webkit-scrollbar {
  width: 20px;
  display: none;
}
.bell_drop_sec_cont_sec_inner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.bell_drop_sec_cont_sec_inner::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
.bell_drop_sec_cont_sec_inner::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.bell_drop_sec_cont_sec_inner h6 {
  position: relative;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.bell_drop_sec_cont_sec_inner_single {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.bell_drop_sec_cont_sec_inner_single:last-child {
  margin-bottom: 0px;
}
.bell_drop_sec_cont_sec_inner_single img {
  width: 100%;
  flex: 0 0 50px;
}
.sing_cont_sec {
  position: relative;
}
.sing_cont_sec h5 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: inherit;
}
.sing_cont_sec h5 b {
  font-weight: 700;
}
.bell_drop_sec_cont_sec_inner_single.active {
  background: #e2f3ffb2 !important;
}
.bell_drop_sec_cont_sec_inner_single.active:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #1865c1;
  border-radius: 50%;
  left: 10px;
}
.bell_drop_sec_cont_sec_inner_single span {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #aaaaaa;
  font-size: 12px;
}
.che_dwn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  border: none;
  background: none;
}
.view_all_btn {
  position: relative;
  color: #1865c1;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: none;
  border: none;
}
.rounded-circle {
  margin-right: 20px;
}
.user_text_new .dropdown-toggle {
  color: #000000;
  position: relative;
  font-weight: 600;
  font-size: 17px;
  background: none;
  border: none;
  padding: 0px;
}
.user_text_new .dropdown-toggle::after {
  background: url(../../../assets/images/expand_more.svg);
  content: "";
  border: none;
  width: 12px;
  height: 7px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  position: absolute;
  right: -15px;
  top: 10px;
}
.user_text_new_inner {
  position: absolute;
  right: -45px;
  top: 50px;
  width: 150px;
  box-shadow: 0px 4px 20px 0px #0000001a;
  border: none;
  background: #fff;
  border-radius: 6px;
  padding: 0px;
}
.user_text_new_inner {
  position: relative;
}
.user_text_new_inner ul {
  display: block;
  width: 100%;
}
.user_text_new_inner ul li {
  display: block;
}
.user_text_new_inner ul li a {
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  height: 50px;
  width: 100%;
  padding: 0 25px;
}
.user_text_new_inner ul li a.active {
  background: #f0f9ff;
  color: #1865C1;
}
.user_text_new_inner ul li a.active:after{display: none;}
.user_text_new_inner ul li a:hover {
  background: #f0f9ff;
}
.user_text_new_inner ul li a i {
  font-weight: 600 !important;
}
.user_text_new p {
  position: relative;
  margin-bottom: 0px;
}

.mobile_header_sec {
  display: none;
  box-shadow: 4px 0px 5px 1px #00000045;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-toggler {
    display: none;
  }
  .user_text_new_inner {
    position: absolute;
    right: -18px;
  }
  /* nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    display: none;
  } */
  .comp_det_rigt_main{
    margin-top: 26px;
  }

  .mobile_header_sec {
    display: flex;
    padding: 15px 0;
    position: relative;
  }
  .mobile_header_sec_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav_bar_sec {
    display: flex !important;
    align-items: center !important;
    gap: 20px;
    flex-direction: row;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bell_drop_sec_cont_sec {
    width: 500px;
  }
  .bell_drop_sec_cont_sec_inner {
    padding: 15px 25px;
    max-height: 420px;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-toggler {
    display: none;
  }
  nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    display: none;
  }
  .user_text_new_inner {
    position: absolute;
    right: -18px;}
  .mobile_header_sec {
    display: flex;
    padding: 15px 0;
    position: relative;
    box-shadow: 4px 0px 5px 1px #00000045;
  }
  .mobile_header_sec_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav_bar_sec {
    display: flex !important;
    align-items: center !important;
    gap: 20px;
    flex-direction: row;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bell_drop_sec_cont_sec {
    width: 500px;
  }
  .bell_drop_sec_cont_sec_inner {
    padding: 15px 25px;
    max-height: 420px;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 767px) {
  .mobile_header_sec {
    display: block;
    background: #fff;
    box-shadow: 0px -3px 17.200000762939453px 0px #00000014;
    padding: 15px 0;
    z-index: 11;
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
  }
  .Credits_btn {
    background-color: #FFEED6;
    color: #FDC511;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #FDC511;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
}
.Credits_btn img {
  margin-right: 10px;
  width: 14px;
}
  .mobile_header_sec_inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .mobile_header_sec_inner_left {
    position: relative;
    width: 75px;
    flex: 0 0 75px;
  }
  .mobile_header_sec_inner_left img {
    width: 100%;
  }
  .mobile_header_sec_inner_right .nav_bar_sec {
    display: flex !important;
    align-items: center !important;
    gap: 20px;
    flex-direction: row;
  }
  .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .mobile_header_sec_inner_right .nav_bar_sec {
    gap: 15px;
    margin-right: 15px;
  }
  .bell_svg {
    width: 12px;
  }
  img.rounded-circle.me-2 {
    width: 25px;
  }
  .user_text_new .dropdown-toggle {
    font-size: 12px;
  }
  .user_text_new .dropdown-toggle::after {
    background-size: 8px;
    right: -12px;
    top: 6px;
  }
  .navbar-nav .dropdown-menu {
    --bs-dropdown-min-width: 0rem !important;
  }
  .user_text_new_inner {
    position: absolute;
    right: -22px;
    top: 44px;
    width: 112px;
    box-shadow: 0px 4px 20px 0px #0000001a;
    border: none;
    background: #fff;
    border-radius: 6px;
    padding: 0px;
  }
  .user_text_new_inner ul li a {
    font-size: 12px;
    height: 40px;
    gap: 8px;
    padding: 0 10px;
    justify-content: center;
  }
  .user_text_new_inner ul li a i {
    font-weight: 600 !important;
    margin-top: 3px;
}
  .bell_drop_sec_cont_sec {
    width: 250px;
    right: -90px;
    top: 45px;
  }
  .bell_drop_sec_cont_sec_inner {
    padding: 10px;
    max-height: 385px;
    overflow-y: scroll;
  }
  .bell_drop_sec_cont_sec_inner h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .bell_drop_sec_cont_sec_inner_single {
    padding: 10px;
    margin-bottom: 10px;
    flex-direction: column;
  }
  .bell_drop_sec_cont_sec_inner_single img {
    width: 50px;
    flex: 0 0 50px;
  }
  .sing_cont_sec h5 {
    font-size: 10px;
  }
  .che_dwn {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
}

@media (min-width: 280px) and (max-width: 320px){
  .get_start_btn{
      font-size: 12px !important;
      padding: 10px 30px;
  }
}