.resume_generator_form .form-control {
    margin-bottom: 1rem; /* Spacing between form inputs */
  }
  
  .resume_generator_form .skill_suggestions button {
    margin-right: 0.5rem; 
    margin-bottom: 0.5rem; 
  }
  
  .resume_generator_form-container{
    border-radius: 16px;
    background: #FFF;
    padding: 30px;
    box-shadow: 0px 6.079px 43.465px 0px rgba(0, 0, 0, 0.08);
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px; 
    /* margin-top: 80px; */
  }
  
  .header-title {
    margin: 0;
    font-size: 26px; 
    font-weight: 700;
  }
  .back-button {
    position: absolute;
    left: 10px; /* Spacing from the left edge */
    border: none; /* Remove default button border */
    background: none; /* Remove default button background */
    cursor: pointer; /* Change cursor on hover */
    font-size: 16px; /* Adjust button text size as needed */
    display: flex;
    gap: 15px;
    align-items: center;
    color: black !important;
  } 
  .resume_generator_form-container input{
        background-color: #F2F2F2;
  }
  .resume_generator_form-container label{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .verify-btn {
    margin-bottom: 1rem;
    border-radius: 6px;
    background: #1865C1
}
.proceed_btn{
    background: #1865C1;
    padding :12px 60px;
    border-radius: 30px;
}
.navbar-nav select{
 border: none;
}
.resume_generator_form-container label span{
    color: #F00;
}
.skill_suggestions button{
    padding: 6px 18px;
    border-radius: 20px;
    border: 1px solid #3a3a3a;
    font-size: 12px;
    color: black;
}

@media only screen and (min-width:1199px) and (max-width:2199px) {
  /* .header{
margin-top: 80px;
  } */
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  
}

@media only screen and (min-width:768px) and (max-width:991px) {
  
}

@media only screen and (max-width:767px) {
   
  .header-title{font-size: 16px;margin-top: 60px;}
  .back-button {left: 0;font-size: 14px;gap: 9px;top: -10px;}
  .resume_generator_form-container{padding: 15px;}
  .resume_generator_form-container .row{flex-direction: column;}
  .resume_generator_form .form-control {margin-bottom: 0 !important;}
  .resume_generator_form-container .col{margin-bottom: 20px;}
   .verify-btn{margin-bottom: 0px;position: absolute !important;right: 0;}
  .resume_generator_form-container .mt-3 {margin-top: 0 !important;}
  .resume_generator_form-container input{width: 100% !important;}
  .css-1jzq0dw-MuiChip-label{font-size: 10px;}
  .proceed_btn { padding: 8px 40px; font-size: 12px;margin-top: 10px;}

  

}