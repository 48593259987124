.comp_det_rigt_main {
  position: relative;
}
.my_comp_dashboard_sec_banner {
  position: relative;
}
.my_comp_dashboard_sec_banner img {
  width: 100%;
}
.comp_gallery_sec_body.expanded .comp_gallery_sec_body_single.hidden {
  display: block; /* or flex, depending on your layout */
}
.edit_icn_dash {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2c2c2c80;
  border-radius: 50%;
}
.margin_80{
  margin-top: 80px;
}
.edit_icn_dash i {
  color: #c7c7c7;
}
.pac-container {
  z-index: 2000 !important;
}
.upload__image-wrapper{
  padding: 20px;
    border: 1px solid #b6b6b6;
    border-radius: 25px;
}

.removeAll_btn{
  border: 1px solid #C90000;
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  padding: 4px 20px;
  font-weight: 500;
  color: #C90000;
}
.image-main-div{
  display: flex;
  flex-wrap: wrap;
margin: 10px 0px;
gap: 10px;
}
.remove_btn_main,.Save_gallery_main{
  display: flex;
  justify-content: end;
}

.addimg{
  border: 1px solid #c7c7c7;
  height:160px;
  width: 230px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.add_up_img{
  width: 60px;
}
.Save_gallery{
  border: 1.8px solid #1865c1;
  border-radius: 25px;
  background-color: transparent;
  font-size: 16px;
  padding: 4px 20px;
  font-weight: 500;
  color: #1865c1;
}
.image-item {
  position: relative;
  background: #fff;
  box-shadow: 0 0px 2px 1px #00000045;
  padding: 10px;
  border-radius: 20px;
  height: auto;
  object-fit: cover;
}
.image-item__btn-wrapper{
  display: flex;
  gap: 10px;
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 4px;
    border-radius: 5px;
    background-color: #0000004f;
}
.image-item__btn-wrapper button{
  border: none;
  background-color: transparent;
  cursor: pointer; 
}
.replace_btn{
  width: 18px !important;
  height: auto !important;
}
.image-item img {
  transition: filter 0.3s ease; /* Add transition for smooth effect */
}

.image-item img:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Transparent black color */
  z-index: 1; /* Ensure overlay is above the image */
}

/* Optional: Adjust the brightness of the image on hover */
.image-item img:hover {
  filter: brightness(0.8); /* Decrease brightness on hover */
}

.save_img_main{
  display: flex;
  justify-content: end;
  gap: 30px;
}
.image-item img{
  width: 230px;
    height: 140px;
    object-fit: cover;
    border-radius: 20px;
}
/* :where(.css-dev-only-do-not-override-17sses9).ant-modal-root .ant-modal-wrap {
  position: fixed;
  inset: 0;
  z-index: 999999 !important;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
} */
/* Corrected CSS to target Ant Design modal wrap */
.ant-modal-root .ant-modal-wrap {
  position: fixed;
  inset: 0;
  z-index: 999999 !important;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.my_comp_dashboard_sec_body {
  position: relative;
  padding: 20px;
  background: #fff;
  /* height: 100vh;
    overflow-y: scroll; */
    border-radius: 12px;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 10px 0px #0000001a;
}
.my_comp_dashboard_sec_body::-webkit-scrollbar {
  width: 9px;
  display: none;
}
.my_comp_dashboard_sec_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 10px;
}
.my_comp_dashboard_sec_body::-webkit-scrollbar-thumb {
  background: #1865c1;
  border-radius: 10px;
}
.my_comp_dashboard_sec_body::-webkit-scrollbar-thumb:hover {
  background: #1865c1;
}
.my_comp_dashboard_sec_body_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my_comp_dashboard_left_logo {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
.logo_sec_img {
  position: relative;
  height: 145px;
  width: 145px;
  background: #fff;
  box-shadow: 0px 4px 33px 0px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}
.logo_sec_img img {
  max-height: 100%; /* or a specific px value if needed */
  max-width: 100%;
  object-fit: contain;
}
.my_comp_dashboard_left_logo h6 {
  position: relative;
  font-size: 32px;
  color: #070707;
  font-weight: 700;
  left: 25px;
}
.my_comp_dashboard_left_edit {
  position: relative;
}
.my_comp_dashboard_left_edit button {
  position: relative;
  display: block;
  border: 2px solid #1865c1;
  width: 120px;
  height: 40px;
  color: #1865c1;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  background: none;
}
.my_comp_dashboard_left_edit button:focus {
  outline: inherit;
  border: 2px solid #1865c1;
}
.my_comp_dashboard_left_edit button i {
  margin-right: 5px;
}
.comp_prof_cont_sec {
  position: relative;
  padding-top: 20px;
}
.comp_prof_inner_text {
  position: relative;
}
.comp_prof_inner_text h5 {
  position: relative;
  color: #25324b;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px !important;
}
.comp_prof_inner_text p {
  position: relative;
  color: #383838;
  font-size: 16px;
  font-weight: 500;
}
.web_site_industy_sec {
  position: relative;
  padding-top: 25px;
}
.web_site_industy_sec_inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-row-gap: 35px;
  margin-bottom: 35px;
}
.web_site_industy_sec_inner_single {
  position: relative;
}
.web_site_industy_sec_inner_single_top {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.web_site_industy_sec_inner_single_top img {
  width: auto;
}
.web_site_industy_sec_inner_single_top h6 {
  position: relative;
  color: #25324b;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.web_site_industy_sec_inner_single_btm {
  position: relative;
}
.web_site_industy_sec_inner_single_btm h5 {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #383838;
  margin-bottom: 0px;
}
.web_site_industy_sec_inner_single_btm h5 a {
  color: #1865c1;
}
.pers_benifits_sec {
  position: relative;
  padding-top: 30px;
}
.pers_benifits_sec h6 {
  position: relative;
  color: #25324b;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px !important;
}
.pers_benifits_sec ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  position: relative;
}
.innvoate_list {
  grid-template-columns: 1fr 1fr 1fr !important;
}
.web_site_industy_sec_inner_single_btm ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  position: relative;
}
.web_site_industy_sec_inner_single_btm ul li {
  position: relative;
  display: block;
  color: #2f343a;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
}

.web_site_industy_sec_inner_single_btm ul li:before {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  width: 4px;
  background: #000;
  border-radius: 50%;
  top: 8px;
}

.Show_all_jobs_text{
font-size: 16px;
color: #000 !important;
}
.Show_all_jobs_text:hover{
  font-size: 16px;
  color: #1865c1 !important;
  }
.web_site_industy_sec_inner_single_btm ul li span {
  position: relative;
  display: block;
  padding-top: 5px;
}

.pers_benifits_sec ul li {
  position: relative;
  display: block;
  color: #2f343a;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
}
.pers_benifits_sec ul li:before {
  content: "";
  position: absolute;
  left: 0;
  height: 4px;
  width: 4px;
  background: #000;
  border-radius: 50%;
  top: 8px;
}
.pers_benifits_sec ul li span {
  position: relative;
  display: block;
  padding-top: 5px;
}
.comp_gallery_sec {
  position: relative;
  padding-top: 30px;
}
.comp_gallery_sec h6 {
  position: relative;
  color: #25324b;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px !important;
}
.comp_gallery_sec_body {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}
.comp_gallery_sec_body_single {
    border-radius: 5px;
    position: relative;
    width: 100%;
    border: 1px solid #e3dfdf;
    padding: 10px;
    height: 260px;
}
.comp_gallery_sec_body_single img {
  width: 100%;
  *height: 260px;
  *object-fit: contain;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
}
.comp_gallery_sec .comp_gallery_sec_body_single img{object-fit: contain;}
.comp_gallery_sec_body_single_grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.photos_main_text {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photos_main_text h5 {
  position: relative;
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  color: #707070;
}
.see_phot_btn {
  position: relative;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.see_phot_btn button {
  position: relative;
  font-size: 18px;
  color: #646464;
  font-weight: 700;
  background: none;
  border: none;
}
.posted_jobs_main_sec {
  position: relative;
  padding-top: 30px;
}
.posted_jobs_main_sec_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.posted_jobs_main_sec_head h6 {
  position: relative;
  color: #25324b;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}
.posted_jobs_main_sec_head a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #1865c1;
  font-size: 12px;
  font-weight: 500;
}
.posted_jobs_main_sec_body {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.posted_jobs_main_sec_single {
  position: relative;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 10px 0px #0000001a;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
  cursor: pointer;
}
.posted_jobs_main_sec_single_top {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px !important;
}

.sing_left_top {
  position: relative;
  display: flex;
  align-items: center;
}
.sing_left_top img {
  width: 80px !important;
  margin-right: 14px;
  height: 80px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 4px;
}
.sing_left_top h6 {
  margin-bottom: 0px;
  display: none;
}
.sing_right_top {
  position: absolute;
  top: 0;
  right: 0;

}
.sing_right_top img {
  width: auto !important;
}
.des_text_sec {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 10px !important;
}
.des_text_sec_sub {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  /* margin-bottom: 25px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
  padding-bottom: 5px;
  margin: 12px 0px;
}
.des_text_sec span {
  position: relative;
  display: inline-block;
  color: #000000;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 400;
}
.cont_list_sec {
  position: relative;
  /* display: block; */
  margin-bottom: 20px !important;
  display: grid;
    grid-template-columns: 1fr 1fr;

    /* display: flex;
    flex-wrap: wrap; */
}
.cont_list_sec li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cont_list_sec li span {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  background: #f8f8f8;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
}
.cont_list_sec li span img {
  width: auto !important;
  margin-right: 0px;
}
.view_btn_inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.view_btn_inner button {
  position: relative;
  display: inline-block;
  background: #0B4A96;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 35px;
  border: none;
  border-radius: 20px;
}
.view_btn_inner span {
  color: #00000099;
  font-size: 12px;
  font-weight: 400;
}
.under-review-disabled-btn {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #000 !important;
  cursor: not-allowed;
}
.custom_modal_scrollable {
  margin: 30px 15px;
  padding: 0px;
}
.saveContinue {
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #1865c1;
  background-color: #1865c1;
  padding: 12px 20px;
  border-radius: 50px;
  width: 200px;
}
.save-btn-container {
  display: flex;
  justify-content: end;
}

.react-quill-container {
  border-radius: 6px;
  background: #f8f8f8 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  width: 100% !important;
  border: none !important;
}
#overview .ql-toolbar{
  border-radius: 5px 5px 0px 0px;
}
#overview .ql-container.ql-snow{
  border-radius: 0px 0px 5px 5px;
  min-height: 180px;
  overflow-y: auto;
}

#overview .ql-editor{
height: 200px !important;
}
/* .scrollable_modal {
  max-height: 90vh; 
  overflow-y: auto; 
  padding: 15px; 
} */

/* Custom scrollbar styles for the .scrollable_modal class */
.scrollable_modal::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.scrollable_modal::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded corners of the scrollbar track */
}

.scrollable_modal::-webkit-scrollbar-thumb {
  background: #1865c1; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the scrollbar thumb */
}

.scrollable_modal::-webkit-scrollbar-thumb:hover {
  background: darkblue; /* Color of the scrollbar thumb on hover */
}

.add_com_data_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}


.new_data_sec_com .progress-wd .ant-progress-text {
  top: 25px !important;
  text-align: center !important;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.new_modl_cont .ant-radio-group{display: flex !important;align-items: center !important;}
.new_modl_cont .ant-form-item-required:after{margin-inline-end: 15px;}


@media only screen and (min-width:1900px){

  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
}
@media only screen and (min-width:1900px){
  .flex-justify-center{
    display: flex;
    justify-content: center !important;
  }
  .flex-justify-center{
    justify-content: center;
  }
  
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .my_comp_dashboard_sec_banner img {
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  .logo_sec_img {
    height: 110px;
    width: 110px;
  }
  .my_comp_dashboard_left_logo h6 {
    font-size: 22px;
    left: 125px;
  }
  .my_comp_dashboard_left_edit button {
    width: 90px;
    height: 35px;
    font-size: 12px;
  }
  .comp_prof_cont_sec {
    padding-top: 40px;
  }
  .comp_prof_inner_text h5 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .comp_prof_inner_text p {
    font-size: 13px;
  }
  .web_site_industy_sec_inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    grid-row-gap: 25px;
    margin-bottom: 25px;
  }
  .pers_benifits_sec ul {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  .innvoate_list {
    grid-template-columns: 1fr 1fr !important;
  }
  .comp_gallery_sec_body {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr 1fr;
  }
  .my_comp_dashboard_sec_banner img {
    display: none;
  }
  .edit_icn_dash {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .my_comp_dashboard_sec_banner img {
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  .logo_sec_img {
    height: 110px;
    width: 110px;
  }
  .my_comp_dashboard_left_logo h6 {
    font-size: 22px;
    /* left: 125px; */
  }
  .my_comp_dashboard_left_edit button {
    width: 90px;
    height: 35px;
    font-size: 12px;
  }
  .des_text_sec_sub{
    font-size: 14px;
  }
  .cont_list_sec li span{
    font-size: 12px;
  }
  .Search_location input, .Search_jobs input {
    padding: 10px 10px 10px 34px;
}
.Search_location img, .Search_jobs img {
  position: absolute;
  top: 16px;
  left: 12px;
  width: 15px;
}
  .comp_prof_cont_sec {
    padding-top: 40px;
  }
  .comp_prof_inner_text h5 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .comp_prof_inner_text p {
    font-size: 15px;
  }
  .web_site_industy_sec_inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    grid-row-gap: 25px;
    margin-bottom: 25px;
  }
  .pers_benifits_sec ul {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  .innvoate_list {
    grid-template-columns: 1fr 1fr !important;
  }
  .comp_gallery_sec_body {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr 1fr;
  }
  .my_comp_dashboard_sec_banner img {
    display: none;
  }
  .edit_icn_dash {
    display: none;
  }
  .image-item img {
    width: 190px;}
    .addimg {
      width: 190px;
}
.web_site_industy_sec_inner_single_top h6{
  font-size: 18px;
}
.add_com_data_container{
      align-items: center;
      height: auto;
}
.pers_benifits_sec h6,.comp_gallery_sec h6{
  font-size: 18px;
}
.posted_jobs_main_sec_head{
  font-size: 14px !important;
}
.posted_jobs_main_sec_single_top{
  margin-bottom: 0px;
}.comp_prof_cont_sec{
  padding-top: 20px;
}
}
@media only screen and (max-width: 767px) {
  .my_comp_dashboard_sec_banner img {
    width: 100%;
    height: 125px;
    object-fit: cover;
    border-radius: 4px;
    display: none;
  }
  .logo_sec_img {
    width: 80px;
    height: 80px;
    top: 0px;
    left: 5px;
    top: 5px;
  }
  .my_comp_dashboard_left_logo h6 {
    font-size: 12px;
    left: 0;
    margin: 0;
  }
  .edit_icn_dash {
    height: 25px;
    width: 25px;
    display: none;
  }
  .edit_icn_dash i {
    font-size: 10px;
  }
  .my_comp_dashboard_sec_body {
    padding: 10px;
    height: auto;
    overflow-y: auto;
    border-radius: 6px;
  }
  .comp_gallery_sec_body_single img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
}
  .my_comp_dashboard_left_edit button {
    width: 65px;
    height: 30px;
    font-size: 10px;
  }
  .comp_prof_cont_sec {
    padding-top: 30px;
  }
  .comp_prof_inner_text h5 {
    font-size: 22px;
  }
  .comp_prof_inner_text p {
    font-size: 14px;
    text-align: justify;
  }
  .web_site_industy_sec {
    padding-top: 10px;
  }
  .web_site_industy_sec_inner {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    grid-row-gap: 15px;
    margin-bottom: 20px !important;
  }
  .web_site_industy_sec_inner_single_top {
    margin-bottom: 10px;
  }
  .web_site_industy_sec_inner_single_top h6 {
    font-size: 10px;
  }
  .web_site_industy_sec_inner_single_btm h5 a {
    font-size: 12px;
  }
  .web_site_industy_sec_inner_single_btm h5 {
    font-size: 12px;
  }
  .pers_benifits_sec ul {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    position: relative;
  }
  .pers_benifits_sec h6 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .web_site_industy_sec_inner_single_top h6 {
    font-size: 14px;
  }
  .pers_benifits_sec ul li {
    font-size: 12px;
  }
  .innvoate_list {
    grid-template-columns: 1fr !important;
  }
  .comp_gallery_sec_body {
    grid-template-columns: 1fr;
    display: flex;
    flex-wrap: inherit;
}
.removeAll_btn{
  font-size: 11px;
  padding: 4px 20px;
  margin-top: 10px;
}
  .photos_main_text h5 {
    font-size: 14px;
  }
  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr;
  }
  .posted_jobs_main_sec_single {
    padding: 15px;
  }
  .posted_jobs_main_sec_single_top {
    margin-bottom: 10px;
  }
  .des_text_sec {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .des_text_sec span {
    padding-left: 12px;
    font-size: 12px;
  }
  .cont_list_sec li span {
    font-size: 10px;
    gap: 5px;
    padding: 10px 12px;
  }
  .cont_list_sec li span img {
    width: 12px;
  }
  .view_btn_inner button {
    font-size: 12px;
    padding: 6px 28px;
  }
  .cont_list_sec {
    margin-bottom: 10px;
  }
  .comp_det_rigt_main {
    padding-right: 0px;
  }
  .add_com_data_container{
    display: block;
  }
  .web_site_industy_sec_inner_single_btm ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .pers_benifits_sec,.comp_gallery_sec{
    padding-top: 10px;
  }
}
@media only screen and (min-width: 680px) and (max-width: 830px){
  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr 1fr;
}
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .comp_gallery_sec_body {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .posted_jobs_main_sec_body {
    grid-template-columns: 1fr 1fr;
  }
  .web_site_industy_sec_inner {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .pers_benifits_sec ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 280px) and (max-width: 320px){
  .des_text_sec_sub{
    font-size: 12px;
    display: block;
  }
  .cont_list_sec li span img {
    width: 12px !important;
}
.view_btn_inner button{
  font-size: 10px;
}
.cont_list_sec{
  grid-template-columns: 1fr;
}
.comp_prof_inner_text h5{
  font-size: 18px;
  margin-bottom: 6px;
}
.comp_prof_cont_sec {
  padding-top: 15px;
}
.comp_prof_inner_text p{
  font-size: 12px;
}
.web_site_industy_sec_inner_single_top img {
  width: 14px;
}
.web_site_industy_sec_inner_single_btm h5{
  font-size: 10px;
  line-height: 16px;
}
.web_site_industy_sec_inner_single_btm ul li{
  font-size: 10px;
    padding-left: 10px;
}
.pers_benifits_sec h6{
  font-size: 14px;
}
.comp_gallery_sec h6,.posted_jobs_main_sec_head h6{
  font-size: 14px;
}
.posted_jobs_main_sec{
  padding-top: 10px;
}
.posted_jobs_main_sec_head{
  margin-bottom: 10px;
}
.cont_list_sec{
  margin-bottom: 0px;
}
.companyDetails_name h5 {
  font-size: 12px;
  margin: 0px;
}
}