.confim_info_sec {
  position: relative;
  padding: 25px 0;
  margin-top: 60px;
}

.confim_back_btn {}

.confim_info_sec_inner {
  position: relative;
  width: 55%;
  margin: 0 auto;
  max-width: 800px;
}

.pdf-canvas{
  display: flex;
}

.custom_astrix{
  color: #EB5757;
  font-size: 18px;
}
.confim_info_sec_inner_head {
  position: relative;
  margin-bottom: 20px;
  text-align: center;
}

.confim_info_sec_inner_head h6 {
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
}

.confim_info_sec_inner_body {
  position: relative;
  box-shadow: 0px 4px 23.399999618530273px 0px #0000002E;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}

.confim_info_sec_inner_body h2 {
  position: relative;
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 8px;
}

.confim_info_sec_inner_body h3 {
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
  line-height: inherit;
}

.confim_info_single {
  position: relative;
  background: #F9F9F9;
  border-radius: 12px;
  padding: 12px 15px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.confim_info_single_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}

.confim_info_single_head h5 {
  position: relative;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: inherit;
}

.confim_info_single_head button {
  position: relative;
  display: inline-block;
  background: none;
  border: none;
}

.confim_info_single_body {
  position: relative;
}

.confim_info_single_body p {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  color: #000;
}

.confim_info_single_body ul {
  display: block;
}

.confim_info_single_body ul li {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0 5px 0;
  color: #000;
  position: relative;
  padding-left: 12px;
}

.confim_info_single_body ul li:before {
  position: absolute;
  content: '';
  left: 0;
  top: 8px;
  height: 5px;
  width: 5px;
  background: #000;
  border-radius: 50%;
}

.login_btn_blue_new {
  position: relative;
  width: 60%;
  margin: 50px auto 0;
}

.login_btn_blue_new button {
  position: relative;
  background: #0B4A96!important;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}

.login_btn_blue_new a.continue {
  position: relative;
  background: #1865c1 !important;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}

.confim_info_sec_inner_btns{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}

.login_btn_blue_new button:disabled {
  background-color: grey;
  /* Disabled state background color */
  color: white;
  /* Disabled state text color */
}

#clickable a{
  color: #4E5862;
  font-size: 14px;
  font-weight: 700;
}


#clickable {
  display: flex;
  color: #666666;
  font-weight: 400 !important;
  width: 82px;
}



.login_btn_blue_new .enhance {
  position: relative;
  padding: 0px 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #4E5862;
  text-decoration: none;
  
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 0 0 2px transparent;
  background-clip: padding-box;
  border: 2px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(to right, #1FB8C3, #7977F7) border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;            
}
.dashed_div{
  width: 100%;
}
.dashed_div .dashed_btn{
  position: relative;
  padding: 0px 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #4E5862;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 0 0 2px transparent;
  background-clip: padding-box;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  border: dashed;
  border-color: #214991;
  border-radius: 30px;         
}

.summary_sec_new .modal-dialog {
  background: #FFFFFF;
  max-width: 640px;
  padding: 30px;
  border-radius: 10px;
  top: 130px;
}

.summary_sec_new .modal-content {
  border: none;
}

.summary_sec_new_inner {
  position: relative;
}

.summary_sec_new_inner_cont {
  position: relative;
}

.summary_sec_new_inner_cont p {
  position: relative;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.gene_ai_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.gene_ai_btn button {
  position: relative;
  display: flex;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent;
  border-image-source: linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%);
  background: none;
  border-image-slice: 1;
  padding: 8px 20px;
  gap: 10px;
  border-radius: 30px;
}

.gene_ai_btn button img {
  width: 16px;
}

button.close_icn_new {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 50%;
  top: -95px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.save_rege_sec_new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.save_btn_new {
  position: relative;
  display: inline-block;
  color: rgba(78, 88, 98, 1);
  font-size: 12px;
  font-weight: 500;
  padding: 6px 24px;
  border-radius: 20px;
  background: none;
  border: 1.5px solid rgba(157, 181, 215, 1)
}

.sync_btn_new {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #1865C1;
  font-weight: 400;
  border: none;
  background: none;
}

.sync_btn_new img {
  width: 16px;
}

.userprofiledata {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  position: relative;
}

/* .userprofiledata img{
  width: 100px;
  height: 100px;
} */
.img-center {
  text-align: center;
}

.userprofiledata .view_delete {
  position: absolute !important;
  top: 118px;
  left: 16px !important;
}

/* .completeProfile label {
  font-weight: 400 !important;
} */
.completeProfile :where(.css-dev-only-do-not-override-17sses9).ant-radio-button-wrapper {
  border-block-start-width: 1px;
  border-inline-start-width: 1px;
  border-inline-end-width: 1px;
}

.completeProfile .num0femp {
  font-weight: normal;
}

.num0femp :where(.css-dev-only-do-not-override-17sses9).ant-radio-button-wrapper {
  border-block-start-width: 1px;
  border-inline-start-width: 1px;
  border-inline-end-width: 1px;
}

.update_cur .ant-select-dropdown .ant-select-dropdown-hidden,
.ant-select-dropdown-placement-topLeft {
  z-index: 9999 !important;
}
.custom_select{
  /* width: 100%;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #f8f8f8 ;
  padding: 10px ; */
  position: relative;
  background: url('../../../../assets/images/down-arrow.png') no-repeat, #F5F5F5;
  background-position: calc(100% - 10px) center;
  background-size: 12px; 
  padding: 7px 30px 7px 10px; 
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f8f8f8;
}


/* resume builder add suggestion modal design */
.suggestion-list {
  display: flex;
  list-style-type: none;
  gap: 10px;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
.suggestion-list li {
  padding: 6px 17px;
  box-shadow: 0px 4px 18px 0px rgb(0 0 0 / 37%);
  border-radius: 30px;
  font-size: 14px;
  /* border-bottom: 1px solid #ddd; */
}
.suggestion-list li a{
  color: #000;
}
/* end */





.inter_panel_ready_sec_inner_btns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.no_btn_int {
  position: relative;
  width: 165px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000000;
  border-radius: 30px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
}


.yes_btn_int {
  position: relative;
  width: 165px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #EB5757;
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background: #EB5757;
}


.inter_panel_ready_sec_inner {
  background: #fff;
  border-radius: 10px;
  padding: 50px 30px;
  text-align: center;
}

.inter_panel_ready_sec_inner img {
  width: 70px;
  margin: 0 auto 20px;
}

.inter_panel_ready_sec_inner h6 {
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

.inter_panel_ready_sec_inner p {
  position: relative;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.inter_panel_ready_sec_inner_btns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* undo modal css*/
.undo_modal .modal-dialog{ max-width: 800px;background: #fff;border-radius: 10px;padding: 30px 15px;}
.undo_modal .modal-content{border: none;}
.undo_modal_inner{position: relative;display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 30px;}
.undo_modal_inner img{width: 70px;}
.undo_modal_inner p{position: relative;font-size: 24px;font-weight: 700;color: #000000;margin-bottom: 0px;}
.undo_modal_inner ul{display: flex;align-items: center;gap: 10px;}
.can_btn{position: relative;width: 160px;height: 40px;display: flex;align-items: center;justify-content: center;border: 1.5px solid #9DB5D7;border-radius: 100px;color: #4E5862;font-size: 14px;font-weight: 700;}
.yes_undo{position: relative;width: 160px;height: 40px;display: flex;align-items: center;justify-content: center;border: 1.5px solid #1865C1;border-radius: 100px;color: #fff;font-size: 14px;font-weight: 700;background: #1865C1;}
/* end */


.save_btn_new_sec_div{
  display: flex;
  gap: 10px;
}


#clickable a{
  color: #4E5862;
  font-size: 14px;
  font-weight: 700;
}
.report_modal_sec .ant-radio-button-label{font-size: 14px !important;font-weight: 500 !important;    color: #000;}
.ant-radio-button-wrapper-checked .ant-radio-button-label{color: #1677ff !important;}
.report_modal_sec .ant-radio-group {margin-top: 5px;}
.report_modal_sec .mt-1{margin-top: 1.25rem !important;}
.report_modal_sec .ant-radio-button-wrapper{border-inline-start-width: 1px;}




@media only screen and (max-width:767px) {
  .inter_panel_ready_sec_inner {
    padding: 20px 10px;
  }

  .inter_panel_ready_sec_inner img {
    width: 40px;
  }

  .inter_panel_ready_sec_inner h6 {
    font-size: 18px;
  }

  .inter_panel_ready_sec_inner p {
    font-size: 12px;
  }
  .userprofiledata {
    gap: 10px;
    margin-bottom: 30px;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .confim_info_sec{
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .confim_info_sec_inner {
    width: 95%;
    margin: 0 auto;
  }

  .confim_info_sec{
    margin-top: 0px;
  }
  .modal_heading{
    font-size: 18px;
    margin-bottom: 0px;
  }
  #profileDetailsForm label{
    font-size: 12px;
  }
}





@media only screen and (max-width:767px) {
  .confim_info_sec{
    margin-top: 0px;
  }
  .confim_info_sec_inner {
    width: 100%;
  }

  .confim_info_sec_inner_head h6 {
    font-size: 22px;
  }

  .confim_info_sec_inner_body {
    padding: 20px;
  }

  .confim_info_sec_inner_body h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .confim_info_sec_inner_body h3 {
    font-size: 14px;
  }

  .confim_info_single_head h5 {
    font-size: 16px;
  }

  .confim_info_single_body p {
    font-size: 12px;
  }

  .confim_info_single_body ul li {
    font-size: 12px;
  }

  .summary_sec_new .modal-dialog {
    padding: 20px;
  }

  .gene_ai_btn button {
    font-size: 12px;
    padding: 8px 10px;
  }

  .summary_sec_new_inner_cont p {
    font-size: 12px;
  }

  .sync_btn_new {
    font-size: 14px;
  }

  .userprofiledata .view_delete {
    position: absolute !important;
    top: 100px;
    left: 16px !important;
}
}

.box_shadow_notice_period_button {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: box-shadow 0.3s ease-in-out;
  border: none;
}

.box_shadow_notice_period_button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}