/* .company_details_sec_new {
  position: relative;
  padding: 30px 0;
  background: #f5f6fb;
  height: 100%;
}
.company_details_sec_new_inner {
  position: relative;
  display: flex;
  grid-gap: 40px;
}

.company_details_sec_new_inner_right {
  position: relative;
  width: 100%;
}
.company_details_sec_new_inner_right_head {
  position: relative;
}
.company_details_sec_new_inner_right_head h6 {
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}
.company_details_sec_new_inner_right_card {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
}
.company_details_single_card_box {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 14px;
}
.card_bx_img {
  position: relative;
  height: 48px;
  width: 48px;
  background: #eff5ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 48px;
}
.card_bx_img img {
  width: 20px;
}
.card_bx_cont_sec {
  position: relative;
}
.card_bx_cont_sec h5 {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
}
.card_bx_cont_sec h6 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #252525;
  margin-bottom: 0px;
}
.add_com_det_sec {
  position: relative;
  width: 80%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 6px;
  padding: 90px 40px;
}
.btn_comp_sec {
  position: relative;
  width: 270px;
  margin: 0 auto;
}
.btn_comp_sec_btn {
  background: #1865c1;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  gap: 10px;
}
.btn_comp_sec_btn span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: #ffffff;
  color: #1865c1;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 900 !important;
}
.add_com_det_sec p {
  position: relative;
  color: #252525;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
} */

.company_details_sec_new_inner{
  display: flex;
  /* margin-top: 100px; */
  gap: 40px;
}
.DashboardSideBar {
  width: 30%;
  min-width: 200px;
  position: fixed;
  top: 93px;
  bottom: 0;
  background-color: #f4f4f4;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #000;
  z-index: 999;
}

.company_details_sec_new {
  display: block;
}
button.copy_link_btn {
  border: none;
  background: transparent;
  color:#1865c1 ;
}

/* .ResposiveContainer {
  display: none;
} */

@media (max-width: 767px) {
  /* .company_details_sec_new {
    display: none;
  } */

  /* .ResposiveContainer {
    display: block;
  } */
}

.new_sub_ftr .sub_footer{margin-top: 0px;}