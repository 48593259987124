.checkout_page_main{position: relative;padding: 30px 0;}
.checkout_page_main_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 50px;}
.checkout_page_main_inner_left{position: relative;}
.checkout_page_main_inner_left_cont_sec{position: relative;box-shadow: 0px 1.8640246391296387px 5.592073917388916px 0px #0000000A;border: 2.25px solid #1865C1;border-radius: 30px;padding: 80px 60px;width: 440px;height: 560px;}
.checkout_page_main_inner_left_cont_sec h6{position: relative;color: #111019;font-size: 40px;font-weight: 700;margin-bottom: 20px;}
.checkout_page_main_inner_left_cont_sec p{position: relative;color: #000000;font-size: 14px;font-weight: 400;margin-bottom: 30px;}
.checkout_page_main_inner_left_cont_sec h5{position: relative;color: #111019;font-size: 24px;font-weight: 700;margin-bottom: 30px;}
.checkout_page_main_inner_left_cont_sec span{position: relative;display: inline-block;color: #212121;font-size: 12px;font-weight: 400;padding-left: 20px;}
.checkout_page_main_inner_left_cont_sec button{position: relative;width: 155px;height: 52px;display: flex;align-items: center;justify-content: center;background: #1865C1;border-radius: 30px;color: #fff;border: none;font-size: 18px;font-weight: 600;margin-bottom: 30px;}
.checkout_page_main_inner_left_cont_sec ul{display: block;position: relative;}
.checkout_page_main_inner_left_cont_sec ul li{display: block;color: #000000;font-size: 16px;font-weight: 400;padding-bottom: 10px;position: relative;padding-left: 15px;}
.checkout_page_main_inner_left_cont_sec ul li:last-child{padding-bottom: 0px;}
.checkout_page_main_inner_left_cont_sec ul li:before{position: absolute;content: '';left: 0;top: 8px;height: 5px;width: 5px;background: #000;border-radius: 50%;}

.checkout_page_main_inner_right{position: relative;}
.checkout_page_main_inner_right h6{position: relative;font-size: 28px;font-weight: 700;color: #000;margin-bottom: 30px;}
.checkout_page_main_inner_right h5{position: relative;color: #000000;font-size: 18px;font-weight: 500;margin-bottom: 30px;}
.card_upi_radio{position: relative;display: flex;align-items: center;gap: 25px;margin-bottom: 60px;}

.card_upi_radio_single{position: relative;}
.card_upi_radio_single .container {display: block; position: relative; padding-left: 35px;
    margin-bottom: 0px;cursor: pointer; font-size: 18px; -webkit-user-select: none; -moz-user-select: none;-ms-user-select: none; user-select: none;color: #0A0A0A;font-weight: 500;}
.card_upi_radio_single .container input { position: absolute; opacity: 0;cursor: pointer;}
.card_upi_radio_single .checkmark { position: absolute; top: 0;left: 0; height: 25px; width: 25px; background-color: #fff;
    border-radius: 50%;border: 2px solid #1865C1;}
.card_upi_radio_single .container:hover input ~ .checkmark {background-color: #fff;}
.card_upi_radio_single .container input:checked ~ .checkmark {background-color: #fff;}
.card_upi_radio_single .checkmark:after { content: "";position: absolute;display: none; }
.card_upi_radio_single .container input:checked ~ .checkmark:after {display: block;}
.card_upi_radio_single .container .checkmark:after {top: 5px; left: 5px; width: 12px; height: 12px;border-radius: 50%;
      background: #1865C1; }
.checkout_page_main_inner_right h4{position: relative;color: #000000;font-weight: 500;font-size: 18px;margin-bottom: 30px;}      
.checkout_page_main_inner_right_form_sec{position: relative;}
.checkout_page_main_inner_right_form_sec_single{position: relative;width: 100%;margin-bottom: 20px;}
.checkout_page_main_inner_right_form_sec_single input{position: relative;border: none; border-bottom: 1px solid #C4C4C4;color: #898989;font-size: 15px;font-weight: 400;padding-bottom: 10px;width: 100%;}

.check_grid_sec_new{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 30px;}
.checkout_page_main_inner_right_form_sec span{position: relative;color: #898989;font-size: 15px;font-weight: 500;margin-bottom: 25px;width: 45%;display: block;}

.proc_btn_new_chek_out{position: relative;display: flex;align-items: center;justify-content: space-between;}
.proc_btn_new_chek_out h3{position: relative;color: #111019;font-size: 24px;font-weight: 700;margin-bottom: 0px;}
.proc_btn_new_chek_out button{position: relative;display: flex;width: 200px;height: 48px;color: #FFFFFF;font-weight: 500;background: #1865C1;border: none;align-items: center;justify-content: center;border-radius: 30px;}
.paytym_sec_new{position: relative;display: flex;align-items: center;gap: 25px;margin-bottom: 30px;}
.paytym_sec_new_single{position: relative;height: 60px;width: 60px;box-shadow: 0px 1px 14px 0px #0000001A;background: #fff;border-radius: 10px;display: flex;align-items: center;justify-content: center;}
.checkout_page_main_inner_right_form_sec_hidden{display: none;}