/* .Responcive_Container_PopUp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */

.PopUp {
    width: 80%;
    height: 360px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 18px 0px #00000014;
    background: #fff;
    padding: 30px;
    gap: 15px;
    border-radius: 15px;
}

.PopUp P {
    text-align: center;
}

.PopUp button {
    padding: 6px 20px;
    border-radius: 20px;
    outline: none;
    background-color: #1865C1;
    color: white;
    border: none;
}

@media (max-width: 330px) {
    .PopUp button{
        font-size: 14px;
    }
  }