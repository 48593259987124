.verification-modal-subtitle {
    font-size: 12px;
    font-weight: 400;
  }
  
  .verification-modal-email {
    font-weight: 700;
  }
  
  .verification-modal-cta {
    cursor: pointer;
    font-weight: 700;
    color: rgba(24, 101, 193, 1);
  }
  
  .resend_otp_btn_new {
    color: rgba(24, 101, 193, 1);
    cursor: pointer;
  }
  
  .resend_otp_btn_new:disabled {
    color: #7b8389;
  }
  