.company_detials_wrapper{
  width: 860px;
  margin: 0 auto;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 28.6px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 60px;
  position: relative;
  margin-bottom: 50px;
}
.company_detail_heading{
  font-size: 28px;
  color: #000;
  margin-bottom: 10px;
}
.top_padding{
margin-top: 90px;
}
.company_detail_subheading{
  font-size: 18px;
  color: #000;
}
.Date_cust legend{
line-height: 0 !important;
}
.Date_cust .MuiInputBase-input{
height: 14px;
}
.Add_credit .ant-select-selector {
padding: 0px !important;
}
.react-quill-container {
border-radius: 6px;
background: #f8f8f8 !important;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
margin-top: 0px;
width: 100% !important;
border: none !important;
}
#overview .ql-toolbar{
border-radius: 5px 5px 0px 0px;
}
#overview .ql-container.ql-snow{
border-radius: 0px 0px 5px 5px;
min-height: 180px;
overflow-y: auto;
}

/* #overview .ql-editor{
height: 200px !important;
} */
.ant-upload-select{
  border-radius: 9.545px;
  background: #FFF;
  border: none !important;
  box-shadow: 0px 4.773px 25.9px 0px rgba(0, 0, 0, 0.10);
}
.profile_pic{
  position: absolute;
  top: 40px;
  left: 150px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.60);
  font-weight: 500;
}
.company_detail_form input ,.ant-select-selector ,.textarea_generator{
  border-radius: 4px;
  background: #F8F8F8 !important;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10); */
  padding-left: 25px;
  margin-top: 0;
  height: 52px;
  color: #333;
  font-size: 14px;
  border: none !important;
  margin-bottom: 5px;
}

.textarea_generator{padding-top: 15px;}
.company_detail_form input::-webkit-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.company_detail_form input::-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.company_detail_form input:-ms-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.company_detail_form input:-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.company_detail_form label{
margin-left: 12px !important;
color: #000;
font-weight: 500;

}
.data_generator {
  position: absolute;
  right: 0px;
  background: white;
  width: 160px;
  padding: 5px 0px;
  border-radius: 30px;
  border: 2px solid var(--AI-Generation-colour, #1FB8C3);
  font-size: 12px;
  color: #000;
  font-weight: 500;
  top: -45px;
  /* z-index: 1111111; */
}
.data_generator:focus{border: 2px solid var(--AI-Generation-colour, #1FB8C3);} 
.data_generator img{
  padding-right: 6px;
}
.submitbtn{
  border-radius: 50px;
  background: var(--Primary-CTA-Colour, #1865C1);
  background-color: #1865C1;
  font-size: 14px;
  color: #FFF;
  padding: 14px 40px;
  width: 200px;
  height: 48px;
  margin-bottom: 30px;
}
.submit_btn_container{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  right: -100px;
  position: relative;
}
.modal-content1 {
  line-height: 1.6;
  color: #333;
  padding: 12px;
}

.modal-content1 h2 { 
  color: #1865C1; 
  text-align: center;
  margin-bottom: 20px;
}

/* Styling for the main list */
.modal-content1 .terms-list {
  list-style-type: decimal; /* Ensures numbers are shown */
}

/* Styling for nested lists, ensuring alphabetical markers */
.modal-content1 .sub-list {
  list-style-type: lower-alpha; /* Alphabetical markers */
}

.type_a li{
  margin-bottom: 5px !important;
}
.modal-content1 p, .modal-content1 li {
  text-align: justify;
  margin-bottom: 10px;
}

/* Increase specificity for list item markers to ensure visibility */
.modal-content1 .terms-list > li, .modal-content1 .sub-list > li {
  position: relative; /* Adjust if necessary for custom markers */
  list-style-position: inside; /* Ensures markers are visible and not cut off */
}

/* Optional: Additional styling for list markers if they're still not displaying */
.modal-content1 ol {
  list-style-position: inside; /* Adjusts position to inside to avoid being cut off */
}


/* :where(.css-dev-only-do-not-override-17sses9).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -2px;
  top: -1px;
  color: #F00;
} */
:where(.css-dev-only-do-not-override-gzal6t).ant-select-single {
  font-size: 14px;
  height: 50px !important;
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: 5px;
  top: 0px;
  color: #FF0000 !important; 
}
.mrg-top{
margin-top: 80px;
}
.num0femp{
  border-radius: 25px !important;
  background: #FFF;
  /* box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.10); */
  margin-right: 20px;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.term_condition{
  color: #6A6A6A !important;
  font-size: 12px;
  font-weight: 400 !important;
}

.workmode_location {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 60px;
  align-items: center !important;
}
.ant-radio-inner{height: 25px !important;width: 25px !important;}
:where(.css-dev-only-do-not-override-gzal6t).ant-radio-wrapper .ant-radio-inner {
  width: 25px !important;
  height: 25px !important;
}
.image-modal{
  width: 160px !important;
}
/* :where(.css-dev-only-do-not-override-gzal6t).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
width: 160px;
height: 120px;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container{
  height: 120px;  
} */

label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.num0femp.css-dev-only-do-not-override-gzal6t {
  padding: 20px;
}
label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.num0femp.css-dev-only-do-not-override-gzal6t span{
  position: relative;
  top: -15px;
}
.ant-col.ant-form-item-control.css-dev-only-do-not-override-gzal6t {
  margin-top: 10px;
}

.Company_details_sec_new{position: relative;padding: 30px 0;}
.sec_form_new{padding-top: 0px !important;}
.Company_details_sec_new_head{position: relative;text-align: center;margin-bottom: 30px;}
.Company_details_sec_new_head h6{position: relative;font-size: 28px;font-weight: 700;color: #000000;margin-bottom: 0px;list-style: inside;}

.Company_details_sec_new .ant-form-item-required{position: relative;margin-left: 0px !important;font-size: 16px !important;font-weight: 500 !important;color: #000000 !important;}
.Company_details_sec_new .ant-form-item-required:before{ color: #FF0000 !important;font-size: 14px !important;right: -5px;
  position: absolute;font-weight: 600;}
.ant-select-in-form-item{margin-bottom: 25px;}    
.ant-select-arrow{height: 16px !important; margin-top: 0 !important; font-size: 12px !important; pointer-events: none; color: #000;font-weight: 600;}
.Reg_btn{
  display: flex;
  justify-content: end;
}
.regt_sec_new {
  position: relative;
  right: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 130px;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #1865C1;
  border: none;
  background: none;
}

.comp_det_shik_head{position: relative;text-align: center;}
.comp_det_shik_head h4{position: relative;font-size: 32px;color: #1865C1;font-weight: 700;margin-bottom: 12px;}
.comp_det_shik_head p{position: relative;margin-bottom: 15px;color: #252525;font-size: 18px;font-weight: 400;}

.ant-radio-button-wrapper:before{display: none !important;}
.mtb{
  margin-top: 90px;
  margin-bottom: 30px;
}
.new_sub_cont {
  width: 860px !important;
  margin: 0 auto;
  text-align: end;
  margin-bottom: 24px;
}
.ant-upload-select{width: 180px;height: 115px;}
.ant-upload-list-item{width: 100%;height: 115px !important;}
.company_detail_form label{font-size: 16px !important;border-inline-start-width: 1px;}

@media only screen and (min-width:992px) and (max-width:1199px) {
.top_padding {
  margin-top: 0px !important;
}
.new_sub_cont {
  width: 860px;
}
.mtb{
  margin-top: 0px;
}
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  
  .Company_details .col-md-7 {flex: 0 0 auto; width: 85%; }
  .company_detials_wrapper{width: 100%;}
  .submit_btn_container{right: 0px;margin-bottom: 30px;}
  .top_padding {
    margin-top: 0px;
}
  /*  */
  .mtb{
    margin-top: 0px;
  }
  .workmode_location{
    margin-right: 10px;
  }
  .company_detail_form label{
    margin-bottom: 10px;
  }
  .custom_modal_container .modal-dialog {
    width: 100%; /* Adjust the width to 90% on smaller screens */
    margin: 0 auto; /* Center the modal */
  }
  .custom_modal_container .modal-content {
    padding: 15px; /* Reduce padding for smaller screens */
  }
  .new_sub_cont{
    width: auto !important;
    margin-bottom: 30px;
  }
  .comp_det_shik_head h4{
    font-size: 22px;
  }
  .comp_det_shik_head p{
    font-size: 16px;
    margin-bottom: 0px;
  }
  .company_detail_form label{
    font-size: 15px !important;
  }
}


@media only screen and (max-width:767px) {

  .company_detials_wrapper{width: 100%;padding: 15px;margin-bottom: 30px;margin-top: 30px;}
  .profile_pic{left: 120px;font-size: 12px;}
  .submit_btn_container{right: 0px;}
  .data_generator{width: 135px;font-size: 10px;top: -60px;}
  .mt-5 { margin-top: 1rem!important;}
  .company_detail_heading{font-size: 22px;}
  .company_detail_subheading{font-size: 13px;}
  .Company_details_sec_new_head h6{font-size: 22px;}
  .comp_det_shik_head h4{font-size: 22px;}
  .comp_det_shik_head p{font-size: 14px;}
  .ant-form-item-row{margin-bottom: 20px;}
  

  .chip {padding: 5px 8px !important;font-size: 12px !important;}
  .chip button{ margin-left: 0;width: auto;padding: 0;}
  .chip button img{width: 15px;}
  .form_sec_new_inner_first .ant-form-item-row{margin-bottom: 10px;}
  .suggestion_sec_new .list_item_sec{display: flex;align-items: center;flex-wrap: wrap;    gap: 0px;}
  .list_item_sec li {padding: 6px 18px;font-size: 10px !important;}
  .form_sec_new_inner_first_innwer .col-6 {flex: 0 0 auto;width: 100%;}
  .Company_details .ant-form-item-row{margin-bottom: 15px !important;margin-right: 10px;}
  .company_detail_form label{font-size: 12px !important;}
  .company_detail_form input{height: 40px;}
  .company_detail_form textarea{font-size: 12px;}
  .ant-space-item{margin-bottom: 0px;}
  .ant-radio-wrapper{margin-bottom: 10px;}
  .ant-radio-wrapper:last-child{margin-bottom: 0px;}
  .ant-radio-button-wrapper{margin-bottom: 10px;}
.new_sub_cont {
display: flex;
justify-content: end;
width: auto;
}
.top_padding{
margin-top: 0px;
}
.mrg-top{
margin-top: 0px;
}
.new_sub_cont{
width: auto !important;
}

.workmode_location {
font-size: 12px !important;
}
.workmode_location{
margin-right: 12px;
}
.num0femp{
margin-right: 5px;
}
.data_generator{
top: -32px;
}

.mtb{
margin-top: 0px;
}
#overview .ql-editor {
height: 103px !important;
}
#overview .ql-container.ql-snow {
min-height: 103px;
}
.lenght_data {
font-size: 10px;
}
}

@media only screen and (min-width: 700px) and (max-width: 767px) {}


.ant-radio-button-wrapper::before{
   background-color: transparent !important; 
}


.chips-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* padding: 8px; */

  border-radius: 4px;
}

.chips-input-container input {
  flex: 1;
  border: none;
  outline: none;
  /* padding: 8px; */
  font-size: 16px;
  min-width: 120px; /* Minimum width for input field */
}

.chip {    
  display: inline-flex;
      align-items: center;
      padding: 4px 16px;
      margin: 10px 5px 5px 5px;
      background-color: #fff;
      color: #1865C1;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      border: 1px solid #0B4A96;
}

.chip button {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  margin-left: 0px;width: 20px;
  padding: 0px;
  position: relative;
  right: -5px;
}
.avatar-uploader{
  position: relative;
}
.view_delete{
  position: absolute;
  bottom: -22px;
  left: 16px;
}
.view_delete button {
  background-color: transparent;
  border: none;
}

@media (max-width: 344px) {
.workmode_location {
  font-size: 12px !important;
}
.span.custom_text{left: 220px;}
}



.Company_details_sec_new .ant-radio-group{display: flex !important;align-items: center !important;}