.InterviewModal_main{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: center;
    height: 400px;
    text-align: center;
}
.blue_bg_btn{
    background-color: 
    rgba(24, 101, 193, 1);
    color: white;
    font-size: 18px;
    padding: 16px 41px;
    border-radius: 50px;
    border:none;
}
.blue_bg_btn.disabled {
    background-color: grey; /* Color for disabled state */
    color: #ccc; /* Lighter text color to indicate it's disabled */
    cursor: not-allowed;
  }
  .loader-container{
    display: flex;
    justify-content: center;
    text-align: center;
    height: 54px;
    /* width: 100%;
    margin: 0 auto; */
  }
  .output_level,.listening,.internet {
    font-size: 18px;
    font-weight: 700;
  }
.clear_bg_btn{
    background-color: white;
    color: black;
    font-size: 18px;
    padding: 0px 41px;
    font-weight: 600;
    border-radius: 50px;
    border:1.5px solid black;
}
.test_button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
}
.Transcript_box{
    padding: 20px;
    background: linear-gradient(99deg, rgba(31, 184, 195, 0.20) 13.07%, rgba(121, 119, 246, 0.20) 79.46%);
    color: #000;
}
.text-left{
    text-align: start;
}
.speed{
    font-size: 16px;
    font-weight: 600;
}
.speed-red {
    color: red;
  }
  .center-text{
    text-align: center !important;
  }
  .speed-green {
    color: green;
  }
  .check{
   text-align: center;
  }
  .check span{
    color: green;
  }
.InterviewModal_main_inner{
    display: block;
    text-align: center;
}
.internet{
    margin-bottom: 10px;
}
.internet_speed{
    font-size: 16px;
    font-weight: 600;
}
.actions{
    display: flex;
    justify-content: space-between;
}
.InterviewModal_main_inner h2{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.space-bt{
    display: flex;
    gap: 20px;
    justify-content: center;
}
.test_speaker{
text-align: left;
margin-bottom: 40px;
}
.test_speaker h2{
    font-size: 28px;
    margin-bottom: 30px;
}
.test_speaker p{
    font-size: 16px;
}
.same_as_system{
    background-color: #F8F8F8;
    padding: 10px;
    border-radius: 5px;
}
.output_level{
    text-align: left;
    margin-bottom: 40px;
}
.output_level p{
    margin-bottom: 5px;
}
.custom-progress .ant-progress-inner,.ant-progress-bg {
    height: 10px !important; /* Set your desired height */
  }

.error_new{margin: 0 0 20px 0;font-size: 16px; color: rgb(217, 72, 47); position: relative;}  

  @media only screen and (max-width: 767px) {
    .blue_bg_btn {
        font-size: 14px;
        padding: 8px 20px;
    }
    .InterviewModal_main_inner h2{
        font-size: 14px;
    }
    .InterviewModal_main{
        height: 300px;
    }
    .InterviewModal_main_inner img{
        width: 180px;
    }
    .error_new{font-size: 14px;} 
  }