.ant-table-thead > tr > th {
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 16px !important;

  }
  .ant-table-cell{
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 500 !important;
  }
  
  .ant-table-tbody > tr > td {
    background-color: #fff;
  }
  .header_font{
    position: relative;
      font-size: 28px;
      font-weight: 700;
      color: #000;
  }
  /* Add more custom styles as needed */
  .eye_icon {
    width: 20px;
    height: 12px;
    position: relative;
    top: -1px;
}
.custom_margin_3{
  margin-right: 10px;
}
.custom-margin{
  margin-right: 3px;
}
.Search_job{
  position: relative;
}
.Search_job input{
  
  border-radius: 30px;
  border: none;
  background: #FFF;
  padding: 6px 10px 6px 50px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  border: 1px solid #D6D6D6;
}
.reset_btn{
  background: transparent;
  color: #DC0000;
  border: none;
}

/* .ant-select-single .ant-select-selector {
  border-radius: 50px;
} */
.Search_icon {
  position: absolute;
  top: 8px;
  left: 14px;
  width: 20px;
}
.jobtype,.Sortby{
  position: relative;
  border-radius: 50px !important;
  background: #fff url('../../../../assets/images/down-arrow.png') no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 12px; 
  padding: 8px 40px 8px 20px; 
  width: auto;
  font-size: 14px;
  font-weight: 500;
  border: none;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  color: #7C8493;
  border: 1px solid #D6D6D6;
}

.ant-table-content table{background: #f5f6fb;border-collapse: separate;border-spacing: 0px 10px;border-radius: 8px;}
/* .ant-table-row{border-radius: 8px;}
.ant-table-cell{border-radius: 8px;} */

.new_menu_sec_jobs{
    margin: 0 auto 0 0 !important;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
}

.Transcript_page li{
  /* list-style-type: disc; */
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
}
.Transcript_conatiner label{
  font-size: 25px;
}
.Transcript_page li span{
   font-size:14px;
   font-weight: 700;
   color: #000;
  
}
.Transcript_main h5{
  font-size: 20px;
  color: #000;
}
.rating_section{
  color: #1865C1 !important;
  font-size: 14px;
  font-weight: 600;
}
.ant-tabs-tab-active{
  background-color: #B5D7FF !important;
  color: #1865C1;
}
.custom_modal_body{
  padding: 0px !important;
}
.tablists .ant-tabs-nav{
  width: 200px;
   
}
.tablists .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab{
  margin-top: 0px; 
  padding: 14px 40px !important;
}
.tablists .ant-tabs-tab{
  border-bottom: 1px solid #E0E0E0;
}
.tablists .ant-tabs-tab-active{
  border-bottom: 1px solid #E0E0E0 !important;
}
.Transcript_main{
  padding: 20px 20px 0px 0px;
  height: 80vh;
  overflow-y: hidden;
}
.Transcript_main > #rc-tabs-0-panel-2,#rc-tabs-0-panel-1,#rc-tabs-0-panel-3{
  padding-right: 24px;
  padding-left: 24px;
}
#rc-tabs-0-panel-2{
  padding: 24px;
  padding-top: 0px;
}
.d-flex{position: relative;}
.ant-table-cell{text-align: center;}
.ant-table-cell:first-child{text-align: left;}
.ant-table-thead > tr > th{text-align: center !important;}
.ant-table-thead > tr > th:first-child{text-align: start !important;}
.ant-table-cell a{text-decoration: none;border: none;box-shadow: none;}
.comp_det_rigt_main p.header_font{margin-bottom: 10px;}


.Dashboard_main_section .menulist.menutabs-wd .d-flex.justify-content-between {
  
  box-shadow: 0px 1px 21px 0px #00000014;
  border-radius: 30px;
  padding: 10px;
}


.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 1rem;
  padding: 0;
}

.filter-container-responsive {
  display: none;
}

.filter-section {
  display: flex;
  align-items: baseline;
  flex: 1 1 auto;
  margin-bottom: 10px;
  gap: 10px;
}

.sort-section {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex: 1 1 auto;
  gap: 10px;
}

.emp_filter {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1075px) {
  .filter-section {
    flex: 0 0 70%;
    max-width: 70%;
    gap: 10px;
  }
  .emp_filter span, .sort-section .sort_p {
    font-size: 13px;
    width: 100%;
  }
  .sort-section {
    flex: 0 0 30%;
    max-width: 30%;
    gap: 10px;
  }
}

@media (min-width: 1076px) {
  .filter-section {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    gap: 30px;
  }
  .sort-section {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    gap: 10px;
  }
}

.Search_job {
  display: flex;
  align-items: center;
}

.reset_container {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.reset_btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}
.Add-credit-table{
  font-weight: 700;
  color: #1865C1;
  margin-left: 20px;
}
/* Responsive adjustments */
@media (max-width: 767px) {
  .filter-container {
    display: none;
  }
  .des_text_sec_sub{
    font-size: 12;
  }
  .filter-container-responsive {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .filter-container-responsive .search-bar-section {
    margin-bottom: 10px;
  }
  .filter-container-responsive .filter-options-section-responsive,
  .filter-container-responsive .sort-section-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .filter-options-section-responsive .emp_filter,
  .sort-section-responsive .sort_p {
    font-size: 13px;
  }
  .sort_p,.emp_filter span{
    font-size: 12px;
  }
}



@media only screen and (min-width: 992px) and (max-width: 1199px){
  .header_font{font-size: 18px;}
  th.ant-table-cell {
    font-size: 12px !important;
    padding: 10px !important;}
  
  .ant-table-thead > tr > th{font-size: 12px !important;}
  .ant-table-cell {font-size: 12px !important;
  padding: 7px !important;}
  
  
  
  
  
  }

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ant-table-thead>tr>th{font-size: 12px !important;}
}


@media only screen and (min-width: 768px) and (max-width: 991px){
.header_font{font-size: 18px;}
th.ant-table-cell {
  font-size: 12px !important;
  padding: 10px !important;}

.ant-table-thead > tr > th{font-size: 12px !important;}
.ant-table-cell {font-size: 12px !important;
padding: 7px !important;}





}


@media only screen and (max-width:767px) {
  
  .header_font {
    position: relative;
    font-size: 18px !important;
    font-weight: 700;
    color: #000;
    margin: 0;
}
/* .emp_filter span{
  display: none;
} */
.emp_filter span{
  display: none;
}
  .ant-table-thead{
    display: none;
  }
  .ant-table-row {
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    /* background-color: white;  */
  }
  .ant-table-cell{
    border-bottom: none !important; 
  }
  .Transcript_main{
    padding: 20px 0px 0px 0px;
  }
  

.Search_icon {top: 15px;width: 15px;}
.Search_job input{padding: 10px 10px 10px 40px;width:100%;}
span.me-3{font-size: 12px;}
div.me-3{font-size: 12px;}
.col-4.justify-content-end {
  width: 100%;
  justify-content: flex-start !important;
}
.col-8.align-items-baseline {
  width: 100%;
  flex-direction: column;
  gap: 15px;
}
.Search_job{width: 100%;}
.comp_det_rigt_main.tab-content .container.view_jobs_min_sec_data{padding: 0px;}


.Search_job{
  position: relative;
  margin-top: 10px;
}

.image-item img {
  width: 120px !important;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
}
.addimg {
  border: 1px solid #c7c7c7;
  height: 100px !important;
  width: 120px !important;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.saveContinue {
  font-size: 12px !important;
  color: #ffffff;
  border: 1px solid #1865c1;
  background-color: #1865c1;
  padding: 6px 8px !important;
  border-radius: 50px;
  width: 148px !important;
}

.image-item__btn-wrapper .replace_btn{
  width: 10px !important;
  height: auto !important;
}
.jobtype, .Sortby{
  font-size: 10px;
  color: #000;
}
.emp_filter{
  gap: 6px;
  justify-content: flex-end;
  width: 100%;
}
.reset_container{
  justify-content: flex-end;
  margin-right: 0px;
}
.reset_btn{
  font-size: 14px;
}
.jobtype, .Sortby {
  position: relative;
  border-radius: 50px !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAABVSURBVDiN7dCxDkBAFETRo9j4RJ3/sxIavk5BoxBhV1DubefOvORR+JUWESHhBPS7exkOmFC/yJPSo/JZnnc5YLwrV4mR7pCvaLDkrp9HovxjC1/ZAN3qDxFwDHgDAAAAAElFTkSuQmCC) no-repeat, #FFF;
  background-position: calc(100% - 10px) center;
  background-size: 8px;
  font-size: 12px;
  width: 100% !important;
  height: 33px;
}
.switch_lable_head .ant-form-item .ant-form-item-label >label{
font-size: 10px;
}
.Transcript_conatiner .ant-tabs{
  display: flex;
  flex-direction: column ;
}
.Transcript_conatiner .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  flex-direction: row;
  justify-content: center;
}
.tablists .ant-tabs-nav {
  width: auto;
}
.tablists .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  margin-top: 0px;
  padding: 14px 20px !important;
}
.Transcript_main h5{
  font-size: 14px;
  margin-bottom: 0px;
}
.transcript_header_sub h6{
  font-size: 12px;
}
/* .Transcript_main{
  padding: 0px;
} */
#rc-tabs-0-panel-2,#rc-tabs-0-panel-3 ,#rc-tabs-0-panel-1{
  padding: 0px 20px 0px 20px;
}

.ant-tabs .ant-tabs-ink-bar{
  background: none;
}
}

@media only screen and (max-width: 680px){
  .ant-table-tbody > tr > td {
    display: flex;
    justify-content: space-between;
    padding: 8px; 
    border-bottom: 1px solid #e8e8e8; 
  }

  .ant-table-tbody > tr > td > div:before {
    content: attr(data-th); 
    font-weight: 700; 
    position: absolute;
    left: 15px;
    font-size: 14px;
  }
.ant-table-tbody > tr >td{
  padding: 10px 15px !important;
}
  .ant-table-tbody > tr > td > div {
    text-align: end; 
    width: 100%;
    font-size: 14px;
  }
  .ant-table-tbody > tr > td > .ant-empty{
    text-align: center; 
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: center;
  }
  .ant-table-tbody > tr > td a{
    width: 100%;
  }
  .ant-table-tbody tr{
      height: 60px;
  }
  .ant-table-tbody tr td:first-child{
    padding-top: 20px !important;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-table-tbody tr td:last-child{
    padding-bottom: 20px !important;
    border-radius: 0px 0px 10px 10px ;
  }
  .ant-table-content table {
    background: #f5f6fb;
    border-collapse: separate;
    border-spacing: 0px 20px;
    border-radius: 8px;
}

}

.ant-tour{
  width: fit-content;
  padding: 10px;
}