.singin_sec_new {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.singin_sec_new_left {
  position: relative;
}

.position_back {
  position: absolute;
  top: 10px;
  left: 65%;
  transform: translateX(-50%);
  width: 50%;
}

.singin_sec_new_left::before {
  content: "";
  background: #f0f9ff;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.singin_sec_new_left img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.singin_sec_new_right {
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  padding: 90px 50px;
  width: 515px;
  border-radius: 10px;
  margin: 0 auto;
}

.singin_sec_new_right_inner {
  position: relative;
}

.singin_sec_new_right_inner h6 {
  position: relative;
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.singin_sec_new_right_inner p {
  position: relative;
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 35px;
  width: 80%;
}

.signup_google_sec {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 50px;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 30px;
  border: none;
  color: #000000;
  font-weight: 500;
  font-size: 16px;

}

.signup_google_sec:disabled {
  opacity: 50%;
}

.signup_google_sec img {
  width: 22px;
}

.alredy_have_account {
  position: relative;
  display: block;
  text-align: center;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
}

.login_btn_alredy {
  color: #1865c1;
  font-weight: 600;
  text-decoration: underline;
}

.job_seeking_rect_sec {
  position: relative;
  width: 100%;
  padding: 0 85px 0 25px;
  text-align: center;
}

.job_seeking_rect_sec_head {
  position: relative;
  margin-bottom: 70px;
}

.job_seeking_rect_sec_head h6 {
  position: relative;
  color: #000000;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 18px;
}

.job_seeking_rect_sec_head h5 {
  position: relative;
  font-size: 36px;
  color: #2f2f2f;
  font-weight: 700;
}

.job_seeking_rect_sec_body_sec {
  position: relative;
  background-color: #f0f9ff;
  width: 100%;
  padding: 110px 30px 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  gap: 20px;
}

.job_seeking_rect_single {
  position: relative;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  background: #fff;
  width: 100%;
  padding: 60px 50px 40px 50px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.job_seeking_rect_single h6 {
  position: relative;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
}

.img_span {
  position: absolute;
  width: 98px;
  height: 98px;
  border: 2px solid #ffffff;
  background: #f4fbff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -60px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.img_span img {
  width: 32px;
}

.acc_verifictaion_sec {
  position: relative;
  width: 80%;
}

.acc_verifictaion_sec h6 {
  position: relative;
  color: #2F2F2F;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
}

.acc_verifictaion_sec_inner {
  position: relative;
  width: 100%;
  padding: 0 0px 0 25px;
  text-align: center;
}

.acc_ver_in_box {
  position: relative;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  background: #FFFFFF;
  padding: 50px;
  text-align: left;
}

.acc_ver_in_box h5 {
  position: relative;
  color: #292731;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}

.inner_input_box_select {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.inner_input_box_select select {
  position: relative;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  background: #F8F8F8;
}

.acc_veri .enter_otp_box_sec_inputs {
  display: flex;
}

.acc_veri label {
  text-align: left;
}

.inner_input_box_select .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root {
  height: 50px;
  border-radius: 10px;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  display: none;
}

.inner_input_box_select .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  display: none;
}

.inner_input_box_select .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0px 0 0 10px;
}

.inner_input_box_select .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}

.inner_input_box_select .css-ittuaa-MuiInputAdornment-root {
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  background: #f8f8f8;
  max-height: inherit;
  border-radius: 5px;
}
.inner_input_box_select .MuiPhoneNumber-flagButton.css-1yxmbwk {
  padding: 0px !important;
}


.inner_input_box_select .css-i44wyl {
  width: 100%;
}

.inner_input_box_select .mobile_number_input {
  width: 100%;
}

.inner_input_box_select .MuiInputBase-root {
  display: flex;
  align-items: center;
  gap: 15px;
}

.inner_input_box_select .css-jcincl::before {
  border-bottom: none;
}

.inner_input_box_select .css-jcincl:hover:not(.Mui-disabled, .Mui-error)::before {
  border: navajowhite;
}


@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .job_seeking_rect_sec_head h6 {
    font-size: 20px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 26px;
  }

  .job_seeking_rect_sec {
    padding: 0 40px 0 25px;
  }

  .job_seeking_rect_single {
    padding: 60px 40px 50px 40px;
  }

  .job_seeking_rect_single h6 {
    font-size: 16px;
  }





}




@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .singin_sec_new {
    grid-template-columns: 1fr;
  }

  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    margin: 30px auto;
  }
  .job_seeking_rect_sec{
    margin-top: 30px;
  }


}





@media only screen and (min-width: 768px) and (max-width: 991px) {

  .singin_sec_new {
    grid-template-columns: 1fr;
  }

  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    margin: 80px auto;
}

  .job_seeking_rect_sec {
    width: 100%;
    padding: 30px 25px;
  }

  .acc_verifictaion_sec {
    width: 80%;
    margin: 50px auto;
  }

  .job_seeking_rect_sec_head {
    margin-bottom: 35px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 25px;
  }
  .job_seeking_rect_sec_head h6{
    font-size: 20px;
  }
  .job_seeking_rect_sec_head h5{
    font-size: 18px;
  }
  

}

@media (max-width: 1200px) {
  .position_back {
    top: 25px;
    left: 34%;
  }
}
@media (max-width: 860px) {
  .position_back {
    left: 30%;
  }
}


@media only screen and (max-width:767px) {
  .singin_sec_new {
    grid-template-columns: 1fr;
    /* align-items: baseline; */
    height: 100vh;
  }
  .singin_sec_new_left {
    display: none;
  }

  .singin_sec_new_right {
    padding: 20px;
    width: 80%;
  }

  .singin_sec_new_right_inner h6 {
    font-size: 20px;
  }

  .singin_sec_new_right_inner p {
    font-size: 14px;
    width: 100%;
  }

  .alredy_have_account {
    font-size: 14px;
  }

  .job_seeking_rect_sec {
    width: 100%;
    padding: 10px;
  }

  .job_seeking_rect_sec_head {
    margin-bottom: 30px;
  }

  .job_seeking_rect_sec_head h6 {
    font-size: 16px;
  }

  .job_seeking_rect_sec_head h5 {
    font-size: 18px;
  }

  .job_seeking_rect_sec_body_sec {
    width: 100%;
    padding: 80px 20px 20px 20px;
    gap: 100px;
    flex-direction: column;
  }

  .acc_verifictaion_sec {
    width: 100%;
    padding: 15px 20px;
  }

  .acc_verifictaion_sec_inner {
    padding: 0px;
  }

  .acc_ver_in_box {
    padding: 20px;
  }

  .acc_verifictaion_sec h6 {
    font-size: 25px;
  }

  .job_seeking_rect_single h6 {
    font-size: 14px;
  }

  .position_back {
    top: 25px;
    left: 34%;
  }



}

@media only screen and (min-width: 700px) and (max-width: 767px) {}
@media (min-width: 280px) and (max-width: 320px){
  .signup_google_sec{
    gap: 15px;
    font-size: 14px;
  }
  .singin_sec_new_right_inner p {
    font-size: 12px;
    margin-bottom: 10px;
}
.singin_sec_new_right {
  padding: 15px;
  width: 90%;
}
.spacer20px,.spacer30px{
  height: 0px;
}
}