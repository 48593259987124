.abut_sec_page_new{position: relative;padding: 30px 0;}
.abut_sec_page_new_head{position: relative;text-align: center;margin-bottom: 80px;}
.abut_sec_page_new_head h6{position: relative;color: #2B2B2B;font-size: 50px;font-weight: 700;line-height: inherit;}
.abut_sec_page_new_head h6 span{color: #1865C1;position: relative;}
.abut_sec_page_new_head h6 span::after{ background: url(../../assets/images/homepage_line.svg);position: absolute; content: "";left: 0;background-repeat: no-repeat;width: 100%;height: 38px;left: 150px;top: 60px;}

.abut_sec_page_new_body_sec{position: relative;display: grid;grid-template-columns: 1fr 1.5fr;grid-gap: 30px;}
.abut_sec_page_new_body_sec_left{position: relative;}
.abut_sec_page_new_body_sec_left img{width: 100%;object-fit: cover;}
.abut_sec_page_new_body_sec_right{position: relative;}
.abut_sec_page_new_body_sec_right h4{position: relative;font-size: 20px;font-weight: 400;color: #000000;margin-bottom: 15px;}
.abut_sec_page_new_body_sec_right h4 span{font-weight: 700;color: #1865C1;font-size: 24px;}
.abut_sec_page_new_body_sec_right h3{position: relative;font-size: 24px;font-weight: 700;color: #1865C1;margin-bottom: 10px;}
.abut_sec_page_new_body_sec_right p{position: relative;font-size: 20px;font-weight: 400;color: #000000;margin-bottom: 20px;line-height: 1.2;}
.abut_sec_page_new_body_sec_right a{position: relative;display: flex;align-items: center;justify-content: center;width: 200px;height: 55px;color: #020202;font-size: 17px;font-weight: 500;border: 2px solid #1865C1;border-radius: 30px;}

.abut_sec_page_new:after { content: ''; position: absolute; left: auto; top: auto; width: 38px; height: 145px;  background-size: cover; background: url(../../assets/images/bg-bg-new.svg); right: -10px;background-repeat: no-repeat;
    bottom: 20px;}

    .about_easy{position: relative;margin-bottom: 160px;margin-top: 50px;}

.hire_ab_sec_new{position: relative;padding: 70px 0;}
.hire_ab_sec_new_inner{position: relative;background: #fff;box-shadow: 0px 13.31839656829834px 46.61438751220703px 0px #00000014;border-radius: 12px;height: 330px;padding: 40px 80px;display: flex;align-items: center;}
.hire_ab_sec_new_inner p{position: relative;margin-bottom: 0px;color: #000000;font-size: 24px;font-weight: 500;}
.hire_ab_sec_new_inner p span{font-weight: 700;color: #1865C1;}
.hire_ab_sec_new::before{ content: ''; position: absolute; left: 0; top: 35px; width: 90px; height: 40px;  background-size: cover; background: url(../../assets/images/gp-bg-new.svg); background-repeat: no-repeat;}
.hire_ab_sec_new::after{position: absolute; content: ""; right: 2px; bottom: -35px;background: url(../../assets/images/Rings_circle_md.svg); width: 218px; height: 218px;background-repeat: no-repeat; z-index: -1;}


.why_coose_sec_new{position: relative;padding: 0px 0 50px 0;}
.why_coose_sec_new_head{position: relative;text-align: center;margin-bottom: 80px;}
.why_coose_sec_new_head h6{position: relative;color: #2B2B2B;font-size: 50px;font-weight: 700;line-height: inherit;}
.why_coose_sec_new_head h6 span{color: #1865C1;position: relative;}
.why_coose_sec_new_head h6::after{ background: url(../../assets/images/why-chooe.svg);position: absolute;
    content: ""; left: 0; background-repeat: no-repeat; width: 400px; height: 32px; left: 0px; top: 60px;
    right: 0; margin: 0 auto;text-align: center;}
.why_coose_sec_new_body_sec{position: relative;}    
.why_btn_main{position: relative;margin-top: 50px;}
.getInTouchRef{padding-top: 40px;}



/* New About us  */


.our_mission_sec_new{position: relative;background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%);padding: 70px 0;}
.our_mission_sec_new_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 50px;align-items: center;}
.our_mission_sec_new_inner_left{position: relative;    padding-right: 50px;}
.our_mission_sec_new_inner_left h3{position: relative;color: #FFFFFF;font-size: 65px;font-weight: 900;margin-bottom: 30px !important;}
.our_mission_sec_new_inner_left p{position: relative;font-size: 16px;font-weight: 400;margin-bottom: 25px !important;color: #fff;}
.our_mission_sec_new_inner_left a{position: relative;display: inline-block;background: #fff;padding: 12px 40px;color: #000;font-weight: 500;border-radius: 30px;}
.our_mission_sec_new::before{content: '';position: absolute;left: 0;top: 0;background: url(../../assets/images/mis_frame.svg);width: 38px; height: 105px;background-size: cover;background-position: center center;}
.our_mission_sec_new::after{content: '';position: absolute;right: 5px;bottom: 10px;background: url(../../assets/images/mis_frame.svg);width: 38px; height: 105px;background-size: cover;background-position: center center;}



.industries_serve{position: relative;padding: 50px 0 0 0;}
.industries_serve_inner{position: relative;}
.industries_serve_inner_head{position: relative;text-align: center;}
.industries_serve_inner_head h4{position: relative;color: #101010;font-size: 65px;font-weight: 700;margin-bottom: 40px !important;}
.industries_serve_inner_body{position: relative;}
.industries_serve_inner_body_single{position: relative;display: flex;align-content: center;justify-content: space-between;gap: 20px;border-top: 1.02px solid #000000;padding-top: 20px;padding-bottom: 40px;}
.industries_serve_inner_body_single span{position: relative;width: 50px;height: 50px;border-radius: 50%;display: flex;align-items: center;justify-content: center;background: #0954AE;color: #fff;font-size: 18px;font-weight: 500;flex: 0 0 50px;}
.industries_serve_inner_body_single h5{position: relative;font-size:26px;font-weight: 600;color: #1865C1;margin-bottom: 0px !important;    display: flex;flex: 0 0 220px;}
.industries_serve_inner_body_single p{position: relative;font-size: 16px;font-weight: 400;color: #000;margin-bottom: 0px !important;display: flex; flex: 0 0 550px;}


.about_us_header{position: relative;padding: 50px 0 150px 0;background: url(../../assets/images/ab_hm.svg);background-size: cover;background-position: 5px 210px;background-repeat: no-repeat;}
.about_us_header_inner{position: relative;text-align: center;}
.about_us_header_inner h4{background: linear-gradient(94.39deg, #0B4A96 50%, #5094E7 103.69%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;font-size: 65px;font-weight: 900;margin-bottom: 30px !important;}
.about_us_header_inner p{position: relative;color: #3D4852;font-size: 24px;font-weight: 500;width: 75%;
    margin: 0 auto 30px !important;}
.about_us_header_inner img{width: 65px;}    
    



@media only screen and (min-width:992px) and (max-width:1199px) {

    .abut_sec_page_new_head h6{font-size: 30px;}
    .abut_sec_page_new_head h6 span::after{left: 0;top: 40px;}
    .hire_ab_sec_new::after{display: none;}
    .hire_ab_sec_new_inner{height: auto;}
    .about_easy {margin-bottom: 50px;margin-top: 20px;}
    .get_in_touch_inner {grid-template-columns: 1fr;grid-gap: 50px;}
    .abut_sec_page_new_body_sec_right h4 {font-size: 14px;margin-bottom: 5px;}
    .abut_sec_page_new_body_sec_right h3 {font-size: 16px; margin-bottom: 10px;line-height: inherit;margin-top: 10px;}
    .abut_sec_page_new_body_sec_right p{font-size: 16px;}
    .abut_sec_page_new_body_sec_right a {width: 140px; height: 40px;font-size: 12px;}
    .hire_ab_sec_new {padding: 25px 0;}
    .hire_ab_sec_new_inner p{font-size: 16px;}
    .hire_ab_sec_new::before{top: 0px;}
    .why_coose_sec_new_head h6{font-size: 30px;}
    .why_coose_sec_new_head h6::after{width: 250px;background-size: contain;}
    .why_coose_sec_new_head{margin-bottom: 40px;}
    .future_hiring_easy_head h3 {font-size: 30px;margin-bottom: 0;}
    .new_esay .text-left{font-size: 16px;}
    .why_coose_sec_new_head h6::after{top: 40px;}
    .why_coose_sec_new {padding: 0px 0 20px 0;}
    .get_in_touch_inner_left h6{font-size: 20px;}
    .get_in_touch_inner_left h5{font-size: 30px;}
    
    }
    




@media only screen and (min-width:768px) and (max-width:991px) {

.abut_sec_page_new_head h6{font-size: 30px;}
.abut_sec_page_new_head h6 span::after{left: 0;top: 40px;}
.hire_ab_sec_new::after{display: none;}
.hire_ab_sec_new_inner{height: auto;}
.about_easy {margin-bottom: 50px;margin-top: 20px;}
.get_in_touch_inner {grid-template-columns: 1fr;grid-gap: 50px;}
.abut_sec_page_new_body_sec_right h4 {font-size: 14px;margin-bottom: 5px;}
.abut_sec_page_new_body_sec_right h3 {font-size: 16px; margin-bottom: 10px;line-height: inherit;margin-top: 10px;}
.abut_sec_page_new_body_sec_right p{font-size: 16px;}
.abut_sec_page_new_body_sec_right a {width: 140px; height: 40px;font-size: 12px;}
.hire_ab_sec_new {padding: 25px 0;}
.hire_ab_sec_new_inner p{font-size: 16px;}
.hire_ab_sec_new::before{top: 0px;}
.why_coose_sec_new_head h6{font-size: 30px;}
.why_coose_sec_new_head h6::after{width: 250px;background-size: contain;}
.why_coose_sec_new_head{margin-bottom: 40px;}
.future_hiring_easy_head h3 {font-size: 30px;margin-bottom: 0;}
.new_esay .text-left{font-size: 16px;}
.why_coose_sec_new_head h6::after{top: 40px;}
.why_coose_sec_new {padding: 0px 0 20px 0;}
.get_in_touch_inner_left h6{font-size: 20px;}
.get_in_touch_inner_left h5{font-size: 30px;}

}





@media only screen and (max-width:767px) {

  .abut_sec_page_new_head h6{font-size: 18px;}
  .abut_sec_page_new_head h6 span::after{left: 0; top: 20px;}
  .abut_sec_page_new_head{margin-bottom: 40px;}
  .abut_sec_page_new_body_sec {grid-template-columns: 1fr; grid-gap: 15px;}      
  .abut_sec_page_new_body_sec_right h4 {font-size: 14px;margin-bottom: 10px;}
  .abut_sec_page_new_body_sec_right h3 {font-size: 18px;margin-bottom: 10px;line-height: inherit;}
  .abut_sec_page_new_body_sec_right p{font-size: 14px;}
  .abut_sec_page_new_body_sec_right a { width: 150px; height: 40px; font-size: 14px; margin: 0 auto;}
  .abut_sec_page_new:after{right: -25px;background-size: 18px;}
  .hire_ab_sec_new {padding: 10px 0;}
  .hire_ab_sec_new_inner {height: auto;padding: 15px;}
  .hire_ab_sec_new_inner p{font-size: 14px;}
  .hire_ab_sec_new::before{display: none;}
  .abut_sec_page_new {padding: 20px 0 15px 0;}
  .hire_ab_sec_new::after{display: none;}
  .why_coose_sec_new {padding: 20px 0 5px 0;}
  .why_coose_sec_new_head h6{font-size: 22px;}
  .why_coose_sec_new_head{margin-bottom: 20px;}
  .why_coose_sec_new_head h6::after{width: 65%;top: 22px;}
  .why_btn_main{margin-top: 15px;}
  .about_easy {margin-bottom: 40px;margin-top: 20px;}
  .new_esay .text-left {font-size: 14px;}
  .text-left span{font-size: 18px;}
  .get_in_touch_inner {grid-template-columns: 1fr;grid-gap: 30px;}
  .get_in_touch_inner_left{padding: 15px;}
  .get_in_touch_inner_left h6 {font-size: 18px;margin-bottom: 15px;}
  .get_in_touch_inner_left h5 {font-size: 20px; margin-bottom: 10px;}
  .get_in_touch_inner_left p {font-size: 12px;margin-bottom: 20px;}
  .get_in_touch_inner_left_form_sec_grid {grid-template-columns: 1fr; grid-gap: 10px;}
  .get_in_touch_inner_left_form_sec_single{margin-bottom: 10px;}
  .get_in_touch_inner_left_form_sec_single label{font-size: 12px;}
  .get_in_touch_inner_left_form_sec_single input{height: 40px;}
  .get_in_touch_btn_sec button {width: 100px; height: 40px; font-size: 12px;}
  .get_in_touch {padding: 0px 0 30px 0;}
  .get_in_touch_inner_right_single { height: 60px; width: 100%;padding: 10px;gap: 15px;margin-bottom: 15px;}
  .get_in_touch_inner_right_single span {width: 40px; height: 40px;}
  .get_in_touch_inner_right_single span i {font-size: 14px;}
  .get_in_touch_inner_right_single a {font-size: 12px;}
  .address-one {
    font-size: 12px !important;
}


.about_us_header {padding: 20px 0 25px 0;}
.about_us_header_inner h4{font-size: 28px;margin-bottom: 15px !important;}
.about_us_header_inner p {font-size: 18px;width: 100%;margin: 0 auto 20px !important;}
.our_mission_sec_new{padding: 40px 0;}
.our_mission_sec_new::before{display: none;}
.our_mission_sec_new::after{display: none;}
.our_mission_sec_new_inner { grid-template-columns: 1fr; grid-gap: 35px;}
.our_mission_sec_new_inner_left{padding-right: 0px;}
.our_mission_sec_new_inner_left h3 {font-size: 25px;margin-bottom: 15px !important;line-height: inherit;}
.our_mission_sec_new_inner_left p { font-size: 12px;margin-bottom: 10px !important;}
.our_mission_sec_new_inner_left a{padding: 8px 24px;font-size: 12px;}
.industries_serve_inner_head h4 {font-size: 30px;margin-bottom: 30px !important;}
.industries_serve{padding: 25px 0 0 0;}
.industries_serve_inner_body_single {gap: 10px;padding-top: 15px;padding-bottom: 30px;flex-direction: column;}
.industries_serve_inner_body_single span {width: 40px; height: 40px;font-size: 14px; flex: 0 0 40px;position: absolute;}
.industries_serve_inner_body_single h5{padding-left: 50px;font-size: 18px; flex: 0 0 40px;}
.industries_serve_inner_body_single p {font-size: 14px;flex: auto;}

.getInTouchRef { padding-top: 0;}
.get_in_touch_sec_left_new h6 {font-size: 30px; margin-bottom: 18px !important;}









}


@media only screen and (min-width:700px) and (max-width:767px) {

.abut_sec_page_new_body_sec {grid-template-columns: 1fr 1fr;}
.hire_ab_sec_new {padding: 10px;width: 90%;margin: 0 auto;}
.why_coose_sec_new_head {margin-bottom: 40px;}
.why_coose_sec_new_head h6::after {width: 25%;top: 22px;}
.get_in_touch_inner {grid-template-columns: 1fr 1fr;grid-gap: 30px;}
.future_hiring_easy_head {width: 80% !important;}



}

@media (min-width: 280px) and (max-width: 320px){
    .get_in_touch_inner_right_single a{
        font-size: 10px !important;
    }
    .getInTouchRef{
        padding-top: 10px;
    }
  }