.tooltipContainer {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .tooltipBox {
    position: absolute;
    top: -40px;
    left: 42%;
    transform: translateX(-50%);
    background-color: #4a4a4a;
    color: white;
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
    padding: 8px 12px;
    line-height: 18px;
    width: 325px;
    /* font-family: Satoshi Variable; */
    height: 50px;
    white-space: wrap;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .tooltipArrow {
    position: absolute;
    left: 0%;
    top: 50%;
    bottom: -5px;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: #4a4a4a;
  }
  