.job_det_step_sec_new {
  position: relative;
  padding: 30px 0;
}
#gauge-chart .text-group {
  display: none !important;
}
.slider-step .ant-slider-mark .ant-slider-mark-text {
  width: 50px !important;
}
.job_det_step_sec_new_inner {
  position: relative;
}
.job_det_step_inner_step_new {
  position: relative;
}
.seventy-line {
  text-align: "right";
  position: absolute;
  top: -24px;
  right: 0px;
}
:where(.css-dev-only-do-not-override-17sses9).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 0 25px;
}
.CurrencyType .ant-select-selector {
  padding: 0 8px !important;
}
#currency_help .ant-form-item-explain-error {
  position: absolute;
  left: -12px;
}
.CurrencyType .ant-select-selection-placeholder {
  font-size: 14px !important;
}
/* .CurrencyType .ant-select-arrow{
  top: 37%;
} */
/* .CurrencyType {
  height: auto;
} */
#mode_of_work .ant-radio-input {
  height: auto !important;
}
.custom-error .ant-select-selector {
  border-color: red !important; /* Change this to your desired color */
}
.post_currency {
  position: absolute;
  left: -12px;
}
.salary_error .ant-input-number {
  border-color: transparent !important;
}
.job_det_head {
  position: relative;
  text-align: center;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 35px;
}
.Monitization_section_padding {
  padding: 35px 35px !important;
}
.react-quill-container {
  border-radius: 6px;
  background: #f8f8f8 !important;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: none !important;
  margin-top: 0px;
  width: 100% !important;
  border: none !important;
}
.react-quill-container:focus{
  
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.react-quill-container{background: #f8f8f8;}
.has-text{
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  transition: 0.5s ease-in-out;
}
#description .ql-toolbar {
  border-radius: 5px 5px 0px 0px;
}
#description .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
  min-height: 180px;
  overflow-y: auto;
}

/* #description .ql-editor {
  height: 200px !important;
} */

.add_question_button_container {
  display: flex;
  justify-content: flex-end;
  border-radius: 25%;
}

.add_question_button {
  border-radius: 50px;
  border-color: #1865c1;
  color: #1865c1;
  font-weight: 500;
}

.budget-main .ant-input-affix-wrapper {
  padding: 0px 2px;
}
.budget-main .ant-input-affix-wrapper > .ant-input-prefix {
  background-color: #f8f8f8;
  padding: 8px;
  margin-inline-end: 0px;
}
.budget-main .ant-input:focus,
.budget-main .ant-input-focused {
  outline: none;
  box-shadow: none;
  border-color: #d9d9d9; /* set it to the default or desired border color */
}
.budget-main .ant-input:hover,
.budget-main .ant-input-affix-wrapper:hover .ant-input {
  border-color: #d9d9d9 !important; /* Set this to your default border color */
  border: none !important;
}
.formitem {
  position: relative;
  top: -15px;
}
.Monitization_section-right {
  display: flex; /* Establishes a flex container */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Align children vertically in the center */
  align-items: center; /* Align children horizontally in the center */
  height: 100%; /* Take full height of the parent or define your own */
}

.Monitization_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Monitization_section-left {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  padding-right: 40px;
}
.p-10 {
  margin-bottom: 6px;
}
:where(.css-dev-only-do-not-override-17sses9).ant-picker {
  padding: 0px 11px 0px;
}
:where(.css-dev-only-do-not-override-17sses9).ant-picker-focused.ant-picker,
:where(.css-dev-only-do-not-override-17sses9).ant-picker {
  background-color: #f8f8f8;
  box-shadow: none;
}
.daily_budget {
  font-size: 14px;
  color: #ff4141;
  margin-bottom: 0px;
}
.daily_budget span {
  font-weight: 600;
}
.ant-steps-item-active .ant-steps-item-icon {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  background: #1865c1 !important;
}
.ant-steps-item-title {
  display: none;
}
.ant-steps-item-icon {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  background: #f8f8f8 !important;
  color: #252525 !important;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
}
.ant-steps-horizontal {
  width: 80%;
  margin: 0 auto 30px;
  display: none;
}
.ant-steps-item-title::after {
  top: 0 !important;
}
.ql-editor{font-size: 14px;font-weight: 500;color: #000;font-family: 'Satoshi-Variable';}
.ql-editor.ql-blank::before{font-size: 14px;font-weight: 500;color: #b3b3b3;font-style: normal;    font-family: 'Satoshi-Variable';}
/* Form 1 Css */

.form_sec_new_inner_first {
  position: relative;
  padding: 5px 0px;
  border-radius: 10px;
}


.form-changed-alert {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9999; /* Adjust the z-index to ensure it appears above other content */
}
#disclose_salary input {
  height: auto !important;
}

.job_details_from input,
.ant-select-selector,
.textarea_generator {
  border-radius: 6px;
  background: #f8f8f8 !important;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  padding-left: 25px;
  margin-top: 0px;
  width: 100% !important;
  height: 42px !important;
  border: none !important;
  color: #000;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}
.job_details_from input::-webkit-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from input::-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from input:-ms-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from input:-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}

.job_details_from select::-webkit-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from select::-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from select:-ms-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from select:-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}

.job_details_from textarea::-webkit-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from textarea::-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from textarea:-ms-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.job_details_from textarea:-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}


.ant-select-selection-search-input::-webkit-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-search-input::-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-search-input:-ms-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-search-input:-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}

.ant-select-selection-placeholder::-webkit-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-placeholder::-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-placeholder:-ms-input-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
.ant-select-selection-placeholder:-moz-placeholder {color: #B3B3B3 !important; opacity:1 !important;font-weight: 400 !important;}
..ant-select-selection-placeholder{font-size: 14px;color: #000;}
.ant-select-selection-search input{font-size: 14px;color: #000;}

.textarea_generator {
  border-radius: 6px;
  background: #f8f8f8 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding-left: 25px;
  margin-top: 0px;
  width: 100% !important;
  border: none !important;
  height: 200px !important;
}
.job_details_from .ant-form-item-row {
  flex-direction: column;
  align-items: flex-start;
}
.job_details_from .ant-form-item-required {
  height: inherit !important;
}
.job_details_from label {
  color: #212529 !important;
  font-weight: 500;
  position: relative;
  font-size: 16px !important;
  margin-bottom: 8px;
  display: block !important;
  font-family: 'Satoshi-Variable';
}
.job_details_from input::-webkit-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from input::-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from input:-ms-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from input:-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}

.job_details_from select::-webkit-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from select::-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from select:-ms-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from select:-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}

.job_details_from textarea::-webkit-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from textarea::-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from textarea:-ms-input-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.job_details_from textarea:-moz-placeholder { opacity:1 !important;font-family: 'Satoshi-Variable';}
.ant-select-selection-item{font-family: 'Satoshi-Variable';}

.ant-form-item-required:after{opacity: 0;}
.job_details_from .ant-form-item-control {
  width: 100%;
  flex: inherit;
}
.job_details_from .ant-form-item {
  margin-bottom: 30px;
}
/* .form_sec_new_inner_first_innwer .ant-select-selector .ant-select-selection-search{font-size: 16px !important;}
input#qualification {
  font-size: 16px !important;
  font-weight: 400 !important;
} */
.ant-select-selection-item {
  font-weight: 400 !important;
  color: #000616;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.ant-select-selector

.select_industry .ant-select-selector {
  padding-left: 25px !important;
  background: #f8f8f8 !important;
}
.select_industry .ant-select-selector .ant-select-selection-placeholder {
  font-size: 15px;
  font-weight: 400;
}

.anticon-down {
  font-size: 14px !important;
}
.suggestion_sec_new_main {
  padding-bottom: "30px";
}
.suggestion_sec_new {
  position: relative;
  display: block;
  margin-bottom: 0px;
  text-align: left;
}
.suggestion_sec_new .list_item_sec{padding-bottom:20px;}

.list_item_sec li {
  display: inline-block;
  border: 1px solid #212529;
  padding: 4px 16px;
  border-radius: 20px;
  font-size: 12px;
  color: #212529;
  margin: 4px;
  font-family: 'Satoshi-Variable';
  font-weight: 500;
}
.job_details_from .ant-form-item-required:before {
  margin-inline-end: 0px !important;
}
.job_details_from .ant-form-item-explain-error {
  text-align: left;
  font-size: 11px;
}
#description .ql-toolbar{text-align: left;}
.data_generator_new {
  position: absolute;
  right: 0px;
  background: white;
  width: 160px;
  padding: 5px 0px;
  border-radius: 30px;
  border: 2px solid var(--AI-Generation-colour, #1fb8c3);
  font-size: 12px;
  color: #000;
  font-weight: 500;
  top: -45px;
}
.data_generator .animated {
  animation: glowEffect 1s infinite alternate;
}

.results-container{
  margin-top:100px
}

@keyframes glowEffect {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.6); /* Increased blur and spread, adjusted opacity */
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 40px 20px rgba(255, 255, 255, 0.8); /* Increased blur and spread, adjusted opacity */
  }
}
.inc_dec {
  position: absolute;
  right: 1px;
}
.inc_dec button {
  height: 49px;
  border: none;
  border-radius: none !important;
  background-color: #f8f8f8 !important;
}
.inc_dec button:first-child {
  border-right: 1px solid #cfcfcf;
  border-radius: 1px !important;
}
.data_generator_new:focus {
  border: 2px solid var(--AI-Generation-colour, #1fb8c3);
}
.data_generator_new img {
  padding-right: 6px;
}
.regt_sec {
  position: absolute;
  right: 0;
  bottom: -40px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #1865c1;
  border: none;
  background: none;
}
.savce_cont_btn {
  background: #1865c1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  width: 260px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_right {
  text-align: right;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}
.yearsExp_lable label::after {
  display: none;
}
.soclia_meid_main_sec {
  position: relative;
  padding: 30px 0;
}
.soclia_meid_main_sec_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.soclia_meid_main_sec_head h6 {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
}
.soclia_meid_main_sec_head button {
  position: relative;
  display: flex;
  border: 1px solid #252525;
  border-radius: 4px;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 15px;
  align-items: center;
  gap: 10px;
  background: none;
}
.soclia_meid_main_sec_body {
  position: relative;
  text-align: left;
}
.soclia_meid_main_sec_body_single {
  position: relative;
  margin-bottom: 20px;
  color: #000;
}
.soclia_meid_main_sec_body_single h5 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.soclia_meid_main_sec_body_single p {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}
.soclia_meid_main_sec_body_single ul {
  display: block;
  position: relative;
  margin-bottom: 20px;
}
.soclia_meid_main_sec_body_single ul li {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding-bottom: 2px;
  position: relative;
  padding-left: 15px;
}
.soclia_meid_main_sec_body_single ul li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  height: 5px;
  width: 5px;
  background: #000;
  border-radius: 50%;
}
.list_top_sec_inner {
  position: relative;
  display: flex !important;
  align-items: center;
  gap: 50px;
}
.list_top_sec_inner li {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000;
  position: relative;
}
.soclia_meid_main_sec_body_single h2 {
  font-size: 20px;
  margin: 0 0 10px 0;
}
.soclia_meid_main_sec_body_single h1 {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.job_details_from
  :where(.css-dev-only-do-not-override-17sses9).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  position: absolute;
  right: -0px;
  top: -1px;
  color: #f00;
}

.custom_radio {
  display: flex;
  align-items: baseline !important;
}
.custom_radio label {
  display: flex !important;
}
.custom_checkbox {
  position: relative;
  margin-bottom: 30px;
}
.custom_checkbox label {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
  cursor: auto;
  width: 1px;
}
/* ModalStyles.css */
.customYesButton {
  border-radius: 10px;
  background-color: #1677ff;
  color: white;
  border: 1px solid #1677ff;
  border-radius: 50px;
  padding: 0px 20px;
}

.normalize_padding {
  /* padding: 35px 60px; */
  padding: 35px 40px;
}

.Monitization_section_credit_section {
  display: flex;
}

.budget-form {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Monitization_section_credit_section-left {
  /* width: 70%; */
  text-align: left;
  /* border-right: 1px solid #E0E0E0; */
  /* padding: 0px 20px 0px 0px; */
}

.Monitization_section_credit_section-right {
  padding: 0px 0px 0px 20px;
  text-align: left;
  width: 50%;
}

.head_sec_main {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.head_sec_main_p {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.green_p {
  color: #088e2e;
  font-weight: 500;
  margin-top: 10px;
}

.bg-blue1 {
  background-color: #f2f9ff;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
}
.bg-blue1-card {
  margin-bottom: 20px;
}


.first {
  font-weight: 700;
}

.wdt_p {
  width: 80%;
  margin-bottom: 20px;
}

.add_credit_btn {
  display: flex;
  justify-content: space-between;
}

.Add_button_credit {
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  color: #1865c1;
  border: 1.5px solid #1865c1;
  width: 80px;
}
.Add_credit .ant-input {
  height: 50px;
}
/* .Add_credit .ant-select-selection-search{
padding-bottom: 20px !important;
} */
.Add_credit .ant-select-selector {
  background-color: transparent !important;
}
.Add_credit .ant-form-item {
  margin-bottom: 0px;
  width: 80px;
  padding: 0px;
}
.Add_credit .ant-select-selection-item {
  padding-bottom: 20px !important;
}
.Add_credit .ant-select .ant-select-arrow {
  top: 35%;
}
.customNoButton {
  border-radius: 10px;
  background-color: #ffffff;
  color: #1677ff;
  border: 1px solid #1677ff;
  border-radius: 50px;
  padding: 0px 20px;
}

#disclose_salary {
  display: flex;
}
.custom_checkbox_head span:nth-child(2) {
  padding-left: 0px !important;
  font-size: 14px;
  font-weight: 400;
}
.custom_checkbox label span:nth-child(1) {
  margin-left: 50px;
  border: 1px solid black;
}
span.custom_text {
  position: absolute;
  top: 0;
  left: 300px;
}
.ai-generated-edit-btn:focus {
  border: 1px solid black;
}
.years_exp label {
  font-size: 12px !important;
  color: #6c6c6c !important;
  margin-bottom: 0px;
  height: 0px;
}
.chips-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.apply-job-btn-container {
  display: flex;
  justify-content: space-between;
}
.tab-content .apply-job-btn-container {
  display: flex;
  justify-content: space-between;
  padding: 0 0px 20px 0px;
}
.next-btn-container {
  display: flex;
  gap: 10px;
}
.next-btn-container button {
  border-radius: 50px;
  width: 217px;
  height: 50px;
  background: #083F82;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
.back_btn_new_sec{border: 2px solid #1865C1 !important;background: none !important;color: #1865C1 !important;}

.job-details-modal-add-question-button {
  border-radius: 50px;
  width: 217px;
  height: 50px;
  background: #083F82 !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
}

.job-details-modal-add-question-button-disabled {
  background: #EAEBEC !important;
  color: #989FA4 !important;
}


.job-details-add-cancel-btn-sec-modal {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.job-details-add-btn-sec-modal {
  border-radius: 50px;
  width: 217px;
  height: 50px;
  background: #083F82;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}


.job-details-cancel-btn-sec-modal {
  border-radius: 50px;
  width: 217px;
  height: 50px;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 700;
}

.currency-form label {
  height: 20px !important;
}
.ant-switch-checked {
  background: #1865c1 !important;
}

span.ant-select-selection-placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.tab-content .job_det_step_sec_new {
  padding: 10px;
}
.tab-content .job_det_step_sec_new .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.company_details_sec_new_inner{gap: 20px;}
.tab-content .job_det_step_sec_new_inner .col-lg-10 {
  flex: 0 0 auto;
  width: 100%;
}
#job_form3{
  display: flex;
  width: 100%;
}
#job_form3 .credit_sec_main,
 #job_form3 .Monitization_section_credit_section-right{
  width: 50%;
}
#job_form3 .credit_sec_main{
  border-right: 1px solid #E0E0E0;
  padding-right: 22px;
}




.custom-currency-dropdown{
  background: #f8f8f8 !important;

  button{
    border: none;
  }
}


.enterkey_btn{position: absolute;right: 20px;background: #808080;border-radius: 30px !important;top: 5px !important;bottom: 0;}

.custom_radio{align-items: center !important;}
.ant-radio-wrapper{margin-bottom: 0px !important;}
.ant-radio input.ant-radio-input{display: none;}
.ant-radio .ant-radio-inner{height: 20px !important;width: 20px !important;background-color: #fff !important; border-radius: 50% !important;border: 1.5px solid #0B4A96 !important;position: relative;}
.ant-radio-checked .ant-radio-inner{border-color: #0B4A96 !important;}
.ant-radio-checked .ant-radio-inner:after {content: "\f111" !important; position: relative !important;
  font-family: 'FontAwesome' !important; color: #0B4A96 !important; font-weight: 900 !important;
  font-size: 30px !important;display: block !important; top: 0px;left: 0px;right: auto;bottom: auto;
  display: flex !important;align-items: center;justify-content: center; height: 100%; width: 100%;
  margin-block-start: 0;  margin-inline-start: 0;}
.text-end label{font-size: 14px;font-weight: 400;color: #000000;} 

.ant-checkbox-inner:after{top: 6px;left: 3px;}
#jobDetailsForm1_disclose_salary .ant-checkbox-inner{position: absolute;top: -2px;}
#jobDetailsForm1_currency{padding: 0px !important;left: 10px;}
button#rfs-btn {
  background: #E4F0FF;
  border-radius: 6px 0 0 6px;
  height: 42px;
}
button#rfs-btn span{color: #000001;font-size: 14px;font-weight: 600;}
button#rfs-btn span:after{position: relative;right: 10px;}
.ReactFlagsSelect-module_selectBtn__19wW7:after{position: relative;right: 10px;}
#jobDetailsForm1_tolerance_margin.ant-switch-checked {background: #0b4a96 !important;}
.text-start p{font-size: 14px;font-weight: 400;color: #3D4852;margin-bottom: 30px;}
.ant-input-number-affix-wrapper{width: 100%;background: #f8f8f8;border-radius: 0;border: none;}
.ant-input-number-affix-wrapper:hover{background: #f8f8f8;}
.ant-input-number-prefix:focus{background: #f8f8f8;}
.ant-input-number-affix-wrapper:focus{background: #f8f8f8;}
.ant-input-number .ant-input-number-input-wrap input{border-radius: 0 6px 6px 0;}
.ant-input-number .ant-input-number-input-wrap input:focus{background: #f8f8f8;}
.ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 1.5em !important;
  border-radius: 50%;
  overflow: hidden;
}


.new_curr_dp_sec {
  background: #E4F0FF;
  border-radius: 6px 0 0 6px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 12px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.new_curr_dp_sec .dropdown-arrow{color: #000;}
.new_curr_dp_sec_option{position: absolute;top: auto;left: 0;width: 100%;z-index: 999;border-radius: 10px;padding: 5px 5px;background: #fff;font-size: 12px;font-weight: 500;    border: 1px solid #f1f1f1;}
/* button#rfs-btn span{color: #000001;font-size: 14px;font-weight: 600;} */
.dis_flex_new_curr_dp_sec{display: flex;align-items: center;    gap: 15px;}
.mr-2{width: 18px;}
.years_exp{left: 10px;position: relative;}
.new_curr_dp_sec_option .flex{display: flex;align-items: center;justify-content: center;gap: 20px;cursor: pointer;border-radius: 10px;margin: 0 0 0px 0;}
.new_curr_dp_sec_option .flex:last-child{margin-bottom: 0px;}
.new_curr_dp_sec_option .flex span{width: 70px;}
.new_curr_dp_sec_option .flex:hover{background: #E4F0FF;transition: 0.5s ease-in-out;}
.new_width_sec{max-width: 150px;}

/* 
.ReactFlagsSelect-module_selectFlag__2q5gC svg{
  border-radius: 50% !important;
}
.ReactFlagsSelect-module_selectFlag__2q5gC svg {
  clip-path: circle(50%) !important;
} */
.job_det_step_inner_step_new .header{margin-bottom: 30px !important;}
.custom-currency-dropdown .ReactFlagsSelect-module_selectFlag__2q5gC svg {
  border-radius: 50% !important;
  overflow: hidden;
}

.custom-flag-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flag-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.saalry_check_box{position: relative;}


#form_box_sec {
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 28.600000381469727px 0 #00000014;
  padding: 35px 80px;
}
.new_form_sec_tool .progress-wd .ant-progress-text {
  top: 25px;
  text-align: center;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}



.ant-select-in-form-item {
  margin-bottom: 12px;
}




.salary_check_box_main_sec{display: flex;align-items: center;justify-content: flex-end; gap: 20px;width: 100%;}
#jobDetailsForm1 .ant-form-item-label{overflow: visible !important;}

.job_det_step_sec_new .back-button{left: 0 !important;}
.comp_det_rigt_main .job_det_step_inner_step_new {position: relative;background: #fff;padding: 20px;border-radius: 10px;
}
.comp_det_rigt_main .job_det_step_inner_step_new .pt-5 {padding-top: 0rem !important;}
.tab-content .job_det_step_sec_new_inner .pt-5 {padding-top: 0rem !important;}
.tab-content .job_det_step_sec_new_inner #form_box_sec{box-shadow: none;padding: 35px 20px 20px 20px;}
#assignment_help .ant-form-item-explain-error {
  position: relative;
  right: 0;
  top: -30px;
  text-align: center;
}

#custom_questions_help .ant-form-item-explain-error {
  position: relative;
  top: -30px;
}

.ant-select-arrow{    color: #727B82;}
.ant-select-selector{padding: 0 25px !important;}
.ant-select-selection-search-input{font-size: 14px;color: #000;background: #f8f8f8 !important;}
.main_both_class{width: 85%;margin: 0 auto;}
.new-class{width: 85%;margin: 0 auto;}
.tab-content .main_both_class{width: 100%;}
.tab-content .new-class{width: 100%;}
.tab-content .next-btn-container button {width: 145px;height: 40px;}
.tab-content .new_budget_info .budget_sec_tool{width: 420px;}
.new_budget_info .budget_sec_tool{line-height: inherit;}

.custom-form-item-new .ant-form-item-explain {
  opacity: 0;
}
.tab-content .job_det_step_sec_new_inner .col-lg-11 {flex: 0 0 auto;width: 100%;}
.tab-content .job_det_step_sec_new_inner .col-md-11 {flex: 0 0 auto;width: 100%;}
/* .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{ left: 90px !important;} */


.new_qx .ant-radio-inner{left: -110px;}
.new_qx .ant-radio .ant-radio-inner{    border: 1.5px solid #000 !important;}
.new_qx .ant-radio-checked .ant-radio-inner:after{color: #000;}



@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{position: absolute;left: 5px;top: -3px;} */

  
  .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 0px;}
  .tab-content .job_details_from label{font-size: 14px !important;}
  .tab-content .custom_radio label{margin-right: 25px !important;}
  .tab-content .job_details_from input{height: 40px !important;}
  .tab-content .next-btn-container button{width: 160px;}
  .tab-content .qc_body_radio .container .checkmark:after {top: 0;} 
  
  #form_box_sec{padding: 35px 30px !important;}
  .job_details_from label{font-size: 14px !important;}
  .ant-radio-wrapper{margin-right: 10px !important;}
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{position: absolute;left: 5px;top: -3px;} */

  .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 0px;}
  .tab-content .job_details_from label{font-size: 14px !important;}
  .tab-content .custom_radio label{margin-right: 25px !important;}
  .tab-content .job_details_from input{height: 40px !important;}
  .tab-content .next-btn-container button{width: 160px;}
  .tab-content .qc_body_radio .container .checkmark:after {top: 0;} 
  
  #form_box_sec{padding: 35px 30px !important;}
  .job_details_from label{font-size: 14px !important;}
  .ant-radio-wrapper{margin-right: 10px !important;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{top: 0;left: 30px;} */
  /* .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{ left: 60px !important;} */
             
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{position: absolute;left: 0px;top: -3px;} */
  
  .tab-content .job_det_step_sec_new_inner #form_box_sec{padding: 35px 40px 0px 40px !important;}
  #form_box_sec{padding: 35px 30px !important;}
  .job_details_from label{font-size: 14px !important;}
  .ant-radio-wrapper{margin-right: 10px !important;}

  /* .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 0px;} */
  .tab-content .job_details_from label{font-size: 14px !important;}
  .tab-content .custom_radio label{margin-right: 25px !important;}
  .tab-content .job_details_from input{height: 40px !important;}
  .tab-content .next-btn-container button{width: 160px;}
  .tab-content .qc_body_radio .container .checkmark:after {top: 0;} 
  .tab-content button#rfs-btn span{gap: 5px !important;font-size: 12px !important;}    
  .ReactFlagsSelect-module_selectBtn__19wW7:after{right: 15px;position: absolute;}
  #jobDetailsForm1_disclose_salary label{font-size: 13px !important;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{top: -3px;left: 100px;} */
  /* .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{ left: 30px !important;} */
  
  
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .ant-radio-wrapper{margin-right: 35px;}
  #form_box_sec{padding: 35px;}
  .ReactFlagsSelect-module_selectBtn__19wW7:after{position: relative;right: 5px;}
  .job_det_step_sec_new_inner .pt-5 {padding-top: 0rem !important;}
  .job_det_step_sec_new_inner .col-lg-10 {flex: 0 0 auto;width: 100%;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 0px;} */
  #jobDetailsForm1_disclose_salary label{font-size: 13px !important;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{top: 0;left: 100px;} */

  .tab-content .recriuter_step_2_inner_top_left h6{font-size: 14px;}
  .tab-content .recriuter_step_2_inner_top_left p{font-size: 11px;}
  .tab-content .recriuter_step_2_inner_top_left button{font-size: 12px;}
  .tab-content .recriuter_step_2_inner_top_right h6{font-size: 12px;}
  .tab-content .recriuter_step_2_inner_top_right img{width: 18px;}
  .tab-content .recriuter_step_2_inner_top_right pre{font-size: 12px;}
  .tab-content .recriuter_step_2_inner_btm_single_btm{grid-gap: 5px;}
  .tab-content .qc_body_radio .container{font-size: 11px;}
  .tab-content .qc_body_text h6{font-size: 10px;}
  .tab-content .qc_body_radio .checkmark:after{font-size: 9px;}
  .tab-content .qc_body_radio .checkmark{height: 18px;width: 18px;line-height: 18px;}
  .tab-content .recriuter_step_2_inner_btm_single_btm_inner p{flex: 0 0 440px !important;}
  .fa_plus_btn {
    position: relative;
    padding: 6px 20px;
    font-size: 11px;
  }
  .tab-content .budget_job_inner_btm_right{font-size: 10px;}
  /* .tab-content #jobDetailsForm1_disclose_salary .ant-checkbox-inner{ left: 10px !important;} */
  .ReactFlagsSelect-module_selectBtn__19wW7:after{display: none;}
  
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media (max-width: 768px) {
  .react-quill-container .quill {
    height: auto;
  }
  .seventy-line span {
    font-size: 10px;
  }
  /* :where(.css-dev-only-do-not-override-17sses9).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: transparent;
    display: none;
} */
  .job_det_step_inner_step_new
    .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    display: none;
  }
  .job_det_step_inner_step_new
    .ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none !important;
  }
  .job_det_step_inner_step_new
    .ant-steps-item-container
    > .ant-steps-item-tail::after {
    display: none;
  }
  .job_det_step_inner_step_new
    .ant-steps.ant-steps-vertical
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none;
  }
  #job_form3 {
    display: flex;
    width: 100%;
    flex-direction: column;
}
#job_form3 .credit_sec_main, #job_form3 .Monitization_section_credit_section-right {
  width: 100%;
}
.Monitization_section_credit_section-right{
  padding-left: 0px;
}
#job_form3 .credit_sec_main{
  padding-right: 0px;
  border:none ;
}
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .job_det_step_sec_new .col-md-8 {
    flex: 0 0 auto;
    width: 85%;
  }
  .list_item_sec li {
    margin-bottom: 10px;
  }
  
  .list_top_sec_inner li {
    font-size: 12px !important;
  }
  .soclia_meid_main_sec_body_single h1 {
    font-size: 16px;
  }
  /* .col-4.d-flex.align-items-baseline.justify-content-end {
    justify-content: flex-start !important;
  } */
  .ant-table-cell span {
    font-size: 10px;
    padding: 4px 12px;
  }
  .soclia_meid_main_sec_body_single h3 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .soclia_meid_main_sec_body_single h5 {
    font-size: 16px;
  }
  .des_text_sec {
    font-size: 18px;
  }
  .soclia_meid_main_sec_body_single h2 {
    font-size: 18px;
  }


  .form_sec_new_inner_first {padding: 30px 5px;  }
  .job_det_step_sec_new_inner .pt-5 {padding-top: 0rem !important;}
  .job_det_step_sec_new_inner .col-lg-10 {flex: 0 0 auto;width: 100%;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 0px;} */
  .mobile_header_sec_inner_left{width: 90px;}
  #form_box_sec{padding: 35px;}
  .ant-radio-checked .ant-radio-inner:after{top: 0px;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner { left: 0;} */
  .recriuter_step_2_inner_btm_single_top_left h5{font-size: 16px;}
  .recriuter_step_2_inner_btm_single_top_left h5 button{font-size: 10px;}
  .recriuter_step_2_inner_btm_single_btm_inner p{flex: 0 0 600px;}
  .recriuter_step_2_inner_btm_single_btm_inner p{font-size: 12px;flex: 0 0 600px;}
  .fa_plus_btn{font-size: 12px;}
  .budget_job_inner_btm_left{    flex: 0 0 60%;}
  #credits_alloted_help .ant-form-item-explain-error{bottom: 0px;}
  .written_test_modal_sec_inner_single_icons ul li button{width: 105px;font-size: 12px;}

}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  
  
  .tab-content .form_sec_new_inner_first {padding: 5px 5px !important;}
  .job_det_step_sec_new_inner .pt-5 {padding-top: 0rem !important;}
  .job_det_step_sec_new_inner .col-lg-10 {flex: 0 0 auto;width: 100%;}
  .mobile_header_sec_inner_left{width: 90px;}
  .tab-content #form_box_sec{padding: 5px !important;}
  .ant-radio-checked .ant-radio-inner:after{top: 0px !important;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner { left: 0 !important;} */
  .recriuter_step_2_inner_btm_single_top_left h5{font-size: 16px !important;}
  .recriuter_step_2_inner_btm_single_top_left h5 button{font-size: 10px !important;}
  .recriuter_step_2_inner_btm_single_btm_inner p{flex: 0 0 500px !important;}
  .recriuter_step_2_inner_btm_single_btm_inner p{font-size: 12px !important;flex: 0 0 500px !important;}
  .fa_plus_btn{font-size: 12px;}
  .budget_job_inner_btm_left{    flex: 0 0 60% !important;}
  #credits_alloted_help .ant-form-item-explain-error{bottom: 0px !important;}
  .written_test_modal_sec_inner_single_icons ul li button{width: 105px !important;font-size: 12px !important;}
  button#rfs-btn span{font-size: 11px;}
  .custome_questions .modal-dialog{max-width: 750px !important;padding: 20px !important;}
  .save_btn {width: 180px !important; height: 40px !important; font-size: 14px !important;}
  .draft_btn{width: 130px !important; height: 40px !important; font-size: 14px !important;}
  .qc_body_radio .container{font-size: 11px;}
  .tab-content .job_details_from label{font-size: 12px !important;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner{left: 80px !important;top: 0px;} */
  #jobDetailsForm1_disclose_salary label{font-size: 12px !important;}
  .job_det_step_sec_new_inner .col-md-11 { flex: 0 0 auto; width: 100%;}
  .list_item_sec li{font-size: 9px;margin: 2px;}
  .new_padd_cls .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{right: 0;}
  .ReactFlagsSelect-module_selectBtn__19wW7:after{display: none;}
  .tab-content .ql-snow .ql-editor h2{font-size: 20px;}
  .tab-content .ql-snow .ql-editor h2{font-size: 16px;}
  .main_both_class{width: 95%;}
  .form_sec_new_inner_first{padding: 0px !important;}
  .written_test_modal_sec .modal-dialog{padding: 30px;}

}


@media only screen and (max-width: 767px) {
  .inc_dec button {
    height: 35px;
  }
  #disclose_salary > span {
    margin-right: 5px;
  }
  .tab-content .job_det_step_sec_new {
    padding: 0px;
  }
  .job_det_step_sec_new {
    padding: 15px 0;
  }
  .ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: row;
    display: none;
  }
  .job_det_step_sec_new .header {
    height: auto;
    margin-bottom: 40px;
  }
  .ant-steps-item-container {
    justify-content: center;
  }
  .job_det_step_sec_new .ant-steps-item-icon {
    margin-inline-end: 0 !important;
  }
  .ant-steps-item-active .ant-steps-item-icon {
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important;
    font-size: 12px !important;
  }
  .ant-steps-item-icon {
    width: 26px !important;
    height: 26px !important;
    line-height: 26px !important;
    font-size: 12px !important;
  }
  .form_sec_new_inner_first {
    padding: 15px;
    /* margin: 15px; */
  }
  .ql-container.ql-snow {
    border: none;
  }
  #description .ql-editor {
    height: 113px !important;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
  }
  #description .ql-editor {
    height: auto !important;
    border: none;
  }
  .job_details_from input {
    padding-left: 15px;
    height: 40px !important;
  }
  .form_sec_new_inner_first .job_details_from .ant-form-item {
    margin-bottom: 20px !important;
  }
  .list_top_sec_inner {
    display: block !important;
  }
  .soclia_meid_main_sec_body_single h2 {
    margin-bottom: 10px;
  }
  .next-btn-container button {
    padding: 8px 20px;
    height: 35px;
    font-size: 12px;
    width: 100px;
  }
  .soclia_meid_main_sec {
    padding: 0px;
  }
  .soclia_meid_main_sec_head h6 {
    font-size: 18px;
  }
  .companyDetails_name h5 {
    font-size: 15px;
  }
  .list_top_sec_inner li {
    font-size: 14px !important;
  }
  .job_details_from label {
    font-size: 12px !important;
  }
  .soclia_meid_main_sec_body_single h2,
  .soclia_meid_main_sec_body_single h5 {
    font-size: 14px;
  }
  .soclia_meid_main_sec_body_single p {
    font-size: 12px;
  }
  .job_details_from .ant-input {
    font-size: 12px;
  }
  .pad-0 {
    padding: 0px;
  }


  #form_box_sec{padding: 15px;}
  .job_det_step_sec_new_inner .pt-5 {padding-top: 1rem !important;}
  #jobDetailsForm1 .col-6 { flex: 0 0 auto; width: 100%;}
  .list_item_sec li{    margin-right: 5px;margin-bottom: 5px;}
  .job_details_from input{font-size: 12px;}
  span.ant-select-selection-placeholder{font-size: 12px !important;}
  #jobDetailsForm1_disclose_salary {width: 65%; position: absolute; right: -35px; top: -85px;*opacity: 0;}
  /* #jobDetailsForm1_disclose_salary .ant-checkbox-inner {position: absolute;left: 0px;top: 0;} */
  .new_row_tolerance{flex-direction: column;}
  .ant-select-selector {padding: 0 15px !important;}
  #jobDetailsForm1_currency{left: 0px;}
  .new_padd_cls{padding: 0 10px;}
  .apply-job-btn-container{width: 95%;margin: 0 auto;}
  .main_both_class{width: 95%;margin-top: 50px;flex-direction: column-reverse;}
  #jobDetailsForm1_disclose_salary .ant-checkbox-inner{top: 0px !important;}
  .new_form_last{margin: 40px 10px 10px 10px;}
  .soclia_meid_main_sec_head{margin-bottom: 5px;}
  .comp_det_rigt_main .job_det_step_inner_step_new{padding: 20px 0 0 0;}
  .tab-content .job_det_step_sec_new_inner #form_box_sec { padding: 5px 0px 0px 0px;}
  




}

@media only screen and (max-width: 525px) {
  span.custom_text {
    position: absolute;
    top: 5px;
    left: 250px;
    font-size: 12px;
  }
  .custom_checkbox_head span:nth-child(2) {
    padding-left: 0px !important;
    font-size: 12px;
    font-weight: 400;
  }
  .custom_checkbox label span:nth-child(1) {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 350px) {
  span.custom_text {
    position: absolute;
    top: 0;
    left: 250px;
  }
  .custom_checkbox label span:nth-child(1) {
    margin-left: 0px;
  }
  span.custom_text {
    position: absolute;
    top: 4px;
    left: 216px;
    font-size: 12px;
  }
  :where(.css-dev-only-do-not-override-17sses9).ant-steps
    .ant-steps-item-title::after {
    position: absolute;
    top: 16px;
    inset-inline-start: 100%;
    display: block;
    width: 9999px;
    height: 1px !important;
    background: rgba(5, 5, 5, 0.06) !important;
    content: "";
  }
  .soclia_meid_main_sec_body_single ul li:before {
    position: absolute;
    content: "";
    left: 0;
    top: 5px;
    height: 4px;
    width: 4px;
    background: #000;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 653px) and (max-width: 720px) {
  .ant-steps .ant-steps-item {
    display: flex;
  }
}
@media only screen and (max-width: 345px) {
  span.custom_text {
    position: absolute;
    top: 5px;
    left: 200px;
    font-size: 10px;
  }
  #disclose_salary > span {
    font-size: 10px;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .soclia_meid_main_sec_body_single p {
    font-size: 11px;
    margin-bottom: 10px;
  }
  .soclia_meid_main_sec_body_single ul li {
    font-size: 10px;
  }
  .soclia_meid_main_sec_body_single h2,
  .soclia_meid_main_sec_body_single h5 {
    font-size: 12px;
  }
}

.points-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  p {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Satoshi-Variable';
  }
}

