/* CodeEditor.module.css */

.code_mirror_sec {
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #282c34;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  
}

.code_edit_lang_sec {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.code_edit_lang_sec_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #000;
  border-radius: 10px 10px 0 0;
}

.language_dropdown {
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #444455;
  border-radius: 4px;
  background: #1F2228;
  color: white;
  cursor: pointer;
}

.language_dropdown:focus {
  outline: none;
  border-color: #007bff;
}

.cm-editor {
  border-radius: 6px;
  overflow: hidden;
}

.btn_code_edt {
  background: #3E3E3E;
  color: white;
  font-size: 11px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 65px;
  height: 27px;
  position: absolute;
  right: -150px;
  top: auto;
  bottom: 230px;
}

.btn_code_edt:hover {
  background: #0B4A96;
}

.output_sec_new {
  background: #282c34;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  color: white;
  border: 1px solid #484c55;
  height: 180px;
  overflow-y: scroll;
}

.output_sec_new::-webkit-scrollbar {width: 20px;display: none;}
.output_sec_new::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px;} 
.output_sec_new::-webkit-scrollbar-thumb {background: red; border-radius: 10px;}
.output_sec_new::-webkit-scrollbar-thumb:hover {background: #b30000;}

.output_sec_new h4 {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
}

.loader_new{top: 40px !important;position: relative;z-index: 99999 !important;bottom: 0 !important;right: 0;left: 0;text-align: center;width: 100%;display: flex;align-items: center;justify-content: center;}