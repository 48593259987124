/* .modal-header {
    border-bottom: none;
    padding: 20px 40px;
  }

  .modal-title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .guideline-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 25px;
    gap: 10px ;
    margin-bottom: 10px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  }

  .guideline-item i {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-size: cover;
    margin-right: 15px;
  }  
  .modal-footer {
    border-top: none;
    justify-content: space-between;
    padding: 0px 40px;
    margin-bottom: 20px;
  }
  .blue_bg_btn{
    background-color: 
    rgba(24, 101, 193, 1);
    color: white;
    font-size: 14px;
    padding: 8% 41px;
    border-radius: 50px;
    border:none;
}
.clear_bg_btn{
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 8px 41px;
    border-radius: 50px;
    border:1px solid black;
}
.guideline-item p{
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
  .modal-footer .btn-secondary {
    background: #ffffff;
    border: 1px solid #000000;
  }

  .modal-footer .btn-primary {
    background: #007BFF;
    border: none;
  }

  .modal-body {
    padding: 0px 40px;
  } */
  .guideline-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 25px;
    gap: 10px ;
    margin-bottom: 10px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  }
  .max-wid{
    max-width: 100%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 767px) {
  .guideline-item{
    padding: 5px;
  }
  #contained-modal-title-vcenter{
    font-size: 18px;
  }
  .guideline-item p{
font-size: 12px;
margin-bottom: 0px;
  }
}