.container {
    text-align: center;
    padding: 0 20px 0 20px;
  }
  
  .heading {
    color: #007a33;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px !important;
  }

  .heading_failed {
    color: #C72500;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px !important;
  }
  
  
  /* .table {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    background: #F3F8FF;
    border-radius: 8px;
    overflow: hidden;
  } */
/*   
  th, td {
    padding: 12px 15px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
  }
  
  th {
    background: #e8f1f8;
    font-weight: bold;
  } */

  .table thead tr th{font-size: 16px;color: #3D4852;font-weight: 400;background:#F3F8FF;border-bottom: 3px solid 
    #FFFFFF;padding: 12px 15px;}
  .table tbody tr td{font-size: 16px;color: #3D4852;font-weight: 400;background:#F3F8FF;border-bottom: 3px solid 
      #FFFFFF;padding: 12px 15px;}

  .bold {
    font-weight: bold;
  }
  
  .totalRow {
    /* background: #eef4f8; */
    font-weight: bold;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
  }
  
  .backButton {
    background: #ffffff;
    border: 2px solid #9DB5D7;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    color: #4E5862;
    font-size: 14px;
    font-weight: 700;
  }
  
  .backButton:hover {
    background: #f1f1f1;
  }
  
  .proceedButton {
    background: #003580;
    color: white;
    padding:8px 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    font-weight: 700;
  }
  
  .proceedButton:hover {
    background: #002060;
  }

  .proceedButton:disabled {
    background: #9DB5D7;
    color: white;
  }
  
  .interview_mscq_module_inner_main{position: relative;box-shadow: 0px 2px 7px 0px #0000001f;padding: 10px;}

  .table_sec_written {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
  }
  .table_sec_written thead {
    height: 50px;
    border-bottom: 1px solid #E6E6E6;
  }
  .table_sec_written thead tr td{position: relative;padding: 0 0 15px 0!important;color: #000001;font-size: 16px;font-weight: 700;border-bottom: 1px solid #F0F0F0;}
  .table_sec_written tbody tr td{position: relative;padding: 15px 0 5px 0!important;color:#3D4852;font-size: 14px;font-weight: 400;border-bottom:none;}

.wrapper {
  border: 2px solid #E6E6E6;
  padding: 20px 0px 20px 0px;
  margin: 0px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0px 2px 7px 0px #0000001f;
  margin-top: 50px;
}

.pageTitle {
  text-align: center;
  font-size: large;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 20px;
}

.summaryContainer {
  border: 1px solid #E6E6E6;
  padding: 0px 0px 20px 0px;
  border-radius: 15px;
}

/* .horizontalline {
  width: 100%;
  border-bottom: 2px solid black;
  margin: 10px 0;
} */

.haire_bg_ass{}

@media (max-width: 767px) {
  .pageTitle{font-size: 14px;padding-bottom: 10px;margin-bottom: 10px;}
  .heading{font-size: 14px;margin-bottom: 15px !important;}
  .table_sec_written tr th{font-size: 12px;}
  .table_sec_written tr td{font-size: 12px !important;padding: 7px !important;}
  .backButton{font-size: 12px;padding: 8px 10px;}


}