.ai_enhance_sec_new {
    padding: 40px 0 80px 0;
  }
  
  .haire-landing-video {
    position: relative;
  }
  
  .video-player {
    width: 100%;
    display: block;
    border: none;
    box-shadow: none;
  }
  
  .center-button {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #007bff; /* Change as needed */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .center-button:hover {
    background-color: transparent; /* Change as needed */
  }
  
  .ai_enhave_btn_sec {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  

  
  /* Hide default controls for the video */
  .video-player::-webkit-media-controls {
    display: none !important;
  }
  
  .video-player::-moz-media-controls {
    display: none !important;
  }
  
  .video-player::-ms-media-controls {
    display: none !important;
  }
  


.haire-landing-video {
  position: relative;
  display: inline-block; /* Ensure the container fits the video */
}

.video-player {
  width: 100%;
  display: block;
  border: none;
  box-shadow: none;
}
.haire-landing-video:hover .play-button {
  display: block;
  position: absolute;
  top: 41.5%;
}
.haire-landing-video:hover .pause-button {
  display: block;
  position: absolute;
  top: 41.5%;
}
/* Hide both buttons by default */
.center-button {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent; 
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: none; /* Hide by default */
}

/* Show play button when video is not playing and container is hovered */
.haire-landing-video:hover .play-button {
  display: block;
}

/* Show pause button only when video is playing and container is hovered */
.haire-landing-video.playing:hover .pause-button {
  display: block;
}

.play-button {
  display: none; /* Initially hidden */
}

.pause-button {
  display: none; /* Initially hidden */
}

/* .center-button:hover {
  background-color: #0056b3; 
} */

.ai_enhave_btn_sec {
  display: flex;
  justify-content: center;
}




/* Hide default controls for the video */
.video-player::-webkit-media-controls {
  display: none !important;
}

.video-player::-moz-media-controls {
  display: none !important;
}

.video-player::-ms-media-controls {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  
    
  .try-button {
    width: 160px;
    border-radius: 90px;
    background: linear-gradient(95deg, #0B4A96 36.34%, #5094E7 105.5%);
    color: #fff;
    border: none;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    line-height: normal;
}
.round-arow{
    right: 2.5px;
    top: 3px;
    width: 30px;
    height: 30px;
}
.round-arow:hover {
  width: 155px;
  height: 30px;
  transition: width 0.4s ease, height 0.4s ease;
}
  .Ready-btn{
    height: 36px;
  }
  
  }