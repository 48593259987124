.login_desc{
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    margin-top: 81px;
  }
  .Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 14.18px;
  }
  
  .haire-logo {
    height: 60px;
    width: 124px;
   margin-top: 29px;
  }
  .modal-close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 44%;
    top: -100px;
    margin: 10px;
    cursor: pointer;
    background-color: white;
    border-radius: 100px;
    padding: 4px;
  }
  
  
  
  