.recriuter_step_2{position: relative;padding: 40px 0;}
.recriuter_step_2_inner{position: relative;}
.recriuter_step_2_inner_top{position: relative;background: #EDF5FE;padding: 25px;border-radius: 10px;display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;}
.recriuter_step_2_inner_top_left{position: relative;text-align: left;}
.recriuter_step_2_inner_top_left h6{position: relative;color: #000001;font-size: 16px;font-weight: 500;margin-bottom: 10px;}
.recriuter_step_2_inner_top_left p{position: relative;color: #000001;font-size: 12px;font-weight: 500;margin-bottom: 10px !important;font-style: italic;}
.recriuter_step_2_inner_top_left button{position: relative;color: #B3B3B3;font-size: 14px;font-weight: 700;margin-bottom: 0px;font-style: italic;background: none;border: none;text-decoration: underline;padding: 0px;}
.recriuter_step_2_inner_top_left button i{margin-left: 5px;font-size: 10px;}
.recriuter_step_2_inner_top_right{position: relative;display: flex;align-items: center;gap: 10px;color: #000;font-size: 14px;font-weight: 700;}
.recriuter_step_2_inner_top_right img{width: 24px;}
.recriuter_step_2_inner_top_right span{display: flex;gap: 5px;}
.recriuter_step_2_inner_top_right pre{font-style: italic;margin: 0px;font-size: 14px;font-weight: 500;    font-family: 'Satoshi-Variable';overflow: visible;}
.active{color: #0B4A96 !important;}
.credit_summary{position: absolute;width: 360px;height: 124px;box-shadow: 0px 4px 28.6px 0px #00000014;background: #fff;
padding: 15px;border-radius: 10px;left: 10px;top: auto;z-index: 9999;}
.credit_summary_inner{position: relative;border: 1px dashed #808080;height: 100%;padding: 15px;}
.credit_summary_inner ul{display: block;}
.credit_summary_inner ul li{display: flex;align-items: center;justify-content: space-between;width: 100%;color: #000000;font-size: 14px;border-bottom: 1px dashed #808080;padding-bottom: 15px;margin-bottom: 10px;}
.credit_summary_inner ul li:last-child{border-bottom: none;padding-bottom: 0px;margin-bottom: 0px;}
.credit_summary_inner ul li h6{position: relative;font-size: 14px;font-weight: 500;color: #000000;margin-bottom: 0px !important;}
.dollar_sign_img{position: relative;display: flex;align-items: center;gap: 10px;}
.dollar_sign_img img{width: 15px;}
.dollar_sign_img span{font-size: 14px;}
.recriuter_step_2_inner_btm{position: relative;border: 1px solid #CCCCCC;padding: 40px 30px;border-radius: 10px;margin-bottom: 20px;}
.recriuter_step_2_inner_btm_single{position: relative;border-bottom: 0.5px solid #CCCCCC;padding-bottom: 30px;margin-bottom: 0px;}
.recriuter_step_2_inner_btm_single_top{position: relative;display: flex;align-items: center;justify-content: space-between;margin-bottom: 15px;}
.recriuter_step_2_inner_btm_single_top_left{position: relative;}
.recriuter_step_2_inner_btm_single_top_left h5{position: relative;color: #000001;margin-bottom: 0px;font-size: 20px;display: flex;}
.recriuter_step_2_inner_btm_single_top_left h5 pre{color: #FF5630;font-size: 14px;margin: 0px;}
.recriuter_step_2_inner_btm_single_top_left h5 button{position: relative;margin-left:10px;color: #0B4A96;font-size: 12px;font-weight: 700;font-style: italic;border: none;background: none;text-decoration: underline;}
.recriuter_step_2_inner_btm_single_top_right{position: relative;}
.recriuter_step_2_inner_btm_single_top_right h6{position: relative;margin-bottom: 0px;color: #0B4A96;font-size: 12px;font-weight: 500;font-style: italic;}
.recriuter_step_2_inner_btm_single_btm{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 15px;}
.recriuter_step_2_inner_btm_single_btm_single{position: relative;}
.recriuter_step_2_inner_btm_single_btm_single h6{position: relative;color: #212529;font-weight: 700;font-style: italic;margin-bottom: 15px;font-size: 13px;text-align: left;}
.qc_body{position: relative;width: 100%;padding: 12px;border-radius: 10px;display: flex;align-items: center;justify-content: space-between;background: #F5F5F5;}
.qc_body_radio{position: relative;}

.qc_body_radio .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #212529;
    font-weight: 500
}

.qc_body_radio .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.qc_body_radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 1.5px solid #1C1B1F;
    line-height: 20px;
}

.qc_body_radio .container:hover input~.checkmark {
    background-color: #fff
}

.qc_body_radio .container input:checked~.checkmark {
    background-color: #fff
}

.qc_body_radio .checkmark:after {
    content: "\f111";
    position: absolute;
    display: none;
    font-family: 'FontAwesome';
    color: #1C1B1F;
    font-weight: 900 !important;
    font-size: 10px;
}

.qc_body_radio .container input:checked~.checkmark:after {
    display: block
}

/* .qc_body_radio .container .checkmark:after {
    top:1.5px;
    left: 3px;
    right: 0;
} */

@supports (-webkit-appearance: none) {
    .qc_body_radio .container .checkmark:after {
        top:0px;
        left: 0px;
        right: 0;
        text-align: center;
        bottom: 0;
    }
}
.qc_body_text{position: relative;}
.qc_body_text h6{position: relative;margin-bottom: 0px !important;color: #008D4A;font-size: 12px;font-weight: 700;font-style: normal;}

.modal_main_sec .modal-dialog{max-width: 975px;background: #EDF5FE;border-radius: 10px;padding: 40px;}
.modal_main_sec .modal-content{border: none;}
.modal_main_sec_inner{position: relative;background: #edf5fe;}
.modal_close_btn{position: absolute;right: 0;top: 0;color: #000;font-weight: 500;font-size: 16px;z-index: 11;background: none;border: none;}
.modal_main_sec_inner h6{position: relative;color: #212529;font-size: 18px;font-weight: 900;margin-bottom: 15px;}

.toggle_sec .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-right: 10px;
  }
  
.toggle_sec .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
.toggle_sec .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F3F3F3;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
.toggle_sec .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4.5px;
    bottom: 4.5px;
    background-color: #C0C0C0;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
.toggle_sec input:checked + .slider {
    background-color: #083F82;
  }
  
.toggle_sec input:focus + .slider {
    box-shadow: 0 0 1px #F3F3F3;
  }
  
.toggle_sec input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background: #fff;
  }
.toggle_sec .slider.round {
    border-radius: 34px;
  }
  
.toggle_sec .slider.round:before {
    border-radius: 50%;
  }
.new_left{position: relative;display: flex;}  
.recriuter_step_2_inner_btm_single_btm_inner{position: relative;display: flex;align-items: center;gap: 20px;justify-content: space-between;width: 100%;}
.recriuter_step_2_inner_btm_single_btm_inner p{position: relative;font-size: 14px;font-weight: 500;color: #212529;margin-bottom: 0px !important;flex: 0 0 645px;text-align: left;}

.fa_plus_btn { position: relative; padding: 8px 24px;font-size: 14px;font-weight: 600;color: #000; text-decoration: none; border: none;border-radius: 30px;cursor: pointer;text-align: center; box-shadow: 0 0 0 2px transparent; background-clip: padding-box; border: 2px solid transparent;background: linear-gradient(#fff, #fff) padding-box,linear-gradient(to right, #1FB8C3, #7977F7) border-box;}

.inof_icn {
  position: absolute;
  margin-left: 10px;
  color: #1C1B1F;
  font-size: 12px;
  right: -10px;
  top: 3px;
  z-index: 9;
}
.inof_icn img{width: 12px;object-fit: contain;}
.inof_icn svg path {
  stroke: #1C1B1F;
  stroke-width: 1px;
  font-weight: 500;
}
.budget_job_inner_btm{position: relative;display: flex;align-items: center;justify-content: space-between;width: 100%;}
.budget_job_inner_btm_left{position: relative;    flex: 0 0 70%;text-align: left;}
.budget_job_inner_btm_left p{position: relative;margin-bottom: 10px;font-size: 14px;font-weight: 400;color: #212529;}
.budget_job_inner_btm_left input{position: relative;height: 47px;width: 100%;padding: 0 15px;color: #949494;font-size: 12px;font-weight: 500;background: #F8F8F8;border-radius: 6px;border: none;}
.brdr_none{border: none;padding: 0px;margin: 0px;}
.save_enter_btn_main{position: relative;display: flex;align-items: center;justify-content: space-between;}
.draft_btn{position: relative;width: 160px;height: 50px;display: flex;align-items: center;justify-content: center;border: 2px solid #0B4A96;color: #0B4A96;font-size: 16px;font-weight: 500;border-radius: 30px;background: #fff;}
.save_btn{position: relative;width: 215px;height: 50px;display: flex;align-items: center;justify-content: center;border: 2px solid #083F82;color: #fff;font-size: 16px;font-weight: 500;border-radius: 30px;background: #083F82;}
.budget_job_inner_btm_right{position: relative; width: 70%;text-align: center;}

.custome_questions .modal-dialog{max-width: 880px;background: #fff;border-radius: 10px;padding: 30px;}
.custome_questions .modal-content{border: none;}
.custome_questions_inner{position: relative;background: #fff;margin-bottom: 50px;}
.custome_questions_inner_head{position: relative;}
.custome_questions_inner_head h6{position: relative;color: #000001;font-size: 18px;font-weight: 700;margin-bottom: 15px;}
.custome_questions_inner_head p{position: relative;color: #212529;font-size: 14px;font-weight: 400;margin-bottom: 20px;}
.custome_questions_inner_body{position: relative;}
.custome_questions_single{position: relative;height: 55px;padding: 15px;background: #F5F5F5;color: #000000;font-size: 14px;font-weight: 400;border-radius: 10px;display: flex;align-items: center;justify-content: space-between;width: 100%;margin-bottom: 10px;}
.custome_questions_single_left{position: relative;}
.custome_questions_single_left h6{position: relative;font-size: 14px;font-weight: 400;color: #000000;margin-bottom: 0px !important;}
.custome_questions_single_right{position: relative;}
.custome_questions_single_right ul{display: flex;align-items: center;gap: 15px;}
.custome_questions_single_right ul li{cursor: pointer;}
.custome_questions_single_right ul li a{font-size: 15px;color: #212529;}
.custome_questions_single_right ul li i{font-size: 15px;color: #212529;}
.add_ques_btn{position: relative;display: flex;align-items: center;justify-content: flex-end;}
.add_ques_btn button{position: relative;width: 155px;height: 40px;background: #EAEBEC;border-radius: 100px;color: #989FA4;font-size: 14px;font-weight: 700;display: flex;align-items: center;gap: 10px;border: none;justify-content: center;}
.active_btn{ background: #083F82 !important;color: #fff !important;}
.custome_questions_single_textarea_interview
.custome_questions_single_textarea{position: relative;margin-bottom: 10px;}
.custome_questions_single_textarea{position: relative;padding: 10px;color: #333;font-size: 14px;font-weight: 400;background: #F5F5F5;border-radius: 10px;width: 100%;border: none;}
.custome_questions_single_textarea:hover{position: relative;background: #F5F5F5;}

.edit-question-interview-input {
  width: 100%;
  margin-left: 20px;
  padding: 8px;
  border: 1px solid #9da6af;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.edit-question-interview-input:focus {
  border-color: #8c97a3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.3);
}


.add_cancel_btn_sec_modal{position: relative;display: flex;align-items: center;justify-content: space-between;}
.draft_btn_new{position: relative;width: 120px;height: 50px;display: flex;align-items: center;justify-content: center;border: 2px solid #0B4A96;color: #0B4A96;font-size: 16px;font-weight: 500;border-radius: 30px;background: #fff;}
.save_btn_new{position: relative;width: 180px;height: 50px;display: flex;align-items: center;justify-content: center;border: 2px solid #083F82;color: #fff;font-size: 16px;font-weight: 500;border-radius: 30px;background: #083F82;}
.ques_add_tag{position: relative;margin-top: 20px;}
.ques_add_tag span{position: relative;display: flex;align-items: center;gap: 10px;font-size: 14px;font-weight: 500;font-style: italic;color: #0B4A96;}
.budget_sec_tool{position: absolute;width: auto;background: #fff;padding: 10px;box-shadow: 0px 4px 28.6px 0px #00000014;
z-index: 11;border-radius: 10px;display: none;transition: 0.5s ease-in-out;margin-bottom: 0px !important;text-align: left;}
.budget_sec_tool h6{position: relative;color: #212529;font-size: 14px;font-weight: 400;margin-bottom: 0px !important;}
.inof_icn:hover .budget_sec_tool{display: block;}


.generate_modal_wriitten .modal-dialog{max-width: 540px;background: #DCEAFC;border-radius: 12px;padding: 25px;}
.generate_modal_wriitten .modal-content{border: none;}
.generate_modal_wriitten_inner{position: relative;background: #fff;padding: 30px;border-radius: 12px;}
.generate_modal_wriitten_inner_head{position: relative;display: flex;align-items: center;justify-content: space-between;width: 100%;margin-bottom: 20px;}
.generate_modal_wriitten_inner_head h6{position: relative;color: #212529;font-size: 16px;font-weight: 700;margin-bottom: 0px !important;}
.generate_modal_wriitten_inner_head button{position: relative;background: none;border:none;}
.generate_modal_wriitten_inner_head button i{font-size: 16px;color: #000;}
.generate_modal_wriitten_inner_body{position: relative;}
.generate_modal_select_box2{position: relative;display: grid;grid-template-columns: 2.5fr 2fr;grid-gap: 15px;}
.generate_modal_select_box{position: relative;display: grid;grid-template-columns: 2.5fr 1fr;grid-gap: 15px;}
.generate_modal_select_box_single{position: relative;}
.generate_modal_select_box_single label{position: relative;color: #3D4852;font-size: 12px;font-weight: 700;margin-bottom: 8px;display: flex;align-items: center;}
.generate_modal_select_box_single label pre{margin-bottom: 0px;color: #FF3434;}
.generate_modal_select_box_single select{width: 100%;position: relative;height: 40px;padding: 0 15px;color: #000000;font-size: 12px;font-weight: 500;border: 1px solid #EBEBEB;border-radius: 10px;}
.ques_add_tag_new{position: relative;margin-top: 10px;}
.ques_add_tag_new span{position: relative;display: flex;align-items: center;gap: 10px;font-size: 14px;font-weight: 500;font-style: normal;color: #038840;}

.enhance_new{position: relative;width: 52%;margin: 25px auto 0;}
.enhance_new button{position: relative; padding: 8px 24px;font-size: 16px;font-weight: 600;color: #000; text-decoration: none; border: none;border-radius: 30px;cursor: pointer;text-align: center; box-shadow: 0 0 0 2px transparent; background-clip: padding-box; border: 2px solid transparent;background: linear-gradient(#fff, #fff) padding-box,linear-gradient(to right, #1FB8C3, #7977F7) border-box;display: flex; align-items: center; justify-content: center; gap: 10px;}
.enhance_new button img{width: 16px;}

.generate_questions_modal .modal-dialog{max-width: 500px;background: #fff;border-radius: 12px;padding: 25px;}
.generate_questions_modal .modal-content{border: none;}
.generate_questions_modal_inner{position: relative;display: flex;align-items: center;gap: 20px;flex-direction: column;}
.generate_questions_modal_inner p{position: relative;color: #000000;font-size: 22px;font-weight: 500;margin-bottom: 0px !important;}
.generate_questions_modal_inner img{width: 44px;}



.written_test_modal_sec .modal-dialog{max-width: 1035px;background: #fff;border-radius: 12px;padding: 50px 50px;}
.written_test_modal_sec .modal-content{border: none;}
.written_test_modal_sec_inner{position: relative;}
.written_test_modal_sec_inner_head{position: relative;display: flex;align-items: center;justify-content: space-between;width: 100%;margin-bottom: 30px;}
.written_test_modal_sec_inner_head h6{position: relative;color: #000001;font-size: 20px;font-weight: 700;margin-bottom: 0px;}
.written_test_modal_sec_inner_head .enhance_new{width: auto;margin: 0px;}
.written_test_modal_sec_inner_body{position: relative;}
.written_test_modal_sec_bo_head{position: relative;display: flex;align-items: center;justify-content: space-between;width: 100%;margin-bottom: 20px;}
.written_test_modal_sec_bo_head_left{position: relative;display: flex;align-items: center;gap: 10px;}
.written_test_modal_sec_bo_head_left h6{position: relative;font-size: 16px;font-weight: 700;color: #212529;margin-bottom: 0px !important;}
.written_test_modal_sec_bo_head_left img{width: 24px;}
.written_test_modal_sec_bo_head_right{position: relative;}
.written_test_modal_sec_bo_head_right button{position: relative;width: 160px;height: 40px;display: flex;align-items: center;justify-content: center;gap: 10px;color: #3A3A3A;font-size: 14px;font-weight: 700;border-radius: 8px;border: 1px solid #CDCDCD;background: #fff;}
.written_test_modal_sec_inner_new{position: relative;height: 380px;max-height: 400px;overflow-y: scroll;padding-right: 10px;margin-bottom: 30px;}
.written_test_modal_sec_inner_single{position: relative;background: #F0F5FC;padding: 15px;border-radius: 10px;margin-bottom: 10px;display: flex;align-items: flex-start;gap: 20px;justify-content: space-between;}
.written_test_modal_sec_inner_single_text{position: relative;flex: 0 0 60%;}
.written_test_modal_sec_inner_single_text h4{position: relative;color: #000000;font-size: 14px;font-weight: 400;margin-bottom: 5px !important;}
.written_test_modal_sec_inner_single_text ul{display: block;}
.written_test_modal_sec_inner_single_text ul li{display: block;color: #000000;font-size: 14px;font-weight: 400;margin-bottom: 0px !important;}
.written_test_modal_sec_inner_single_text ul li:last-child{margin-bottom: 0px !important;}
.written_test_modal_sec_inner_single_icons{position: relative;}
.written_test_modal_sec_inner_single_icons ul{display: flex;gap: 15px;align-items: center;}
.written_test_modal_sec_inner_single_icons ul li a{font-size: 16px;color: #000616;}
.written_test_modal_sec_inner_single_icons ul li button{position: relative;width: 140px;height: 32px;color: #4E5862;font-size: 14px;display: flex;align-items: center;gap: 10px;justify-content: center;background: #fff;border: 1.5px solid #9DB5D7;border-radius: 30px;font-weight: 700;}

.written_test_modal_sec_inner_single_text input{position: relative;width: 100%;border: none;background: none;
  color: #a8a8a8;font-size: 14px;padding: 0 0 10px 0;}
.written_test_modal_sec_inner_single_text ul li input{position: relative;width: 100%;border: none;background: none;
  color: #a8a8a8;font-size: 12px;padding: 0 0 2px 0;}

.custom_list {
    list-style: none;
    counter-reset: list-counter;
}

.custom_list li {
    counter-increment: list-counter;
    margin-bottom: 2px!important;
    padding-left: 20px;
    position: relative;
}

.custom_list li::before {
    content: counter(list-counter, lower-alpha) ". ";
    font-weight: 500;
    position: absolute;
    left: 0px;
    font-size: 13px;
    color: #a8a8a8;
    top: 2px;
}

.written_test_modal_sec_inner_new::-webkit-scrollbar {
  width: 4px;
}

.written_test_modal_sec_inner_new::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #D9D9D9;
  border-radius: 10px
}

.written_test_modal_sec_inner_new::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px
}

.written_test_modal_sec_inner_new::-webkit-scrollbar-thumb:hover {
  background: #D9D9D9
}

.add_que_sec_new{position: absolute;width: 500px;padding: 15px;box-shadow: 0px 1px 20.1px 0px #00000014;border-radius: 10px;z-index: 999;background: #fff;right: 0;}
.add_que_sec_new h3{position: relative;color: #3A3A3A;font-size: 14px;font-weight: 700;margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #E8E8E8;line-height: inherit;}
.ques_list_sec{position: relative;}
.ques_list_sec li{position: relative;display: flex;align-items: center;justify-content: space-between;width: 100%;gap: 10px;margin-bottom: 15px;}
.ques_list_sec li:last-child{margin-bottom: 30px;}
.ques_list_sec_text2{position: relative; display: flex; justify-content: center; width: 100%;}
.ques_list_sec_text{position: relative;}
.ques_list_sec_text h6{position: relative;margin-bottom: 0px;font-size: 12px;font-weight: 700;margin-bottom: 0px !important;color: #008D4A;}
.add_questionj_tag{position: relative;display: flex;align-items: center;justify-content: center;width: 115px;height: 30px;background: #0B4A96;border-radius: 30px;border: none;margin: 0 auto;font-size: 12px;color: #fff;}
.add_questionj_tag2{position: relative;display: flex;align-items: center;justify-content: center;width: 115px;height: 30px;background: #FF3434;border-radius: 30px;border: none;margin: 0 auto;font-size: 12px;color: #fff;}
.delete_modal_sec_new .modal-dialog{max-width: 800px;background: #fff;border-radius: 12px;padding: 50px;}
.delete_modal_sec_new .modal-content{border: none;}
.delete_modal_sec_new_inner{position: relative;text-align: center;display: flex;align-items: center;flex-direction: column;gap: 20px;}
.delete_modal_sec_new_inner p{position: relative;margin-bottom: 0px !important;font-size: 24px;font-weight: 700;color: #000;}
.delete_modal_sec_new_btns{position: relative;display: flex;align-items: center;gap: 15px;}
.cance_btn_mod{position: relative;width: 160px;height: 40px;display: flex;align-items: center;justify-content: center;border: 1.5px solid #9DB5D7;color: #4E5862;font-size: 14px;font-weight: 700;border-radius: 30px;}
.remove_btn_mod{position: relative;width: 160px;height: 40px;display: flex;align-items: center;justify-content: center;border: 1.5px solid #1865C1;color: #fff;font-size: 14px;font-weight: 700;border-radius: 30px;background: #1865C1;}

.overlay_sec_regenerate_box {
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 20%);
  z-index: 11;
  width: 100%;
  height: 360px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.overlay_sec_regenerate_box_inner{position: relative;display: flex;align-items: center;gap: 10px;flex-direction: column;width: 30%;margin: 0 auto;background: #fff;padding: 15px 0;border-radius: 10px;}
.overlay_sec_regenerate_box_inner p{position: relative;color: #000000;font-size: 18px;font-weight: 500;margin-bottom: 0px !important;}
.overlay_sec_regenerate_box_inner img{width: 30px;}

.written_test_duration_sec{position: relative;display: flex;align-items: center;justify-content: space-between;      margin-top: 20px;}
.written_test_duration_sec_left{position: relative;text-align: left;}
.written_test_duration_sec_left h4{position: relative;color: #000001;font-size: 16px;font-weight: 700;line-height: inherit;margin-bottom: 7px !important;}
.written_test_duration_sec_left p{position: relative;color: #212529;font-size: 14px;font-weight: 400;margin-bottom: 0px !important;}
.written_test_duration_sec_left p span{font-style: italic;}
.written_test_duration_sec_right{position: relative;}
.written_test_duration_sec_right_inner{position: relative;display: flex;align-items: center;gap: 10px;}
.inc_btn{position: relative;border: none;background: none;color: #000;font-size: 24px;font-weight: 600;padding:0 10px;}
.count_btn_sec{position: relative;width: 65px;height: 33px;background: #F8F8F8;color: #212529;font-size: 14px;font-weight: 400;display: flex;align-items: center;justify-content: center;border-radius: 10px;}

.carousel_main_sec_body{position: relative;display: block;grid-template-columns: 1fr;}
.carousel_main_sec_body_single{position: relative;width: 90%;margin: 0 auto;}
.carousel_main_sec_body_single img{width: 100%;}
/* .ant-form-item-explain-error{position: absolute;bottom: 65px; right: 0; left: 0;} */

.carousel_main_sec_body .owl-carousel .owl-nav {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.carousel_main_sec_body .owl-carousel .owl-nav .owl-prev{position: relative;width: 24px;height: 24px;border-radius: 4px;display: flex;align-items: center;justify-content: center;background: #CDDAEB;line-height: 24px;}
.carousel_main_sec_body .owl-carousel .owl-nav .owl-prev span{color: #000;font-size: 14px;font-weight: 500;}
.carousel_main_sec_body .owl-carousel .owl-nav .owl-next{position: relative;width: 24px;height: 24px;border-radius: 4px;display: flex;align-items: center;justify-content: center;background: #CDDAEB;line-height: 24px;}
.carousel_main_sec_body .owl-carousel .owl-nav .owl-next span{color: #000;font-size: 14px;font-weight: 500;}

.carousel_main_sec_body .owl-carousel .owl-dots{position: relative;display: flex;align-items: center;justify-content: center;gap: 10px;top: 15px;}
.carousel_main_sec_body .owl-carousel .owl-dots .owl-dot{position: relative;height: 8px;width: 8px;background: #97C0F3;border-radius: 50%;}
.carousel_main_sec_body .owl-carousel .owl-dots .owl-dot.active{background: #0B4A96;}

.new_budget_info{right: -20px;}
.new_budget_info .budget_sec_tool{width: 700px;}
.budget_job_inner_btm_left input{color: #000;}

.box_sec_new{width: 100%;position: relative;height: 40px;padding: 0 15px;color: #000000;font-size: 12px;font-weight: 500;border: 1px solid #EBEBEB;border-radius: 10px;gap: 10px;justify-content: space-between; display: flex;align-items: center;}


.new_ques_type .checkbox-dropdown {
  position: relative;
  width: 100%;
  margin: 0px;
}

.new_ques_type .dropdown-label {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 5px;
  display: block;
}

.new_ques_type .dropdown-header {
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_ques_type .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 10px;
  display: block;
  z-index: 100;
}

.new_ques_type .dropdown-option {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.new_ques_type .dropdown-option input {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.new_ques_type .dropdown-option:last-child {
  margin-bottom: 0;
}

.new_ques_type .arrow {
  font-size: 12px;
  color: #000;
}
.main_sugg_ques{}
.selected_options_box{position: relative;margin-top: 10px;}
.selected_options_box ul{display: flex;align-items: center;gap: 10px;flex-wrap: wrap;}
.selected_options_box ul li{display: flex; align-items: center; padding: 4px 12px;background: #fff;border: 1px solid #0B4A96;border-radius: 20px;font-size: 10px; font-weight: 600;color: #0B4A96;}
.remove_icon{font-size: 12px; font-weight: 600;color: #0B4A96;margin-left: 10px;}


@media screen and (min-width: 1400px) and (max-width: 1500px) {
      .qc_body_radio .container .checkmark:after {
          top: 0px;
          left: 0px;
          right: 0;
      }
}

  
@media screen and (min-width: 1200px) and (max-width: 1300px) {
      .qc_body_radio .container .checkmark:after {
          top: 1px;
          left: 0px;
          right: 0;
      }
}




.new_budget_sec_box{width: 700px;}
.new_budget_sec_box h6{line-height: inherit;}
.custom_class_required .ant-form-item-explain {position: absolute;bottom: 35px;left: 14px;text-align: left;}
#credits_alloted_help .ant-form-item-explain-error{text-align: left;top: auto;position: absolute;bottom: 45px;}


@media screen and (min-width: 900px) and (max-width: 1199px) {
  .modal_main_sec .modal-dialog{max-width: 850px;padding: 30px;}
  .budget_sec_tool{    width: 200px;overflow: scroll;}
}




@media only screen and (max-width: 767px) {
  .recriuter_step_2_inner_top{padding: 15px;flex-direction: column;gap: 15px;align-items: flex-start;}
  .recriuter_step_2_inner_btm{padding: 10px;}
  .recriuter_step_2_inner_btm_single_top{align-items: flex-start;flex-direction: column;}
  .recriuter_step_2_inner_btm_single_top_left h5{font-size: 12px;}
  .recriuter_step_2_inner_btm_single_top_left h5 pre {font-size: 10px;display: inline-block;overflow: visible;}
  .recriuter_step_2_inner_btm_single_top_left h5 button{font-size: 10px;margin-left: 5px;}
  .recriuter_step_2_inner_btm_single_top_right h6{font-size: 10px;}
  .recriuter_step_2_inner_btm_single_btm{grid-template-columns: 1fr;grid-gap: 10px;}
  .recriuter_step_2_inner_btm_single_btm_inner p{flex: auto;font-size: 12px;}
  .recriuter_step_2_inner_btm_single_btm_inner{gap: 10px;flex-direction: column;}
  .fa_plus_btn {padding: 6px 18px;font-size: 12px;}
  .budget_job_inner_btm{flex-direction: column;gap: 20px;}
  .budget_job_inner_btm_left p{font-size: 12px;}
  .budget_job_inner_btm_left input{height: 40px;}
  #credits_alloted_help .ant-form-item-explain-error{bottom: 0px;}
  .budget_job_inner_btm_right{width: 100%;margin-bottom: 20px;}
  .credit_summary{width: 100%;padding: 10px;}
  .modal_main_sec .modal-dialog {max-width: 100%; padding: 15px;}
  .custome_questions .modal-dialog {max-width: 100%;padding: 15px;}
  .custome_questions_inner_head h6{font-size: 14px;}
  .custome_questions_inner_head p{font-size: 12px;}
  .add_ques_btn button {width: 130px;height: 35px;font-size: 12px; gap: 5px;}
  .draft_btn {width: 120px;height: 40px;font-size: 12px;}
  .save_btn{width: 150px;height: 40px;font-size: 12px;}
  .custome_questions_single{height: 50px;padding: 10px;font-size: 12px;}
  .custome_questions .custome_questions_single{gap: 10px;}
  .overlay_sec_regenerate_box_inner{padding: 15px;}
  .overlay_sec_regenerate_box_inner p{font-size: 12px;}
  .custome_questions_single_right ul li i{font-size: 12px;}
  .generate_modal_wriitten .modal-dialog {max-width: 100%;padding: 10px;}
  .generate_modal_wriitten_inner{padding: 12px;}
  .generate_modal_wriitten_inner_head h6{font-size: 12px;}
  .enhance_new button { padding: 8px 15px;font-size: 12px;}
  .enhance_new{width: 65%;}
  .written_test_modal_sec .modal-dialog{max-width: 100%;padding: 15px;}
  .written_test_modal_sec_inner_head h6{font-size: 14px;}
  .written_test_modal_sec_inner_head{flex-direction: column;}
  .written_test_modal_sec_bo_head{flex-direction: column;gap: 10px;align-items: center;}
  .written_test_modal_sec_bo_head_left h6{font-size: 12px;}
  .written_test_modal_sec_inner_single{flex-direction: column-reverse;}
  .written_test_modal_sec_inner_single_icons ul li button {width: 110px;height: 30px;font-size: 10px;}
  .written_test_modal_sec_inner_single_text ul li{font-size: 12px;}
  .written_test_modal_sec_inner_single_text{flex: 0 0 100%;}
  .written_test_duration_sec{flex-direction: column;}

}

.enhance22{display: flex; justify-content: center; margin-top: 20px;}
.enhance22 button{position: relative; padding: 2px 2px;font-size: 12px;font-weight: 600;color: #000; text-decoration: none; border: none;border-radius: 30px;cursor: pointer;text-align: center; box-shadow: 0 0 0 2px transparent; background-clip: padding-box; border: 2px solid transparent;background: linear-gradient(#fff, #fff) padding-box,linear-gradient(to right, #1FB8C3, #7977F7) border-box;display: flex; align-items: center; justify-content: center; gap:5px;}
.enhance22 button img{width: 12px;}