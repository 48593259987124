.applicant_profile_left_section {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px #0000001a;
}

.application_image_name {
  display: flex;
  align-items: center;
}

.applicant_profile_image {
  flex-shrink: 0;
  width: 137px;
  overflow: hidden;
  border-radius: 6px; /* Changed to make it a square with rounded corners */
  margin-right: 20px;
}

.applicant_profile_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.Myprofile_mt {
  margin-top: 72px;
  /* padding-top: 24px; */
}

.profile_description {
  flex-grow: 1;
}

.Professional_section_para_head {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.pageHeading {
  font-size: 24px;
  color: black;
  margin-bottom: 15px;
}
.applicant_profile_left_section_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_contact_details {
  display: grid;
  width: 600px;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
}
.application_image_name {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
.profile_description h4 {
  margin-bottom: 10px;
  font-size: 25px;
  color: black;
}
.profile_description p {
  margin-bottom: 10px;
  font-size: 20px;
  color: #7c8493;
}
.profile_contact_details h5 {
  margin-bottom: 10px;
  font-size: 18px;
  color: black;
}
.Professional_section_grey_conatainer {
  background: #f5f5f5;
  padding: 20px 20px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.info {
  margin-right: 10px;
}
.detail_section {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.detail_section img {
  margin-right: 10px;
}
.detail_section p {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  color: black;
}
.more_details{
  line-height: normal;
  font-size: 24px !important;
}
.profile_image{
  line-height: normal;
  font-size: 24px !important;
}
.custom_modal_container .btn-close{
 font-size: 12px;
}
.detail_section p:first-child {
  color: #7c8493;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.pdf_downloader {
  color: #007bff;
  border-radius: 7px;
  padding: 20px 16px;
  border: 1px solid #d6ddeb;
  width: auto;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
}
.pdf_downloader .pdf_line {
  border: none;
  color: black !important;
}
.edit_profile_btn-width {
  border: #1865c1;
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: right;
}
.upload-label span {
  cursor: pointer;
  color: #1865c1;
  font-size: 18px;
  font-weight: 500;
}

.pdf_downloader img {
  margin-right: 10px;
}
.detail_section_list li {
  color: #1865c1;
  font-size: 16px;
  margin-bottom: 5px;
}
.websites_details {
  font-size: 16px;
  font-weight: 400;
  color: #7c8493;
  margin-bottom: 5px;
}
.personal_information_section {
  margin-top: 50px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;
  padding: 20px 30px;
}
.Profile_head {
  font-size: 20px;
  font-weight: 700;
}
.personal_information_section_head {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6ddeb;
}
.Professional_section_para {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700;
  color: black;
  margin-bottom: 10px;
}
.edit_profile_btn {
  float: right;
  clear: both;
  border-radius: 50px;
  border: 2px solid #1865c1;
  background: #fff;
  font-size: 15px;
  color: #1865c1;
  font-weight: 500;
  padding: 6px 20px;
}
.edit_profile_btn:hover {
  color: #fff;
  background: #1865c1;
  border: 2px solid #1865c1;
}
.edit_profile_btn i {
  font-size: 12px;
  margin-right: 5px;
}
.total_years {
  font-size: 14px;
  color: #7c8493;
}
.save-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
}

.saveContinue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
}
.ProfileForm .profile_label {
  font-size: 16px !important;
  color: black !important;
  height: 30px;
}

.required-asterisk{
  color: #e30202;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pdf_downloader {
    padding: 20px 20px;
    margin-right: 20px;
    width: 220px;
    font-size: 14px;
    margin-top: 20px;
  }
  .profile_description h4 {
    font-size: 22px;
  }
  .profile_description p {
    font-size: 14px;
  }
  .detail_section {
    margin-bottom: 15px;
  }
  .applicant_profile_left_section {
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pdf_downloader {
    padding: 20px 20px;
    margin-right: 20px;
    width: 220px;
    font-size: 14px;
    margin-top: 20px;
  }
  .profile_description h4 {
    font-size: 22px;
  }
  .profile_description p {
    font-size: 14px;
  }
  .detail_section {
    margin-bottom: 15px;
  }
  .applicant_profile_left_section {
    padding: 15px;
  }
  .applicant_profile_image{
    margin-right: 0px;
  }
  .application_image_name{
    gap:10px;
  }
  .pdf_downloader{
    margin: 0px;
    padding: 10px;
    font-size: 12px;
  }
  .pdf_downloader img{
    margin-right: 5px;
  }
  .edit_profile_btn{
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .pageHeading {
    font-size: 18px;
  }
  .applicant_profile_left_section {
    padding: 15px;
  }
  .applicant_profile_left_section_inner {
    flex-direction: column;
  }
  .application_image_name {
    gap: 15px;
    align-items: flex-start;
    flex-direction: column;
  }
  .profile_description h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .profile_description p {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: inherit;
  }
  .applicant_profile_left_section_inner .align-items-baseline {
    gap: 10px;
  }
  .pdf_downloader {
    padding: 10px;
    margin-right: 40px;
    width: 100%;
    font-size: 12px;
    margin-top: 15px;
  }
  .personal_information_section {
    padding: 15px 10px;
  }
  .Professional_section_head {
    font-size: 16px;
  }
  .edit_profile_btn {
    font-size: 12px;
    padding: 0px 15px;
  }
  .Professional_section_para {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .detail_section {
    display: block;
    margin-bottom: 10px;
  }
  .detail_section p:first-child {
    font-size: 12px;
  }
  .detail_section p {
    font-size: 12px;
  }
  .profile_contact_details {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .websites_details {
    font-size: 12px;
  }
  .detail_section_list li {
    font-size: 12px;
  }
  .applicant_profile_image{
    margin-right: 0px;
    width: auto;
  }
  .applicant_profile_image img{
    width: 100px;
  }
  .application_image_name{
    display: flex;
    flex-direction: row;
    width: 90%;
  }
  .profile_description h4{margin-bottom:5px !important}
  .ProfileForm .profile_label{
    font-size: 14px !important;
    margin-left: 0px !important;
  }
  .ProfileForm .main-label{
    margin-left: 0px !important;
  }
  .personal_information_section{
    margin-top: 10px;
  }
  .company_detail_form input, .ant-select-selector, .textarea_generator{
    padding-left: 10px;
  }
  .edit_profile_btn i{
    font-size: 10px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .applicant_profile_left_section_inner {
    flex-direction: row;
    align-items: flex-start;
  }
  .profile_contact_details {
    grid-template-columns: 1fr 1fr;
  }
}
