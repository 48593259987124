.company_details_sec_new_inner_left {
  /* position: sticky; */
  top: 0px;
  /* width: 100%; */
  flex: 0 0 260px;
  /* overflow: hidden; */
  /* height: 100vh;
  height: auto;
  height: 650px; */
  /* background: #fff; */
  height: 100vh;
}

.company_details_sec_new_inner_left_list {
  position: relative;
  border-radius: 8px;
  padding: 30px 25px;
  position: fixed;
  height: 100vh;
  background: #fff;
  /* height: 600px; */


}

.company_details_sec_new_inner_left_list li {
  position: relative;
  padding-bottom: 40px;
  padding-left: 20px;
}

.company_details_sec_new_inner_left_list li a {
  position: relative;
  color: #252525;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.company_details_sec_new_inner_left_list li a img {
  width: 20px;
}

.company_details_sec_new_inner_left_list li:first-child {
  padding-left: 0px;
}

/* .company_details_sec_new_inner_left_list li:last-child {
  padding-top: 60px;
} */
.post_blue_btn {
  background: #1865c1;
  width: 100%;
  height: 56px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  gap: 10px !important;
  border: none;
}

.post_blue_btn span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: #ffffff;
  color: #1865c1;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 900 !important;
}

/* .company_details_sec_new_inner_left_list li a.active {
  font-weight: 700;
  color: #1865c1;
} */
/* .company_details_sec_new_inner_left_list li a.active img {
  fill: #1865c1;
} */
.blue_img {
  display: none;
}

.black_img {
  display: flex;
}

.company_details_sec_new_inner_left_list li a.active .black_img {
  display: none;
}

.company_details_sec_new_inner_left_list li a.active .blue_img {
  display: flex;
}

.mobile_sidebar_dash_main_sec {
  display: none;
}

.mobile_head_btn {
  display: none;
}

.sticky_left {
  position: fixed;
  top: 100px;
  width: 260px;
  /* height: fit-content;} */
}


.cross {
  display: none;
  width: 16px;
}

.Line {
  display: flex;
  width: 14px !important;
}

body.no-scroll {
  overflow: hidden;
}





@media only screen and (min-width: 992px) and (max-width: 1199px) {

  .company_details_sec_new_inner_left {
    width: 190px;
    flex: 0 0 190px;
  }
  .sticky_left{
    top: 100px;
    width: 190px;
  }

  .company_details_sec_new_inner_left_list {
    padding: 15px;
  }

  .company_details_sec_new_inner_left_list li a {
    font-size: 14px;
  }

  .post_blue_btn {
    height: 50px;
    font-size: 14px !important;
  }

  .post_blue_btn span {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }

  /* .comp_det_rigt_main.tab-content .row.d-flex.mb-3.align-items-baseline {
    flex-direction: column;
    gap: 20px;
  } */

  .Search_job input {
    width: 190px;
  }

  .new_menu_sec_jobs .text-center {
    width: 100px !important;
    font-size: 12px !important;
  }

  .new_menu_sec_jobs .col-4.d-flex.align-items-baseline.justify-content-end {
    justify-content: flex-start !important;
  }


}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .company_details_sec_new_inner_left_list{padding: 20px;}
  .company_details_single_card_box{padding: 12px 10px;}


}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .company_details_sec_new_inner_left {
    width: auto;
    flex: 0 0 190px;
  }
  .company_details_sec_new_inner_left_list li {
    padding-left: 0px;
  }


  .company_details_sec_new_inner_left_list {
    padding: 15px;
        position: fixed;
        top: 100px;
        width: 194px;
  }

  .company_details_sec_new_inner_left_list li a {
    font-size: 14px;
    gap: 10px;
  }

  .post_blue_btn {
    height: 50px;
    font-size: 14px !important;
  }

  .post_blue_btn span {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }

  /* .comp_det_rigt_main.tab-content .row.d-flex.mb-3.align-items-baseline {
    flex-direction: column;
    gap: 20px;
  } */

  .Search_job input {
    width: 190px;
  }

  .new_menu_sec_jobs .text-center {
    width: 100px !important;
    font-size: 12px !important;
  }

  .new_menu_sec_jobs .col-4.d-flex.align-items-baseline.justify-content-end {
    justify-content: flex-start !important;
  }

}


@media only screen and (max-width:767px) {

  .desk_top_version {
    display: none;
  }
  .mobile-ham{
    background-color: transparent;
    border: none;
    padding: 0px !important;
  }

  .mobile_sidebar_dash_main_sec {
    display: block;
  }

  .mobile_sidebar_dash {
    position: relative;
  }

  .mobile_sidebar_dash h6 {
    position: relative;
    font-size: 22px;
    font-weight: 700;
    color: #000000;

    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile_sidebar_dash h6 button {
    border: none;
    background: none;
    width: 16px;
    padding: 0px;
    top: -2px;
    position: relative;
  }

  .mobile_sidebar_dash h6 button img {
    width: 100%;
  }

  .mobile_sidebar_dash_main_sec {
    position: relative;
    display: flex;
    align-items: baseline;  
    gap: 10px;
    margin-bottom: 20px;
  }
  .Line {
    display: flex;
    width: 18px !important;
}
  .mobile_sidebar_dash_body_sec {
    position: absolute;
    width: 100%;
    background: #F5F9FF;
    padding: 0px;
    margin-top: 0px;
    z-index: 11;
    left: -10px;
    transition: 1s ease-in-out;
  }

  .left_place {
    transform: translateX(-300px);
    transition: 1s ease-in-out;
    opacity: 0;
  }

  .mobile_sidebar_dash_body_sec .company_details_sec_new_inner_left_list {
    background: #F5F9FF;
    padding: 15px;
    width: 60%;
  }

  .mobile_sidebar_dash_body_sec .company_details_sec_new_inner_left_list li {
    padding-bottom: 25px;
    padding-left: 0;
  }

  .mobile_sidebar_dash_body_sec .company_details_sec_new_inner_left_list li:last-child {
    padding-top: 0;
  }

  .mobile_sidebar_dash_body_sec .company_details_sec_new_inner_left_list li a {
    font-size: 14px;
    gap: 15px;
  }

  .company_details_sec_new_inner_left_list li a img {
    width: 18px;
  }

  /* .mobile_head_btn {
    position: relative;
    display: block;
    width: 60%;
        margin: auto;
  } */

  .mobile_head_btn .post_blue_btn {
    margin-bottom: 25px;
  }

  .mobile_head_btn .post_blue_btn {
    font-size: 15px !important;
    height: 42px;
  }

  .mobile_head_btn .post_blue_btn span {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }

  .post_blue_btn {
    height: 45px;
    font-size: 14px !important;
  }

  .post_blue_btn span {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }


  .company_details_sec_new_inner_left_list{
    padding: 0px;
  }

  .company_details_sec_new_inner_left_list li{
    padding: 0px;
    margin-bottom: 20px;
}
.company_details_sec_new_inner_left_list li a p{
  margin-bottom: 0px;
}
.company_details_sec_new_inner_left_list li a{
  align-items: center;
}




}

