.question_container {
    display: flex;
    flex-direction: column;
    margin-left: -150px;
    width: 800px;
    gap: 10px;
    padding: 0px;
  }
  
  .question_header {
    margin-top: 20px;
    /* font-size: 16px; */
    /* font-weight: bold; */
    /* padding-bottom: 8px; */
  }
  
  /* .code_editor {
    padding: 12px;
    background-color: #272822;
    border-radius: 6px;
    min-height: 200px;
    font-family: monospace;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .code_editor .code_mirror_sec {
    width: 200px !important;
  } */



  .interview_mscq_module_inner_main{position: relative;box-shadow: 0px 2px 7px 0px #0000001f;padding: 10px;background: #fff;}

  
 