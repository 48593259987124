.under_review_main{
    background-color: white;
    border-radius: 6px;
    margin-top: 20px;
    text-align: center;
}
.under_review_main h4{
    font-size: 24px;
    font-weight: 700;
    color: black;
    margin-bottom: 10px;
}
.under_review_main p{
    margin: 0 auto;
    width: 60%;
    font-size: 16px;
    color: black;
}