.related_sec_new {
  position: relative;
  padding: 20px 0;
  padding-top: 80px;
}

.related-job-skeleton {
  margin-bottom: 20px;
}

.back_btn_main_sec {
  position: relative;
  margin-bottom: 20px;
  display: flex;
}
.disabled-button {
  background-color: #ccc; /* Grey background */
  color: #666; /* Darker text color for better contrast */
  pointer-events: none; /* Disable pointer events */
  cursor: not-allowed; /* Show a 'not allowed' cursor */
}
.app_btn_rel.disabled, .app_btn_rel:disabled {
  background-color: #ccc; /* Gray color */
  color: #666;
  cursor: not-allowed;
  pointer-events: none; /* Ensure no interaction is possible */
}

.back_btn_main_sec button {
  position: relative;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
}
.back_btn_main_sec button i {
  font-size: 16px;
}
.related_sec_new_inner {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}
.companyDetails_name h5{
  margin-bottom: 8px;
  margin-top: 5px;
  font-size: 16px;
}
.expand {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    
}
.related_sec_new_inner_left {
  position: relative;
}
.related_sec_new_inner_left_top {
  position: relative;
  box-shadow: 0px 4px 18px 0px #00000014;
  background: #fff;
  border-radius: 10px;
  padding: 25px 25px;
  margin-bottom: 25px;
}
.related_sec_new_inner_left_top_head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}
.related_sec_new_inner_left_top_head h6 {
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
}
.related_sec_top_right {
  position: relative;
}
.related_sec_top_right ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
.related_sec_top_right ul li {
  display: inline-block;
}
.share_btn_icn {
  position: relative;
  color: #777777;
  width: 20px;
  font-weight: 400 !important;
}
.app_btn_rel {
  position: relative;
  display: inline-block;
  background: rgba(11, 74, 150, 1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  padding: 7px 28px;
  border: none;
}
.related_sec_new_inner_left_top_body {
  position: relative;
}
.related_sec_new_inner_left_top_body ul {
  display: flex;
  gap: 50px;
  align-items: center;
}
.related_sec_new_inner_btm {
  position: relative;
  box-shadow: 0px 4px 18px 0px #00000014;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
}
.related_sec_new_inner_right {
  position: relative;
}
.related_sec_new_inner_right h2 {
  position: relative;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.related_sec_new_inner_right .posted_jobs_main_sec_single {
  margin-bottom: 20px;
}
.sticky {
  position: sticky;
  top: 0;
  height: fit-content;
}
.share_prof_sec_mod .modal-dialog {
  max-width: 440px;
  background: #fff;
  border-radius: 10px;
  top: 30%;
}
.share_prof_sec_mod .modal-content {
  border: none;
}
.share_prof_sec_mod_inner {
  position: relative;
  padding: 30px 25px;
}
.share_prof_sec_mod_inner h6 {
  position: relative;
  color: #000000;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}
.share_prof_sec_mod_inner ul {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.share_prof_sec_mod_inner ul li {
  display: inline-block;
}
.share_prof_sec_mod_inner ul li a {
  position: relative;
  display: flex;
  height: 48px;
  width: 48px;
  flex: 0 0 48px;
  background: #f1f8ff;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.copy_link_sec {
  position: relative;
}
.copy_link_sec label {
  position: relative;
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: 'Satoshi-Variable';
}
.copy_link_sec_input {
  position: relative;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-gap: 8px;
}
.copy_link_sec_input input {
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: none;
}

.copy_link_sec_input input::-webkit-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.copy_link_sec_input input::-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.copy_link_sec_input input:-ms-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.copy_link_sec_input input:-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}


.copy_link_sec_input button {
  position: relative;
  width: 100%;
  height: 40px;
  background: #1865c1;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}
.close_icn_new_sh {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 50%;
  top: -65px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.related_sec_new_inner_left_top_body {
  position: relative;
}
.related_sec_new_inner_left_top_body ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.job_banner{
  background: #EAF3FE;
  padding: 20px 40px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.job_banner p{
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 18px;
}
.job_banner_demo{
  background: #DCEAFC;
  padding: 13px 15px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.job_banner_demo p{
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 15px;
}

.related_sec_new_inner1 {
  position: relative;
  display: block;
}
.job_banner ul {
  list-style-type: disc; /* Adds bullets */
  padding-left: 20px; /* Adds space before bullets */
}

.job_banner li {
  margin-bottom: 10px; /* Increases space between list items */
  font-size: small;
}

.job_banner_demo ul {
  list-style-type: none; /* Adds bullets */
  padding-left: 20px; /* Adds space before bullets */
}

.job_banner_demo li {
  margin-bottom: 10px; /* Increases space between list items */
  font-size: small;
}

.related_jobs_new_btn_under {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.report_btn_new_app_jobs {
  position: relative;
}
.report_btn_new_app_jobs button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 125px;
  height: 38px;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  background: #00000024;
  justify-content: center;
  border: none;
}

.report_modal_sec .modal-dialog {
  max-width: 640px;
  background: #fff;
  border-radius: 60px;
  top: 10%;
}
.report_modal_sec .modal-content {
  position: relative;
  border: none;
  padding: 0px;
}
.report_modal_sec_inner {
  position: relative;
  padding: 30px;
}
.report_modal_sec_inner_head {
  position: relative;
}
.report_modal_sec_inner_head h6 {
  position: relative;
  color: #000000;
  font-size: 26px;
  font-weight: 500;
  padding: 15px 25px;
  border-bottom: 1px solid #0000005e;
}
.report_modal_sec_inner_head button {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -90px;
  border: none;
  right: 0;
  left: 0;
  margin: 0 auto;
  box-shadow: 0px 4px 4px 0px #0000000A;

}
.report_modal_sec_inner_head button i{font-size: 20px;color: #000;}
.report_modal_sec_inner_body {
  position: relative;
  padding: 5px 25px 25px 25px;
}
.report_modal_sec_inner_body h5 {
  position: relative;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px !important;
}
.report_modal_sec_inner_body h3 {
  position: relative;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}

.report_modal_sec_inner_body p {
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.report_mod_list_new {
  position: relative;
  display: block;
}
.report_mod_list_new li {
  display: block;
  margin-bottom: 20px;
}

.report_mod_list_new li .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
}

.report_mod_list_new li .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.report_mod_list_new li .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1.5px solid #1865c1;
  border-radius: 50%;
}
.report_mod_list_new li .container:hover input ~ .checkmark {
  background-color: #fff;
}
.report_mod_list_new li .container input:checked ~ .checkmark {
  background-color: #fff;
}
.report_mod_list_new li .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.report_mod_list_new li .container input:checked ~ .checkmark:after {
  display: block;
}
.report_mod_list_new li .container .checkmark:after {
  top: 3.5px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1865c1;
}
.describe_issue_sec {
  position: relative;
}
.describe_issue_sec h4 {
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
.describe_issue_sec textarea {
  position: relative;
  background: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  border: none;
}
.submit_btn_report_pop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.submit_btn_report_pop .enabledButton {
  position: relative;
  width: 110px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: #1865c1;
}
.log_ce_icn {
  height: 100px;
  width: 100px;
  object-fit: contain;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 10px;
}

.disabledButton {
  position: relative;
  width: 110px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: #b0b0b0;
  cursor: not-allowed;
}
.radio{
  display: flex;
  flex-wrap: wrap;
}
.modal-wd .checkbox-flex{
  display: flex !important;
}
.job_details_from label{
  display: flex !important;
}
.job_details_from .ant-form-item {
  margin-bottom: 30px !important;
}
.button_profile{
  display: flex;
  justify-content: space-between;
  
}
.button_profile button{
  border-radius: 30px ;
  background-color:  #1865c1;
  color: white;
  border: 1px solid #1865c1;
  padding: 6px 20px;
}
.report-icon{
  width: 20px;
}
.report-button{cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  color: #B3B3B3;
  position: absolute; 
  bottom: 20px;        
  right: 20px;         
  margin-left: auto;
  
}
.transp{
  background-color: transparent;
  border: 1px solid black;
  color: #000;
}

.transpnew{
  background-color: transparent !important;
  border: 1px solid black;
  color: #000 !important;
  padding: 10px 30px 10px 30px !important;
}
.pading_modal{
  padding: 40px 60px;
}
.modal-wd .modal-dialog {
  max-width: 850px !important;
}
.update_cur .ant-select-dropdown .ant-select-dropdown-hidden,.ant-select-dropdown-placement-topLeft {
  z-index: 9999 !important;
}
.related_sec_new_inner_left_top_body_left{position: relative;}
.new_relted_body{position: relative;display: flex;align-items: center;gap: 30px;}
.top_reletd_body{position: relative;padding-bottom: 20px;}
.top_reletd_body h6{position: relative;font-size: 20px;font-weight: 700;color:rgba(0, 0, 0, 1);margin-bottom: 0 !important;}
.top_reletd_body h6 span{font-size: 18px;font-weight: 400;margin-left: 30px;position: relative;display: inline-block;}
.top_reletd_body h6 span::before{content: '';height: 4px;width: 4px;background: rgba(0, 0, 0, 0.6);border-radius: 50%;position: absolute;top: 8px;left: -15px;}
.top_reletd_body_btm_sec{position: relative;margin-top: 20px;}
.top_reletd_body_btm_sec p{position: relative;margin-bottom: 0 !important;color:rgba(0, 0, 0, 1);font-size: 12px;font-weight: 400;}
.spacer30px{position: relative;height: 30px;}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .related_sec_new_inner_left_top_body ul{gap: 25px;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .related_sec_new_inner {
    grid-template-columns: 1fr;
  }
  .related_sec_new{
    padding-top: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related_sec_new_inner {
    grid-template-columns: 1fr;
  }
  .related_sec_new{
    padding-top: 24px;
  }
  .related_sec_new_inner_left_top_body ul li{
    font-size: 14px;
  }
  .related_sec_new_inner_left_top_head h6{
    font-size: 22px;
  }
  .modal-wd .modal-dialog{
    width: 80%;
  }
  .pading_modal{
    padding: 20px 30px;
  }
  .report_modal_sec_inner_head h6,.report_modal_sec_inner_body h3{
    font-size: 20px;
  }
  .report_modal_sec_inner_head h6{
    font-size: 20px;
  }
  .report_mod_list_new li .container,.report_modal_sec_inner_body p{
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .back_btn_main_sec button {
    font-size: 14px;
  }
  .back_btn_main_sec button i {
    font-size: 12px;
  }
  .related_sec_new_inner {
    grid-template-columns: 1fr;
  }
  .related_sec_new_inner_left_top {
    padding: 15px;
  }
  .related_sec_new_inner_left_top_head {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }
  .related_sec_new_inner_left_top_head h6 {
    font-size: 18px18px;
  }
  .share_btn_icn {
    width: 15px;
    display: block;
  }
  .app_btn_rel {
    font-size: 12px;
    padding: 5px 24px;
    border: none;
  }
  .related_sec_new_inner_left_top_body ul {
    display: grid;
    gap: 15px;
    align-items: center;
    grid-template-columns: 1fr;
  }
  .related_sec_new_inner_left_top_body ul li {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .soclia_meid_main_sec_body_single h5 {
    font-size: 16px;
  }
  .soclia_meid_main_sec_body_single p {
    font-size: 12px;
  }
  .pading_modal {
    padding: 15px 15px;
}
.modal_heading{
  font-size: 18px;
  margin-bottom: 0px;
}
.related_sec_new{
  padding-top: 24px;
}
.related_sec_new_inner_left_top_head h6{
  font-size: 18px;
}
.related_sec_new_inner_right h2{
  font-size: 18px;
}
.report_modal_sec_inner_head h6{
  font-size: 14px;
}
.report_modal_sec_inner_body h5{
  font-size: 14px;
  margin-bottom: 10px;
}
.report_mod_list_new li .container{
  font-size: 12px;
  padding-right: 0px;
  padding-left:24px;
  padding-top:3px;
}
.report-icon{
  width: 20px !important;
}
.report_mod_list_new li .checkmark{
  height: 15px;
  width: 15px;
  top: 5px;
}
.report_mod_list_new li .container .checkmark:after {
  top: 2.5px;
  left: 2.5px;
  width: 9px;
  height: 9px;
}
.describe_issue_sec h4,.report_modal_sec_inner_body h3{
  font-size: 14px;
  line-height: 30px;
}
.report_modal_sec_inner_head h6{
  padding: 15px;
}
.report_modal_sec_inner_head h6{
  margin-bottom: 0px;
}
.describe_issue_sec textarea,.report_modal_sec_inner_body p{
  font-size: 12px;
  margin-bottom: 0px;
}
.report_modal_sec_inner_body {
  position: relative;
  padding: 5px 15px 15px 15px;
}
.button_profile button{
  font-size: 12px;
}
.app_btn_rel {
  padding: 5px 14px;
}
.copy_link_sec_input{
  grid-template-columns:1fr;
}

.report_modal_sec_inner{padding: 20px;}


}



@media only screen and (min-width: 653px) and (max-width: 720px){
  .back_btn_main_sec{
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 690px) and (max-width: 830px){
  .related_sec_new_inner_left_top_body ul {
    display: flex;
    gap: 10px;
}
}

@media (min-width: 280px) and (max-width: 320px){
  .related_sec_new_inner_left_top_head{
    gap: 10px;
  }
  .related_sec_new_inner_left_top_head h6{
    font-size: 12px;
  }
  .related_sec_top_right ul{
    gap: 6px;
  }
  .back_btn_main_sec{
    margin-bottom: 0px;
  }
  .related_sec_new_inner_btm{
    padding: 15px;
  }
  .app_btn_rel {
    font-size: 9px;
}
.related_sec_new_inner_left_top{
  padding: 10px;
}
  .share_btn_icn {
    width: 13px;
  }
  .report-icon {
    width: 16px !important;
}
.related_sec_new_inner_right h2 {
  font-size: 14px;
  margin-bottom: 8px;
}
.shadow_pill::placeholder{
  font-size: 12px;
}
.salary-button{
  font-size: 9px;
  padding: 6px 6px;
}
.share_prof_sec_mod_inner ul{
  margin-bottom: 10px;
}
.share_prof_sec_mod_inner h6{
  font-size: 16px;
  margin-bottom: 10px;
}
.share_prof_sec_mod_inner{
  padding: 20px;
}
.copy_link_sec_input input{
  padding: 10px;
  font-size: 12px;
  height: auto;
}
.copy_link_sec_input{
  grid-template-columns:1fr;
}
}