@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../src/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('../src/fonts/Satoshi-Variable.woff') format('woff'),
       url('../src/fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
    font-family: 'Satoshi-VariableItalic';
    src: url('../src/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-VariableItalic.woff') format('woff'),
         url('../src/fonts/Satoshi-VariableItalic.ttf') format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Light';
    src: url('../src/fonts/Satoshi-Light.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Light.woff') format('woff'),
         url('../src/fonts/Satoshi-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('../src/fonts/Satoshi-LightItalic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-LightItalic.woff') format('woff'),
         url('../src/fonts/Satoshi-LightItalic.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Regular';
    src: url('../src/fonts/Satoshi-Regular.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Regular.woff') format('woff'),
         url('../src/fonts/Satoshi-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Italic';
    src: url('../src/fonts/Satoshi-Italic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Italic.woff') format('woff'),
         url('../src/fonts/Satoshi-Italic.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('../src/fonts/Satoshi-Medium.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Medium.woff') format('woff'),
         url('../src/fonts/Satoshi-Medium.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('../src/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-MediumItalic.woff') format('woff'),
         url('../src/fonts/Satoshi-MediumItalic.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Bold';
    src: url('../src/fonts/Satoshi-Bold.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Bold.woff') format('woff'),
         url('../src/fonts/Satoshi-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('../src/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-BoldItalic.woff') format('woff'),
         url('../src/fonts/Satoshi-BoldItalic.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Black';
    src: url('../src/fonts/Satoshi-Black.woff2') format('woff2'),
         url('../src/fonts/Satoshi-Black.woff') format('woff'),
         url('../src/fonts/Satoshi-Black.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('../src/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
         url('../src/fonts/Satoshi-BlackItalic.woff') format('woff'),
         url('../src/fonts/Satoshi-BlackItalic.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: italic;
  }







/*html {scroll-behavior: smooth;}*/



/*Rj Code*/

::selection {
    background:#0096C7;
    color:#FFF;
}

::-moz-selection {
    background:#0096C7;
    color:#FFF;
}

/*html {scroll-behavior: smooth;}*/
select::-ms-expand { display: none; }
img{max-width:100%;height:auto;}
a{  -webkit-transition: all .3s ease;-moz-transition: all .3s ease;-o-transition: all .3s ease;-ms-transition: all .3s ease;transition: all .3s ease;}
h1 {*margin:0px 0px 30px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h2 {*margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h3 {*margin:0px 0px 25px 0px;padding:0px;line-height: 46px;font-weight:600;}
h4 {*margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h5 {*margin:0px 0px 15px 0px;line-height: 25px;font-weight:600;}
h6 {*margin:0px 0px 15px 0px;line-height: 1.1;font-weight:normal;}



.spacer1px {clear:both;line-height:0;font-size:0;overflow:hidden;height:1px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer5px {clear:both;line-height:0;font-size:0;overflow:hidden;height:5px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer15px {clear:both;line-height:0;font-size:0;overflow:hidden;height:15px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer10px {clear:both;line-height:0;font-size:0;overflow:hidden;height:10px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer20px {clear:both;line-height:0;font-size:0;overflow:hidden;height:20px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer30px {clear:both;line-height:0;font-size:0;overflow:hidden;height:30px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer50px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer80px {clear:both;line-height:0;font-size:0;overflow:hidden;height:80px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer100px {clear:both;line-height:0;font-size:0;overflow:hidden;height:100px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}

body {
    overflow-x:hidden;
    color:#333333;
    font-size:16px;
    line-height:1.4; 
    margin:0px; 
    padding:0px; 
    outline:none;
    text-decoration:none;
    list-style:none;
    box-sizing: border-box;
    font-family: 'Satoshi-Variable' !important;
    background-color:#fff;
    /*height: 100vh;*/
    /* overflow: auto; */
}

p{line-height:1.4;margin:0px 0px 20px 0px;}
ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ant-pagination .ant-pagination-item{
border-radius: 0px;
}
a:hover{ text-decoration:none;}
-webkit-input-placeholder {color: #333 !important; opacity:1 !important;}
-moz-placeholder {color: #333 !important; opacity:1 !important;}
-ms-input-placeholder {color: #333 !important; opacity:1 !important;}
-moz-placeholder {color: #333 !important; opacity:1 !important;}
.form-control::-webkit-input-placeholder {color: #333 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.form-control::-moz-placeholder {color: #333 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.form-control:-ms-input-placeholder {color: #333 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.form-control:-moz-placeholder {color: #333 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}

:focus, button:focus {outline-style:none;box-shadow:none;border-color:inherit;font-family: 'Satoshi-Variable';}
*:focus {outline: inherit;}
input:focus{outline:inherit;}

.pagetitle{ position:relative; text-align:left; padding:0px 0px 70px 0px; }
.pagetitle h2{line-height: 1.1; font-size:51px;color:#fff;padding:0px; margin:0px 0px 0px 0px;}

img{max-width:100%;height:auto; outline:none;}
a{outline:inherit; border:inherit;text-decoration: none;}
.fl_w{float: left;}
.container-fluid{    padding-left: 60px;
  padding-right: 60px;
}


.new_sign_up_sec_box::-webkit-scrollbar {width: 20px;display: none;}
.new_sign_up_sec_box::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px;} 
.new_sign_up_sec_box::-webkit-scrollbar-thumb {background: red; border-radius: 10px;}
.new_sign_up_sec_box::-webkit-scrollbar-thumb:hover {background: #b30000;}

@media only screen and (min-width:1401px){
  .margin-lg{
    margin-top: 60px;
  }
}

@media only screen and (min-width:1301px) and (max-width:1400px) {
    
  .container-fluid {
      padding-left: 40px !important;
      padding-right: 40px !important;
  }
  .margin-lg{
    margin-top: 60px;
  }

}

@media only screen and (min-width:1200px) and (max-width:1300px) {
    
  .container-fluid {
      padding-left: 40px !important;
      padding-right: 40px !important;
  }
  .margin-lg{
    margin-top: 60px;
  }

}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .container-fluid { padding-left: 40px !important;padding-right: 40px !important;}
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .container-fluid { padding-left: 35px !important;padding-right: 35px !important;}
}

@media only screen and (max-width:767px) {
  .container-fluid { padding-left: 15px !important;padding-right: 15px !important;}
}
@media (min-width: 280px) and (max-width: 320px){
.spacer20px{
  height: 0px;
}
}