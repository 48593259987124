.header_font {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.recent_job_head {
  font-size: 22px !important;
}

.job_banner{
  background: #EAF3FE;
  padding: 20px 40px;
  border-radius: 10px;
}
.job_banner p{
  margin-bottom: 0px;
}
.company_details_sec_new {
  position: relative;
  /* padding: 30px 0px 0px 0px; */
  background: #f5f6fb;
  /* min-height: 100vh;
   */

  /* min-height: 100vh;
  overflow: hidden; */
  /* margin-top: 70px; */
}
.grilling{
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.company_details_sec_new::-webkit-scrollbar {
  width: 9px;
  display: none;
}

.company_details_sec_new::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc;
  border-radius: 10px;
}

.company_details_sec_new::-webkit-scrollbar-thumb {
  background: #1865c1;
  border-radius: 10px;
}

.company_details_sec_new::-webkit-scrollbar-thumb:hover {
  background: #1865c1;
}

.company_details_sec_new_inner {
  /* position: relative;
  display: flex; */
  /* display: grid; */
  /* grid-gap: 30px; */
  /* grid-template-columns: 1.2fr 5fr; */
  /* height: 100vh; */
  /* margin-bottom: 30px; */


}

.loader-skeleton {
  display: flex;
  align-items: center;
  gap: 1rem; /* Spacing between skeleton elements */
}

.skeleton-switch {
  width: 70px; /* Mimic the size of the 'Open' switch */
  height: 30px;
  border-radius: 15px; /* Fully rounded corners */
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-button {
  width: 100px; /* Placeholder for 'Edit' and 'Invite' buttons */
  height: 40px;
  border-radius: 20px; /* Rounded corners */
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}



.company_details_sec_new_inner_right {
  position: relative;
}

.company_details_sec_new_inner_right_head {
  position: relative;
}

.company_details_sec_new_inner_right_head h6 {
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.company_details_sec_new_inner_right_card {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
}

.company_details_single_card_box {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border-radius: 10px;
  padding: 20px 14px;
}

.card_bx_img {
  position: relative;
  height: 48px;
  width: 48px;
  background: #eff5ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 48px;
}

.card_bx_img img {
  width: 20px;
}

.card_bx_cont_sec {
  position: relative;
}

.card_bx_cont_sec h5 {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
}

.card_bx_cont_sec h6 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #252525;
  margin-bottom: 0px;
}

.add_com_det_sec {
  position: relative;
  width: 80%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 6px;
  padding: 90px 40px;
}

.btn_comp_sec {
  position: relative;
  width: 270px;
  margin: 0 auto;
}

.btn_comp_sec_btn {
  background: #1865c1;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  gap: 10px;
  border: none;
}

.btn_comp_sec_btn:hover {
  background: #ffffff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #1865c1;
  font-size: 16px;
  font-weight: 700;
  gap: 10px;
  border: 1px solid #1865c1;
}

.btn_comp_sec_btn span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: #ffffff;
  color: #1865c1;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 900 !important;
}

.add_com_det_sec p {
  position: relative;
  color: #252525;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.comp_det_rigt_main {
  position: relative;
  width: 100%;
  /* padding-left: 15px; */
  padding-right: 0px;
  /* padding-right: 10px; */
  padding-bottom: 0px;
  border-radius: 10px;
  margin-top: 100px;
  display: block;
  /* height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; */

  /* Adjustments for Firefox */
  /* Makes the scrollbar thinner */
  /* Thumb to blue and track to light gray */
}
.subscription_plan_mt{
  margin-top: 0px;
}
.view_jobs_min_sec_data {
  display: block;
}

/* Adjustments for Webkit (Chrome, Safari) */

.Hide {
  display: none;
}

.Show {
  display: block;
}

.add_company_popup_main .modal-dialog {
  max-width: 774px !important;
  position: relative;
  background: #fff;
  border-radius: 10px;
  top: 30%;
}

.add_company_popup_main_section {
  display: block;
}

.skip_to {
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.skip_to img {
  margin-left: 10px;
}

.skip_to:hover {
  color: #000;
  cursor: pointer;
  font-weight: 600;
}

.skip-arrow {
  font-size: 14px;
  color: white;
  /* Default color */
  margin-left: 5px;
  /* Adjust spacing as needed */
}

.skip_to:hover .skip-arrow {
  color: black;
  /* Color on hover */
  font-size: 14px;
}

.job_drafted {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job_drafted p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.company_details_sec_draft_main {
  padding: 20px;
  border-radius: 10px;
  background: #FFF;
  margin-bottom: 30px;
  position: relative;
}

.company_details_sec_draft_main::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #5B93FF 0%, #96C6FF 34.71%, #C2A3C5 64.52%, #F7797D 100.98%);
  border-radius: 0 0 10px 10px;
}

.view-job-btn {
  border: 2px solid #1865c1;
  color: #1865c1;
  background-color: #FFF;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
}

.view-job-btn:hover {
  border: 2px solid #1865c1;
  color: #FFF;
  background-color: #1865c1;
}


.recent_jobs_cont_jovs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort_bt_sec {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ant-table-cell{
  padding: 10px;
  /* font-size: 12px !important; */
}




@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comp_det_rigt_main {
    margin-top: 26px;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company_details_sec_new {
    height: 100vh;
  }
  .header_font{
    font-size: 20px;
  }
  .add_com_det_sec p{
    font-size: 16px;
  }
  .btn_comp_sec_btn{
    font-size: 14px;
  }
  .company_details_sec_new_inner {
    grid-gap: 15px;
  }

  .company_details_sec_new_inner_right_card {
    grid-template-columns: repeat(2, 1fr);
  }

  .add_com_det_sec {
    width: 100%;
    padding: 30px 20px;
  }
  .comp_det_rigt_main {
    margin-top: 0px;
}
.company_details_sec_new_inner {
  display: flex;
  flex-direction: column;
}
.company_details_sec_new_inner_left{
  height: 100%;
  display: flex;
        flex-direction: column;
        flex: 0;
}
.company_details_sec_new_inner_left_list{
  display: flex;
  height: auto;
  position: relative;
  top: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.post_blue_btn{
  width: 175px;
}
.company_details_sec_new_inner_left_list li{
  padding-bottom: 0px;
}
.sticky_left{
  position: relative;
}
.company_details_sec_new_inner_left_list li p{
  margin-bottom: 0px;
  padding-left:
  0px !important;
}
.add_company_popup_main .modal-dialog {
  max-width: 620px !important;
}
.btn_comp_sec{
  display: flex;
  justify-content: center;
}
}

@media only screen and (max-width: 767px) {
  .company_details_sec_new_inner {
    display: block;
  }
  .skip_to{
    font-size: 12px;
  }

  .company_details_sec_new {
    padding: 20px 0;
    height: 100%;
  }

  .company_details_sec_new_inner_right_head {
    display: none;
  }

  .company_details_sec_new_inner_right_card {
    grid-template-columns: repeat(1, 1fr 1fr);
  }

  .add_com_det_sec {
    padding: 20px;
    width: 100%;
    margin-top: 15px;
  }

  .add_com_det_sec p {
    font-size: 14px;
  }

  .btn_comp_sec {
    width: 100%;
    padding: 0 10px;
  }

  .btn_comp_sec_btn {
    height: 40px;
    font-size: 12px;
    gap: 5px;
  }

  .btn_comp_sec_btn span {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .comp_det_rigt_main {
    width: 100%;
    max-height: inherit;
    overflow-y: inherit;
    padding-left: 0;
    margin-top: 10px;
    padding-right: 0px;
  }

  .job_drafted {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .recent_jobs_cont_jovs {
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .sort_bt_sec {
    margin-bottom: 0px;
  }






  }


@media only screen and (min-width: 653px) and (max-width: 720px){
  .company_details_sec_new_inner_right_card {
    grid-template-columns: repeat(1, 1fr 1fr) ;
}
.company_details_sec_new {
  height: 100vh;
}
}

@media only screen and (min-width: 900px) and (max-width: 1199px){
  .company_details_sec_new_inner_right_card {grid-template-columns: repeat(4, 1fr);}
  .ant-table-cell, .ant-table-thead>tr>th{font-size: 14px;}

}
@media only screen and (min-width: 330px) and (max-width: 500px) {
  .company_details_single_card_box{
    display: flex;
    flex-direction: column;
  }
  .card_bx_cont_sec{
    text-align: center;
  }
}

.job-title-cell {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1300px) {
  .job-title-cell {
    max-width: 110px;
    font-size: 12px;
  }
  .ant-table-cell{
    font-size: 12px !important;
  }
  .ant-table-thead > tr > th{
    font-size: 12px !important; 
  }
}

@media screen and (max-width: 1000px) {
  .job-title-cell {
    max-width: 140px;
    font-size: 12px;
  }
}

@media screen and (max-width: 820px) {
  .job-title-cell {
    max-width: 100px;
  }
}

@media screen and (max-width: 680px) {
  .job-title-cell {
    white-space: unset;
    max-width: 100%;
  }
}