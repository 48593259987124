.term_policy{
    font-size: 20px;
    font-weight: 600;
}
.Term_head{
    font-size: 46px;
}
.terms-list{
    list-style-type: decimal; /* Ensure numbers are shown */
    text-align: left; /* Align text to the left */
    padding-left: 20px; /* Add some padding to ensure the numbers aren't too close to the edge */
  
  }
  .terms-list li{
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .sub-list {
    text-align: left;
    list-style-type: lower-alpha; /* Alphabetical markers */
  }
  .sub-list h5{
    margin-bottom: 5px;
  }
  .sub-list li{
    margin-bottom: 16px;
  }
  @media only screen and (max-width:767px) {
    .Term_head {
      font-size: 20px;
  }
  .term_policy
  {
    font-size: 14px;
  }
  .sub-list h5{
    font-size: 12px;
  }
  .sub-list li{
    margin-left: 20px;
  }
  .sub-list li ol li{
    margin-left: 0px;
  } 
  }