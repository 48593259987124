.heading {
    color: #007a33;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px !important;
    text-align: center;
  }

  .heading_failed {
    color: #C72500;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px !important;
    text-align: center;
  }
  