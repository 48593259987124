.accordion .accordion-item {
  border-radius: 8px;
  background: #fff;
  padding: 20px 40px;
  background-color: #fff;
  border: none !important;
}
.custom-gap {
  gap: 10px;
}
.responsive_filter {
  display: none;
}
.accordion-body,
.accordion-button {
  font-size: 16px;
  background: none !important;
}
.accordion-body {
  color: #000000;
}
.accordion-button {
  padding: 0px !important;
}
.accordion-body {
  padding: 15px 0 0 0;
  font-weight: 300;
}
.accordion-button:not(.collapsed) {
  background: #fff;
  border: none !important;
}
.custom_filter_componant {
  text-align: end;
  margin-top: 10px;
}
.FilterAi_btn {
  border-radius: 50px;
  border: 2px solid #1865c1;
  background: #fff;
  color: #1865c1;
  font-size: 16px;
  padding: 4px 20px;
  font-weight: 600;
  width: 140px;
}
/* .FilterAi_btn:hover
{
    border: 2px solid #1865C1;
    background: #1865C1;
    color: #FFF;
    background-image: url("../../../../assets/images/Filter.svg");
} */
.Assessment_p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.FilterAi_btn img {
  margin-right: 6px;
  margin-top: -4px;
}
.filter_section_main label {
  margin-bottom: 4px !important;
  font-size: 14px;
  font-weight: 500;
}
.filter_section_main
  :where(.css-dev-only-do-not-override-17sses9).ant-select
  .ant-select-arrow {
  top: 35%;
}
.filter_btn_main {
  display: flex;
  justify-content: center;
}
.clear_filter_btn {
  text-align: center;
  padding: 6px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  width: 140px;
  margin: 0 auto;
  background-color: #fff;
}
.company_logo {
  width: 150px;
}
.company_detail_main {
  display: flex;
  justify-content: space-between;
}
.company_address {
  display: flex;
  gap: 30px;
  padding: 0px;
  margin-bottom: 16px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.headingOne {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
}
.headingOne span {
  font-size: 16px;
  font-weight: 400;
}
.comp_data {
  font-size: 14px;
}
.doc_file {
  margin-right: 10px;
}
.doc_file img {
  width: 64px;
  height: 16px;
}

.job_des img {
  margin-right: 8px;
  width: 18px;
  height: 16px;
}
.job_des_wd img {
  height: 14px;
}
.job_des {
  font-size: 14px;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
}
.Requirement_list {
  list-style: bullet;
}
.Responsibilities_list,
.Requirement_list {
  list-style-type: disc; /* Use disc bullets */
  padding-left: 0px; /* Add some left padding to the list items */
}
/* #rc-tabs-0-panel-2{
    padding: 24px;
} */
.Transcript_main_pd {
  /* padding-left: 20px; */
}
.Transcript_main_action {
  overflow-y: scroll;
  height: 80%;
  padding-right: 8px;
}
.mr-left {
  margin-left: 20px;
}

.Responsibilities_list li,
.Requirement_list li {
  margin-bottom: 6px; /* Add space between each list item */
  font-size: 14px;
}
.job_details_main h4 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.salary_count {
  font-size: 14px;
  color: black;
}
.switch_lable_head label {
  font-size: 14px;
  font-weight: 700;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0px 20px;
}
.not-confirm {
  font-size: 16px;
  color: #1865c1;
  border: 1px solid #1865c1;
  padding: 12px 30px;
  border-radius: 50px;
  width: 150px;
}
.confirm {
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #1865c1;
  background-color: #1865c1;
  padding: 12px 60px;
  border-radius: 50px;
  width: 150px;
}
.confirm-closing {
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #e82709;
  background-color: #e82709;
  padding: 12px 60px;
  border-radius: 50px;
  width: 150px;
}
.stay_connect_sec {
  padding: 40px 20px;
}
.Qustion_mark img {
  margin-bottom: 20px;
}
.ant-switch-checked {
  background: #e82709 !important;
}
.test .ant-switch-checked {
  background: #fdfdfd !important;
}
.dowload_pdf_text {
  color: #1865c1;
}
.transcript_text {
  color: black;
  padding: 3px 10px;
}
.transcript_text:hover {
  color: black;
  cursor: pointer;
}
.custom-edit-button-class:focus {
  outline: none;
  border: 2px solid #1865c1 !important;
}

.accordion-button::after {
  background-position: 10px;
  background-size: 10px;
  content: "\f078";
  font-family: FontAwesome;
  background-image: none !important;
}
.down_button,
.up_button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view_jobs_min_sec_data .accordion-button::after {
  display: none;
}
.job_dm {
  margin-top: 30px;
}
.terminated {
  display: flex;
  gap: 10px;
  color: #dc0000;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
.terminated_p {
  margin-bottom: 10px;
}

.ant-switch.custom-switch {
  background-color: #c72500 !important;
}

/* Red background when checked */
.ant-switch.custom-switch.ant-switch-checked {
  background-color: #7eb88b !important;
}

.ant-tabs-content-holder {
  font-family: "Satoshi-Variable";
}
.termineted_section {
  background-color: hsla(0, 100%, 43%, 0.08);
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.view_jobs_min_sec_data .accordion-button::after {
  color: #000;
  /* position: absolute;
    bottom: -30px;
    right: 0;
    left: 0;
    margin: 0 auto; */
}

.profile-header {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  align-items: center;
}
.profile-section p {
  margin-bottom: 10px;
}
.profile-section {
  background: #f8f8f8;
  margin-bottom: 10px;
  padding: 10px;
}

.profile-warning {
  color: #ff891c;
  margin-top: 60px;
  margin-bottom: 30px;
}
.profile_base p {
  margin-bottom: 4px;
  font-size: 12px;
}

.profile-ctc {
  font-size: 20px;
  margin-bottom: 5px;
}
.profile_name {
  font-size: 18px;
  font-weight: 600;
}
.resumePdf {
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 5px;
  border: 0.6px solid #d6ddeb;
  cursor: pointer;
  color: #000;
  font-weight: 500;
}
.transcript_download_button {
  background: transparent;
  border: none;
}
.transcript_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.transcript_header_sub {
  display: flex;
  align-items: center;
  gap: 30px;
}
.trans_answer strong {
  color: rgba(51, 51, 51, 1);
  margin-bottom: 6px;
}
.trans_question strong {
  color: rgba(11, 74, 150, 1);
  margin-bottom: 6px;
}
.trans_question {
  position: relative;
  padding-left: 0px; /* Adjust the padding to make space for the line */
}

.trans_question::before {
  /* content: "";     UNCOMMENT TO ACTIATE THE LINE BESIDE THE TEXT */
  position: absolute;
  left: 0;
  top: 50%; /* Vertically center the line */
  transform: translateY(-50%);
  height: 100%; /* or specify a fixed height if you want */
  width: 5px; /* Adjust the width of the line */
  background-color: #309cff; /* The color of the line */
  z-index: 1; /* Ensure it is above other elements if necessary */
}

.trans_answer {
  position: relative;
  padding-left: 0px; /* Adjust the padding to make space for the line */
}

.trans_answer::before {
  /* content: "";  UNCOMMENT TO ACTIATE THE LINE BESIDE THE TEXT */
  position: absolute;
  left: 0;
  top: 50%; /* Vertically center the line */
  transform: translateY(-50%);
  height: 100%; /* or specify a fixed height if you want */
  width: 5px; /* Adjust the width of the line */
  background-color: #0557b9; /* The color of the line */
  z-index: 1; /* Ensure it is above other elements if necessary */
}
.trans_interpretation {
  position: relative;
  padding-left: 0px; /* Adjust the padding to make space for the line */
}
.trans_interpretation_inner {
  display: flex;
  justify-content: space-between;
}
.trans_interpretation_inner span {
  font-size: 12px;
  color: #858c93;
  font-weight: 600;
}
.trans_interpretation::before {
  /* content: ""; UNCOMMENT TO ACTIATE THE LINE BESIDE THE TEXT */
  position: absolute;
  left: 0;
  top: 50%; /* Vertically center the line */
  transform: translateY(-50%);
  height: 100%; /* or specify a fixed height if you want */
  width: 5px; /* Adjust the width of the line */
  background-color: #08bcd0; /* The color of the line */
  z-index: 1; /* Ensure it is above other elements if necessary */
}
.trans_interpretation strong {
  color: #08bcd0;
  margin-bottom: 6px;
}
.strong_answer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.pdf {
  border: none;
  color: #000;
}
.resumePdf img {
  margin-right: 4px;
  margin-top: -2px;
}
.profile-warning img {
  margin-right: 4px;
  margin-top: -2px;
}
.resumePdf a:hover {
  border: none;
  color: #1865c1;
}
.profile-ctc-value {
  font-size: 14px;
}

.profile-notice,
.profile-challenged {
  margin-top: 10px;
}
.bold_text {
  font-weight: 600;
}
.company_address.accordion-button
  [aria-expanded="true"]
  .view_jobs_min_sec_data
  .accordion-button::after {
  color: #e82709;
}

.currency {
  color: #00c838;
  font-size: 16px;
  font-weight: 700;
  margin-right: 8px;
}
.invite_modal_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invite_modal .invite_modal_head {
  position: relative;
  color: #000000;
  font-size: 26px;
  font-weight: 500;
  border-bottom: 1px solid #0000005e;
}
.invite_modal .invite_modal_head h6 {
  padding: 15px 25px;
  border-bottom: none;
  margin-bottom: 0px;
}
.invite_mod_inner {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
}
.Invite_btn {
  width: 120px;
  height: 45px;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #d9d9d9;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.sub_footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.sub_footer p {
  margin-bottom: 0px;
}
.invite_btn_icon {
  margin-left: 10px;
}
.copylink {
  color: #1865c1 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.copylink i{margin-right: 5px;font-size: 16px;}
.sub_footer p {
  font-size: 14px;
}


.ant-tabs-nav:before{border-bottom:none;}





@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .invite_modal .invite_modal_head h6 {
    font-size: 18px;
  }
  .copylink {
    font-size: 16px;
  }
  .filter_btn_cont {
    display: flex;
    gap: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 20px !important;
    margin-top: 0px;
  }
  /* .header .back-button{display: none;} */
  .accordion .accordion-item {
    padding: 10px;
  }
  .company_detail_main {
    flex-direction: column;
  }
  .new_comp_sec_data_sec .ant-form-item-row {
    margin-bottom: 0;
  }
  .new_comp_sec_data_sec .ant-form-item {
    margin-bottom: 0;
  }
  .headingOne {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .doc_file {
    display: none;
  }
  .company_address {
    gap: 20px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  .company_address::after {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .comp_data {
    font-size: 12px;
  }
  #collapseOne p {
    font-size: 12px;
  }
  .Responsibilities_list li,
  .Requirement_list li {
    font-size: 12px;
  }
  .mt-3 {
    position: relative;
  }
  .view_jobs_min_sec_data .mt-3 .mb-3 {
    flex-direction: column;
  }
  .view_jobs_min_sec_data .mt-3 .mb-3 .col-8 {
    flex-direction: column;
    gap: 15px;
  }
  .view_jobs_min_sec_data
    .col-4.d-flex.align-items-baseline.justify-content-end {
    justify-content: flex-start !important;
  }
  .responsive_filter {
    display: block;
  }
  .non-responsive-filter {
    display: none;
  }
  .sort-by-label::before {
    content: " ";
  }
  .sort_p1 {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .sort_p {
    display: none;
  }
  .custom-margin {
    display: none;
  }
  .responsive_status {
    width: 100%;
  }
  .FilterAi_btn img {
    width: 14px;
  }
  .reset_filter {
    font-size: 10px;
  }
  .filter_btn_cont {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .ant-drawer-header-title .ant-drawer-close {
    margin-right: 0px;
  }
  /* #rc-tabs-0-panel-2{
    padding: 24px;
} */
  .FilterAi_btn {
    border-radius: 50px;
    border: 2px solid #1865c1;
    background: #fff;
    color: #1865c1;
    font-size: 12px;
    padding: 4px 3px;
    font-weight: 600;
    width: 75px;
  }
  .company_logo {
    margin: 0 0 20px 0;
    width: 30%;
  }
  .accordion .accordion-item {
    padding: 15px;
  }
  .invite_mod_inner {
    display: flex;
    gap: 0px;
    flex-direction: row;
  }
  .new_invite_mod_sec_new .input {
    font-size: 16px;
  }
  .copylink {
    font-size: 12px !important;
  }
  .Invite_btn {
    width: 100px;
    padding: 6px 19px;
    height: auto;
  }
  .sub_footer p {
    font-size: 12px;
  }
  .my_comp_dashboard_left_edit i {
    display: none;
  }
  .my_comp_dashboard_left_edit button {
    height: 24px;
  }
  .rc-tabs-0-panel-3,
  .rc-tabs-0-panel-1 {
    padding: 10px !important;
  }
  .transcript_header_sub {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
  }
  .strong_pd {
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .profile_name {
    font-size: 14px;
  }
  .profile-header {
    margin-top: 10px;
  }
  .ant-tabs-left > .ant-tabs-content-holder {
    border-left: none;
  }
  .baseline_align {
    align-items: baseline;
  }
  .trans_interpretation_inner {
    display: flex;
    flex-direction: column;
  }
  .ant-switch-inner .ant-switch-inner-checked{
    font-size: 14px !important;
  }
  .custom-switch-size {width: 100px !important;height: 30px !important;font-size: 12px !important;line-height: 30px !important;}
  .custom-switch-size .ant-switch-handle { top: 8px !important; width: 15px !important; height: 15px !important;}
  


}

@media only screen and (min-width: 700px) and (max-width: 767px) {
}

.switch-label {
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }
}

.custom-switch-size {
  width: 120px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 18px;
  position: relative;
}

.custom-switch-size .ant-switch-handle {
  top: 9px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.custom-switch-size .ant-switch-inner {
  margin-top: 0px;
}

.custom-switch-size.ant-switch-checked {
  background-color: #4caf50 !important;
}

.custom-switch-size .ant-switch-inner-unchecked {
  margin-top: -40px !important;
}

.switch_lable_head .ant-form-item {
  margin-bottom: 0px;
}

.custom-switch-size.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 24px);
}

.custom-switch-size.ant-switch:not(.ant-switch-checked) {
  background-color: #f5222d !important;
}

.ant-switch:focus {
  box-shadow: none;
}
.ant-switch-inner .ant-switch-inner-checked{font-size: 16px !important;font-weight: 500;}
.ant-switch-inner .ant-switch-inner-unchecked{font-size: 16px !important;font-weight: 500;}


.new_invite_mod_sec_new .invite_modal_head {flex-direction: column;align-items: flex-start;gap: 10px;}
.new_invite_mod_sec_new .invite_modal_head h6{color: #212529;font-size: 20px;font-weight: 700;margin-bottom: 0px !important;line-height: inherit;padding: 0px;}
.new_invite_mod_sec_new .invite_modal_head p{color: #333333;font-size: 14px;font-weight: 500;margin-bottom: 0px !important;line-height: inherit;}
.new_invite_mod_sec_new .invite_modal .invite_modal_head{border-bottom: 1px solid #CDDAEB;padding-bottom: 15px;}
.share_job_new_report{margin-top: 15px;display: flex;align-items: center;justify-content: space-between;}
.share_job_new_report_left{display: flex;flex-direction: column;gap: 0px;}
.share_job_new_report_left h5{color: #333333;font-size: 16px;font-weight: 700;}
.share_job_new_report_left .share_prof_sec_mod_inner{padding: 0px;margin-bottom: 40px;}
.share_job_new_report_right{position: relative;}
.new_invite_mod_sec_new .share_prof_sec_mod_inner ul{margin: 0px;}
.new_invite_mod_sec_new .report_modal_sec_inner_body span{font-size: 16px;font-weight: 700;color: #333333;margin-bottom: 10px;display: block;}
.new_invite_mod_sec_new .input{border: none !important;background: #f8f8f8;height: 45px;padding: 0 20px;margin-top: 0px;color: #000;}
.new_invite_mod_sec_new .input::-webkit-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.new_invite_mod_sec_new .input::-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.new_invite_mod_sec_new .input:-ms-input-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.new_invite_mod_sec_new .input:-moz-placeholder {color: #999999 !important; opacity:1 !important;font-family: 'Satoshi-Variable';}
.new_invite_mod_sec_new .sub_footer p{font-weight: 500;color: #000;}
.new_invite_mod_sec_new .report_modal_sec_inner_body{padding: 0px;}