.resume_builder_sec{position: relative;padding: 0 0 0px 0;}
.resume_builder_sec_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;    align-items: center;    height: 90vh;}
.resume_builder_sec_inner_left{position: relative;display: flex;
  justify-content: center;}
.resume_builder_sec_inner_left img{width: 532px;height: 457px;}
.resume_builder_sec_inner_right{position: relative;width: 572px;height: 400px; padding: 60px 35px;background: #fff;box-shadow: 0px 4px 28.600000381469727px 0px #00000014;text-align: center;border-radius: 10px;display: flex;flex-direction: column;justify-content: center;}
.resume_builder_sec_inner_right h6{position: relative;color: #000000;font-size: 26px;font-weight: 700;margin-bottom: 35px !important;}
.resume_builder_sec_inner_right p{position: relative;font-size: 20px;color: #000;font-weight: 400;margin-bottom: 30px;}
.upload_resume_btn #myfile{display: none;}
.upload_resume_btn label { position: relative;display: flex; align-items: center; justify-content: center; height: 51px; width: 49%; background: #0B4A96;border: none; border-radius: 50px; font-size: 16px;color: #fff; font-weight: 500; margin: 0 auto 10px;cursor: pointer;}
.upload_resume_btn .resume_link_btn { position: relative;display: flex; align-items: center; justify-content: center; height: 60px; width: 80%; background: #fff;border: 2px solid #000; border-radius: 50px; font-size: 16px;color: #000; font-weight: 500; margin: 0 auto 10px;}
.enahce_btn_new .link_enhave{position: relative;display: block;display: flex; align-items: center; justify-content: center; height: 60px; width: 80%; background: linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%);border-radius: 50px; font-size: 16px;color: #fff; font-weight: 500;gap: 10px;margin: 0 auto;}

.verifictaion_mod .modal-dialog{ max-width: 500px;background: #fff;box-shadow: 0px 4px 28.600000381469727px 0px #00000014;padding: 30px 40px 80px 40px;border-radius: 10px;top: 5%;}
.verifictaion_mod .modal-content{border: none;}
.verifictaion_mod h6{position: relative;color: #2F2F2F;font-size: 32px;font-weight: 700;text-align: center;margin-bottom: 25px;}
.verifictaion_mod p{position: relative;color: #000;font-size: 17px;font-weight: 400;text-align: center;margin-bottom: 25px;}
.enter_otp_box_sec{position: relative;}
.enter_otp_box_sec label{position: relative;display: block;color: #455360;font-size: 15px;font-weight: 500;margin-bottom: 8px;}
.enter_otp_box_sec_inputs{position: relative;display: inherit;grid-template-columns: repeat(5,1fr);grid-gap: 10px;margin-bottom: 8px;}
.enter_otp_box_sec_inputs input{position: relative;width: 100%;height: 60px;background-color: #F8F8F8;display: flex;align-items: center;justify-content: center;font-size: 14px;font-weight: 500;color: #000;border: none;border-radius: 5px;padding: 0px;}
.resend_btn_new{position: relative;display: flex;align-items: center;justify-content: space-between;}
.resend_otp_btn_new{color: #1865c1;position: relative;font-size: 14px;font-weight: 400;}
.resend_otp_btn_new:disabled{
  color: #7B8389;
}
.resend_btn_new span{color: #7B8389;position: relative;font-size: 16px;font-weight: 500;display: inline-block;}
.resrt_inner{padding: 40px 50px;}
.phone_edit_sec{position: relative;margin: 0 0 25px 0;text-align: center;display: flex;align-items: center;justify-content: center;gap:10px}
.phone_edit_sec span{position: relative;font-size: 17px;font-weight: 500;color: #000000;}
.edit_link_btn{position: relative;font-size: 14px;color: #455360;font-weight: 500;}
.verifictaion_mod .enter_otp_box_sec_inputs{display: flex;}
.verifictaion_mod .enter_otp_box_sec_inputs input{text-align: center;}
.mobile_no_fileld{position: relative;margin: 20px 0;display: flex;align-items: center;gap: 20px;justify-content: center;}
.mobile_no_fileld select{position: relative;width: 60px;height: 50px;display: flex;align-items: center;justify-content: center;color: #000000;font-size: 16px;font-weight: 700;border-radius: 4px;border: none;background: #F8F8F8;}
.mobile_no_fileld input{position: relative;width: 285px;height: 50px;padding-left: 15px;border-radius: 4px;border: none;background: #F8F8F8;color: #000;font-size: 16px;}
/* .pdf_view_scan_modal{
  width: 500px;
  height: 800px;
  margin: 0 auto;
} */
.b-gg{
  padding: 20px;
  background-color: #717171;
  border-radius: 6px;
}
.b-gg1{
  background-color: none;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.resume-builder-header-back{
  margin-top: 20px;
}
/* .scan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.scan .fingerprint {
  position: relative;
  width: 100%;
  /* height: 80vh; */
}
.scan .fingerprint::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes animate {
  0%,
  100% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
}
.scan .fingerprint::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #7977F7;
  opacity: 0.6;
  filter: drop-shadow(0 0 20px #7977F7) drop-shadow(0 0 60px #7977F7);
  animation: animate_line 4s ease-in-out infinite;
  background: linear-gradient(356.54deg, rgba(226, 225, 255, 0.08) 5.17%, rgba(121, 119, 247, 0.8) 96.67%);
}
@keyframes animate_line {
  0%,
  100% {
    top: 0%;
  }
  50% {
    top: 90%;
  }
}
.scan h3 {
  text-transform: uppercase;
  font-size: 2em;
  letter-spacing: 2px;
  margin-top: 20px;
  color: #7977F7;
  filter: drop-shadow(0 0 20px #7977F7) drop-shadow(0 0 60px #7977F7);
  animation: animate_text 0.5s steps(1) infinite;
}
.textLayer{
  margin: 0 auto;
}
.react-pdf__Page__canvas{
  margin: 0 auto !important;
}
.react-pdf__Page{
  background-color: transparent !important;
}
@keyframes animate_text {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.inner_input_box_select input{
    background: #f8f8f8;
    border-radius: 5px;
    height: 50px;
}

@media only screen and (min-width: 992px) and (max-width:1199px) {

  .resume_builder_sec_inner {grid-template-columns: 1fr;}
  .resume_builder_sec_inner_left img{height: auto;}
  .resume_builder_sec_inner_left{display: none;}
  .resume_builder_sec_inner_right {width: 80%; margin: 50px auto;}
  





}


@media only screen and (min-width: 768px) and (max-width: 991px) {

  .resume_builder_sec_inner {grid-template-columns: 1fr;}
  .resume_builder_sec_inner_left img{height: auto;}
  .resume_builder_sec_inner_left{display: none;}
  .resume_builder_sec_inner_right {width: 80%; margin: 50px auto;}

  .resume_builder_sec_inner_right h6{font-size: 22px;}
  .resume_builder_sec_inner_right p{font-size: 18px;}



}




@media only screen and (max-width:767px) {
   
    .verifictaion_mod .modal-dialog{max-width: 100%;}
    .verifictaion_mod .modal-dialog{padding: 15px;}
    .verifictaion_mod h6{font-size: 22px; margin-bottom: 10px;}
    .css-1d1r5q-MuiFormHelperText-root.Mui-error {color: #d32f2f;text-align: left; font-size: 12px;}
    .resume_builder_sec_inner_left img { width: auto; object-fit: cover;  height: auto; }
    .resume_builder_sec_inner{grid-template-columns: 1fr;grid-gap: 0px;display: flex;flex-direction: column-reverse;height: auto;}
    .resume_builder_sec_inner_right { padding: 0px;border-radius: 10px;width: 100%;box-shadow: none;}
    .resume_builder_sec_inner_right h6{font-size: 25px;}
    .resume_builder_sec_inner_right p{font-size: 14px;}
    .resume_builder_sec{padding: 0px;}
    .upload_resume_btn label { height: 45px; width: 85%;font-size: 14px;}
    .upload_resume_btn .resume_link_btn{ height: 45px; width: 85%;font-size: 14px;}
    .enahce_btn_new .link_enhave{ height: 45px; width: 85%;font-size: 14px;}
    .resume_builder_sec_inner_left img{
      display: none;
    }
    .resume-builder-header-back header{
      margin-top: 40px;
    }
    







  
  }