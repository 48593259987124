.login_sec_new {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  gap: 10px;
}
.login_sec_new_left {
  position: relative;
}
.login_sec_new_left::before {
  content: "";
  background: #f0f9ff;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.login_sec_new_left img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}
.login_sec_new_left svg {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}
.login_sec_new_right {
  position: relative;
  width: 75%;
  margin: 0 auto 0 0;
}
.login_sec_new_right_inner {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  padding: 60px 60px;
  border-radius: 10px;
}

.inner_input_box {
  position: relative;
  margin-bottom: 10px;
}
.inner_input_box label {
  position: relative;
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: #455360;
  margin-bottom: 8px;
}
.inner_input_box input {
  position: relative;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  background: #f8f8f8;
  border-radius: 4px;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  border: none;
}

.inner_input_box input::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
  font-family: "Satoshi-Variable";
}
.inner_input_box input::-moz-placeholder {
  color: #bababa !important;
  opacity: 1 !important;
  font-family: "Satoshi-Variable";
}
.inner_input_box input:-ms-input-placeholder {
  color: #bababa !important;
  opacity: 1 !important;
  font-family: "Satoshi-Variable";
}
.inner_input_box input:-moz-placeholder {
  color: #bababa !important;
  opacity: 1 !important;
  font-family: "Satoshi-Variable";
}
.password_eye_sec {
  position: relative;
}
.eye_icn_slash {
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
.eye_icn_slash i {
  color: #000000;
  font-size: 22px;
  font-weight: 500 !important;
}
.frgt_pass_sec_new {
  position: relative;
  color: #1865c1;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  margin: 10px 0 0 0;
  width: 100%;
  display: block;
}
.login_btn_blue {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: contents;
}
.login_btn_blue button {
  position: relative;
  background: #1865c1;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 50px;
  max-width: 500px;
}
.login_btn_blue button:disabled {
  background-color: grey; /* Disabled state background color */
  color: white; /* Disabled state text color */
  /* Add other disabled state styling as needed */
}
.blue_btn_links {
  position: relative;
  background: #1865c1;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}
.or_text {
  position: relative;
  display: block;
  text-align: center;
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
}
.signup_google_sec {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 50px;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 30px;
  border: none;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}
.signup_google_sec img {
  width: 22px;
}

.frgt_pass_sec_new_main {
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  padding: 80px 50px;
  width: 515px;
  border-radius: 10px;
  margin: 0 auto;
}
.frgt_pass_sec_new_main_inner {
  position: relative;
}
.login_sec_new_right_inner h6 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
.frgt_pass_sec_new_main_inner h6 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
.login_sec_new_right_inner p {
  position: relative;
  color: #6a6a6a;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}
.frgt_pass_sec_new_main_inner p {
  position: relative;
  color: #6a6a6a;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}
.send_verfy_btn {
  position: relative;
  margin-top: 50px;
}

.verifictaion_mod .modal-dialog {
  max-width: 500px;
  background: #fff;
  box-shadow: 0px 4px 28.600000381469727px 0px #00000014;
  padding: 30px 40px 80px 40px;
  border-radius: 10px;
  top: 15%;
}
.verifictaion_mod .modal-content {
  border: none;
}
.verifictaion_mod h6 {
  position: relative;
  color: #2f2f2f;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
.enter_otp_box_sec {
  position: relative;
}
.enter_otp_box_sec label {
  position: relative;
  display: block;
  color: #455360;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}
.enter_otp_box_sec_inputs {
  position: relative;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin-bottom: 8px;
}
.enter_otp_box_sec_inputs input {
  position: relative;
  width: 100%;

  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border: none;
  border-radius: 5px;
}
.resend_btn_new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resend_otp_btn_new {
  color: #7b8389;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border: none;
  outline: none;
}
.resend_btn_new span {
  color: #7b8389;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}
.resrt_inner {
  padding: 40px 50px;
}
.bak_login_btn {
  position: relative;
  text-align: center;
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  display: block;
  background: transparent;
  border: none;
  outline: none;
}

.no-outline:focus {
  border: none;
}

.no-outline {
  background: transparent;
  border: none;
  outline: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login_sec_new {
    grid-template-columns: 1fr;
    margin: 50px auto;
  }
  .login_sec_new_left {
    display: none;
  }
  .login_sec_new_right {
    width: 80%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login_sec_new {
    grid-template-columns: 1fr;
    margin: 50px auto;
  }
  .login_sec_new_left {
    display: none;
  }
  .login_sec_new_right {
    width: 80%;
    margin: 0px auto;
  }
}

@media only screen and (max-width: 767px) {
  .login_sec_new {
    display: block;
  }
  .login_sec_new_left {
    display: none;
  }
  .login_sec_new_right {
    width: 100%;
  }
  .login_sec_new_right_inner {
    padding: 20px 25px !important;
  }

  .login_sec_new_right_inner h6 {
    font-size: 20px;
  }
  .login_sec_new_right_inner p {
    font-size: 13px;
  }
  .inner_input_box label {
    font-size: 14px;
  }
  .inner_input_box input {
    height: 40px;
    font-size: 13px;
  }
  .eye_icn_slash i {
    font-size: 14px;
  }
  .frgt_pass_sec_new {
    font-size: 11px;
  }
  .login_btn_blue button {
    font-size: 14px;
    height: 40px;
  }
  .frgt_pass_sec_new_main {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
  }
  .frgt_pass_sec_new_main_inner h6 {
    font-size: 20px;
  }
  .frgt_pass_sec_new_main_inner p {
    font-size: 13px;
  }
  .send_verfy_btn {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .forgot-passoword{
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.forgot-passoword {
  background: none;
  border: none;
  padding: 0;
  cursor: "pointer";
  color: #1865c1;
  font: inherit;
  font-size: 16px;
  font-weight: 400;
}

.disclaimer{
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: rgba(153, 153, 153, 1);
}

.disclaimer-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitle{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {

  .disclaimer{margin: 15px 0 !important;}
  .wrapper{box-shadow: 0 0.5rem 1rem 1rem #0000001a;}

}