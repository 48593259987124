.question_container {
    display: flex;
    flex-direction: column;
    margin-left: -150px;
    width: 800px;
    gap: 10px;
    padding: 16px;
  }
  
  .question_header {
    margin-top: 20px;
    /* font-size: 16px; */
    /* font-weight: bold; */
    /* padding-bottom: 8px; */
  }
  
  .code_editor {
    padding: 0px;
    background-color: #272822;
    border-radius: 6px;
    min-height: 200px;
    font-family: monospace;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .code_editor .code_mirror_sec {
    width: 200px !important;
  }

.asses_main_sec{position: relative;padding: 40px 20px 20px 20px;background: #F3F8FF;height: calc(100vh - 0vh);}
.container_main{box-shadow: 0px 2px 7px 0px #0000001f;background: #fff;border-radius: 10px;padding: 50px 60px;}
.container_main h4{position: relative;color: #000;font-size: 22px;font-weight: 700;margin-bottom: 25px !important;}
.section{position: relative;}
.sectionTitle{position: relative;color: #3D4852;font-size: 16px;font-weight: 600;margin-bottom: 10px !important;}
.section ul{display: block;position: relative;margin-bottom: 30px; list-style: disc !important;padding-left: 15px;}
.section ul li{display: block;margin-bottom: 20px;}
.section ul li{position: relative;margin-bottom: 5px !important;color: #212529;font-size: 14px;font-weight: 400;list-style: disc !important;}
.section ul li:before{content: '';position: absolute;width: 5px;height: 5px;background: #717171;border-radius: 50%;
  top: 7px;left: -15px;}
.buttonContainer{position: relative;box-shadow: 0px 2px 7px 0px #0000001f;background: #fff;border-radius: 10px;padding: 10px;width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 30px;}




@media only screen and (min-width: 1050px) and (max-width: 1200px) {
  .asses_main_sec{margin-top: 0px;}
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .asses_main_sec{margin: 0px;background: none;}
  .container_main{padding: 20px;}
  .interview_mscq_module{background: none;}
}