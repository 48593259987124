.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 14.18px;
}
.login_desc{
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin-top: 81px;
}
.login_title {
  font-size: 14px;
  font-weight: 400;
  margin-top: 31px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.DividerContainer{
    display: flex;
    align-items: center;
    width: 336px;
    justify-content: center;
    margin-bottom: 31px;
    span{
        color: rgba(214, 214, 214, 1);
        margin-left: 14px;
        margin-right: 14px;
    }

    hr{
        width: 80%;
        border: 1px solid rgba(214, 214, 214, 1)
    
    }
}

.google_button{
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);

}
