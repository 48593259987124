.root {
  display: "flex";
  height: "100vh";
}
.column {
  flex: "1";
  display: "flex";
  flex-direction: "column";
}
.fixedColumn {
    padding: 2px;
  background-color: "#f0f0f0";
  overflow: "hidden";
}
.scrollableColumn {
  overflow-y: auto;
  padding: 2px;
}

/* Custom style */
.resume_temp_cous_inner_left_body .accordion-button::after {
  background-image: url("../../../../assets/images/plus-icn.svg");
  background-color: #1865C1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.resume_temp_cous_inner_left_body .accordion-button:not(.collapsed)::after {
  background-image: url("../../../../assets/images/minus-icn.svg");
  background-color: #1865C1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}



.resume_temp_cous{position: relative;padding: 30px 0 20px 0;}
.resume_temp_cous_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 40px;}
.resume_temp_cous_inner_right_img{position: relative;}
.resume_temp_cous_inner_right_img img{width: 100%;object-fit: cover;margin-bottom: 30px;}
.resume_temp_cous_inner_right_btn{position: relative;display: flex;align-items: center;justify-content: flex-end;}
.resume_temp_cous_inner_right_btn button{position: relative;background: #1865C1;color: #fff;font-size: 18px;font-weight: 600;padding: 8px 32px;border-radius: 30px;border: none;}

.resume_temp_cous_inner_left{position: relative;}
.resume_temp_cous_inner_left_head{display: flex;position: relative;align-items: center;justify-content: space-between;width: 100%;margin-bottom: 20px;}
.resume_temp_cous_inner_left_head h6{position: relative;margin-bottom: 0px;line-height: inherit;}
.resume_temp_cous_inner_left_head h6 button {position: relative; display: flex;align-items: center; gap: 10px;
  color: #000000;font-size: 25px;font-weight: 700; border: none;background: none;}
.resume_temp_cous_inner_left_head h6 button  i{font-size: 20px;}

.acc_sec_new {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.acc_sec_new .accordion-item {
  border-radius: 10px;
  background: none;
  width: 100%;
  border: none;

}

.acc_sec_new .accordion-button{
  background: #fff;
  box-shadow: 0px 0px 10px 0px #0000001A;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 65px;
  text-align: left;
  padding: 0 25px;
  color: #000 !important;
  font-size: 19px;
  font-weight: 500 !important;
}
.acc_single .accordion-collapse {margin-top: 10px;}
.acc_single_body_sec{position: relative;background: #fff;box-shadow: 0px 0px 10px 0px #0000001A;border-radius: 10px;padding: 25px;}
.acc_single_body_sec_head{position: relative;}
.acc_single_body_sec_head h6{position: relative;font-size: 22px;font-weight: 700;margin-bottom: 15px;}
.acc_single_body_sec_body{position: relative;}
.acc_single_body_sec_body_upload{position: relative;display: flex;align-items: center;gap:15px}
.acc_singl_up_btn{position: relative;width: 100px;height: 100px;overflow: hidden;border-radius: 50%;flex: 0 0 100px;}
.acc_singl_up_btn .ant-upload-wrapper{width: 100%;height: 100%;background: #D9D9D980;box-shadow: none;position: relative;}